import React from "react";
import moment from "moment";
import PersonalIcon from "../../../../../../assets/images/personal-icon.svg";
import RoleIcon from "../../../../../../assets/images/role.svg";
import AvartaIcon from "../../../../../../assets/images/avatar.svg";
import TableActionDropdown from "../../../../../shared/tableActionDropdown/tableActionDropdown";
import Lozenge from "../../../../../shared/lozenges/lozenge";
import { ProgressBar } from "react-bootstrap";

import "./details.scss";
import getPercentColor from "../../../../../../utils/progress-color";
import { LineLoader } from "../../../../../shared";

const KeyResultDetails = ({ data, contributions = [], loading }) => {
  const actions = [
    // { id: 1, name: <span className="detail-action">Edit Key Result</span>, method: handleSearch },
    // { id: 1, name: <span>Delete Key Result</span>, method: handleSearch },
  ];
  return (
    <div className="view-details-container">
      <div className="kr-title">{data && data.description ? data.description : "-"}</div>
      <div className="kr-team-info">
        <img src={PersonalIcon} alt="team icon" />
        {data && data.teamName ? data.teamName : "-"}
      </div>
      <div className="progress-summary flex-and-space-between">
        <ProgressBar now={data?.progress} variant={getPercentColor(data?.progress)} />

        <span className="d-flex" style={{ alignItems: "center" }}>
          <span className="percent-count">{data && typeof data.progress === "number" ? data.progress : "-"}%</span>
          <TableActionDropdown data-testid="table-action-dropdown" actions={actions} />
        </span>
      </div>
      <div className="alignment-content">
        <div className="alignment">Alignment</div>
        <div className="features">{data && data.objective ? data.objective : "-"}</div>
        <div className="role">
          <img src={RoleIcon} alt="role" /> {data && data.teamName ? data.teamName : "-"}
        </div>
      </div>
      <div className="contributions-content">
        <div className="contributions-title">Contributions under this Key Result</div>
        {loading ? (
          <>
            <div className="loader-content w-100 mb-3">
              <div className="w-75">
                <LineLoader />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
              </div>
            </div>
            <div className="loader-content w-100">
              <div className="w-75">
                <LineLoader />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
              </div>
            </div>
          </>
        ) : contributions && contributions.length ? (
          contributions.map(contribution => (
            <div key={contribution?.id} className="contributing-key-result-item  d-flex justify-content-between align-items-center">
              <div>
                <div className="name">{contribution.description ? contribution.description : "-"}</div>
                <div className="key-results-date">Due Date: {contribution.dueDate ? moment(new Date(contribution.dueDate), "DD/MM/YYYY").format("L") : "-"}</div>
              </div>
              <div>
                <Lozenge data-testid="lozenge" variant={getPercentColor(contribution?.progress)} style={{ marginRight: "24px" }} content={`${contribution?.progress}%`} />
              </div>
            </div>
          ))
        ) : (
          <span className="no-data">No Contribution for this KeyResult</span>
        )}
      </div>

      <div className="owner-content">
        <div className="owner">Owner</div>
        <div className="name">
          {data && data.owner ? (
            <>
              <img src={data.owner.image ? data.owner.image : AvartaIcon} alt="Profile" /> {`${data?.owner?.firstName} ${data?.owner?.lastName}`}
            </>
          ) : (
            <>
              <img src={AvartaIcon} alt="avatar" /> -- --
            </>
          )}
        </div>
      </div>
      <div className="date-content">
        <div className="date-item">
          <div className="due-date">Due Date</div>
          <div className="date">{data.dueDate && moment(new Date(data.dueDate), "DD/MM/YYYY").format("L")}</div>
        </div>
        <div className="date-item">
          <div className="due-date">Last Update</div>
          <div className="date">{data.lastUpdatedDate ? moment(new Date(data.lastUpdatedDate), "DD/MM/YYYY").format("l") : moment(new Date(data.dateSubmitted), "DD/MM/YYYY").format("l")}</div>
        </div>
      </div>
    </div>
  );
};

export default KeyResultDetails;
