const getPercentColor = percent => {
  let color = "";
  if (percent === 0) {
    return "notStarted";
  } else if (percent > 1 && percent <= 44) {
    return "atRisk";
  } else if (percent >= 45 && percent <= 64) {
    return "behind";
  } else if (percent >= 65 && percent <= 99) {
    return "onTrack";
  } else if ((percent = 100)) {
    return "completed";
  }
  return color;
};

export default getPercentColor;
