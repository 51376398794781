import React, { useEffect } from "react";
import "./App.css";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import { Switch } from "react-router-dom";
import Layout from "./components/layout/layout";
import Registration from "./components/auth/registration/registration";
import Login from "./components/auth/login/login";
import ResetPassword from "./components/auth/resetPassword/resetPassword";
import ForgotPassword from "./components/auth/forgotPassword/forgotPassword";
import { PrivateRoute } from "./routes";
import { Urls } from "./utils/urls";
import { createBrowserHistory as history } from "history";
import Notifications from "./components/shared/notifications/notifications";

const isAuth = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    return false;
  }
  const expires = user.expires_at;
  return new Date().getTime() / 1000 < expires;
};

const App = () => {
  useEffect(() => {
    if (isAuth()) {
      // const user = JSON.parse(localStorage.getItem('user_profile'));
    }
  });

  return (
    <>
      <Notifications />
      <ErrorBoundary history={history()}>
        <Switch>
          <PrivateRoute exact path={Urls.ResetPassword} component={ResetPassword} isAuthenticated={isAuth} />
          <PrivateRoute exact path={Urls.ForgotPassword} component={ForgotPassword} isAuthenticated={isAuth} />
          <PrivateRoute path={Urls.Login} component={Login} isAuthenticated={isAuth} />
          <PrivateRoute path={Urls.Registration} component={Registration} isAuthenticated={isAuth} />
          <PrivateRoute path={Urls.Invite} component={Registration} isAuthenticated={isAuth} />
          <PrivateRoute path="/" component={Layout} isAuthenticated={isAuth} redirect={Urls.Login} />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default App;
