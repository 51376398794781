import { Get, Put } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const teamMembersType = {
  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",
  ASSIGN_TEAM_LEAD: "ASSIGN_TEAM_LEAD",
};

export const getTeamMembers = createAsyncAction(teamMembersType.GET_TEAM_MEMBERS, async (teamId, pageNo, pageSize) => {
  return await Get(`Team/members/?teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}`);
});

export const assignTeamLead = createAsyncAction(teamMembersType.ASSIGN_TEAM_LEAD, async (payload) => {
  return await Put(`Admin/teams/assignteamlead`, payload);
});
