import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Button from "../../../shared/button/Button";
import SearchBar from "../../../shared/searchBar/searchBar";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import CustomTable from "../../../shared/table/table";

// import "./viewTeams.scss";

const OkrReport = ({ okrInsights, getAllInsights, handleFilter, handleSelectCycle, selectedCycle, cycleList, okrLoading, pageNo, pageSize, handlePageClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const dropdownList = [
    { id: 1, display: "with Contributing KRs", name: "logged" },
    { id: 2, display: "with Not Started Contibutions", name: "unlogged" },
  ];

  const columnHeaders = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
    { id: 3, name: "Line Manager" },
    { id: 4, name: "Total Key Results" },
    { id: 5, name: "Contributing Key Results" },
    { id: 6, name: "Not Started Contributions" },
    { id: 7, name: "Last Updated" },
    { id: 8, name: "Status" },
  ];

  const getProgress = (data) => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  const arrangeTableData = (tableData) => {
    return (
      tableData &&
      tableData.map((value, index) => {
        return (
          <tr key={index + 1} className="reports-table">
            <td className="bordered">{`${value.firstName} ${value.lastName}`}</td>
            <td className="bordered">{value.jobTitle}</td>
            <td className="bordered">{value.manager}</td>
            <td className="bordered">{value?.userKeyResultStatus?.keyResultsCount}</td>
            {/* subtract not started KRs from total user KRs */}
            <td className="bordered">{`${startedSubtraction(value?.userKeyResultStatus?.totalKeyResult, value?.userKeyResultStatus?.notStarted)}/${
              value?.userKeyResultStatus?.totalKeyResult
            } ${getPercentage(startedSubtraction(value?.userKeyResultStatus?.totalKeyResult, value?.userKeyResultStatus?.notStarted), value?.userKeyResultStatus?.totalKeyResult)}%`}</td>
            <td className="bordered">{`${value?.userKeyResultStatus?.notStarted}/${value?.userKeyResultStatus?.totalKeyResult} ${getPercentage(
              value?.userKeyResultStatus?.notStarted,
              value?.userKeyResultStatus?.totalKeyResult
            )}%`}</td>
            <td className="bordered">{value.lastUpdated}</td>
            <td className="bordered">
              <span className="mr-3">
                {value.userKeyResultStatus?.length ? <ProgressBar>{getProgress(value.userKeyResultStatus)}</ProgressBar> : <ProgressBar variant="warning" now={0} key={0} className="mr-1" />}
              </span>
            </td>
          </tr>
        );
      })
    );
  };

  // search table
  const handleSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const search = (rows) => {
    const columns = ["jobTitle", "firstName", "lastName", "lineManager", "lastUpdated"];
    if (rows && rows !== []) {
      return rows.filter((row) =>
        columns.some((column) => {
          //   if (column === "teamLead") {
          //     let teamLeadData = { ...row[column] };
          //     return teamLeadData.leadName.toString().toLowerCase().indexOf(searchTerm) > -1;
          //   } else {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
          //   }
        })
      );
    }
  };

  const handleSelectRow = (values) => {
    setSelectedRows(values);
  };

  const filterTableData = () => {
    if (selectedRows && selectedRows.length > 1) {
      //  fetch all insights
      getAllInsights();
    } else {
      // fetch only selected insight
      handleFilter(selectedRows, "okr_page");
    }
  };

  const getPercentage = (a, b) => {
    if (a <= 0 || b <= 0) {
      return 0;
    } else {
      return (a / b) * 100;
    }
  };

  const startedSubtraction = (total, notStarted) => {
    if (total <= 0) {
      return 0;
    } else {
      return total - notStarted;
    }
  };

  return (
    <div>
      <div className="col insights-container">
        <div className="row pb-3 mb-4 border-below">
          <div className="row col-6">
            <div className="col-4">
              <div className="pb-3 logged">No of Team Members</div>
              <div className="pb-2 d-flex insight-info">
                <span>{okrInsights?.okrReports?.items?.length}</span>
              </div>
            </div>
            <div className="col-4">
              <div className="pb-3 logged">Contributing Key Results</div>
              <div className="pb-2 insight-info">
                <span>{getPercentage(okrInsights?.noOfKeyresultStarted, okrInsights?.totalNoOfKeyresult)}%</span>
                <span>
                  {" "}
                  &nbsp;({okrInsights?.noOfKeyresultStarted}/{okrInsights?.totalNoOfKeyresult})
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="pb-3 logged">Not Started Contributions</div>
              <div className="pb-2 insight-info">
                <span>{getPercentage(okrInsights?.noOfKeyresultNotStarted, okrInsights?.totalNoOfKeyresult)}%</span>
                <span>
                  {" "}
                  &nbsp;({okrInsights?.noOfKeyresultNotStarted}/{okrInsights?.totalNoOfKeyresult})
                </span>
              </div>
            </div>
          </div>
          <div className="col-2 pr-0">
            <div>
              <PMSSelect dataSource={cycleList} closeOnActionClick={true} displayExpr="description" valueExpr="cycleId" onChange={handleSelectCycle} menuWidth="272px" value={selectedCycle} />
            </div>
          </div>
        </div>
      </div>
      <div className="view-teams-container">
        <div className="row mb-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="row mb-3">
                  <div className="col-4" style={{ paddingRight: "0" }}>
                    <SearchBar placeholder="Search for a user" handleSearch={handleSearch} searchTerm={searchTerm} />
                  </div>

                  <div className="col-3" style={{ padding: "0" }}>
                    <PMSSelect
                      placeholder="Filter"
                      selectMultiple={true}
                      useSelectAll={true}
                      dataSource={dropdownList}
                      onActionButtonClick={() => filterTableData(okrInsights?.okrReports?.items)}
                      closeOnActionClick={true}
                      displayExpr="display"
                      valueExpr="name"
                      onChange={handleSelectRow}
                      menuWidth="220px"
                      selectAllText="All Team Members"
                      useCount
                      value={[]}
                    />
                  </div>

                  <div className="col-5" style={{ padding: "0" }}></div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 tableTopBtn-div mb-3">
                <Button size="sm" color="blue" handleClick={() => {}} className="pull-right">
                  Export
                </Button>
              </div>
            </div>

            <CustomTable
              arrangedTableData={arrangeTableData(search(okrInsights?.okrReports?.items))}
              tableHeads={columnHeaders}
              isLoading={okrLoading}
              tableData={okrInsights?.okrReports?.items}
              searchTableData={search(okrInsights?.okrReports?.items)}
              bordered
              currentPage={pageNo}
              perPage={pageSize}
              totalItems={okrInsights?.okrReports?.totalCount}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OkrReport;
