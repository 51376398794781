import React from "react";
// import XLSX from "xlsx";

import ImportExcel from "../../../../shared/importExcel/importExcel";
import "./fromMultipleTeams.scss";

const FromMultipleTeams = props => {
  const { onFileUpload } = props;
  const excelTemplate = [{ "User Email": "", "Primary Team": "" }];

  const handleFileUpload = data => {
    console.log(data);
    if (onFileUpload) {
      onFileUpload(data);
    }
  };

  return (
    <div className="from-multiple-teams-container" data-testid="from-multiple-teams">
      <ImportExcel onFileUploadChanged={handleFileUpload} excelTemplate={excelTemplate} templateName="clarit-users-invite-template" />
    </div>
  );
};

export default FromMultipleTeams;
