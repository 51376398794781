import React from "react";
import AlertModal from "../../shared/alertModal/alertModal";

const DeleteModal = ({ modalShow, setModalShow, handleDeleteObjectives, onConfirm, idForAction, title, loading }) => {
  return (
    <AlertModal
      openModal={modalShow}
      closeModal={() => setModalShow(false)}
      onConfirm={handleDeleteObjectives ? () => handleDeleteObjectives(idForAction) : onConfirm}
      textContent={`Are you sure you want to delete this ${title ? title : "key result"}? This action is not reversible.`}
      actionText="Delete"
      title={`Delete ${title ? title : "Key Result"}`}
      loading={loading}
    />
  );
};

export default DeleteModal;
