import React from "react";
import "./userTooltipDetail.scss";

const UserTooltipDetail = ({ name, title }) => (
  <div className="user-tooltip-detail-container">
    <p className="lead-name">{name}</p>
    <p className="lead-title">{title}</p>
  </div>
);

export default UserTooltipDetail;
