import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../shared";
import "./alertModal.scss";

const AlertModal = ({ openModal, closeModal, actionText, textContent, title, onConfirm, loading, actionColor }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    openModal ? handleShow() : handleClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal show={show} onHide={closeModal} className="alert-modal-container" centered>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>{textContent}</Modal.Body>
        <Modal.Footer>
          <div>
            <Button ghost={true} color="normal" handleClick={closeModal} disabled={false} className="mr-2">
              Cancel
            </Button>
            <Button color={actionColor || "red"} handleClick={onConfirm} loading={loading}>
              {actionText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AlertModal;
