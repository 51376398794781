import React, { useEffect, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../assets/images/avatar-user.svg";
// import PMSSelect from "../shared/selectInput/pmsSelect/pmsSelect";
import Button from "../shared/button/Button";
import PasswordModal from "./changePassword/passwordModal";
import { updateUserProfile } from "../../redux/actions/profile/profileActions";
import { successMessage, errorMessage } from "../../redux/actions/notification/notification";
import "./profile.scss";
import { userDetails, setUserDetails } from "../../apiServices/user.service";

const Profile = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const select = useSelector(({ profile }) => {
    return {
      getProfilePayload: profile && profile.getUserProfilePayload,
      getProfileLoading: profile && profile.getUserProfileLoading,
      getProfileError: profile && profile.getUserProfileError,
      updateUserProfileLoading: profile && profile.updateUserProfileLoading,
      updateUserProfilePayload: profile && profile.updateUserProfilePayload,
      updateUserProfileError: profile && profile.updateUserProfileError,
    };
  });
  console.log({ select });
  // const { getProfileError, getProfileLoading, getProfilePayload, updateUserProfileError, updateUserProfileLoading, updateUserProfilePayload } = select;
  // const timezones = [{ id: 1, name: "UTC + 1:00 (West Central Africa)" }];
  const { updateUserProfileError, updateUserProfileLoading, updateUserProfilePayload } = select;
  const previewImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    let { size } = file;

    if (size > 3 * 1024 * 1024) {
      return "File should not be more than 3mb";
    }
    reader.addEventListener(
      "load",
      () => {
        console.log({ file: reader.result });
        setProfileImage(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const submit = (event, value) => {
    setPayload(value);
    dispatch(updateUserProfile({ ...value, jobTitle: value?.workTitle }));
  };

  // const handleInputChange = () => {};

  // useEffect(() => {
  //   dispatch(getUserProfile());
  // }, []);
  useEffect(() => {
    if (updateUserProfilePayload && !updateUserProfileLoading) {
      dispatch(successMessage("Profile update was successful"));
      setUserDetails({ ...userDetails(), ...payload });
    } else if (updateUserProfileError && !updateUserProfileLoading) {
      dispatch(dispatch(errorMessage(updateUserProfileError?.data?.message)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserProfileLoading]);
  return (
    <section className="row profile-container">
      <div className="col">
        <div className="content-header">My Profile</div>
        <div className="profile-picture">Profile Picture</div>
        <div className="profile-detail">
          <img src={profileImage || Avatar} alt="user profile" className="profile-image" />
          <div className="photo-detail">
            <label htmlFor="imageUpload" data-testid="image-upload" className="upload-photo">
              {profileImage ? "Upload new Photo" : "Upload Photo"}
            </label>
            {profileImage && (
              <label className="cancel-photo" onClick={() => setProfileImage(null)}>
                Remove
              </label>
            )}
              
            <input type="file" id="imageUpload" data-testid="file-image" accept="image/*" onChange={(e) => previewImage(e)} />
                        
            <div className="phote-desc">You can upload jpeg or png image files. Maximum size of 3mb.</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="detail-update">
              <div className="personal-detail">Personal Details</div>
              <div>
                <Button size="sm" outline className="update-btn" loading={updateUserProfileLoading} handleClick={() => form.submit()}>
                  Update
                </Button>
              </div>
            </div>
            <AvForm onValidSubmit={submit} ref={(c) => setForm(c)} model={userDetails()}>
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="firstName" className="mt-0">
                        First Name
                      </label>
                      <AvField
                        autoComplete="off"
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter First Name"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter First Name",
                          },
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="lastName" className="mt-0">
                        Last Name
                      </label>
                      <AvField
                        autoComplete="off"
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter Last Name"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Last Name",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="workTitle">Work Title</label>
                      <AvField
                        autoComplete="off"
                        type="text"
                        name="workTitle"
                        id="workTitle"
                        placeholder="Enter Work Title"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Work Title",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="teamName">Primary Team</label>
                      <AvField
                        autoComplete="off"
                        type="text"
                        name="teamName"
                        id="teamName"
                        placeholder="Enter Primary Team"
                        disabled={true}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter Primary Team",
                          },
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="teamLeader">Team Leader</label>
                      <AvField autoComplete="off" type="text" name="manager" id="teamLeader" placeholder="Enter Team Leader" disabled={true} />
                    </div>
                  </div>
                  {/* <div>
                    <div>
                      <label>Other Team(s):</label>
                    </div>
                    <div className="other-team">
                      <span>Advancly</span>
                      <span>DTx</span>
                      <span>Product Organisation</span>
                      <span>Research & Development</span>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-6">
                      <label htmlFor="timeZone" className="time-zone">
                        Timezone
                      </label>
                      <PMSSelect
                        arialLabel="primary-team"
                        id="timeZone"
                        testId="primary-team"
                        name="timeZone"
                        placeholder="Select your timeZone"
                        dataSource={timezones}
                        onChange={handleInputChange}
                        controlHeight="48px"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="account-info">Account Info</div>
              <div className="row">
                <div className="col-8">
                  <label htmlFor="email">Email Address</label>
                  <AvField
                    autoComplete="off"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter Email Address",
                      },
                    }}
                    disabled={true}
                  />
                </div>
              </div>
            </AvForm>
            <div>
              <Button size="sm" outline className="change-password" handleClick={() => setShowModal(true)}>
                Change Password
              </Button>
            </div>
          </div>
        </div>
      </div>
      <PasswordModal openModal={showModal} closeModal={() => setShowModal(false)} />
    </section>
  );
};

export default Profile;
