import React, { Fragment } from "react";
import { components } from "react-select";
import "./spIndicator.scss";

const SpIndicator = props => {
  return (
    <Fragment>
      <components.DropdownIndicator {...props}>
        <span>{props.selectProps.menuIsOpen ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>}</span>
      </components.DropdownIndicator>
    </Fragment>
  );
};

export default SpIndicator;
