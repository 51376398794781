import React from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import Comment from "./comment";

const CommentModal = ({ openModal, closeModal }) => {
  return (
    <PMSModal
      title="Manager’s Comment"
      openModal={openModal}
      handlePrimaryButtonClick={() => {}}
      processing={false}
      primaryButtonText="Update"
      containerId="user-editor"
      closeModal={closeModal}
      contentClassName="comment-content"
      showPrimaryButton={false}
      showSecondaryButton={false}
      useHeaderBorder={false}
      disablePrimaryButton={false}
      closeOnOutsideClick={false}
    >
      <Comment />
    </PMSModal>
  );
};

export default CommentModal;
