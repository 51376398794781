import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Button from "../../../shared/button/Button";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import LinkIcon from "../../../../assets/images/link.svg";
import CloseIcon from "../../../../assets/images/close.svg";
import { postUserKeyResult } from "../../../../redux/actions/keyResult/keyResultActions";

import "./editForm.scss";

const EditKeyResultForm = ({ setShowAlignKeyResultModal, setModalShow, setAlignment, alignment, setFormValues, formValues }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [reviewCycleId, setReviewCycle] = useState("");
  const [validForm, setValidForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [tracking, setTracking] = useState(null);

  const select = useSelector(({ keyResults }) => {
    return {
      payload: keyResults && keyResults.postUserKeyResultPayload,
      isLoading: keyResults && keyResults.postUserKeyResultLoading,
      errorResponse: keyResults && keyResults.postUserKeyResultError,
    };
  });

  const { payload, isLoading } = select;

  const dropdownList = [
    { id: 1, name: "2020 Cycle 1" },
    { id: 2, name: "2020 Cycle 2" },
  ];
  useEffect(() => {
    if (isSubmitted && payload) {
      form.reset();
      setModalShow(false);
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const submit = (event, value) => {
    setIsSubmitted(true);
    dispatch(postUserKeyResult(value));
  };

  const onSave = (event, errors, values) => {
    console.log({ values });
    setFormValues(values);
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  console.log({ formValues });
  return (
    <AvForm className="goal-form-content" data-testid="submit" model={formValues} onValidSubmit={submit} onSubmit={onSave} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="yourGoal">What is your goal?</label>
          <AvField
            autoComplete="off"
            type="text"
            name="Keyresult"
            className="form-field"
            placeholder="Enter your goal here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter goal",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="reviewCycle">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            id="reviewCycle"
            useSelectAll={true}
            dataSource={dropdownList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="name"
            valueExpr="id"
            onChange={(e) => setReviewCycle(e.label)}
            selectAllText="All Types"
            useCount
            controlHeight="48px"
            value={{ id: 1, name: "2020 Cycle 1" }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="dueDate">Due Date</label>
          <AvField
            autoComplete="off"
            type="date"
            name="dueDate"
            className="form-field"
            placeholder="Select date"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter due date",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="owner">Owner</label>
          <AvField
            autoComplete="off"
            type="text"
            className="form-field"
            name="owner"
            value="Elvis daz"
            disabled={true}
            placeholder="Enter the owner"
            validate={{
              required: {
                value: true,
                errorMessage: "Please the owner",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="type">Type</label>
          <AvField
            autoComplete="off"
            type="text"
            name="objKeyresultType"
            className="form-field"
            placeholder="Enter the type"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter type",
              },
            }}
          />
        </div>
      </div>
      <div>
        <div className="alignment">Alignment</div>
        <div>
          {alignment && (
            <>
              <div className="alignment-title">
                {alignment.Keyresult}
                <img src={CloseIcon} alt="close icon" onClick={() => setAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> {alignment.Team.TeamName}
              </div>
            </>
          )}
        </div>
        {!alignment && (
          <div
            className="align-goal"
            onClick={() => {
              // setFormValues(form);
              form.submit();
              setModalShow(false);
              setShowAlignKeyResultModal(true);
            }}
          >
            Add an Alignment
          </div>
        )}
      </div>

      <div>
        <div className="progress-track">Progress Tracking</div>
        <AvRadioGroup inline className="mb-0" data-testid="progressTrack" name="trackingType" label="">
          <AvRadio label="Percentage (%)" value="PERCENTAGE" className="mb-0" />
          <AvRadio label="Key Performance Index (KPI)" value="KPI" className="mb-0" />
        </AvRadioGroup>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="start">Start</label>
          <AvField
            autoComplete="off"
            type="number"
            name="startGoal"
            className="form-field"
            placeholder="Enter your start"
            validate={{
              required: {
                value: true,
                errorMessage: "Please your start",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="Goal">Goal</label>
          <AvField
            autoComplete="off"
            type="number"
            className="form-field"
            name="targetGoal"
            placeholder="Enter your goal"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your goal",
              },
            }}
          />
        </div>
      </div>

      <hr className="border-btm" />
      <div className="row btn-goal-content">
        <div className="col-6">
          <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={() => setModalShow(false)}>
            Cancel
          </Button>
        </div>
        <div className="col-6">
          <Button size="sm" disabled={reviewCycleId === "" || !validForm} color="blue" className="btn-goal" handleClick={() => form.submit()}>
            Save Changes
          </Button>
        </div>
      </div>
    </AvForm>
  );
};

export default EditKeyResultForm;
