import { Get, Post } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { COMPANY_OBJECTIVES_APIS } from "../../../apiServices/endPoints";

export const objectivesActionTypes = {
  GET_OVERVIEW_OBJECTIVES: "[COMPANY - OBJECTIVES] GET_OVERVIEW_OBJECTIVES",
  GET_PUBLISHED_OBJECTIVES: "[COMPANY - OBJECTIVES] GET_PUBLISHED_OBJECTIVES",
  GET_DRAFTED_OBJECTIVES: "[COMPANY - OBJECTIVES] GET_DRAFTED_OBJECTIVES",
  GET_CYCLE_OPTIONS: "[COMPANY - OBJECTIVES] GET_CYCLE_OPTIONS",
  GET_ALIGNED_TEAMS_OBJECTIVES: "[COMPANY - OBJECTIVES] GET_ALIGNED_TEAMS_OBJECTIVES",
  GET_ALIGNED_TEAMS_OPTIONS: "[COMPANY - OBJECTIVES] GET_ALIGNED_TEAMS_OPTIONS",
  GET_OBJECTIVE_KEY_RESULTS: "[COMPANY - OBJECTIVES] GET_OBJECTIVE_KEY_RESULTS",
  GET_KEY_RESULT_CONTRIBUTIONS: "[COMPANY - OBJECTIVES] GET_KEY_RESULT_CONTRIBUTIONS",
  SAVE_OBJECTIVE: "[COMPANY - OBJECTIVES] SAVE_OBJECTIVE",
  GET_OBJECTIVE_SUMMARY: "[COMPANY - OBJECTIVES] GET_OBJECTIVE_SUMMARY",
};

export const LoadOverviewObjectives = createAsyncAction(objectivesActionTypes.GET_OVERVIEW_OBJECTIVES, async () => {
  return await Get(COMPANY_OBJECTIVES_APIS.overview);
});

export const LoadPublishedObjectives = createAsyncAction(objectivesActionTypes.GET_PUBLISHED_OBJECTIVES, async ({ cycleId, searchTerm }) => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.getObjectives}?isPublished=true${cycleId ? "&reviewCycleId=" + cycleId : ""}${searchTerm ? "&objective=" + searchTerm : ""}`);
});

export const LoadDraftedObjectives = createAsyncAction(objectivesActionTypes.GET_DRAFTED_OBJECTIVES, async () => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.getObjectives}?isPublished=false`);
});

export const LoadCycleOptions = createAsyncAction(objectivesActionTypes.GET_CYCLE_OPTIONS, async () => {
  return await Get(COMPANY_OBJECTIVES_APIS.cycleOptions);
});

export const LoadAlignedTeamsOptions = createAsyncAction(objectivesActionTypes.GET_ALIGNED_TEAMS_OPTIONS, async objId => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.alignedTeamsOptions}/${objId}`);
  // return await Get(`${COMPANY_TEAMS_APIS.getAll}`);
});

export const LoadAlignedTeamsObjectives = createAsyncAction(objectivesActionTypes.GET_ALIGNED_TEAMS_OBJECTIVES, async ({ objId, teamId, searchTerm, pageNumber, pageSize }) => {
  return await Get(
    `${COMPANY_OBJECTIVES_APIS.alignedTeamsObj}?objectiveId=${objId}${teamId ? "&teamId=" + teamId : ""}${searchTerm ? "&objective=" + searchTerm : ""}${
      pageNumber ? "&pageNumber=" + pageNumber : ""
    }${pageSize ? "&pageSize=" + pageSize : ""}`
  );
});

export const LoadObjectiveKeyResults = createAsyncAction(objectivesActionTypes.GET_OBJECTIVE_KEY_RESULTS, async ({ objId }) => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.alignedKeyResults}?objectiveId=${objId}`);
});

export const LoadKeyResultContributions = createAsyncAction(objectivesActionTypes.GET_KEY_RESULT_CONTRIBUTIONS, async ({ krId }) => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.objKeyresults}/${krId}/contributingkeyresults`);
});

export const LoadObjectiveSummary = createAsyncAction(objectivesActionTypes.GET_OBJECTIVE_SUMMARY, async ({ objId }) => {
  return await Get(`${COMPANY_OBJECTIVES_APIS.overview}/?objectiveId=${objId}`);
});

export const SaveObjective = createAsyncAction(objectivesActionTypes.SAVE_OBJECTIVE, async data => {
  return await Post(COMPANY_OBJECTIVES_APIS.orgObjective, data);
});
