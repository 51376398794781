import React, { useState, useEffect } from "react";

const PMSCheckbox = props => {
  const { checkbox, checked, containerClassName, label, labelClassName, labelStyle, name, onChange, ref, right, value, size, ...rest } = props;
  const [check, setCheck] = useState(checked);

  const toggle = e => {
    e.preventDefault();
    setCheck(!check);
    onChange && onChange({ target: { value: !check, name: name ? name : undefined } });
  };

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <span className={`d-flex align-items-center ${containerClassName}`} onClick={toggle} data-testid="checkboxContainer">
      {(right && label && (
        <label htmlFor={name} className={labelClassName} style={labelStyle} data-testid="labelLeft">
          {label}
        </label>
      )) ||
        null}
      {checkbox || (
        <span style={{ marginLeft: right ? 11 : "", marginRight: !right ? 11 : "", cursor: "pointer" }}>
          {check ? (
            <i className="ri-checkbox-fill" style={{ fontSize: size }} data-testid="checkedIcon"></i>
          ) : (
            <i className="ri-checkbox-blank-line" style={{ fontSize: size }} data-testid="uncheckedIcon"></i>
          )}
          <input data-testid="checkboxInputId" ref={ref} type="checkbox" name={name} checked={check} value={value} onChange={toggle} hidden {...rest} />
        </span>
      )}
      {(!right && label && (
        <label htmlFor={name} className={labelClassName} style={labelStyle} data-testid="labelRight">
          {label}
        </label>
      )) ||
        null}
    </span>
  );
};
PMSCheckbox.defaultProps = {
  checked: false,
  right: false,
  name: "",
  className: "",
  labelStyle: {},
  labelClassName: "",
  containerClassName: "",
  value: "",
  onChange: null,
  size: 18,
};
export default PMSCheckbox;
