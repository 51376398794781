import React, { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import SideBar from "./sideBar/sideBar";
import RouteLists from "../../routes/route-list";
import MainTop from "./mainNavTop/mainNavTop";
import "./layout.scss";
import { Urls } from "../../utils/urls";
import { userDetails } from "../../apiServices/user.service";

const Layout = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (!userDetails()) {
      history.push(Urls.Login);
    }
  });
  return (
    <div className="container-fluid">
      <div className="row m-margin">
        <div className="col-auto p-0 sidebar-fixed">
          <SideBar />
        </div>
        <div className="col routes">
          <MainTop />
          <RouteLists baseUrl={url} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
