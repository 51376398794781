import React from "react";
import PersonalIcon from "../../../../../assets/images/personal-icon.svg";
import CompanyIcon from "../../../../../assets/images/company.svg";
import AvartaIcon from "../../../../../assets/images/avatar.svg";
import ArrowRight from "../../../../../assets/images/arrow-greater-than-sign.svg";
import { ProgressBar } from "react-bootstrap";
import { getPercentColor } from "../../../../../utils/helpers";
import TableActionDropdown from "../../../../shared/tableActionDropdown/tableActionDropdown";
import Lozenge from "../../../../shared/lozenges/lozenge";

import "./objectivesDetails.scss";
import { LineLoader } from "../../../../shared";

const ObjectivesDetails = ({
  details,
  setModalShow,
  setModalEditShow,
  alignedKeyResultsData,
  levelTwoLoading,
  navigateToViewTwo,
  getContributionsData,
  setIndividualKeyResultData,
  setModalDeleteShow,
  handleSpecificLevelDelete,
  setDataToEdit,
}) => {
  const actions = [
    {
      id: 1,
      name: <span className="detail-action">Edit Key Result</span>,
      method: () => {
        setModalEditShow(true);
        setModalShow(false);
        setDataToEdit(details);
      },
    },
    {
      id: 2,
      name: <span>Delete Key Result</span>,
      method: () => {
        setModalShow(false);
        setModalDeleteShow(true);
        handleSpecificLevelDelete(details.id, 1);
      },
    },
  ];

  return (
    <div className="goal-view-content objectives-details">
      <div className="goal-title">{details?.objectives}</div>
      <div className="personal">
        <img src={PersonalIcon} alt="personal icon" />
        {details?.team?.teamName}
      </div>
      <div className="approved-full flex-and-space-between">
        <ProgressBar now={75} variant={getPercentColor(75)} data-testid="progress-bar" />

        <span className="d-flex">
          <span className="percent-count">{details?.progress}%</span>
          <TableActionDropdown data-testid="table-action-dropdown" actions={actions} />
        </span>
      </div>
      <div className="alignment-content">
        <div className="alignment">Alignment</div>
        <div className="features">{details?.parentObjective?.description}</div>
        <div className="role">
          <img src={CompanyIcon} alt="role" /> Company
        </div>
      </div>

      <div className="keyresults-content">
        <div className="key-results">Key Results</div>
        {levelTwoLoading && <LineLoader lines={6} />}
        {alignedKeyResultsData &&
          alignedKeyResultsData?.map((keyResultsData, index) => {
            return (
              <div className="key-results-content">
                <div>
                  <div>{keyResultsData?.description}</div>
                  <div className="key-results-date">Due Date: {keyResultsData?.dueDate}</div>
                </div>
                <div>
                  <Lozenge className="sp-lozenge sp-lozenge--success" style={{ marginRight: "22px" }} content={keyResultsData?.progress} />
                  <span
                    onClick={() => {
                      getContributionsData(keyResultsData?.id);
                      setIndividualKeyResultData(keyResultsData);
                      // setModalShow(false);
                      navigateToViewTwo(true);
                    }}
                  >
                    <img src={ArrowRight} className="arrow-down" alt="arrow right icon" />
                  </span>
                </div>
              </div>
            );
          })}
      </div>

      <div className="owner-content">
        <div className="owner">Owner</div>
        <div className="name">
          <img src={AvartaIcon} alt="avatar" /> {details?.owner?.firstName} {details?.owner?.lastName}
        </div>
      </div>
      <div className="date-content">
        <div className="date-item">
          <div className="due-date">Due Date</div>
          <div className="date">{details?.dueDate}</div>
        </div>
        <div className="date-item">
          <div className="due-date">Last Update</div>
          <div className="date">{details?.lastUpdatedDate}</div>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesDetails;
