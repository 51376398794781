import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation/lib";
import { PMSSelect, PMSTooltip } from "../../../../shared";
import "./fromSingleTeam.scss";
import SVGS from "../../../../../utils/svgs";

const FromSingleTeam = ({ handleChange, formData, teamsOptions, isLoadingTeamsOptions }) => (
  <div className="single-user-container">
    <AvForm className="comment-form">
      <div className="row">
        <div className="col-12">
          <p>Invite users within the same team to the platform</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2">
          <label htmlFor="email" className="form-label">
            Email Addresses
          </label>
          <AvField
            autoComplete="off"
            type="textarea"
            name="email"
            value={formData.email}
            placeholder="Enter emails seperated by space or commas"
            onChange={handleChange}
            validate={{
              required: {
                value: true,
                errorMessage: "Please Enter emails seperated by space or commas",
              },
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <PMSTooltip
            content={
              <p className="mb-0">
                This is the primary team that <br /> the user reports to.
              </p>
            }
            direction="right"
            width={171}
          >
            <label htmlFor="teamId" className="form-label mr-2">
              Primary Team
            </label>
            {SVGS.info()}
          </PMSTooltip>
          <PMSSelect
            arialLabel="primary-team"
            name="teamId"
            id="teamId"
            placeholder="Select or Search in the List"
            dataSource={teamsOptions}
            isLoading={isLoadingTeamsOptions}
            displayExpr="teamName"
            valueExpr="teamId"
            onOptionSelected={handleChange}
            controlHeight="48px"
            value={formData.teamId}
          />
        </div>
      </div>
    </AvForm>
  </div>
);

export default FromSingleTeam;
