import React, { useEffect, useState } from "react";

import { PMSSelect } from "../../shared";

import moment from "moment";

import "./insights.scss";
import CustomTimeLineModal from "../../teams/insights/customTimeLineModal/customTimeLineModal";
import OKRReportTable from "../okrReportTable/okrReportTable";
import OneOnOneReportTable from "../oneOnOneReportTable/oneOnOneReportTable";

import { useDispatch, useSelector } from "react-redux";
import { LoadOkrPeople, LoadOkrTeams, LoadOneOnOneStaff, LoadTeamsCustomTimelineSessions, LoadTeamsOptions, LoadTeamsSessions } from "../../../redux/actions/company";

import TopSection from "./sections/topSection";
import { useHistory } from "react-router";
import ChartSection from "./sections/chartSection";

const CompanyInsights = () => {
  const dispatch = useDispatch();
  const {
    isLoadingTeamsSessions,
    teamsSessions,
    isLoadingTeamsSessionsCustomTimeline,
    teamsSessionsCustomTimeline,
    oneOnOneStaff,
    isLoadingOneOnOneStaff,
    okrPeople,
    isLoadingOkrPeople,
    okrTeams,
    isLoadingOkrTeams,
    teamsOptions,
    isLoadingTeamsOptions,
    timelineOptions,
    isLoadingTimelineOptions,
    okrCycleOptions,
    isLoadingOkrCycleOptions,
  } = useSelector(({ companyInsights }) => companyInsights);
  const history = useHistory();
  const [selectedReport, setSelectedReport] = useState(history?.location?.state?.report || "oneonone");
  const [selectedOneononeTimeline, setSelectedOneononeTimeline] = useState(1);
  const [selectedOkrCycle, setSelectedOkrCycle] = useState(1);
  const [timeLineModal, setTimeLineModal] = useState(false);
  const [customTimelineValue, setCustomTimeLineValue] = useState(null);
  const [selectedTableOption, setSelectedTableOption] = useState(history?.location?.state?.table || "people");
  const [duration, setDuration] = useState(null);
  const [filterChartValue, setFilterChartValue] = useState("logged");
  const [staffFilter, setStaffFilter] = useState("all");
  const [tableOptions, setTableOptions] = useState([]);

  useEffect(() => {
    dispatch(LoadTeamsSessions(selectedOneononeTimeline));
    dispatch(LoadTeamsOptions());
    setTableOptions([
      { id: "people", description: "People" },
      { id: "team", description: "Team" },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customTimelineValue && duration && filterChartValue) {
      dispatch(LoadTeamsCustomTimelineSessions(duration, filterChartValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTimelineValue, duration, filterChartValue]);

  useEffect(() => {
    if (selectedTableOption === "people") {
      dispatch(LoadOkrPeople({ cycleOption: selectedOkrCycle }));
    } else {
      dispatch(LoadOkrTeams({ cycleOption: selectedOkrCycle }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOkrCycle, selectedTableOption]);

  const handleOneononeTimelineSelect = evt => {
    if (evt.label === "Custom Timeline") {
      setTimeLineModal(true);
      // setSelectedOneononeTimeline(undefined);
    } else {
      setSelectedOneononeTimeline(evt.value);
      setCustomTimeLineValue(undefined);
      setDuration(null);
    }
  };

  const handleOkrCycleSelect = evt => {
    setSelectedOkrCycle(evt.value);
  };

  const handleTableOptionSelect = evt => {
    setSelectedTableOption(evt.value);
  };

  const handleSelectReport = evt => {
    setSelectedReport(evt.value);
    if (customTimelineValue) {
      setCustomTimeLineValue(null);
    }
  };

  const onDurationSelected = val => {
    let startDate = moment(val.startDate, "YYYY-MM-DD").format("ll");
    let endDate = moment(val.endDate, "YYYY-MM-DD").format("ll");

    let dur = `${startDate} - ${endDate}`;
    setDuration({ startDate, endDate });
    setCustomTimeLineValue(dur);
    setSelectedOneononeTimeline(undefined);
  };

  const handleFilterChart = evt => {
    setFilterChartValue(evt.value);
  };

  const handleOneOnOneStaff = evt => {
    if (evt.length === teamsOptions.length) {
      setStaffFilter("all");
    } else {
      setStaffFilter(evt.map(v => v.value));
    }
  };

  useEffect(() => {
    console.log(staffFilter);
    LoadOneOnOneStaff({ filterBy: staffFilter });
  }, [staffFilter]);

  const filterChartOptions = [
    { id: "logged", description: "Logged" },
    { id: "unlogged", description: "Unlogged" },
  ];

  const reportList = [
    { id: "oneonone", description: "1:1 Report" },
    { id: "okr", description: "OKR Report" },
  ];

  return (
    <div className="company-insights-container">
      <CustomTimeLineModal openModal={timeLineModal} closeModal={() => setTimeLineModal(false)} setCustomTimeValue={onDurationSelected} submitValue={null} />
      <div className="row">
        <div className="col">
          <div className="row pb-3 sub-items">
            <div className="col-auto">
              <div className="search-contain">
                <PMSSelect dataSource={reportList} closeOnActionClick={true} displayExpr="description" valueExpr="id" onChange={handleSelectReport} menuWidth="100%" value={selectedReport} opened />
              </div>
            </div>
          </div>
          <TopSection
            selectedReport={selectedReport}
            teamsSessions={teamsSessions}
            handleOneononeTimelineSelect={handleOneononeTimelineSelect}
            selectedOneononeTimeline={selectedOneononeTimeline}
            customTimelineValue={customTimelineValue}
            teamsSessionsCustomTimeline={teamsSessionsCustomTimeline}
            isLoadingTeamsSessionsCustomTimeline={isLoadingTeamsSessionsCustomTimeline}
            timelineOptions={timelineOptions}
            loadingTimelineOptions={isLoadingTimelineOptions}
            okrCycleOptions={okrCycleOptions}
            handleOkrCycleSelect={handleOkrCycleSelect}
            selectedOkrCycle={selectedOkrCycle}
            loadingOkrCycleOptions={isLoadingOkrCycleOptions}
            tableOptions={tableOptions}
            selectedTableOption={selectedTableOption}
            handleTableOptionSelect={handleTableOptionSelect}
          />
        </div>
      </div>
      <ChartSection
        selectedReport={selectedReport}
        customTimelineValue={customTimelineValue}
        filterChartOptions={filterChartOptions}
        handleFilterChart={handleFilterChart}
        filterChartValue={filterChartValue}
        teamsSessionsCustomTimeline={teamsSessionsCustomTimeline}
        isLoadingTeamsSessions={isLoadingTeamsSessions}
        teamsSessions={teamsSessions}
      />
      <div className={selectedReport === "okr" ? "bt-page" : ""}>
        {selectedReport === "oneonone" ? (
          // navigateToOkrReport={navigateToOkrReport}
          <OneOnOneReportTable
            teamsFilterOptions={teamsOptions}
            loadingTeamsOptions={isLoadingTeamsOptions}
            staff={oneOnOneStaff}
            loading={isLoadingOneOnOneStaff}
            customTimelineValue={customTimelineValue}
            onTeamFilterSelect={handleOneOnOneStaff}
            selectedTeams={staffFilter}
          />
        ) : (
          // navigateToOneonOne={navigateToOneonOne}
          <OKRReportTable teams={okrTeams} reportType={selectedTableOption} staff={okrPeople} loading={isLoadingOkrPeople || isLoadingOkrTeams} />
        )}
      </div>
    </div>
  );
};

export default CompanyInsights;
