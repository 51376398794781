export const Urls = {
  Home: `/`,
  Registration: `/register`,
  Invite: `/invite/:id`,
  Login: `/login`,
  ForgotPassword: `/forgot-password`,
  ResetPassword: `/reset-password`,
  Teams: `/teams`,
  ViewTeamMembersProfile: `/teams/view-team-member/:id`,
  ViewTeamDetail: `/teams/team/:id`,
  Settings: `/settings`,
  Profile: `/profile`,
  Company: `/company`,
  PendingKRs: `/company/okrs/people/pending`,
  NotStartedKRs: `/company/okrs/people/not-started`,
  TeamNoContributions: `/company/okrs/teams/not-started`,
  TeamNotStarted: `/company/okrs/teams/not-started`,
};

export const ViewTeamMembersProfile = (id) => {
  return Urls.ViewTeamMembersProfile.replace(":id", id);
};

export const TeamDetail = (id) => {
  return Urls.ViewTeamDetail.replace(":id", id);
};
