import React, { useState } from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import CustomTimelineForm from "./customTimelineForm/customTimelineForm";
import "./customTimeLineModal.scss";

const CustomTimeLineModal = ({ openModal, closeModal, setCustomTimeValue }) => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({ startDate: undefined, endDate: undefined });

  const validForm = event => {
    if (event !== undefined) {
      setForm(event);
    }
  };

  const handleChange = evt => {
    if (evt.target) {
      const { name, value } = evt.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleClick = () => {
    setCustomTimeValue(formData);

    closeModal();
  };

  const resetFormData = () => {
    console.log(formData);
    setFormData({ startDate: null, endDate: null });
  };

  return (
    <PMSModal
      title="Custom Timeline"
      openModal={openModal}
      handlePrimaryButtonClick={handleClick}
      processing={false}
      primaryButtonText="Apply"
      containerId="custom-timeline"
      closeModal={closeModal}
      contentClassName=""
      showSecondaryButton={true}
      useHeaderBorder
      disablePrimaryButton={!form}
      closeOnOutsideClick={false}
    >
      <CustomTimelineForm validForm={validForm} formData={formData} handleChange={handleChange} resetFormData={resetFormData} />
    </PMSModal>
  );
};

export default CustomTimeLineModal;
