import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Datepicker, Button } from "../../../shared";

import { postOnOnOne, putOnOnOne, putReview, postReview } from "../../../../redux/actions/settingsActions/userSettingsActions";
import "./cycle.scss";

const Cycle = ({ closeModal, data }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form, setForm] = useState(null);
  const [validForm, setValidForm] = useState(null);
  const [formvalue, setFormvalue] = useState(data);

  const { loading, loadingReview } = useSelector(({ userSettings }) => {
    return {
      loading: userSettings?.postOneOnOneLoading,
      loadingReview: userSettings?.postReviewLoading,
    };
  });

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  const submit = (event, value) => {
    if (data?.type === "one") {
      if (data?.val?.id) {
        dispatch(putOnOnOne({ ...value, endDate: new Date(endDate), startDate: new Date(startDate), id: data?.id }));
      } else {
        dispatch(postOnOnOne({ ...value, endDate: new Date(endDate), startDate: new Date(startDate) }));
      }
    } else if (data?.type === "review") {
      if (data?.val?.organisationId) {
        dispatch(putReview({ ...value, description: value?.name, reviewCycleDueDate: new Date(endDate), reviewCycleStartDate: new Date(startDate), organisationId: data?.organisationId }));
      } else {
        dispatch(postReview({ ...value, description: value?.name, reviewCycleDueDate: new Date(endDate), reviewCycleStartDate: new Date(startDate), id: data?.id }));
      }
    }
  };

  useState(() => {
    if (data?.type === "review") {
      setFormvalue({ ...formvalue, name: data?.val?.description });
    }
    setEndDate(data?.val?.endDate || data?.val?.reviewCycleDueDate);
    setStartDate(data?.val?.startDate || data?.val?.reviewCycleStartDate);
  }, [data]);
  return (
    <AvForm className="change-password-container" model={formvalue} onKeyUp={checkForm} data-testid="submit" onInv onValidSubmit={submit} ref={(c) => setForm(c)}>
      <div className="row" onClick={checkForm}>
        <div className="col-12">
          <label htmlFor="name">Cycle Name</label>
          <AvField
            autoComplete="off"
            type="text"
            name="name"
            data-testid="name"
            placeholder="e.g Q4 2020"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter cycle name",
              },
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label htmlFor="startDate">Start date</label>
          <Datepicker id="startDate" data-testid="startDate" name="startDate" mode="single" dateFormat="Y-m-d" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div className="col-6">
          <label htmlFor="endDate">End date</label>
          <Datepicker id="endDate" data-testid="endDate" name="endDate" mode="single" dateFormat="Y-m-d" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>
      </div>
      <div className="message">You can edit the dates of a cycle before the cycle starts running.</div>
      <div className="edit-btn-content">
        <Button size="sm" color="blue" className="mr-2" outline={true} handleClick={closeModal}>
          Cancel
        </Button>
        <Button size="sm" color="blue" loading={loading || loadingReview} disabled={!validForm || !endDate || !startDate} handleClick={() => form?.submit()}>
          Save Changes
        </Button>
      </div>
    </AvForm>
  );
};

export default Cycle;
