import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "../../../assets/images/close.svg";

import "./sideModal.scss";

const SideModal = ({ title, modalShow, setModalShow, onClick, children, formOnly = false }) => {
  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        className={`${formOnly ? "side-modal-form-content" : "side-modal-content"} p-0`}
      >
        <Modal.Body className="p-0">
          <div>
            <div className="title">
              {title} <img src={CloseIcon} alt="goal form close icon" onClick={onClick ? onClick : () => setModalShow(false)} />
            </div>
            {children}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SideModal;
