import React from "react";
import { Spinner } from "reactstrap";
import "./button.scss";

/**
 *
 * @param {handleClick, children, loding, color, size, icon, ouline} props
 * @handleClick returns button event
 * @children: button text default undefined
 * @loading: boolean default undefined
 * @color: blue | green | purple | orange | destructive
 * outline: boolean default false
 * icon: Any supported remix icon refrence e.g. ri-send-plane-fill
 * size: lg | md | sm. Default sm
 */

const Button = (props) => {
  const { type, handleClick, children, loading, color, size, ghost, icon, outline, disabled, className, dataTestId } = props;

  return (
    <button
      data-testid={dataTestId}
      type={type ? type : "button"}
      onClick={handleClick}
      className={`sp-button sp-button--${size ? size : "sm"} ${ghost && "sp-button--ghost-" + (color ? color : "blue")}
        ${outline ? "sp-button--outline-" + (color ? color : "blue") : "sp-button--primary-" + (color ? color : "blue")} ${className}`}
      disabled={disabled}
    >
      {icon && <i className={`${icon}`}></i>} &nbsp;
      {children} &nbsp; {loading && <Spinner size="sm" color={`${outline ? (color ? color : "blue") : "light"}`} />}
    </button>
  );
};

export default Button;
