import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { COMPANY_PEOPLE_APIS } from "../../../apiServices/endPoints";

export const peopleActionTypes = {
  GET_PEOPLE: "[COMPANY - PEOPLE] GET_PEOPLE",
};

export const LoadPeople = createAsyncAction(peopleActionTypes.GET_PEOPLE, async ({ pageNo, pageSize, name }) => {
  return await Get(`${COMPANY_PEOPLE_APIS.getAll}${pageNo ? "?pageNumber=" + pageNo : ""}${pageSize ? "&pageSize=" + pageSize : ""}${name ? "&search=" + name : ""}`);
});
