import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _debounce from "lodash.debounce";
import { LoadAllTeams } from "../../../redux/actions/company";
import SVGS from "../../../utils/svgs";
import { TeamDetail } from "../../../utils/urls";
import { CustomTable, PageTitle, PMSTooltip, SearchBar, UserTooltipDetail } from "../../shared";
import "./teams.scss";

const Teams = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoadingTeams, allTeams } = useSelector(({ companyTeams }) => companyTeams);
  const [showEditorModal] = useState(false);
  const [editorData, setEditorData] = useState(undefined);
  const [actionType, setActionType] = useState(undefined);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    dispatch(LoadAllTeams({ pageNumber: paging.pageNumber, pageSize: paging.pageSize }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Type" },
    { id: 3, name: "Parent Team" },
    { id: 4, name: "Team Leader" },
    { id: 5, name: "Members" },
    { id: 6, name: "" },
  ];
  const handleDetailClick = data => {
    history.push({ pathname: TeamDetail(data.id), state: { tab: "teams", data } });
  };

  const handleSearch = _debounce(event => {
    dispatch(LoadAllTeams({ teamName: event.target.value, pageNumber: paging.pageNumber, pageSize: paging.pageSize }));
    // cb(true);
  }, 1000);

  const tableRows = data => {
    return (
      data &&
      data.map((team, idx) => {
        return (
          <tr key={team.id}>
            <td className="bordered">{team.teamName}</td>
            <td className="bordered">{team.teamType ? team.teamType : "--"}</td>
            <td className="bordered">{team.parentTeam ? team.parentTeam : "--"}</td>
            <td className="bordered">
              <PMSTooltip content={<UserTooltipDetail name={team?.teamOwner || "No Name Specified"} title={team?.teamOwnerJobTitle || "No Job Title Specified"} />} direction="left" width={171}>
                <span>{team.teamOwerImage ? <img className="table-avatar" src={team.teamOwerImage} alt="team owner avatar" /> : SVGS.avatar()}</span>
              </PMSTooltip>
            </td>
            <td className="bordered">{typeof team.membersCount === "number" ? team.membersCount : "-"}</td>
            <td className="bordered">
              <div className="action">
                <span onClick={() => handleDetailClick(team)} title={`View details of ${team.teamName}`}>
                  {SVGS.caret({ direction: "right" })}
                </span>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  useEffect(() => {
    if (!showEditorModal) {
      setEditorData(undefined);
      setActionType("create");
    } else if (showEditorModal && actionType === "edit") {
      setEditorData(editorData);
    }
    return () => {
      setEditorData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditorModal, actionType]);

  const onPageClick = evt => {
    const { selected } = evt;
    setPaging(prev => ({ ...prev, pageNumber: selected + 1 }));
  };

  return (
    <div className="company-teams-container">
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row pg-header">
            <div className="col">
              <div className="d-flex justify-content-between align-items-center">
                <PageTitle title={`${allTeams.items && typeof allTeams.items.length === "number" ? allTeams.items.length : "-"} Teams`} />
                <div className="search-contain">
                  <SearchBar placeholder="Search for a team" handleSearch={handleSearch} name="teamSearch" />
                </div>
              </div>
            </div>
          </div>

          <CustomTable
            arrangedTableData={tableRows(allTeams?.items)}
            tableHeads={tableHeads}
            isLoading={isLoadingTeams}
            tableData={allTeams?.items}
            searchTableData={allTeams?.items}
            showPaginator
            currentPage={paging.pageNumber}
            pageSize={paging.pageSize}
            pageCount={allTeams?.totalCount}
            handlePageClick={onPageClick}
            bordered
          />
        </div>
      </div>
    </div>
  );
};

export default Teams;
