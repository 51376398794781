import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../shared/button/Button";
import DocumentIcon from "../../../assets/images/documents.svg";
import { LoadingTableContent } from "../../shared/loader/Loader";
import CycleModal from "./cycle/cycleModal";
import { errorMessage } from "../../../redux/actions/notification/notification";
import { getOneOnOne, deleteOneOnOne } from "../../../redux/actions/settingsActions/userSettingsActions";
import "./timePeriod.scss";
import TimePeriodList from "./listTimePeriod";
import DeleteModal from "../../shared/deleteModal/deleteModal";

const OneOnOneCycle = ({ setTimeType }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(null);
  const [seeAll, setSeeAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const handleDelete = () => {
    dispatch(deleteOneOnOne(id));
  };

  const addData = (val, type) => {
    setData({ val, type });
    setShowModal(true);
  };
  const { payload, loading, error, payloadList, loadingList, errorList, payloadDelete, loadingDelete, errorDelete } = useSelector(({ userSettings }) => {
    return {
      payload: userSettings?.postOneOnOnePayload,
      loading: userSettings?.postOneOnOneLoading,
      error: userSettings?.postOneOnOneError,
      payloadList: userSettings?.getOneOnOnePayload,
      loadingList: userSettings?.getOneOnOneLoading,
      errorList: userSettings?.getOneOnOneError,

      payloadDelete: userSettings?.deleteOneOnOnePayload,
      loadingDelete: userSettings?.deleteOneOnOneLoading,
      errorDelete: userSettings?.deleteOneOnOneError,
    };
  });

  useEffect(() => {
    dispatch(getOneOnOne());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && payload?.data) {
      setShowModal(false);
      dispatch(getOneOnOne());
    } else if (error?.data?.message && !loading) {
      dispatch(errorMessage(error?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (errorList?.data?.message && !loadingList) {
      dispatch(errorMessage(errorList?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingList]);

  useEffect(() => {
    if (!loadingDelete && payloadDelete?.data) {
      setModalDeleteShow(false);
      dispatch(getOneOnOne());
    } else if (errorDelete?.data?.message && !loadingDelete) {
      dispatch(errorMessage(errorDelete?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDelete]);

  return (
    <div className="time-period-container">
      {!seeAll ? (
        <>
          <div className="row">
            <div className="col">
              <div className="review-cycle">One-on-One Cycles</div>
              <div className="review-desc">Set up periods for One-on-One meetings across your organisation.</div>
              <Button size="sm" color="blue" outline={true} handleClick={() => addData(null, "one")}>
                Add Cycle
              </Button>
              <div className="row">
                <div className="col">
                  <div className="title-content">
                    <span className="subtitle">Upcoming Review Cycles</span>
                    <span
                      className="see-all"
                      onClick={() => {
                        setSeeAll(true);
                        setType("one");
                        setList(payloadList?.data?.data?.items);
                        setTimeType("one");
                      }}
                    >
                      See all upcoming cycles
                    </span>
                  </div>
                  <div className="table-container">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Cycle Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingList && <LoadingTableContent colSpan={4} />}
                        {payloadList?.data?.data?.items?.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{val?.name}</td>
                              <td>{new Date(val?.startDate).toLocaleDateString("en-US")}</td>
                              <td>{new Date(val?.endDate).toLocaleDateString("en-US")}</td>
                              <td>
                                <span className="edit" onClick={() => addData(val, "one")}>
                                  Edit
                                </span>
                                <span
                                  className="delete"
                                  onClick={() => {
                                    setModalDeleteShow(true);
                                    setType("one");
                                    setId(val?.id);
                                  }}
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {!loadingList && payloadList?.data?.data?.items?.length === 0 && (
                      <div className="empty-content">
                        <img src={DocumentIcon} alt="empty goals" />
                        <div className="no-goals">You have not added any Cycle</div>
                        <div className="add-goal">Click on the “Add Cycle button to get started</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
        </>
      ) : (
        <TimePeriodList
          loadingList={loadingList}
          setTimeType={setTimeType}
          list={list}
          setShowModal={setShowModal}
          setSeeAll={setSeeAll}
          type={type}
          addData={addData}
          setId={setId}
          setModalDeleteShow={setModalDeleteShow}
        />
      )}
      <CycleModal openModal={showModal} closeModal={() => setShowModal(false)} loading={loading} data={data} />
      <DeleteModal modalShow={modalDeleteShow} setModalShow={setModalDeleteShow} handleDeleteObjectives={() => handleDelete()} idForAction="" title="One on One" loading={loadingDelete} />
    </div>
  );
};

export default OneOnOneCycle;
