import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { userDetails } from "../../apiServices/user.service";
import { useTitle } from "../../utils/useTitle";
import ViewContent from "../shared/viewContent/viewContent";
import CompanyInsights from "./insights/insights";
import Objectives from "./objectives/objectives";
import People from "./people/people";
import Teams from "./teams/teams";

const Company = () => {
  useTitle("Company Setup");
  const history = useHistory();
  const tab = history?.location?.state?.tab;
  const tabDetails = [
    { id: 1, key: "objectives", title: "Objectives", content: <Objectives /> },
    { id: 2, key: "teams", title: "Teams", content: <Teams /> },
    { id: 3, key: "people", title: "People", content: <People /> },
    { id: 4, key: "insights", title: "Insights", content: <CompanyInsights /> },
  ];
  const [key, setKey] = useState(tab ? tab : "objectives");
  const [companyName] = useState(userDetails()?.companyName);

  return (
    <section className="row">
      <div className="col">
        <div data-testid="companyName" className="content-header d-flex justify-content-between">
          <span>{companyName ? companyName : "-"}</span>
        </div>
        <div className="card-body px-0">
          <ViewContent activeKey={key} tabDetails={tabDetails} handleSelect={k => setKey(k)} />
        </div>
      </div>
    </section>
  );
};

export default Company;
