import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import Draft from "../../../shared/draft/draft";
import "./objectiveEditor.scss";
import ObjectiveForm from "./objectiveForm/objectiveForm";

const ObjectiveEditor = ({ modalShow, setModalShow, cycleOptions, objectiveDrafts, onResume, isLoadingDraftedObjectives, onSave, resumeDraft, saving, organisation }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Create a Company Objective">
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <ObjectiveForm cycleOptions={cycleOptions} onSave={onSave} resumeDraft={resumeDraft} saving={saving} organisation={organisation} />
            </div>
            <div className="col modal-sub-nav">
              <Draft data={objectiveDrafts} isLoading={isLoadingDraftedObjectives} onAction={onResume} />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default ObjectiveEditor;
