import React from "react";
import { Link } from "react-router-dom";

import LessThan from "../../../../assets/icons/lessThan.svg";
import MoreThan from "../../../../assets/icons/moreThan.svg";

import { LineLoader, PMSSelect } from "../../../shared";

const TopSection = ({
  customTimelineValue,
  selectedReport,
  teamsSessions,
  teamsSessionsCustomTimeline,
  isLoadingTeamsSessionsCustomTimeline,
  timelineOptions,
  loadingTimelineOptions,
  handleOneononeTimelineSelect,
  selectedOneononeTimeline,
  okrCycleOptions,
  loadingOkrCycleOptions,
  tableOptions,
  selectedTableOption,
  handleTableOptionSelect,
  handleOkrCycleSelect,
  selectedOkrCycle,
}) => {
  if (customTimelineValue) {
    if (selectedReport === "oneonone") {
      return (
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="row">
              <div className="col-auto">
                <div className="logged">Number of 1:1 Cycles</div>
                <div className="d-flex insight-info">
                  {isLoadingTeamsSessionsCustomTimeline ? (
                    <LineLoader lines={1} />
                  ) : (
                    <span>{teamsSessionsCustomTimeline && typeof teamsSessionsCustomTimeline.noOfCycles === "number" ? teamsSessionsCustomTimeline.noOfCycles : "-"}</span>
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="logged">Logged Sessions</div>
                <div className="d-flex insight-info">
                  {isLoadingTeamsSessionsCustomTimeline ? (
                    <LineLoader lines={1} />
                  ) : (
                    <span>{teamsSessionsCustomTimeline && typeof teamsSessionsCustomTimeline.totalLogged === "number" ? teamsSessionsCustomTimeline.totalLogged : "-"}</span>
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="logged">Unlogged Sessions</div>
                <div className="d-flex insight-info">
                  {isLoadingTeamsSessionsCustomTimeline ? (
                    <LineLoader lines={1} />
                  ) : (
                    <span>{teamsSessionsCustomTimeline && typeof teamsSessionsCustomTimeline.totalUnlogged === "number" ? teamsSessionsCustomTimeline.totalUnlogged : "-"}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="cycle-custom-select-container">
              <PMSSelect
                // placeholder={selectedOneononeTimeline}
                dataSource={timelineOptions}
                isLoading={loadingTimelineOptions}
                closeOnActionClick={true}
                displayExpr="description"
                valueExpr="id"
                onChange={handleOneononeTimelineSelect}
                placeholder={customTimelineValue}
                value={selectedOneononeTimeline}
              />
            </div>
          </div>
        </div>
      );
    } else if (selectedReport === "okr") {
      return;
    }
  } else {
    if (selectedReport === "okr") {
      return (
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="row">
              <div className="col-auto">
                <div className="logged">Number of {selectedTableOption === "people" ? "Employees" : "Teams"}</div>
                <div className="d-flex insight-info">
                  <span>{selectedTableOption === "people" ? 360 : 17}</span>
                </div>
              </div>
              <div className="col-auto">
                <div className="logged">{selectedTableOption === "people" ? "Pending Key Results" : "Not Started OKRs"}</div>
                <div className="d-flex insight-info">
                  <span>{selectedTableOption === "people" ? 40 : 23.5}%</span>&nbsp;
                  <span>{selectedTableOption === "people" ? "(144/360)" : "(2/17)"}</span>
                </div>

                <Link
                  to={{
                    pathname: `${selectedTableOption === "people" ? "/company/okrs/people/pending" : "/company/okrs/teams/not-started"}`,
                    state: {
                      tab: "insights",
                      table: selectedTableOption,
                      report: "okr",
                    },
                  }}
                  className="link-text"
                >
                  See More
                </Link>
              </div>
              <div className="col-auto">
                <div className="logged">{selectedTableOption === "people" ? "Not Started Key Results" : "No Contributions"}</div>
                <div className="d-flex insight-info">
                  <span>{selectedTableOption === "people" ? 25 : 11.8}%</span>
                  <span>{selectedTableOption === "people" ? "(90/360)" : "(2/17)"}</span>
                </div>
                <Link
                  to={{
                    pathname: `${selectedTableOption === "people" ? "/company/okrs/people/not-started" : "/company/okrs/teams/no-contributions"}`,
                    state: {
                      tab: "insights",
                      table: selectedTableOption,
                      report: "okr",
                    },
                  }}
                  className="link-text"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row">
              <div className="col-auto pr-0">
                <div className="cycle-select-container">
                  <PMSSelect
                    placeholder={selectedOkrCycle}
                    dataSource={okrCycleOptions}
                    isLoading={loadingOkrCycleOptions}
                    closeOnActionClick
                    displayExpr="description"
                    valueExpr="id"
                    onChange={handleOkrCycleSelect}
                    menuWidth="272px"
                    value={selectedOkrCycle}
                  />
                </div>
              </div>

              <div className="col-auto pl-0">
                <div className="tb-select-container">
                  <PMSSelect dataSource={tableOptions} closeOnActionClick displayExpr="description" valueExpr="id" onChange={handleTableOptionSelect} value={selectedTableOption} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectedReport === "oneonone") {
      return (
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="logged">Logged Sessions</div>
            <div className="d-flex insight-info">
              <span>{teamsSessions && typeof teamsSessions.totalLoggedSession === "number" ? teamsSessions.totalLoggedSession : "-"}</span> /{" "}
              <span>{teamsSessions && typeof teamsSessions.totalLoggedSession === "number" ? teamsSessions.totalSessionsExpected : "-"}</span>
            </div>
            <div className="summary">
              {teamsSessions && teamsSessions.lastPeriodRelation < 0 ? (
                <>
                  <span className="less-than">
                    <img src={LessThan} alt="less than icon" />
                    {Math.abs(teamsSessions.lastPeriodRelation)}
                  </span>{" "}
                  <span>less than last period</span>
                </>
              ) : (
                <>
                  <span className="greater-than">
                    {teamsSessions && teamsSessions.lastPeriodRelation ? (
                      <>
                        <img src={MoreThan} alt="more than icon" />
                        &nbsp;{teamsSessions.lastPeriodRelation}
                      </>
                    ) : (
                      "-"
                    )}
                  </span>{" "}
                  <span>more than last period</span>
                </>
              )}
            </div>
          </div>
          <div className="col-auto">
            <div className="cycle-select-container">
              <PMSSelect
                placeholder={selectedOneononeTimeline}
                dataSource={timelineOptions}
                isLoading={loadingTimelineOptions}
                closeOnActionClick={true}
                displayExpr="description"
                valueExpr="id"
                onChange={handleOneononeTimelineSelect}
                value={selectedOneononeTimeline}
              />
            </div>
          </div>
        </div>
      );
    }
  }
};

export default TopSection;
