import { TeamSettingsActionTypes } from "../../actions/settingsActions/teamSettingsActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";
import profile from "../../../assets/images/profile.jpeg";

const INITIAL_STATE = {
  isLoading: false,
  allTeamsMock: [
    { id: 0, teamName: "Elvis Mandem", teamType: "Competency Center", teamLead: { img: profile, leadName: "Israel" }, members: ["members", "member 2"] },
    { id: 1, teamName: "Gafar Mandem", teamType: "SBU", teamLead: { img: profile, leadName: "Bade" }, members: ["members", "member 2"] },
    { id: 2, teamName: "Wisdom Mandem", teamType: "Competency Center", teamLead: { img: profile, leadName: "Kola" }, members: ["members", "member 2"] },
    { id: 3, teamName: "Ore Womandem", teamType: "Division", teamLead: { img: null, leadName: "Diamond" }, members: ["members", "member 2"] },
    { id: 4, teamName: "Nma Womandem", teamType: "-", teamLead: { img: null, leadName: "Israel" }, members: ["members", "member 2"] },
  ],
  allTeamMembersMock: [
    { id: 0, teamMembers: "random", role: "israel", status: ["members", "member 2"] },
    { id: 1, teamMembers: "random", role: "bade", status: ["members", "member 2"] },
    { id: 2, teamMembers: "random", role: "israel", status: ["members", "member 2"] },
    { id: 3, teamMembers: "random", role: "israel", status: ["members", "member 2"] },
    { id: 4, teamMembers: "random", role: "israel", status: ["members", "member 2"] },
  ],

  deletingTeam: false,
  deleteTeamPayload: null,

  teamsOptions: [],
  isLoadingTeamsOptions: false,
  teamsOptionsError: null,

  savingTeam: false,
  saveTeamPayload: null,

  updatingTeam: false,
  updateTeamPayload: null,

  savingTeamMembers: false,
  saveTeamMembersPayload: null,

  performingAction: false,
  performActionPayload: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REQUEST(TeamSettingsActionTypes.GET_TEAMS):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(TeamSettingsActionTypes.GET_TEAMS):
      return {
        ...state,
        isLoading: false,
        allTeams: payload,
      };

    case FAILURE(TeamSettingsActionTypes.GET_TEAMS):
      return {
        ...state,
        isLoading: false,
        allTeams: payload,
      };

    case REQUEST(TeamSettingsActionTypes.GET_TEAM_MEMBERS):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(TeamSettingsActionTypes.GET_TEAM_MEMBERS):
      return {
        ...state,
        isLoading: false,
        allTeamMembers: payload,
      };

    case FAILURE(TeamSettingsActionTypes.GET_TEAM_MEMBERS):
      return {
        ...state,
        isLoading: false,
        allTeamMembers: payload,
      };

    case REQUEST(TeamSettingsActionTypes.DELETE_TEAM):
      return {
        ...state,
        deletingTeam: true,
      };

    case SUCCESS(TeamSettingsActionTypes.DELETE_TEAM):
      return {
        ...state,
        deletingTeam: false,
        deleteTeamPayload: payload?.data || payload,
      };

    case FAILURE(TeamSettingsActionTypes.DELETE_TEAM):
      return {
        ...state,
        deletingTeam: false,
        deleteTeamPayload: payload?.data || payload,
      };

    case REQUEST(TeamSettingsActionTypes.PERFORM_ACTION_MEMBER):
      return {
        ...state,
        performingAction: true,
      };

    case SUCCESS(TeamSettingsActionTypes.PERFORM_ACTION_MEMBER):
      return {
        ...state,
        performingAction: false,
        performActionPayload: payload?.data || payload,
      };

    case FAILURE(TeamSettingsActionTypes.PERFORM_ACTION_MEMBER):
      return {
        ...state,
        performingAction: false,
        performActionPayload: payload?.data || payload,
      };

    case REQUEST(TeamSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: true,
      };

    case SUCCESS(TeamSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: false,
        teamsOptions: payload?.data?.data,
      };

    case FAILURE(TeamSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: false,
        teamsOptionsError: payload,
      };

    case REQUEST(TeamSettingsActionTypes.SAVE_TEAM):
      return {
        ...state,
        savingTeam: true,
      };

    case SUCCESS(TeamSettingsActionTypes.SAVE_TEAM):
      return {
        ...state,
        savingTeam: false,
        saveTeamPayload: payload?.data || payload,
      };

    case FAILURE(TeamSettingsActionTypes.SAVE_TEAM):
      return {
        ...state,
        savingTeam: false,
        saveTeamPayload: payload?.data || payload,
      };

    case REQUEST(TeamSettingsActionTypes.UPDATE_TEAM):
      return {
        ...state,
        updatingTeam: true,
      };

    case SUCCESS(TeamSettingsActionTypes.UPDATE_TEAM):
      return {
        ...state,
        updatingTeam: false,
        updateTeamPayload: payload?.data || payload,
      };

    case FAILURE(TeamSettingsActionTypes.UPDATE_TEAM):
      return {
        ...state,
        updatingTeam: false,
        updateTeamPayload: payload?.data || payload,
      };

    case REQUEST(TeamSettingsActionTypes.SAVE_TEAM_MEMBERS):
      return {
        ...state,
        savingTeamMembers: true,
      };

    case SUCCESS(TeamSettingsActionTypes.SAVE_TEAM_MEMBERS):
      return {
        ...state,
        savingTeamMembers: false,
        saveTeamMembersPayload: payload?.data || payload,
      };

    case FAILURE(TeamSettingsActionTypes.SAVE_TEAM_MEMBERS):
      return {
        ...state,
        savingTeamMembers: false,
        saveTeamMembersPayload: payload?.data || payload,
      };

    default:
      return { ...state };
  }
};
