import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/home/home";
import Settings from "../components/settings/settings";
import Teams from "../components/teams/teams";
import Profile from "../components/profile/profile";
import ViewMemberProfile from "../components/teams/teamMembers/viewMemberProfile/viewMemberProfile";
import { Company, CompanyTeamObjectives, NoContributions, NotStartedKeyResults, NotStartedKRs, PendingKeyResults } from "../components/company";
import PrivateRoute from "./PrivateRoute";
import { Urls } from "../utils/urls";
import "./route-list.scss";

const RouteLists = () => {
  return (
    <div className="route-container">
      <Switch>
        <PrivateRoute exact path={Urls.Home} component={Home} />
        <PrivateRoute exact path={Urls.Profile} component={Profile} />
        <PrivateRoute exact path={Urls.Teams} component={Teams} />
        <PrivateRoute exact path={Urls.Settings} component={Settings} />
        <PrivateRoute exact path={Urls.ViewTeamMembersProfile} component={ViewMemberProfile} />
        <PrivateRoute exact path={Urls.Company} component={Company} />
        <PrivateRoute exact path={Urls.ViewTeamDetail} component={CompanyTeamObjectives} />
        <PrivateRoute exact path={Urls.PendingKRs} component={PendingKeyResults} />
        <PrivateRoute exact path={Urls.NotStartedKRs} component={NotStartedKeyResults} />
        <PrivateRoute exact path={Urls.PendingKRs} component={PendingKeyResults} />
        <PrivateRoute exact path={Urls.NotStartedKRs} component={NotStartedKeyResults} />
        <PrivateRoute exact path={Urls.TeamKRsNotStarted} component={NotStartedKRs} />
        <Route exact path={Urls.TeamNoContributions} component={NoContributions} />
      </Switch>
    </div>
  );
};
export default RouteLists;
