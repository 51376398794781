import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InfoTip from "../../shared/infoTip/infoTip";
import SearchBar from "../../shared/searchBar/searchBar";
import Button from "../../shared/button/Button";
import NewGoal from "./newGoal/newGoal";
import CommentModal from "./comment/commentModal";
import CheckinModal from "./checkin/checkinModal";
import AlignKeyResultModal from "../../shared/alignKeyResult/alignKeyResultModal";
import GoalTableContent from "./goalTableContent/goalTableContent";
import ViewKeyResultsModal from "../../shared/viewKeyResultsSideModal/viewKeyResultsModal";
import EditKeyResultModal from "../../shared/editKeyResult/editKeyResult";
import DeleteModal from "../../shared/deleteModal/deleteModal";
import { getUsersKeyResultList, submitUserReview, getAllReviewCycle, deleteUserKeyResults } from "../../../redux/actions/keyResult/keyResultActions";
import PMSSelect from "../../shared/selectInput/pmsSelect/pmsSelect";

import "./goals.scss";

const Goals = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [reviewCycleId, setReviewCycle] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showAlignKeyResultModal, setShowAlignKeyResultModal] = useState(false);

  const [selectAllStatus, setSelectAllStatus] = useState([]);
  const [allItemSelected, setAllItemSelected] = useState(false);
  const [searchTerm] = useState("");
  const [submitApproval] = useState(false);
  const [submittedReview, setSubmittedReview] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalCheckinShow, setModalCheckinShow] = useState(false);
  const [modalViewShow, setModalViewShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [alignment, setAlignment] = useState(null);

  const select = useSelector(({ keyResults }) => {
    return {
      reviewCyclePayload: keyResults && keyResults.reviewCyclePayload,
      reviewCycleError: keyResults && keyResults.reviewCycleError,
      reviewCycleLoading: keyResults && keyResults.reviewCycleLoading,
      resultListLoading: keyResults?.userKeyResultListLoading,
      resultListPayload: keyResults?.userKeyResultListPayload,
      resultListError: keyResults?.userKeyResultListError,
      getKeyResultByIdError: keyResults?.getKeyResultByIdError,
      getKeyResultByIdPayload: keyResults?.getKeyResultByIdPayload,
      getKeyResultByIdLoading: keyResults?.getKeyResultByIdLoading,
      submitUserReviewLoading: keyResults?.submitUserReviewLoading,
      deleteUserKeyResultLoading: keyResults?.deleteUserKeyResultLoading,
    };
  });
  const { resultListPayload, resultListLoading, deleteUserKeyResultLoading, submitUserReviewLoading, getKeyResultByIdPayload, getKeyResultByIdLoading, reviewCyclePayload } = select;
  useEffect(() => {
    dispatch(getAllReviewCycle());
    dispatch(getUsersKeyResultList(reviewCycleId, 1, 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCycleId]);

  const dropdownList = reviewCyclePayload?.data?.data?.map((val) => {
    return {
      id: val.cycleId,
      name: val?.description,
      label: val?.description,
    };
  });
  const handleSearch = () => {};

  const handleDelete = () => {
    dispatch(deleteUserKeyResults(id));
  };

  const editHandler = (e, data) => {
    setFormValues({
      keyResultStatus: data?.status,
      keyResultId: data?.keyResultId,
      parentTeamKeyResultId: data?.parent?.keyResultId,
      description: data?.description,
      isPublished: true,
      progress: data?.progress,
      progressType: "KPI",
      dueDate: `${new Date(data?.dueDate).getFullYear()}-${(new Date(data?.dueDate).getMonth() + 1).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}-${new Date(data?.dueDate)
        .getDate()
        .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}`,
      staffComment: data?.staffComment,
      targetGoal: data?.targetGoal,
      reviewCycleId: data?.reviewCycleId,
      parent: data?.parent,
      edit: true,
    });
    setModalShow(true);
  };

  const actions = [
    {
      id: 1,
      name: <span className="goal-action">View Details</span>,
      method: (e, data) => {
        setFormValues(data);
        setModalViewShow(true);
      },
    },
    {
      id: 2,
      name: <span className="goal-action">Edit Key Result</span>,
      method: editHandler,
    },
    {
      id: 3,
      name: <span className="goal-action">Delete Key Result</span>,
      method: (e, data) => {
        setModalDeleteShow(true);
        setId(data?.keyResultId);
      },
    },
  ];

  const selectAll = () => {
    if (allItemSelected) {
      setSelectAllStatus([]);
      setAllItemSelected(false);
    } else {
      const ids = resultListPayload?.data?.data?.items.map((value) => value.keyResultId);
      setSelectAllStatus([...ids]);
      setAllItemSelected(true);
      console.log({ ids });
    }
  };
  const selectGoal = (id) => {
    const index = selectAllStatus.indexOf(id);
    if (index > -1) {
      selectAllStatus.splice(index, 1);
      setSelectAllStatus([...selectAllStatus]);
    } else {
      setSelectAllStatus([...selectAllStatus, id]);
    }
  };

  useEffect(() => {
    if (getKeyResultByIdPayload) {
      const payload = getKeyResultByIdPayload.data.data;
      const result = {
        Keyresult: payload.keyresult,
        dueDate: payload.dueDate,
        owner: `${payload.owner.firstName} ${payload.owner.lastName}`,
        objKeyresultType: payload.objKeyresultType,
        tracking: { value: { currentProgress: 0, targetGoal: 100 } },
        start: "12",
        Goal: "34",
      };
      setAlignment({
        Keyresult: payload.teamKeyresult.keyresult,
        Team: {
          TeamName: "fer",
        },
      });
      setFormValues({ ...result });

      console.log({ getKeyResultByIdPayload: resultListPayload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getKeyResultByIdLoading]);

  return (
    <div className="row home-goal-container">
      <div className="col">
        <div className="d-flex justify-content-between">
          <div className="title">
            {resultListPayload?.data?.data?.totalCount} Key Result{resultListPayload?.data?.data?.totalCount > 1 && "s"}
          </div>
          <div className="btns">
            <div className="cycle-select">
              <PMSSelect
                placeholder="Review Cycle"
                selectMultiple={false}
                dataSource={[
                  {
                    id: "",
                    name: "All",
                    label: "All",
                  },
                  ...(dropdownList || []),
                ]}
                onActionButtonClick={(e) => console.log({ e })}
                closeOnActionClick={true}
                value={
                  formValues?.reviewCycleId
                    ? dropdownList?.find((option) => {
                        option["title"] = "edit";
                        return option.id === "6378BF32-7105-4E7C-CB7B-08D926BC093B";
                      })
                    : []
                }
                displayExpr="name"
                valueExpr="id"
                onChange={(e) => {
                  setReviewCycle(e.value);
                }}
                selectAllText="All Types"
                controlHeight="40px"
              />
            </div>
            <Button
              size="sm"
              color="blue"
              handleClick={() => {
                setModalShow(true);
                setFormValues(null);
              }}
              className="add-key"
            >
              Add Key Result
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <div className="label">
              <InfoTip itemName="Overview" infoText="This is the overview" />
            </div>
            <div className="label-text">
              <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="label">-</div>
            <div className="label-text">Average Progress</div>
          </div>
        </div>
        <div className="row sub-items">
          <div className="col-auto">
            <SearchBar placeholder="Search" handleSearch={handleSearch} searchTerm={searchTerm} />
          </div>
          {selectAllStatus.length > 0 && (
            <div className="col">
              <Button size="sm" outline={true} color="blue" loading={submitUserReviewLoading} handleClick={() => dispatch(submitUserReview({ keyResultIds: selectAllStatus }))} className="pull-right">
                Submit for Review
              </Button>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <GoalTableContent
              setModalDeleteShow={setModalDeleteShow}
              data={resultListPayload?.data?.data}
              setModalCheckinShow={setModalCheckinShow}
              loadingContent={resultListLoading}
              selectAll={selectAll}
              selectAllStatus={selectAllStatus}
              submittedReview={submittedReview}
              setSubmittedReview={setSubmittedReview}
              actions={actions}
              selectGoal={selectGoal}
              submitApproval={submitApproval}
              setShowCommentModal={(e) => setShowCommentModal(e)}
            />
          </div>
        </div>
      </div>
      <CheckinModal modalShow={modalCheckinShow} setModalShow={setModalCheckinShow} setShowAlignKeyResultModal={setShowAlignKeyResultModal} />
      <ViewKeyResultsModal
        details={formValues}
        loadingDetails={getKeyResultByIdLoading}
        modalShow={modalViewShow}
        setModalShow={setModalViewShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        setModalCheckinShow={setModalCheckinShow}
      />

      <EditKeyResultModal
        modalShow={modalEditShow}
        setModalShow={setModalEditShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        setAlignment={setAlignment}
        alignment={alignment}
        setFormValues={setFormValues}
        formValues={formValues}
      />
      <NewGoal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        setAlignment={setAlignment}
        alignment={alignment}
        setFormValues={setFormValues}
        formValues={formValues}
      />
      <CommentModal openModal={showCommentModal} closeModal={() => setShowCommentModal(false)} />
      <AlignKeyResultModal
        openModal={showAlignKeyResultModal}
        closeModal={() => {
          setShowAlignKeyResultModal(false);
          setModalShow(true);
        }}
        setAlignment={setAlignment}
        alignment={alignment}
      />
      <DeleteModal
        modalShow={modalDeleteShow}
        setModalShow={setModalDeleteShow}
        handleDeleteObjectives={() => handleDelete()}
        idForAction=""
        title="User key result"
        loading={deleteUserKeyResultLoading}
      />
    </div>
  );
};

export default Goals;
