import React from "react";
import PropTypes from "prop-types";
import FileSaver from "file-saver";
import XLSX from "xlsx";

import Button from "../button/Button";

const ExportExcel = ({ excelData, fileName, buttonColor, useButton, className, disabled, labelText, buttonOutline }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {useButton ? (
        <Button color={buttonColor} className={className} handleClick={e => exportToCSV(excelData, fileName)} disabled={disabled} outline={buttonOutline}>
          {labelText ? labelText : "Export"}
        </Button>
      ) : (
        <a href="#/" className={className} onClick={e => exportToCSV(excelData, fileName)}>
          {labelText}
        </a>
      )}
    </>
  );
};

ExportExcel.propTypes = {
  /**
   * Sets color of the button if used
   */
  buttonColor: PropTypes.string,
  /**
   * Passed into the component to create custom style to the button
   */
  className: PropTypes.string,
  /**
   * A json/object of the data to be exported
   */
  data: PropTypes.object,
  /**
   * Used to enable/disable button
   */
  disabled: PropTypes.bool,
  /**
   * The name the file is to have after exporting
   */
  fileName: PropTypes.string,
  /**
   * Set whether to use Ghost button
   */
  useButton: PropTypes.bool,
};

ExportExcel.defaultProps = {
  buttonColor: "blue",
  disabled: false,
  useButton: false,
};

export default ExportExcel;
