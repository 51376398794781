import React from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import ModalInfo from "./modalInfo";

const OneOnOneModal = ({ openModal, closeModal }) => {
  return (
    <div className="align-key-result-container">
      <PMSModal
        title="One-on One: Line Report’s Name"
        openModal={openModal}
        processing={false}
        modalClassWidth="730"
        containerId="user-editor"
        closeModal={closeModal}
        contentClassName=""
        showPrimaryButton={false}
        useHeaderBorder={false}
        closeOnOutsideClick={false}
      >
        <ModalInfo />
      </PMSModal>
    </div>
  );
};

export default OneOnOneModal;
