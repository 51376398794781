import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector } from "react-redux";

import PersonalIcon from "../../../../../assets/images/personal-icon.svg";

import "./form.scss";

const Checkin = ({ setShowAlignKeyResultModal, setModalShow, validForm, details, submitForm, setCheckInData, isSubmitted }) => {
  const userId = JSON.parse(localStorage.getItem("user_details"))?.userId;
  const [form, setForm] = useState([]);

  const select = useSelector(({ userActivity }) => {
    return {
      success: userActivity && userActivity.checkinSuccess,
      isLoading: userActivity && userActivity.activityLoading,
      errorResponse: userActivity && userActivity.checkinError,
    };
  });

  const { success, isLoading } = select;

  useEffect(() => {
    if (submitForm) {
      form && form.submit && form.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  useEffect(() => {
    if (isSubmitted && success) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const submit = (event, value) => {
    let checkInValue = {
      userId: userId,
      goalProgress: parseInt(value.goal),
      keyResultStatus: parseInt(value.progress),
      comment: value.comment,
    };

    setCheckInData(checkInValue);
  };
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    console.log({ emptyValidation });
    validForm(emptyValidation);
  };

  return (
    <AvForm className="checkin-form-content" data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="checkin-title layout-style">
        <img src={PersonalIcon} alt="personal icon" />
        {details?.description}
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="progress">Progress</label>
          <AvField
            autoComplete="off"
            type="text"
            name="progress"
            className="form-field"
            placeholder="Enter your progress"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your progress",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="goal">Goal</label>
          <AvField
            autoComplete="off"
            type="text"
            name="goal"
            className="form-field"
            placeholder="Enter your goal here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter goal",
              },
            }}
          />
        </div>
      </div>
      <div className="additional-info layout-style">Additional information</div>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="comment">Comments (Optional)</label>
          <AvField
            autoComplete="off"
            type="textarea"
            name="comment"
            className="textarea-field"
            placeholder="Enter your comment"
            validate={{
              required: {
                value: false,
                errorMessage: "Please enter your comment",
              },
            }}
          />
        </div>
      </div>
      <div className="align-goal layout-style">Add an Attachment</div>
    </AvForm>
  );
};

export default Checkin;
