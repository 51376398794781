import { teamMembersType } from "../../actions/teamsActions/teamMembersActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";
import profile from "../../../assets/images/profile.jpeg";

const INITIAL_STATE = {
  isLoading: false,
  teamMembersPayload: [],
  assignManagerSuccessPayload: null,
  teamMemberProfile: {},
  teamMembersPayloadMock: {
    teamName: "R&D",
    teamLead: "isreal oyinlola",
    parentTeam: "Engineering",
    teamType: "SBU",
    teamMembers: [
      {
        id: 2,
        firstName: "akin",
        lastName: "bayo",
        email: "akin@gmail.com",
        primaryTeam: "R&D",
        jobTitle: "Business analyst",
        role: "-",
        img: profile,
        lastSeen: "08/02/2020",
      },
      {
        id: 5,
        firstName: "dada",
        lastName: "aku",
        email: "dadaku@gmail.com",
        primaryTeam: "R&D",
        jobTitle: "Software Eng",
        role: "-",
        img: profile,
        lastSeen: "08/02/2020",
      },
    ],
  },
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(teamMembersType.GET_TEAM_MEMBERS):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(teamMembersType.GET_TEAM_MEMBERS):
      return {
        ...state,
        isLoading: false,
        teamMembersPayload: actions.payload,
      };

    case FAILURE(teamMembersType.GET_TEAM_MEMBERS):
      return {
        ...state,
        teamMembersError: actions.payload,
        isLoading: false,
      };

    case REQUEST(teamMembersType.ASSIGN_TEAM_LEAD):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(teamMembersType.ASSIGN_TEAM_LEAD):
      return {
        ...state,
        isLoading: false,
        assignManagerPayload: actions.payload.response,
      };

    case FAILURE(teamMembersType.ASSIGN_TEAM_LEAD):
      return {
        ...state,
        assignManagerPayload: actions.payload.response,
        isLoading: false,
      };

    default:
      return { ...state };
  }
};
