import React from "react";
import { Link } from "react-router-dom";

import "./tableActionDropdown.scss";

const TableActionDropdown = ({ actions, data, level }) => {
  return (
    <div className="dropdown d-flex justify-content-center">
      <span id="dropdownMenuButton" data-toggle="dropdown" className="dd-icon" aria-haspopup="true" aria-expanded="false" title="Actions">
        <i className="ri-more-2-fill"></i>
      </span>
      <div className="dropdown-menu action-dropdown py-0" aria-labelledby="dropdownMenuButton">
        {actions.map((action) => (
          <Link className="dropdown-item d-flex align-items-center pl-3" key={action.id} to="#" onClick={(e) => action.method(e, data, level && level)}>
            {action.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TableActionDropdown;
