import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ArrowLeftPoint from "../../../../assets/images/arrow-left-pointer.svg";

const NavLinkWrapper = ({ item, icon, to, collapse, dropdown, className = "" }) => {
  const [showItem, setShowItem] = useState("");

  //show the nav sub item on collsape bar
  const showOnCollapse = (itemName) => {
    console.log({ itemName });
    setShowItem(itemName);
  };
  //hide the nav sub item on collsape bar
  const hideOnCollapse = () => setShowItem("");
  const NavbarLink = dropdown ? "div" : NavLink;
  return (
    <li
      className={`nav-item ${collapse && "nav-collapse"}`}
      onMouseHover={() => showOnCollapse(item)}
      onMouseEnter={() => showOnCollapse(item)}
      onMouseLeave={() => {
        hideOnCollapse();
      }}
      data-testid="nav-item"
    >
      <NavbarLink to={to} activeClassName="active item-name-active" className={`nav-link ${className}`} exact={true}>
        {icon}
        {showItem === item && (
          <div className="mobile-menu-item">
            <img src={ArrowLeftPoint} alt="arrow point" /> <span data-testid="mobile-item">{item}</span>
          </div>
        )}
        <span className="item-name" data-testid="navLink">
          {item}
        </span>
      </NavbarLink>
    </li>
  );
};
export default NavLinkWrapper;
