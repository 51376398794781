import React, { useState } from "react";
import avatar from ".././../../../assets/images/avatar.svg";
import loggedIcon from "../../../../assets/icons/logged.svg";
import unloggedIcon from ".././../../../assets/icons/unlogged.svg";
import Button from "../../../shared/button/Button";
import SearchBar from "../../../shared/searchBar/searchBar";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import CustomTable from "../../../shared/table/table";
import { Link } from "react-router-dom";

// import "./viewTeams.scss";

import "./oneOnOneReport.scss";
import ReportModal from "./reportModal/reportModal";

const OneOnOneReport = ({ selectedCyle, handleSelectCycle, customTimeLineValue, getAllInsights, oneOnOneInsights, handleFilter, oneOnOneLoading, duration, handleCustomSelect, customCycleList }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [setModalValues] = useState("");

  const dropdownList = [
    { id: 1, display: "Logged", name: "logged" },
    { id: 2, display: "Not Logged", name: "unlogged" },
  ];
  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
    { id: 3, name: "Line Manager" },
    { id: 4, name: customTimeLineValue ? "Sessions Logged" : "Status" },
    { id: 5, name: "Action" },
  ];

  const handleModal = (val) => {
    setShowModal(true);
    setModalValues(val);
  };

  const arrangeTableData = (tableData) => {
    return (
      tableData &&
      tableData.map((value, index) => {
        return (
          <tr key={index + 1} className="reports-table">
            <td className="bordered">
              <span>
                <img className="mr-3 table-avatar" src={value.img ? value.img : avatar} alt="team lead avatar" /> {value.user}
              </span>
            </td>

            <td className="bordered">{value.jobTitle ? value.jobTitle : "-"}</td>
            <td className="bordered">{value.manager ? value.manager : "-"}</td>
            <td className="bordered">
              {!customTimeLineValue ? (
                <span className="d-flex">
                  <img className="mr-2" src={value.isLogged ? loggedIcon : unloggedIcon} alt="one on one status" />
                  {value.isLogged ? "Logged" : "Unlogged"}
                </span>
              ) : (
                `${value.cyclesLogged} of ${oneOnOneInsights.totalCycles}`
              )}
            </td>
            <td className="bordered">
              <Link to="#" onClick={() => handleModal(value)} className="resume">
                View 1:1 history
              </Link>
            </td>
          </tr>
        );
      })
    );
  };

  // search table
  const handleSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const search = (rows) => {
    const columns = ["jobTitle", "user", "manager"];
    if (rows && rows !== []) {
      return rows.filter((row) =>
        columns.some((column) => {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
        })
      );
    }
  };

  const handleSelectRow = (values) => {
    setSelectedRows(values);
  };

  // const handleSelectFilter = values => {
  //   setFilterOption(values);
  // };

  const filterTableData = () => {
    if (selectedRows && selectedRows.length > 1) {
      //  fetch all insights
      getAllInsights();
    } else {
      // fetch only selected insight
      handleFilter(selectedRows, "one_on_one_page");
    }
  };

  return (
    <div>
      <div className="col insights-container">
        {!customTimeLineValue ? (
          /* One on One insights */
          <div className="row mb-4" style={{ justifyContent: "space-between" }}>
            <div>
              <div className="pb-3 logged">Logged Sessions</div>
              <div className="pb-2 d-flex insight-info">
                <span>{oneOnOneInsights?.loggedSessionCount}&nbsp;</span>
                <span> /&nbsp;{oneOnOneInsights?.data?.length}</span>
              </div>
              {/* show data based on organization cycles */}

              {/* <div className="checkin-title layout-style">
                <img src={DownIcon} alt="personal icon" />
                <span className="three">{oneOnOneInsights?.data?.length - 0}&nbsp;</span>
                <span className="more">more than last period</span>
              </div> */}
            </div>
            <div className="col-2 pr-0">
              <div className="search-contain">
                <PMSSelect dataSource={customCycleList} closeOnActionClick={true} displayExpr="name" valueExpr="id" onChange={handleSelectCycle} menuWidth="184px" value={selectedCyle} />
              </div>
            </div>
          </div>
        ) : (
          /* One on One insights with custom date */
          <div className="row mb-4" style={{ justifyContent: "space-between" }}>
            <div className="row col-7">
              <div className="col-4">
                <div className="pb-3 logged">No of 1:1 Cycles</div>
                <div className="pb-2 d-flex insight-info">
                  <span>{oneOnOneInsights?.totalCycles}</span>
                </div>
              </div>
              <div className="col-4">
                <div className="pb-3 logged">Logged Sessions</div>
                <div className="pb-2 d-flex insight-info">
                  <span>{oneOnOneInsights.loggedSessionCount}</span>
                </div>
              </div>
              <div className="col-4">
                <div className="pb-3 logged">Unlogged Sessions</div>
                <div className="pb-2 d-flex insight-info">
                  <span>{oneOnOneInsights.unloggedSessionCount}</span>
                </div>
              </div>
            </div>
            <div className="col-3 pr-0">
              <div className="custom-contain">
                <PMSSelect
                  placeholder={duration}
                  dataSource={customCycleList}
                  closeOnActionClick={true}
                  displayExpr="name"
                  valueExpr="id"
                  onChange={handleCustomSelect}
                  menuWidth="272px"
                  value={duration}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="one-on-one-container">
        <div className="row mb-3">
          <ReportModal openModal={showModal} closeModal={() => setShowModal(false)} useCloseIcon useButtomBorder />
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="row mb-3">
                  <div className="col-auto pr-0">
                    <div className="search-container">
                      <SearchBar placeholder="Search for a user" handleSearch={handleSearch} searchTerm={searchTerm} />
                    </div>
                  </div>

                  {!customTimeLineValue && (
                    <div className="col-auto pl-0">
                      <div className="select-container">
                        <PMSSelect
                          placeholder="Status"
                          selectMultiple={true}
                          useSelectAll={true}
                          dataSource={dropdownList}
                          onActionButtonClick={() => filterTableData()}
                          closeOnActionClick={true}
                          displayExpr="name"
                          valueExpr="id"
                          onChange={handleSelectRow}
                          menuWidth="220px"
                          selectAllText="All Users"
                          useCount
                          value={[]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 tableTopBtn-div mb-3">
                <Button size="sm" color="blue" handleClick={() => {}} className="pull-right">
                  Export
                </Button>
              </div>
            </div>

            <CustomTable
              arrangedTableData={arrangeTableData(search(oneOnOneInsights?.data))}
              tableHeads={tableHeads}
              isLoading={oneOnOneLoading}
              tableData={oneOnOneInsights?.data}
              searchTableData={search(oneOnOneInsights?.data)}
              bordered
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOneReport;
