import { teamObjectivesType } from "../../actions/teamsActions/teamObjectivesActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  isLoading: false,
  objectivesLoading: false,
  teamObjectivesPayload: [],
  progressOverview: {},
  progressOverviewMock: {
    avgProgress: 65.3,
    overview: {
      pending: 6,
      atRisk: 9,
      completed: 15,
      behind: 3,
      onTrack: 32,
      notStarted: 7,
    },
  },
  teamObjectivesPayloadMock: {
    message: "successfully",
    data: {
      pageNumber: 0,
      pageSize: 0,
      items: [
        {
          id: 9,
          organisationId: "string",
          orgObjId: 1,
          teamId: 3,
          reviewCycleId: 65,
          description: "string",
          objectiveType: "Team",
          reviewCycle: "string",
          objKeyresultTypeId: "string",
          completed: false,
          progress: 75,
          lastUpdatedDate: "15/03/2021",
          dueDate: "30-03-2021",
          objectives: "Generate more income",
          tracking: "percentage",
          start: 0,
          goal: 10,
          owner: {
            staffId: 3,
            // image: profile,
            firstName: "Elvis ",
            lastName: " Jones",
            jobDescription: "Product Designer",
          },
          team: {
            id: "1",
            teamId: "2",
            organisationId: 3,
            teamName: "Devops",
            teamOwner: "Israel",
            teamOwnerId: 5,
            teamType: "SBU",
            parentObjective: "Quality people",
            parentTeam: "Engineering",
            parentId: "string",
          },
        },
        {
          id: 10,
          organisationId: "string",
          orgObjId: 1,
          teamId: 3,
          reviewCycleId: 65,
          description: "string",
          objectiveType: "Team",
          reviewCycle: "string",
          objKeyresultTypeId: "string",
          completed: false,
          progress: 75,
          lastUpdatedDate: "15/03/2021",
          dueDate: "30-03-2021",
          objectives: "Generate more income",
          tracking: "kpi",
          start: 0,
          goal: 3,
          owner: {
            staffId: 3,
            // image: profile,
            firstName: "Elvis ",
            lastName: " Jones",
            jobDescription: "Product Designer",
          },
          team: {
            id: "1",
            teamId: "2",
            organisationId: 3,
            teamName: "Devops",
            teamOwner: "Israel",
            teamOwnerId: 5,
            teamType: "SBU",
            parentObjective: "Quality people",
            parentTeam: "Engineering",
            parentId: "1",
          },
        },
      ],
    },
  },
  keyResultByAlignmentMock: [
    {
      id: 1,
      ObjectiveId: 7,
      isPublished: false,
      keyresult: "Sign up two new business in Q1",
      completed: false,
      status: "pending",
      progress: 35,
      lastUpdatedDate: "15/03/2021",
      dueDate: "18/03/2021",
      reviewCycleId: 65,
      objKeyresultType: "Team",
      team: {
        organisationId: 3,
        teamName: "Devops",
        teamOwnerId: 5,
        teamType: "SBU",
        parentTeam: "Engineering",
        parentId: "1",
      },
      tracking: "kpi",
      start: 0,
      goal: 3,
    },
    {
      id: 2,
      ObjectiveId: 7,
      keyresult: "Get govt approval",
      completed: true,
      status: "completed",
      progress: 35,
      lastUpdatedDate: "15/03/2021",
      dueDate: "18/03/2021",
      reviewCycleId: 65,
      objKeyresultType: "Team",
      team: {
        organisationId: 3,
        teamName: "Devops",
        teamOwnerId: 5,
        teamType: "SBU",
        parentTeam: "Engineering",
        parentId: "1",
      },
      tracking: "kpi",
      start: 0,
      goal: 3,
    },
  ],
  objectivesDraft: [
    {
      objectiveTitle: "Facilitate at least 2 user interview sessions",
      keyResults: ["Complete PMS", "Line Resource Key Result"],
      owner: "Saliu",
      type: "Team",
      reviewCycle: "2020 Cycle 1",
      dueDate: "2020-04-03",
      team: "Prod Design",
    },

    {
      objectiveTitle: "Facilitate at least 2 user interview sessions",
      keyResults: ["Complete PMS", "Line Resource Key Result", "My Key Result", "Give everyone Laptops"],
      owner: "Salisu",
      type: "Company Wide",
      reviewCycle: "2021 Cycle 1",
      dueDate: "2021-21-30",
      team: "Design Team",
    },
    {
      objectiveTitle: "Facilitate at least 2 user interview sessions",
      keyResults: ["Complete PMS API", "Line Manager Key Result"],
      owner: "Israel",
      type: "Team",
      reviewCycle: "2020 Cycle 4",
      dueDate: "2020-12-15",
      team: "R&D",
    },
  ],
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(teamObjectivesType.GET_TEAM_OBJECTIVES):
      return {
        ...state,
        objectivesLoading: true,
      };

    case SUCCESS(teamObjectivesType.GET_TEAM_OBJECTIVES):
      return {
        ...state,
        objectivesLoading: false,
        teamObjectivesPayload: actions.payload,
      };

    case FAILURE(teamObjectivesType.GET_TEAM_OBJECTIVES):
      return {
        ...state,
        teamObjectivesError: actions.payload,
        objectivesLoading: false,
      };

    case REQUEST(teamObjectivesType.GET_TEAM_OBJECTIVES_OVERVIEW):
      return {
        ...state,
        progressLoading: true,
      };

    case SUCCESS(teamObjectivesType.GET_TEAM_OBJECTIVES_OVERVIEW):
      return {
        ...state,
        progressLoading: false,
        progressOverview: actions.payload,
      };

    case FAILURE(teamObjectivesType.GET_TEAM_OBJECTIVES_OVERVIEW):
      return {
        ...state,
        progressOverviewError: actions.payload,
        progressLoading: false,
      };

    case REQUEST(teamObjectivesType.DELETE_OBJECTIVE):
      return {
        ...state,
        deleteLoading: true,
        deleteSuccessful: false,
      };

    case SUCCESS(teamObjectivesType.DELETE_OBJECTIVE):
      return {
        ...state,
        deleteSuccessful: actions.payload,
        deleteLoading: false,
      };

    case FAILURE(teamObjectivesType.DELETE_OBJECTIVE):
      return {
        ...state,
        deleteError: actions.payload,
        deleteLoading: false,
      };

    case REQUEST(teamObjectivesType.POST_TEAM_OBJECTIVE):
      return {
        ...state,
        postObjectivesLoading: true,
      };

    case SUCCESS(teamObjectivesType.POST_TEAM_OBJECTIVE):
      return {
        ...state,
        postObjectivesLoading: false,
        postObjectivesPayload: actions.payload,
      };

    case FAILURE(teamObjectivesType.POST_TEAM_OBJECTIVE):
      return {
        ...state,
        postObjectivesError: actions.payload,
        postObjectivesLoading: false,
      };

    case teamObjectivesType.SAVE_OBJECTIVES_DRAFTS:
      return {
        ...state,
        isLoading: false,
        objectivesDraft: actions.payload,
      };

    case REQUEST(teamObjectivesType.UPDATE_TEAM_OBJECTIVE):
      return {
        ...state,
        updateObjectiveLoading: true,
      };

    case SUCCESS(teamObjectivesType.UPDATE_TEAM_OBJECTIVE):
      return {
        ...state,
        updateObjectiveLoading: false,
        updateObjectivePayload: actions.payload,
      };

    case FAILURE(teamObjectivesType.UPDATE_TEAM_OBJECTIVE):
      return {
        ...state,
        updateObjectiveError: actions.payload,
        updateObjectiveLoading: false,
      };

    default:
      return { ...state };
  }
};
