import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Datepicker, PMSSelect } from "../../../../shared";

import "./objectiveForm.scss";

const ObjectiveForm = ({ cycleOptions = [], onSave, resumeDraft, saving, organisation }) => {
  const [form, setForm] = useState([]);
  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({
    organisationId: null,
    objectiveKeyresultType: "Organisation",
    description: "",
    reviewCycleId: null,
    dueDate: null,
    isPublished: false,
    owner: organisation,
  });

  const submit = (event, value) => {
    onSave({ ...value, ...formData, isPublished: actionType === "publish" });
  };

  const handleChange = (e) => {
    if (e) {
      if (e.target) {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          reviewCycleId: e.value,
        }));
      }
    }
  };

  useEffect(() => {
    return () => {
      // resumeDraft = { organisationId: null, objectiveKeyresultType: "Organisation", description: "", reviewCycleId: null, dueDate: null, isPublished: false, owner: "Venture Garden Group" };
      setFormData({
        organisationId: null,
        objectiveKeyresultType: "Organisation",
        description: "",
        reviewCycleId: null,
        dueDate: null,
        isPublished: false,
        owner: organisation,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = (type) => {
    setActionType(type);
  };

  useEffect(() => {
    if (form.submit && actionType) {
      form.submit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType]);

  useEffect(() => {
    if (resumeDraft) {
      setFormData((prev) => ({ ...prev, ...resumeDraft }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeDraft]);

  return (
    <AvForm data-testid="submit" className="objective-form-content" onValidSubmit={submit} ref={(c) => setForm(c)} onChange={handleChange}>
      <div className="row layout-style">
        <div className="col-12 px-0">
          <label htmlFor="description">Objective Title</label>
          <AvField
            autoComplete="off"
            type="text"
            name="description"
            id="description"
            className="form-field"
            placeholder="Enter objective title"
            value={formData.description}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a description",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6 pl-0">
          <label htmlFor="reviewCycleId">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            id="reviewCycleId"
            name="reviewCycleId"
            dataSource={cycleOptions}
            displayExpr="description"
            valueExpr="cycleId"
            onChange={handleChange}
            controlHeight="48px"
            value={formData.reviewCycleId}
          />
        </div>
        <div className="col-6 pr-0">
          <label htmlFor="dueDate">Due Date</label>
          <Datepicker id="dueDate" name="dueDate" mode="single" dateFormat="Y-m-d" value={formData.dueDate} onChange={handleChange} />
        </div>
      </div>
      <div className="row layout-style mt-3">
        <div className="col-6 pl-0">
          <label htmlFor="owner">Owner</label>
          <AvField autoComplete="off" type="text" className="form-field" name="owner" id="owner" disabled value={formData.owner} placeholder="Owner" />
        </div>
        <div className="col-6 pr-0">
          <label htmlFor="objectiveKeyresultType">Type</label>
          <AvField
            autoComplete="off"
            type="text"
            name="objectiveKeyresultType"
            value={formData.objectiveKeyresultType}
            id="objectiveKeyresultType"
            disabled
            className="form-field"
            placeholder="Type"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter type",
              },
            }}
          />
        </div>
      </div>
      <div className="row mt-3 mx-2">
        <div className="col-6 pl-0">
          <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={() => handleBtnClick("save")} loading={actionType === "save" && saving}>
            Save as Draft
          </Button>
        </div>
        <div className="col-6 pr-0">
          <Button
            disabled={!formData.reviewCycleId || !formData.description}
            size="sm"
            color="blue"
            className="btn-goal"
            handleClick={() => handleBtnClick("publish")}
            loading={actionType === "publish" && saving}
          >
            Publish
          </Button>
        </div>
      </div>
    </AvForm>
  );
};

export default ObjectiveForm;
