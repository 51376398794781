import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import UpdatesDetails from "./updatesDetails";
// import "./viewGoal.scss";

const ViewUpdatesModal = ({ modalShow, setModalShow, userId }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Updates" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <UpdatesDetails userId={userId} />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default ViewUpdatesModal;
