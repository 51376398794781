import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Button from "../../../shared/button/Button";
import IdentityIcon from "../../../../assets/images/identity.svg";
import SquareArrow from "../../../../assets/images/square-arrow.svg";
import { signup, newAccount, activateInviteUser, getInviteUser } from "../../../../redux/actions/onboarding/onboarding";
import { Urls } from "../../../../utils/urls";

import "./form.scss";
import { setUserDetails } from "../../../../apiServices/user.service";
import { errorMessage } from "../../../../redux/actions/notification/notification";

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const param = useParams();
  const [inviteUser, setInviteUser] = useState(false);
  const [passwordText, setPasswordText] = useState("");

  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ssoSignup, setSsoSignup] = useState(false);

  const { payload, loading, error, getInviteError, getInviteLoading, getInvitePayload } = useSelector(({ onboarding }) => {
    return {
      error: onboarding?.signupError,
      payload: onboarding?.signupPayload,
      loading: onboarding?.signupLoading,
      getInviteError: onboarding?.getInviteUserError,
      getInviteLoading: onboarding?.getInviteUserLoading,
      getInvitePayload: onboarding?.getInviteUserPayload,
    };
  });

  const data = getInvitePayload?.data?.data;
  const userDetails = { email: data?.email, organisationName: data?.organisation, organisationId: data?.organisationId };

  const submit = (event, value) => {
    if (inviteUser) {
      const { firstName, lastName, password } = value;
      dispatch(activateInviteUser({ firstName, lastName, password, jobTitle: value.workTitle, phoneNumber: value.telephone, isSSOUser: ssoSignup, inviteId: param?.id }));
    } else {
      dispatch(signup({ ...value, isSSOUser: ssoSignup }));
    }
  };
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  const validatePassword = (value, ctx, input, cb) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    cb(re.test(value));
  };

  useEffect(() => {
    if (payload?.data?.success && !loading) {
      setUserDetails(payload?.data?.data);
      dispatch(newAccount(true));
      history.push(Urls.Home);
    } else if (error?.data) {
      !loading && dispatch(errorMessage(error?.data?.message || "Error occurred while processing this request"));
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (getInvitePayload?.data?.success && !getInviteLoading) {
    } else if (getInviteError?.data && !getInviteLoading) {
      dispatch(errorMessage(getInviteError?.data?.message || "Error occurred while processing this request"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInviteLoading]);

  useEffect(() => {
    if (location?.pathname?.includes("/invite/")) {
      setInviteUser(true);
      dispatch(getInviteUser(param?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log({ userDetails });
  return (
    <div className="registration-form-content">
      {ssoSignup ? (
        <div className="row">
          <div className="col">
            <div className="create-account">
              <img src={SquareArrow} alt="back icon" className="back-icon" onClick={() => setSsoSignup(false)} />
              Sign Up with VGG Single Sign-On
            </div>
            <div className="create-account-subtitle">Enter your SSO credentials to proceed</div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            <div className="create-account">Create an account</div>
            <div className="create-account-subtitle">Enter your details to proceed</div>
            <div className="identity-signup" onClick={() => setSsoSignup(true)}>
              <img src={IdentityIcon} alt="identity sign up" />
              Sign Up with VGG SSO
            </div>
            <div className="or-line">
              <div className="hr-left">
                <hr />
              </div>
              Or
              <div className="hr-right">
                <hr />
              </div>
            </div>
          </div>
        </div>
      )}
      <AvForm data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
        {!ssoSignup && (
          <div className="row layout-style">
            <div className="col-6">
              <label htmlFor="firstName">First Name</label>
              <AvField
                autoComplete="off"
                type="text"
                name="firstName"
                className="form-field"
                placeholder="Enter your first name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter first name",
                  },
                }}
              />
            </div>
            <div className="col-6">
              <label htmlFor="lastName">Last Name</label>
              <AvField
                autoComplete="off"
                type="text"
                name="lastName"
                className="form-field"
                placeholder="Enter your last name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter last name",
                  },
                }}
              />
            </div>
          </div>
        )}
        <div className="row layout-style">
          <div className="col-12">
            <label htmlFor="email">{!ssoSignup && "Work"} Email Address</label>
            <AvField
              autoComplete="off"
              type="text"
              name="email"
              disabled={userDetails?.email ? true : false}
              className="form-field"
              value={userDetails?.email}
              placeholder={`Enter your ${!ssoSignup ? "work" : ""} email address`}
              validate={{
                required: {
                  value: true,
                  errorMessage: `Please enter your ${!ssoSignup ? "work" : ""} email address`,
                },
              }}
            />
          </div>
        </div>
        {!ssoSignup && (
          <div className="row layout-style">
            <div className="col-12">
              <label htmlFor="email">Phone number</label>
              <AvField
                autoComplete="off"
                type="text"
                name="telephone"
                className="form-field"
                placeholder="Enter your phone number"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter your work phone number",
                  },
                  pattern: { value: "^[0-9]+$", errorMessage: "Your Phone number must be a valid number" },
                  minLength: { value: 11 },
                  maxLength: { value: 13 },
                }}
              />
            </div>
          </div>
        )}
        {!inviteUser && (
          <div className="row layout-style">
            <div className="col-12">
              <label htmlFor="email">Company name</label>
              <AvField
                autoComplete="off"
                type="text"
                name="organisationName"
                className="form-field"
                placeholder="Enter your company name"
                validate={{
                  required: {
                    value: inviteUser ? true : false,
                    errorMessage: "Please enter your company name",
                  },
                }}
              />
            </div>
          </div>
        )}
        <div className="row layout-style">
          <div className="col-12">
            <label htmlFor="workTitle">Work Title</label>
            <AvField
              autoComplete="off"
              type="text"
              name="workTitle"
              className="form-field"
              placeholder="eg. Sale Representative"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter work title",
                },
              }}
            />
          </div>
        </div>

        <div className="row layout-style">
          <div className="col-12">
            <label htmlFor="password">{ssoSignup && "SSO "}Password</label>
            <span className="passwordToggle" onClick={() => setShowPassword(!showPassword)}>
              {!showPassword ? "show" : "Hide"}
            </span>
            <AvField
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={(e) => setPasswordText(e.target.value)}
              className="form-field"
              placeholder={`Enter your ${ssoSignup ? "SSO " : ""}password`}
              errorMessage={
                passwordText === "" ? `Please enter your ${ssoSignup ? "SSO " : ""}password` : "Your password must be composed of lowercase letter, uppercase letter, special character and numbers"
              }
              validate={{
                async: validatePassword,
                required: {
                  value: true,
                  errorMessage: `Please enter your ${ssoSignup ? "SSO " : ""}password`,
                },
                minLength: { value: 7 },
              }}
            />
          </div>
        </div>
        <div className="row layout-style">
          <div className="col-12">
            <Button size="sm" className="btn-goal" color="blue" loading={loading} disabled={!validForm} handleClick={() => form.submit()}>
              Create Account
            </Button>
          </div>
        </div>
        <div className="row layout-style">
          <div className="col already ">
            Already have an account?{" "}
            <span className="login-text" onClick={() => history.push(Urls.Login)}>
              Login
            </span>
          </div>
        </div>
      </AvForm>
    </div>
  );
};

export default Form;
