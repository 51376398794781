import React from "react";
import { useDispatch } from "react-redux";
import { LoadKeyResultContributions } from "../../../../../redux/actions/company";
import SideModal from "../../../../shared/sideModal/sideModal";
import KeyResultDetails from "./details/keyResultDetails";
import ObjectivesDetails from "./details/objectiveDetails";

const ObjectiveViewer = ({ showModal, setShowModal, viewKeyResult, objectiveData, keyResultData, loading, keyResultContributions, objectiveKeyResults }) => {
  const dispatch = useDispatch();
  const [showObjectiveDetails, setShowObjectiveDetails] = React.useState(!viewKeyResult);
  const [krData, setKrData] = React.useState(null);

  const handleSwitchView = data => {
    setKrData(data);
    dispatch(LoadKeyResultContributions({ krId: data.id }));
    setShowObjectiveDetails(prevState => !prevState);
  };

  return (
    <SideModal
      modalShow={showModal}
      setModalShow={setShowModal}
      title={
        showObjectiveDetails ? (
          <span></span>
        ) : (
          <span style={{ display: "flex" }} data-testid="title-page-icon" onClick={handleSwitchView} className="mr-2 kr-back">
            <i className="ri-arrow-left-line pr-" style={{ paddingRight: "11px" }}></i> Back
          </span>
        )
      }
      formOnly={true}
    >
      <div className="container sidebar-content">
        <div className="row">
          <div className="col form-width">
            {viewKeyResult ? (
              <KeyResultDetails onViewObjective={handleSwitchView} data={keyResultData} contributions={keyResultContributions} loading={loading} />
            ) : showObjectiveDetails ? (
              <ObjectivesDetails onViewKeyResult={handleSwitchView} objectiveKeyResults={objectiveKeyResults} loading={loading} objectiveData={objectiveData} />
            ) : (
              <KeyResultDetails onViewObjective={handleSwitchView} data={krData} contributions={keyResultContributions} loading={loading} />
            )}
          </div>
        </div>
      </div>
    </SideModal>
  );
};
export default ObjectiveViewer;
