import React, { useState } from "react";
import PMSModal from "../../shared/pmsModal/pmsModal";
import ChangePassword from "./changePassword";
import "./changePassword.scss";

const PasswordModal = ({ openModal, closeModal }) => {
  const [submitForm, setSubmitForm] = useState(null);
  const [form, setForm] = useState(null);
  const validForm = (event) => {
    if (event !== undefined) {
      setForm(event);
    }
  };
  return (
    <PMSModal
      title="Change Password"
      openModal={openModal}
      handlePrimaryButtonClick={() => setSubmitForm(new Date())}
      processing={false}
      primaryButtonText="Update"
      containerId="user-editor"
      closeModal={closeModal}
      contentClassName="user-editor-content"
      showSecondaryButton={true}
      useHeaderBorder={false}
      disablePrimaryButton={!form}
      closeOnOutsideClick={false}
    >
      <ChangePassword submitForm={submitForm} validForm={(e) => validForm(e)} />
    </PMSModal>
  );
};

export default PasswordModal;
