import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../shared/button/Button";
import WelcomeIcon from "../../../assets/images/welcomeIcon.svg";
import { showOnboardingSteps } from "../../../redux/actions/profile/profileActions";
import { newAccount } from "../../../redux/actions/onboarding/onboarding";
import "./welcomeMessage.scss";

const Message = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [steps, setSteps] = useState(1);

  const onRender = () => {
    switch (steps) {
      case 2:
        return (
          <>
            <div className="title">Align OKRs from top to bottom</div>
            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </>
        );
      case 3:
        return (
          <>
            <div className="title">Align OKRs from top to bottom</div>
            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </>
        );
      case 4:
        return (
          <>
            <div className="title">Align OKRs from top to bottom</div>
            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </>
        );

      case 5:
        return (
          <>
            <div className="title">All in One platform</div>
            <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
          </>
        );
      default:
        return (
          <>
            <div className="title">
              Welcome to Clarit <img src={WelcomeIcon} alt="welcome icon" />
            </div>
            <div className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisi cras fermentum odio eu. Tempus egestas sed sed risus
              pretium quam vulputate dignissim
            </div>
          </>
        );
    }
  };
  useEffect(() => {
    dispatch(showOnboardingSteps(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (steps === 6) {
      setShowModal(false);
      dispatch(showOnboardingSteps(true));
      dispatch(newAccount(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);
  return (
    <div className="row message-container">
      <div className="col-7 message-content">
        {onRender()}
        <div className="btns">
          <div className="steps">{steps > 5 ? 5 : steps}/5</div>
          <div className="d-flex">
            {steps > 1 && (
              <Button size="sm" className="btn-goal" data-testid="button" outline={true} color="blue" handleClick={() => setSteps(steps - 1)}>
                Prev
              </Button>
            )}
            <Button
              size="sm"
              className="btn-goal next"
              data-testid="button"
              color="blue"
              handleClick={() => {
                setSteps(steps + 1);
              }}
            >
              {steps === 5 ? "Get Started" : "Next"}
            </Button>
          </div>
        </div>
      </div>
      <div className="col-5">
        <div className="empty-space">&nbsp;</div>
      </div>
    </div>
  );
};

export default Message;
