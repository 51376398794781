import React, { useEffect, useState } from "react";
import Select from "react-select";
import SpMenu from "../components/menu/spMenu";
import SpIndicator from "../components/indicator/spIndicator";
import SpOption from "../components/option/spOption";
import ValueContainer from "../components/valueContainer/valueContainer";
import { transformToSelectOption } from "../../../../utils/transformToSelectOption";
import "./pmsSelect.scss";

const PMSSelect = props => {
  const {
    displayExpr,
    valueExpr,
    dataSource = [],
    placeholder,
    onChange,
    selectMultiple,
    actionButtonText = "Apply",
    onActionButtonClick,
    menuWidth,
    controlHeight,
    name,
    value,
    allowCreateWhileLoading = false,
    disableControl,
    isLoading,
    useSelectAll,
    selectAllText,
    useCount,
    controlShouldRenderValue,
    testId,
    arialLabel,
    id,
    onOptionSelected,
    defaultValue,
    clearable = false,
    onMenuClose,
  } = props;

  const [renderValue] = useState(typeof controlShouldRenderValue === "boolean" ? controlShouldRenderValue : selectMultiple ? false : true);
  const [selectAll, setSelectAll] = useState(false);
  const [hidePlaceholder, setHidePlaceholder] = useState(false);
  const transformedData = transformToSelectOption(dataSource, { value: valueExpr, label: displayExpr });

  const customTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#2C6EEC",
      primary75: "#f4f5f6",
      primary25: "#f4f5f6",
      primary50: "#f4f5f6",
    },
  });

  useEffect(() => {}, []);

  const handleSelectAll = () => {
    setSelectAll(prev => !prev);
  };

  const onInputChanged = evt => {
    if (selectMultiple) {
      setHidePlaceholder(!!evt.length);
      if (evt.length === dataSource.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
    if (onChange) {
      onChange(evt);
    }

    if (onOptionSelected) {
      onOptionSelected({ target: { name, value: evt.value, label: evt.label } });
    }
  };

  const getDefaultValue = val => {
    if (val?.title === "edit") {
      return val;
    } else if (typeof val === "object") {
      return;
    } else {
      return (valueExpr && displayExpr ? transformedData : dataSource).filter(option => option.value === value);
    }
  };

  const handleActionButtonClick = evt => {
    if (onActionButtonClick) {
      onActionButtonClick(evt);
    }
  };

  return (
    <Select
      arialLabel={arialLabel}
      theme={customTheme}
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: controlHeight ? controlHeight : 40,
        }),
        menu: (provided, state) => ({
          ...provided,
          minWidth: menuWidth ? menuWidth : "100%",
          zIndex: 1000,
        }),
        menuList: (provided, state) => ({
          ...provided,
          "color": "#3a434b",
          "&:focus": {
            backgroundColor: "#f4f5f6",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          color: "#3a434b",
        }),
        loadingIndicator: (provided, state) => ({
          ...provided,
          color: "#1354D3",
        }),
      }}
      options={valueExpr && displayExpr ? transformedData : dataSource}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: SpIndicator,
        Menu: SpMenu,
        Option: SpOption,
        ValueContainer,
      }}
      placeholder={hidePlaceholder ? "" : placeholder}
      onChange={onInputChanged}
      isMulti={selectMultiple}
      actionButtonText={actionButtonText}
      onActionButtonClick={handleActionButtonClick}
      controlShouldRenderValue={renderValue}
      closeMenuOnSelect={!selectMultiple}
      hideSelectedOptions={false}
      onSelectAll={handleSelectAll}
      allSelected={selectAll}
      name={name}
      defaultValue={defaultValue}
      value={getDefaultValue(value)}
      allowCreateWhileLoading={allowCreateWhileLoading}
      isDisabled={disableControl}
      isLoading={isLoading}
      useSelectAll={useSelectAll}
      selectAllText={selectAllText}
      useCount={useCount}
      testId={testId}
      disable={disableControl}
      data-testid="pmsSelect"
      inputId={id}
      id={id}
      isClearable={clearable}
      onMenuClose={onMenuClose}
    />
  );
};

export default PMSSelect;
