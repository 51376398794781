import { Delete, Get, Post, Put } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const TeamSettingsActionTypes = {
  GET_TEAMS: "GET_TEAMS",
  TEAMS_DETAILS: "TEAMS_DETAILS",
  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",
  TEAM_MEMBERS_DETAILS: "TEAM_MEMBERS_DETAILS",
  SAVE_TEAM: "SAVE_TEAM",
  SAVE_TEAM_MEMBERS: "SAVE_TEAM_MEMBERS",
  DELETE_TEAM: "DELETE_TEAM",
  GET_TEAMS_OPTIONS: "GET_TEAMS_OPTIONS",
  UPDATE_TEAM: "UPDATE_TEAM",
  PERFORM_ACTION_MEMBER: "PERFORM_ACTION_MEMBER",
};

export const getTeams = createAsyncAction(TeamSettingsActionTypes.GET_TEAMS, async (pageNo, pageSize, searchTerm, teamIds) => {
  return await Get(`Admin/teams/paged?pageNumber=${pageNo}&pageSize=${pageSize}${searchTerm ? "&searchTerm=" + searchTerm : ""}${teamIds ? teamIds : ""}`);
});

export const getTeamMembers = createAsyncAction(TeamSettingsActionTypes.GET_TEAM_MEMBERS, async (teamId, pageNo, pageSize, searchTerm) => {
  return await Get(`Team/members?teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}${searchTerm ? "&searchTerm=" + searchTerm : ""}`);
});

export const getTeamsOptions = createAsyncAction(TeamSettingsActionTypes.GET_TEAMS_OPTIONS, async () => {
  return await Get(`Team/GetAll`);
});

export const saveTeam = createAsyncAction(TeamSettingsActionTypes.SAVE_TEAM, async ({ data }) => {
  return await Post(`Team`, data);
});

export const updateTeam = createAsyncAction(TeamSettingsActionTypes.UPDATE_TEAM, async ({ data, teamId }) => {
  return await Put(`Admin/team/edit/${teamId}`, data);
});

export const saveTeamMembers = createAsyncAction(TeamSettingsActionTypes.SAVE_TEAM_MEMBERS, async ({ data, teamId }) => {
  return await Post(`Team/members/${teamId}`, data);
});

export const performActionOnMember = createAsyncAction(TeamSettingsActionTypes.PERFORM_ACTION_MEMBER, async ({ memberId, teamId, action }) => {
  return (await action) === "make" ? Put(`Admin/team/members/make/${teamId}/${memberId}`, {}) : Delete(`Admin/team/members/delete/${teamId}/${memberId}`);
});

export const deleteTeam = createAsyncAction(TeamSettingsActionTypes.DELETE_TEAM, async teamId => {
  return await Delete(`Team/${teamId}`);
});
