import React from "react";
import PersonalIcon from "../../../../../assets/images/personal-icon.svg";
import LocationIcon from "../../../../../assets/images/location-white.svg";
import RoleIcon from "../../../../../assets/images/role.svg";
import AvartaIcon from "../../../../../assets/images/avatar.svg";
import Button from "../../../../shared/button/Button";
import TableActionDropdown from "../../../../shared/tableActionDropdown/tableActionDropdown";
import Lozenge from "../../../../shared/lozenges/lozenge";
import { ProgressBar } from "react-bootstrap";
import { getPercentColor } from "../../../../../utils/helpers";

import "./objectivesDetails.scss";
import { LineLoader } from "../../../../shared";

const ObjectivesDetailsTwo = ({
  details,
  setKeyResultModalEditShow,
  setModalShow,
  contributionsData,
  contributionsLoading,
  setModalDeleteShow,
  handleSpecificLevelDelete,
  setDataToEdit,
  handleCheckinModal,
}) => {
  const actions = [
    {
      id: 1,
      name: <span className="detail-action">Edit Key Result</span>,
      method: () => {
        setDataToEdit(details);
        setKeyResultModalEditShow(true);
        setModalShow(false);
      },
    },
    {
      id: 2,
      name: <span>Delete Key Result</span>,
      method: () => {
        setModalShow(false);
        setModalDeleteShow(true);
        handleSpecificLevelDelete(details.id, 2);
      },
    },
  ];

  return (
    <div className="goal-view-content objectives-details">
      <div className="goal-title">{details?.description}</div>
      <div className="personal">
        <img src={PersonalIcon} alt="personal icon" />
        {details?.teamName}
      </div>
      <div className="approved">
        <ProgressBar data-testid="progress-bar" now={details?.progress} variant={getPercentColor(details?.progress)} />
        <span className="percent-count">{details?.progress}%</span>
        <Button
          size="sm"
          className="btn-goal"
          color="blue"
          handleClick={() => {
            handleCheckinModal(true, details);
          }}
        >
          <span className="checkin">
            Check-in
            <img src={LocationIcon} alt="location" />
          </span>
        </Button>
        <TableActionDropdown data-testid="table-action-dropdown" actions={actions} />
      </div>
      <div className="alignment-content">
        <div className="alignment">Alignment</div>
        <div className="features">{details.objective}</div>
        <div className="role">
          <img src={RoleIcon} alt="role" />
          {details?.teamName}
        </div>
      </div>

      <div className="keyresults-content">
        <div className="key-results">Contributions under this Key Result</div>
        {contributionsLoading && <LineLoader lines={6} />}

        {contributionsData &&
          contributionsData.map((data, index) => {
            return (
              <div className="key-results-content">
                <div>
                  <div>{data?.description}</div>
                  <div className="key-results-date">Due Date: {data.dueDate}</div>
                </div>
                <div>
                  <Lozenge data-testid="lozenge" className="sp-lozenge sp-lozenge--success" style={{ marginRight: "24px" }} content={data?.progress} />
                </div>
              </div>
            );
          })}
      </div>

      <div className="owner-content">
        <div className="owner">Owner</div>
        <div className="name">
          <img src={AvartaIcon} alt="avatar" /> {details?.owner?.firstName} {details?.owner?.lastName}
        </div>
      </div>
      <div className="date-content">
        <div className="date-item">
          <div className="due-date">Due Date</div>
          <div className="date">{details?.dueDate}</div>
        </div>
        <div className="date-item">
          <div className="due-date">Last Update</div>
          <div className="date">{details?.lastUpdatedDate}</div>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesDetailsTwo;
