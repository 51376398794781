import { onboardingType } from "../../actions/onboarding/onboarding";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INTIAL_STATE = {};

export default (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(onboardingType.SIGNUP):
      return { ...state, signupLoading: true };
    case SUCCESS(onboardingType.SIGNUP):
      return {
        ...state,
        signupPayload: actions.payload,
        signupLoading: false,
      };
    case FAILURE(onboardingType.SIGNUP):
      return {
        ...state,
        signupError: actions.payload,
        signupLoading: false,
      };
    case REQUEST(onboardingType.LOGIN):
      return { ...state, loginLoading: true };
    case SUCCESS(onboardingType.LOGIN):
      return {
        ...state,
        loginPayload: actions.payload,
        loginLoading: false,
      };
    case FAILURE(onboardingType.LOGIN):
      return {
        ...state,
        loginError: actions.payload,
        loginLoading: false,
      };
    case REQUEST(onboardingType.RESET_PASSWORD):
      return { ...state, resetPasswordLoading: true };
    case SUCCESS(onboardingType.RESET_PASSWORD):
      return {
        ...state,
        resetPasswordPayload: actions.payload,
        resetPasswordLoading: false,
      };
    case FAILURE(onboardingType.RESET_PASSWORD):
      return {
        ...state,
        resetPasswordError: actions.payload,
        resetPasswordLoading: false,
      };
    case REQUEST(onboardingType.FORGOT_PASSWORD):
      return { ...state, forgotPasswordLoading: true };
    case SUCCESS(onboardingType.FORGOT_PASSWORD):
      return {
        ...state,
        forgotPasswordPayload: actions.payload,
        forgotPasswordLoading: false,
      };
    case FAILURE(onboardingType.FORGOT_PASSWORD):
      return {
        ...state,
        forgotPasswordError: actions.payload,
        forgotPasswordLoading: false,
      };

    case REQUEST(onboardingType.GET_INVITE_USER):
      return { ...state, getInviteUserLoading: true };
    case SUCCESS(onboardingType.GET_INVITE_USER):
      return {
        ...state,
        getInviteUserPayload: actions.payload,
        getInviteUserLoading: false,
      };
    case FAILURE(onboardingType.GET_INVITE_USER):
      return {
        ...state,
        getInviteUserError: actions.payload,
        getInviteUserLoading: false,
      };
    case onboardingType.NEW_ACCOUNT:
      return {
        ...state,
        newAccount: actions.payload,
      };
    default:
      return { ...state };
  }
};
