import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import DocumentIcon from "../../../assets/images/documents.svg";
import { LineLoader, SearchBar, Button, PMSSelect, PMSTooltip, OverviewColors, PageTitle } from "../../shared";
import { ProgressBar } from "react-bootstrap";
import NewObjectives from "./newObjective/newObjective";
import ViewObjectives from "./viewObjectives/viewObjectives";
import AlignKeyResultModal from "../../shared/alignKeyResult/alignKeyResultModal";
import AddKeyResultModal from "./addKeyResult/addKeyResultModal";
import DeleteModal from "../../shared/deleteModal/deleteModal";
import SVGS from "../../../utils/svgs";
import { useDispatch, useSelector } from "react-redux";
import { getTeamObjectivesByTeamId, getTeamProgress, deleteObjective, updateTeamObjective } from "../../../redux/actions/teamsActions/teamObjectivesActions";
import { getOrganisationCycles } from "../../../redux/actions/cyclesActions/cyclesActions";
import {
  getKeyResultByObjective,
  getKeyResultContributions,
  updateUserKeyResults,
  updateTeamKeyResults,
  deleteTeamKeyResults,
  deleteUserKeyResults,
} from "../../../redux/actions/keyResult/keyResultActions";
import { LoadingTableContent } from "../../shared/loader/Loader";
import { successMessage, errorMessage } from "../../../redux/actions/notification/notification";

import "./objectives.scss";
import TeamObjectiveRows from "./teamObjectiveRows";
import EditObjectiveModal from "../../shared/editObjective/editObjective";
import EditTeamKeyResultModal from "./editTeamKeyResult/editTeamKeyResult";
import CheckinModal from "../../home/goals/checkin/checkinModal";
import EditUserKeyResultModal from "./editUserKeyResult/editUserKeyResult";

const Objectives = ({ teamId }) => {
  const dispatch = useDispatch();
  const [selectedLevel1, setSelectedLevel1] = useState("");
  const [selectedLevel2, setSelectedLevel2] = useState("");
  const [selectedLevel3, setSelectedLevel3] = useState("");
  const [showAlignKeyResultModal, setShowAlignKeyResultModal] = useState(false);
  const [showAddKeyResultModal, setShowAddKeyResultModal] = useState(false);
  const [searchTerm] = useState("");
  const [modalNewShow, setModalNewShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [keyResultModalEditShow, setKeyResultModalEditShow] = useState(false);
  const [userKeyResultModalEditShow, setUserKeyResultModalEditShow] = useState(false);
  const [modalViewShow, setModalViewShow] = React.useState(false);
  const [modalLevel, setModalLevel] = useState("");
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [addKeyResultValue, setAddKeyResultValue] = useState(null);
  const [alignment, setAlignment] = useState(null);
  const [viewModalData, setViewModalData] = useState({});
  const [alignedKeyResultsData, setAlignedKeyResultsData] = useState({});
  const [individualKeyResultData, setIndividualKeyResultData] = useState({});
  const [contributionsData, setContributionsData] = useState({});
  const [idForAction, setIdForAction] = useState("");
  const [levelToDelete, setLevelToDelete] = useState("");
  const [dataToEdit, setDataToEdit] = useState({});
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [checkinDetails, setCheckinDetails] = useState(null);

  const select = useSelector((state) => {
    return {
      teamsLoading: state.organizationTeams && state.organizationTeams.teamsLoading,
      cycleListLoading: state.organizationCycles && state.organizationCycles.orgCyclesLoading,
      cycleList: state.organizationCycles && state.organizationCycles.cycleList,
      cycleListError: state.organizationCycles && state.organizationCycles.cycleListError,
      allTeamObjectives: state.teamObjectives && state.teamObjectives.teamObjectivesPayload?.data?.data,
      objectivesLoading: state.teamObjectives && state.teamObjectives.objectivesLoading,
      contributionsLoading: state.keyResults && state.keyResults.contributionsLoading,
      keyResultsContributions: state.keyResults && state.keyResults.teamContributionsPayload?.data?.data.items,
      progressLoading: state.teamObjectives && state.teamObjectives.progressLoading,
      progressOverview: state.teamObjectives && state.teamObjectives.progressOverviewMock,
      levelTwoLoading: state.keyResults && state.keyResults.keyResultByObjectiveLoading,
      keyResultsByObjectives: state.keyResults && state.keyResults.keyResultByObjectivePayload?.data?.data?.items,
      deleteLoading: state.teamObjectives && state.teamObjectives.deleteLoading,
      deleteSuccessful: state.teamObjectives && state.teamObjectives.deleteSuccessful,
      deleteError: state.teamObjectives && state.teamObjectives.deleteError,
      deleteUserKeyResultLoading: state.keyResults && state.keyResults.deleteUserKeyResultLoading,
      deleteUserKeyResultPayload: state.keyResults && state.keyResults.deleteUserKeyResultPayload,
      deleteUserKeyResultError: state.keyResults && state.keyResults.deleteUserKeyResultError,
      deleteTeamKeyResultLoading: state.keyResults && state.keyResults.deleteTeamKeyResultLoading,
      deleteTeamKeyResultPayload: state.keyResults && state.keyResults.deleteTeamKeyResultPayload,
      deleteTeamKeyResultError: state.keyResults && state.keyResults.deleteTeamKeyResultError,
    };
  });

  const {
    teamsLoading,
    progressOverview,
    cycleListLoading,
    cycleList,
    cycleListError,
    keyResultsContributions,
    contributionsLoading,
    allTeamObjectives,
    progressLoading,
    objectivesLoading,
    keyResultsByObjectives,
    deleteSuccessful,
    levelTwoLoading,
    deleteLoading,
    deleteError,
    deleteUserKeyResultLoading,
    deleteUserKeyResultPayload,
    deleteUserKeyResultError,
    deleteTeamKeyResultLoading,
    deleteTeamKeyResultPayload,
    deleteTeamKeyResultError,
  } = select;

  useEffect(() => {
    dispatch(getOrganisationCycles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /********** set cycleList in dropdown *******/
  useEffect(() => {
    if (cycleList?.length > 0) {
      setSelectedCycle(cycleList[0]?.cycleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleList]);

  /********** Get team Objectives *******/
  useEffect(() => {
    if (teamId && selectedCycle) {
      dispatch(getTeamObjectivesByTeamId(teamId, selectedCycle));
      dispatch(getTeamProgress(teamId, selectedCycle));
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, selectedCycle]);

  /********** set Team Keyresults *******/
  useEffect(() => {
    setAlignedKeyResultsData(keyResultsByObjectives);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResultsByObjectives && keyResultsByObjectives?.length > 0]);

  /********** set Contribution keyresult *******/
  useEffect(() => {
    setContributionsData(keyResultsContributions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResultsContributions && keyResultsContributions?.length > 0]);

  /******show notification after deleting team objective */
  useEffect(() => {
    if (deleteSuccessful?.data?.success && !deleteLoading) {
      dispatch(successMessage(deleteSuccessful?.data?.message));
      setModalDeleteShow(false);
    } else {
      !deleteLoading && deleteError && dispatch(errorMessage(deleteError?.response?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  /****show notification after deleting contributing key result */
  useEffect(() => {
    if (deleteUserKeyResultPayload?.data?.success && !deleteUserKeyResultLoading) {
      dispatch(successMessage(deleteUserKeyResultPayload?.data?.message));
      setModalDeleteShow(false);
    } else {
      !deleteUserKeyResultLoading && deleteUserKeyResultError && dispatch(errorMessage(deleteUserKeyResultError?.response?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserKeyResultLoading]);

  /****show notification after deleting team key result */
  useEffect(() => {
    if (deleteTeamKeyResultPayload?.data?.success && !deleteTeamKeyResultLoading) {
      dispatch(successMessage(deleteTeamKeyResultPayload?.data?.message));
      setModalDeleteShow(false);
    } else {
      !deleteTeamKeyResultLoading && deleteTeamKeyResultError && dispatch(errorMessage(deleteTeamKeyResultError?.response?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTeamKeyResultLoading]);

  useEffect(() => {
    cycleListLoading && cycleListError && dispatch(errorMessage(cycleListError?.response?.data?.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleListLoading]);

  const selectObj = (level, id) => {
    if (level === "level1") {
      if (id === selectedLevel1) {
        setSelectedLevel1("");
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel1(id);
        setSelectedLevel2("");
        setSelectedLevel3("");
      }
    }
    if (level === "level2") {
      if (id === selectedLevel2) {
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel2(id);
        setSelectedLevel3("");
      }
    }
    if (level === "level3") {
      id === selectedLevel3 ? setSelectedLevel3("") : setSelectedLevel3(id);
    }
  };

  const handleSearch = () => {
    console.log("searched");
  };

  const handleSelectCycle = (evt) => {
    setSelectedCycle(evt.value);
  };

  const handleCheckinModal = (value, details) => {
    setShowCheckinModal(value);
    setCheckinDetails(details);
  };

  const getContributionsData = (keyResultId) => {
    dispatch(getKeyResultContributions(keyResultId));
  };

  const getKeyResultsData = (keyResultId) => {
    dispatch(getKeyResultByObjective(keyResultId));
  };

  const handleCheckinSubmit = (formValue) => {
    console.log("form value", formValue);
  };

  const handleDelete = (id, levelToDelete) => {
    if (levelToDelete === 1) {
      dispatch(deleteObjective(id));
    } else if (levelToDelete === 2) {
      dispatch(deleteTeamKeyResults(id));
    } else if (levelToDelete === 3) {
      dispatch(deleteUserKeyResults(id));
    }
  };

  const handleSpecificLevelDelete = (id, level) => {
    setIdForAction(id);
    setLevelToDelete(level);
  };

  // table actions
  const actions = [
    {
      id: 1,
      name: <span className="goal-action">View Details</span>,
      method: (e, data, level) => {
        setModalLevel(level);
        setViewModalData(data);
        if (level === 1) {
          dispatch(getKeyResultByObjective(data.id));
        } else if (level === 2) {
          dispatch(getKeyResultContributions(data.id));
        }
        setModalViewShow(true);
      },
    },
    {
      id: 2,
      name: <span className="goal-action">Edit</span>,
      method: (e, data, level) => {
        setModalLevel(level);
        setDataToEdit(data);
        if (level === 1) {
          setModalEditShow(true);
        } else {
          level === 2 ? setKeyResultModalEditShow(true) : setUserKeyResultModalEditShow(true);
        }
      },
    },
    {
      id: 3,
      name: <span className="goal-action">Delete</span>,
      method: (e, data, level) => {
        setModalDeleteShow(true);

        if (level !== 3) {
          handleSpecificLevelDelete(data.id, level);
        } else {
          handleSpecificLevelDelete(data.keyResultId, level);
        }
      },
    },
  ];

  const tableRow = (value, index, level = 1) => {
    let selectedLevel = "";

    if (level === 1) {
      selectedLevel = selectedLevel1;
    } else if (level === 2) {
      selectedLevel = selectedLevel2;
    } else if (level === 3) {
      selectedLevel = selectedLevel3;
    }
    return (
      <TeamObjectiveRows
        value={value}
        index={index}
        level={level}
        allTeamObjectives={allTeamObjectives}
        actions={actions}
        selectedLevel={selectedLevel}
        selectObj={selectObj}
        tableRow={tableRow}
        getContributionsData={getContributionsData}
        getKeyResultsData={getKeyResultsData}
      />
    );
  };

  const tableHeads = [
    { id: 2, name: "Title" },
    { id: 3, name: "Owner" },
    { id: 4, name: "Last Update" },
    { id: 5, name: "Status" },
  ];

  const getProgress = (data) => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  return (
    <div className="row teams-objectives-container">
      <div className="col">
        <div className="row tabContent-title">
          <div className="col-4 pl-0">{objectivesLoading || cycleListLoading || teamsLoading ? <LineLoader lines={1} /> : <PageTitle title={`${allTeamObjectives?.items?.length} Objectives`} />}</div>

          <div className="col-8 pr-0 d-flex flex-column flex-md-row align-items-end justify-content-end">
            <div className="pb-2 mr-3 p-md-0 select-cycle">
              <PMSSelect
                placeholder="Cycles"
                dataSource={cycleList}
                closeOnActionClick={true}
                displayExpr="description"
                valueExpr="cycleId"
                onChange={handleSelectCycle}
                menuWidth="220px"
                value={selectedCycle}
              />
            </div>
            <Button size="sm" color="blue" handleClick={() => setModalNewShow(true)} className="pull-right" data-testid="add-objectives">
              Add Objective
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <PMSTooltip content={<OverviewColors data={progressOverview?.overview} />} direction="bottom" width={247}>
              <div className="label">
                <span className="mr-2">Overview</span>
                {SVGS.info()}
              </div>
              <div className="label-text">
                <div className="progressBar">{progressLoading ? <LineLoader lines={1} /> : <ProgressBar>{getProgress(progressOverview?.overview)}</ProgressBar>}</div>
              </div>
            </PMSTooltip>
          </div>

          <div className="col-auto">
            <div className="label">{progressLoading ? <LineLoader lines={1} /> : `${progressOverview?.avgProgress}%`}</div>
            <div className="label-text">Average Progress</div>
          </div>
        </div>
        <div className="row sub-items">
          <div className="col-auto objectives-searchbar">
            <SearchBar placeholder="Search goals" handleSearch={handleSearch} searchTerm={searchTerm} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {objectivesLoading || cycleListLoading || teamsLoading ? (
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeads.map((tableHead) => {
                      return <th key={tableHead.id}>{tableHead.name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <LoadingTableContent colSpan={tableHeads.length} />
                </tbody>
              </Table>
            ) : (
              // {/*Accordion Table Wrapper */}
              <Table responsive id="accordionTable1">
                <thead>
                  <tr>
                    {tableHeads.map((tableHead) => {
                      return <th key={tableHead.id}>{tableHead.name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody className="panel">
                  {allTeamObjectives?.items &&
                    allTeamObjectives?.items.map((level1, index) => {
                      return tableRow(level1, index);
                    })}
                </tbody>
              </Table>
            )}
            {allTeamObjectives?.items && allTeamObjectives?.items?.length === 0 && (
              <div className="empty-content">
                <img src={DocumentIcon} alt="empty goals" />
                <div className="no-goals">You have not added any Team Objectives for this cycle</div>
                <div className="add-goal">Click on the “Add Objective” button to get started</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <NewObjectives
        modalShow={modalNewShow}
        setModalShow={setModalNewShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        setShowAddKeyResultModal={setShowAddKeyResultModal}
        addKeyResultValue={addKeyResultValue}
        setAddKeyResultValue={setAddKeyResultValue}
        setAlignment={setAlignment}
        alignment={alignment}
        teamId={teamId}
        cycleList={cycleList}
      />
      <EditObjectiveModal
        modalShow={modalEditShow}
        setModalShow={setModalEditShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        details={dataToEdit}
        updateTeamObjective={updateTeamObjective}
        modalLevel={modalLevel}
        setAlignment={setAlignment}
        alignment={alignment}
        cycleList={cycleList}
        selectedCycle={selectedCycle}
        setShowAddKeyResultModal={setShowAddKeyResultModal}
        addKeyResultValue={addKeyResultValue}
        setAddKeyResultValue={setAddKeyResultValue}
      />
      <EditTeamKeyResultModal
        modalShow={keyResultModalEditShow}
        setModalShow={setKeyResultModalEditShow}
        details={dataToEdit}
        updateTeamKeyResults={updateTeamKeyResults}
        modalLevel={modalLevel}
        cycleList={cycleList}
        selectedCycle={selectedCycle}
      />
      <DeleteModal
        modalShow={modalDeleteShow}
        setModalShow={setModalDeleteShow}
        onConfirm={() => {
          handleDelete(idForAction, levelToDelete);
        }}
      />
      <EditUserKeyResultModal
        modalShow={userKeyResultModalEditShow}
        setModalShow={setUserKeyResultModalEditShow}
        setShowAlignKeyResultModal={setShowAlignKeyResultModal}
        details={dataToEdit}
        updateKeyResults={updateUserKeyResults}
        modalLevel={modalLevel}
        setAlignment={setAlignment}
        alignment={alignment}
        cycleList={cycleList}
        selectedCycle={selectedCycle}
      />
      <ViewObjectives
        details={viewModalData}
        alignedKeyResultsData={alignedKeyResultsData}
        levelTwoLoading={levelTwoLoading}
        modalShow={modalViewShow}
        modalLevel={modalLevel}
        setModalShow={setModalViewShow}
        getContributionsData={getContributionsData}
        setIndividualKeyResultData={setIndividualKeyResultData}
        individualKeyResultData={individualKeyResultData}
        contributionsData={contributionsData}
        contributionsLoading={contributionsLoading}
        handleSpecificLevelDelete={handleSpecificLevelDelete}
        setModalDeleteShow={setModalDeleteShow}
        setModalEditShow={setModalEditShow}
        setKeyResultModalEditShow={setKeyResultModalEditShow}
        setUserKeyResultModalEditShow={setUserKeyResultModalEditShow}
        // handleEditObjectives={handleEditObjectives}
        setDataToEdit={setDataToEdit}
        // showCheckinModal={showCheckinModal}
        handleCheckinModal={handleCheckinModal}
        // handleCheckinSubmit={handleCheckinSubmit}
        selectedCycle={selectedCycle}
      />
      <AlignKeyResultModal
        openModal={showAlignKeyResultModal}
        alignment={alignment}
        setAlignment={setAlignment}
        closeModal={() => {
          setShowAlignKeyResultModal(false);
        }}
      />
      <AddKeyResultModal
        setAddKeyResultValue={setAddKeyResultValue}
        openModal={showAddKeyResultModal}
        closeModal={() => {
          setShowAddKeyResultModal(false);
        }}
      />
      <CheckinModal modalShow={showCheckinModal} setModalShow={setShowCheckinModal} formTitle="Key Result title" titleIcon={SVGS.teamsIcon()} onSubmit={handleCheckinSubmit} details={checkinDetails} />
    </div>
  );
};

export default Objectives;
