import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SearchBar, PMSSelect, PMSTooltip, CustomTable, OverviewColors } from "../../../shared";
import ProfileIcon from "../../../../assets/images/profile.svg";
// import CustomTable from "../../../shared/table/table";
import avatar from "../../../../assets/images/avatar.svg";
import { Urls } from "../../../../utils/urls";
// import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import { ProgressBar } from "react-bootstrap";
import { getPercentColor } from "../../../../utils/helpers";
import EditKeyResultModal from "../../../shared/editKeyResult/editKeyResult";
import DeleteModal from "../../../shared/deleteModal/deleteModal";
import CheckinModal from "../../../shared/checkin/checkinModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserKeyResults } from "../../../../redux/actions/keyResult/keyResultActions";
import { postUserCheckin } from "../../../../redux/actions/userActions/userActivityActions";
import { getOrganisationCycles } from "../../../../redux/actions/cyclesActions/cyclesActions";
import ViewKeyResultsModal from "../../../shared/viewKeyResultsSideModal/viewKeyResultsModal";
import SVGS from "../../../../utils/svgs";

import "./viewMemberProfile.scss";

const ViewMemberProfile = (details) => {
  const userId = details?.match?.params?.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const [memberProfileDetails, setMemberProfileDetails] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCycle, setSelectedCycle] = useState("");
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [modalViewShow, setModalViewShow] = useState(false);
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [activeTab, setActiveTab] = useState(null);

  const select = useSelector((state) => {
    return {
      cycleList: state.organizationCycles && state.organizationCycles.cycleList,
      userKeyResults: state.keyResults && state.keyResults.userKeyResults?.data?.data,
      isLoading: state.keyResults && state.keyResults.isLoading,
      checkinSuccess: state.userActivity && state.userActivity.checkinSuccess,
      checkinError: state.userActivity && state.userActivity.checkinError,
    };
  });

  const { cycleList, userKeyResults, isLoading, checkinError } = select;

  useEffect(() => {
    dispatch(getOrganisationCycles());
    setActiveTab(history?.location?.state?.tab);
    setMemberProfileDetails(history?.location?.state?.memberProfile);
    return () => {
      setActiveTab(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cycleList.length > 0 && userId) {
      dispatch(getUserKeyResults(userId, cycleList[0]?.cycleId));
      setSelectedCycle(cycleList[0]?.cycleId);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleList, userId]);

  useEffect(() => {
    setModalViewShow(false);
    setShowCheckinModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkinError === true]);

  // search table
  const handleSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const getPathname = (tab) => {
    if (tab === "people") {
      return Urls.Company;
    } else if (tab === "Members") {
      return Urls.Teams;
    } else {
      return "/";
    }
  };

  const handleBackClick = () => {
    history.push({
      pathname: getPathname(activeTab),
      state: { tab: activeTab },
    });
  };

  const handleSelectCycle = (evt) => {
    setSelectedCycle(evt.value);
    dispatch(getUserKeyResults(userId, evt.value));
  };

  const handleRowClick = (val) => {
    const data = {
      ...val,
      ...memberProfileDetails,
    };
    setModalData(data);
    setModalViewShow(true);
  };

  const submitCheckin = (val) => {
    let finalData = {
      ...val,
      reviewCycleId: selectedCycle,
      keyresultId: modalData.id,
    };

    dispatch(postUserCheckin(finalData));
  };

  // Overview progress bar
  const progressOverview = {
    avgProgress: 65.3,
    overview: {
      pending: 6,
      atRisk: 9,
      completed: 15,
      behind: 3,
      onTrack: 32,
      notStarted: 7,
    },
  };

  const tableHeads = [
    { id: 1, name: "Title" },
    { id: 2, name: "Last Update" },
    { id: 3, name: "Status" },
  ];

  const arrangeTableData = (tableData) => {
    if (tableData) {
      return tableData.map((value, index) => {
        return (
          <tr key={index + 1} onClick={() => handleRowClick(value)}>
            <td>
              <div className="td-title">
                {value?.description} <img src={ProfileIcon} alt="profile" />
              </div>
              <div className="td-subtitle">
                <span style={{ fontWeight: "bold" }}>{value?.parentObjective?.team?.teamName} </span>
                <span> {value?.parentObjective?.description}</span>
              </div>
            </td>
            <td className="td-date">{value?.lastUpdatedDate ? value?.lastUpdatedDate : "-"}</td>
            <td>
              <div className="approved">
                <ProgressBar data-testid="progress-bar" now={value?.progress} variant={getPercentColor(value?.progress)} />
                <span className="percent-count">{value?.progress}%</span>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  const search = (rows) => {
    const columns = ["description", "lastUpdatedDate", "status"];
    if (rows && rows !== []) {
      return rows.filter((row) =>
        columns.some((column) => {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
        })
      );
    }
  };

  const getProgress = (data) => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  return (
    <section className="row home-container">
      <div className="col">
        <div className="d-flex" style={{ marginBottom: "24px" }}>
          <i className="ri-arrow-left-line goback" onClick={handleBackClick}></i>
        </div>

        <div className="d-flex">
          <img className="mr-1 member-avatar" src={avatar} alt="user avatar"></img>
          {/* <img className="mr-3 table-avatar" src={value.owner?.image ? value.owner.image : avatar}></img> */}
          <div>
            <div data-testid="profile-name" className="name">{`${memberProfileDetails?.firstName} ${memberProfileDetails?.lastName}`}</div>
            <div data-testid="profile-job" className="sub-title">
              {memberProfileDetails?.jobTitle}
            </div>
          </div>
        </div>
        <div className="card-body px-0">
          <div className="row team-member-container">
            <div className="col">
              <div className="d-flex justify-content-between">
                <div className="title">{userKeyResults ? userKeyResults?.items?.length : 0} Key Results</div>
                <div className="col-3 pr-0">
                  <PMSSelect
                    placeholder="Cycles"
                    dataSource={cycleList}
                    closeOnActionClick={true}
                    displayExpr="description"
                    valueExpr="cycleId"
                    onChange={handleSelectCycle}
                    menuWidth="220px"
                    value={selectedCycle}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <PMSTooltip content={<OverviewColors data={progressOverview?.overview} />} direction="bottom" width={247}>
                    <div className="label">
                      <span className="mr-2">Overview</span>
                      {SVGS.info()}
                    </div>
                    <div className="label-text">
                      {/* <div className="progressBar">{progressLoading ? <LineLoader lines={1} /> : <ProgressBar>{getProgress(overview)}</ProgressBar>}</div> */}
                      <ProgressBar>{getProgress(progressOverview?.overview)}</ProgressBar>
                    </div>
                  </PMSTooltip>
                </div>

                <div className="col-auto">
                  <div data-testid="profile-average" className="label">
                    {progressOverview?.avgProgress}%
                  </div>
                  <div className="label-text">Average Score</div>
                </div>
              </div>
              <div className="row sub-items">
                <div className="col-auto">
                  <SearchBar placeholder="Search goals" handleSearch={handleSearch} searchTerm={searchTerm} />
                </div>
              </div>
              <CustomTable
                arrangedTableData={arrangeTableData(search(userKeyResults?.items))}
                tableHeads={tableHeads}
                isLoading={isLoading}
                tableData={userKeyResults?.items}
                searchTableData={search(userKeyResults?.items)}
                emptyStateInfoText="This user has not added any Key Results for this Cycle"
                showPaginator={false}
              />
            </div>
            <CheckinModal
              show={showCheckinModal}
              setShow={showCheckinModal}
              onSubmit={submitCheckin}
              checkinError={checkinError}
              closeModal={() => {
                setModalViewShow(true);
                setShowCheckinModal(false);
              }}
            />
            <EditKeyResultModal modalShow={modalEditShow} setModalShow={setModalEditShow} />
            <DeleteModal modalShow={modalDeleteShow} setModalShow={setModalDeleteShow} />
            <ViewKeyResultsModal
              useJobTitle
              // checkin={true}
              modalShow={modalViewShow}
              setModalShow={setModalViewShow}
              setModalEditShow={setModalEditShow}
              setModalDeleteShow={setModalDeleteShow}
              details={modalData}
              setModalCheckinShow={setShowCheckinModal}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewMemberProfile;
