import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { COMPANY_INSIGHTS_APIS } from "../../../apiServices/endPoints";

export const insightsActionTypes = {
  GET_TEAMS_SESSIONS: "[COMPANY - INSIGHTS] GET_TEAMS_SESSIONS",
  GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE: "[COMPANY - INSIGHTS] GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE",
  GET_ONE_ON_ONE_STAFF: "[COMPANY - INSIGHTS] GET_ONE_ON_ONE_STAFF",
  GET_TEAMS_OPTIONS: "[COMPANY - INSIGHTS] GET_TEAMS_OPTIONS",
  GET_OKR_TEAMS: "[COMPANY - INSIGHTS] GET_OKR_TEAMS",
  GET_OKR_PEOPLE: "[COMPANY - INSIGHTS] GET_OKR_PEOPLE",
};

export const LoadTeamsSessions = createAsyncAction(insightsActionTypes.GET_TEAMS_SESSIONS, async (timeLine) => {
  return await Get(`${COMPANY_INSIGHTS_APIS.getTeamsDestribution}/${timeLine}`);
});

export const LoadTeamsCustomTimelineSessions = createAsyncAction(insightsActionTypes.GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE, async ({ startDate, endDate }, filterBy) => {
  return await Get(`${COMPANY_INSIGHTS_APIS.getTeamsDestribution}/?startDate=${startDate}?endDate=${endDate}?filterBy=${filterBy}`);
});

export const LoadOneOnOneStaff = createAsyncAction(insightsActionTypes.GET_ONE_ON_ONE_STAFF, async ({ filterBy }) => {
  return await Get(`${COMPANY_INSIGHTS_APIS.getOneOnOneStaff}/?team=${filterBy}`);
});

export const LoadTeamsOptions = createAsyncAction(insightsActionTypes.GET_TEAMS_OPTIONS, async () => {
  return await Get(COMPANY_INSIGHTS_APIS.getTeamsOptions);
});

export const LoadOkrTeams = createAsyncAction(insightsActionTypes.GET_OKR_TEAMS, async ({ cycleOption }) => {
  return await Get(`${COMPANY_INSIGHTS_APIS.getTeamsOptions}/team/${cycleOption}`);
});

export const LoadOkrPeople = createAsyncAction(insightsActionTypes.GET_OKR_PEOPLE, async ({ cycleOption }) => {
  return await Get(`${COMPANY_INSIGHTS_APIS.getTeamsOptions}/people/${cycleOption}`);
});
