import React, { useState } from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import DisapproveForm from "./disapproveForm";

const DisapproveModal = ({ modalShow, setModalShow }) => {
  const [submitForm] = useState(false);
  const [form, setForm] = useState(null);
  const validForm = (event) => {
    if (event !== undefined) {
      setForm(event);
    }
  };

  return (
    <div className="checkin-modal-content">
      <PMSModal
        title="Disapprove Goal"
        openModal={modalShow}
        handlePrimaryButtonClick={() => setModalShow(false)}
        processing={false}
        primaryButtonText="Send"
        containerId="user-editor"
        closeModal={() => setModalShow(false)}
        contentClassName=""
        useHeaderBorder={false}
        disablePrimaryButton={!form}
        closeOnOutsideClick={false}
      >
        <DisapproveForm submitForm={submitForm} validForm={(e) => validForm(e)} />
      </PMSModal>
    </div>
  );
};
export default DisapproveModal;
