import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveObjectivesDrafts } from "../../../../store/objectives/obectives-actions";

// import "./draft.scss";

const ObjectivesDraft = ({ resumeDraft }) => {
  const dispatch = useDispatch();
  const select = useSelector((state) => {
    return {
      objectivesDrafts: state.teamObjectives && state.teamObjectives.objectivesDraft,
    };
  });

  const handleClick = (draft) => {
    let draftData = select.objectivesDrafts.filter((item) => {
      return item !== draft;
    });
    dispatch(saveObjectivesDrafts(draftData));
    resumeDraft(draft);
  };
  console.log({ select });

  return (
    <>
      <div className="draft">Drafts</div>
      {select.objectivesDrafts &&
        select.objectivesDrafts.map((draft, index) => {
          return (
            <div key={index} className="draft-content">
              <div className="desc">{draft.description}</div>
              <div className="result-key">{draft.keyResults && draft.keyResults.length} Key Results</div>
              <Link onClick={() => handleClick(draft)} className="resume">
                Resume
              </Link>
            </div>
          );
        })}
    </>
  );
};
export default ObjectivesDraft;
