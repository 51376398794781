import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ViewContent from "../shared/viewContent/viewContent";
import Insights from "./insights/teamInsights";
import LineReports from "./lineReports/lineReports";
import Objectives from "./objectives/teamObjectives";
import TeamMembers from "./teamMembers/teamMembers";
import PMSSelect from "../shared/selectInput/pmsSelect/pmsSelect";
import { useDispatch, useSelector } from "react-redux";
import { getOrgTeams } from "../../redux/actions/teamsActions/teamActions";
import { createDropdownList } from "../../utils/helpers";
import { errorMessage } from "../../redux/actions/notification/notification";

import "./teams.scss";

const Teams = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tab = history?.location?.state?.tab;

  const [key, setKey] = useState(tab ? tab : "Objectives");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);
  const tabDetails = [
    {
      id: 1,
      key: "Objectives",
      title: "Objectives",
      content: <Objectives teamId={selectedTeam} />,
    },
    { id: 2, key: "Reports", title: "Line Reports", content: <LineReports teamDetails={teamDetails} /> },
    { id: 3, key: "Members", title: "Teams Members", content: <TeamMembers teamId={selectedTeam} /> },
    { id: 4, key: "Insights", title: "Insights", content: <Insights teamId={selectedTeam} /> },
  ];

  const select = useSelector((state) => {
    return {
      teamsLoading: state.organizationTeams && state.organizationTeams.teamsLoading,
      allTeamsError: state.organizationTeams && state.organizationTeams.allTeamsError,
      allTeams: state.organizationTeams && state.organizationTeams.allTeams?.data?.data,
    };
  });

  const { teamsLoading, allTeamsError } = select;

  useEffect(() => {
    dispatch(getOrgTeams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // retain selected tab on reload
  useEffect(() => {
    history.push({
      state: { tab: key },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    teamsLoading && allTeamsError && dispatch(errorMessage(allTeamsError?.response?.data?.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsLoading]);

  useEffect(() => {
    if (select.allTeams) {
      setSelectedTeam(select.allTeams[0].teamId);
      setTeamDetails(select.allTeams[0]);
    }
  }, [select.allTeams]);

  const handleSelect = (evt) => {
    setSelectedTeam(evt.value);
    getTeamOwnerId(select.allTeams, evt.value);
  };

  const getTeamOwnerId = (options, teamId) => {
    if (options !== []) {
      return options.filter((option) => {
        if (option["teamId"] === teamId) {
          setTeamDetails(option);
        }
        return option;
      });
    }
  };

  return (
    <section className="row">
      <div className="col">
        <div className="teams-header d-flex justify-content-between">
          <span className="title">Teams</span>
          <div className="select-cycle">
            <PMSSelect
              placeholder="Teams"
              dataSource={createDropdownList(select.allTeams, "teamId", "teamName")}
              closeOnActionClick={true}
              displayExpr="teamName"
              valueExpr="teamId"
              onChange={handleSelect}
              menuWidth="220px"
              value={selectedTeam}
            />
          </div>
        </div>
        <div className="card-body px-0">
          <ViewContent activeKey={key} tabDetails={tabDetails} handleSelect={(k) => setKey(k)} />
        </div>
      </div>
    </section>
  );
};

export default Teams;
