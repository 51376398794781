import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import EditUserKeyResultForm from "./editUserKeyResultForm";

const EditUserKeyResultModal = ({ modalShow, setModalShow, setShowAlignKeyResultModal, setAlignment, alignment, setFormValues, formValues, details, cycleList, updateKeyResults, selectedCycle }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Edit Key Result" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <EditUserKeyResultForm
                setShowAlignKeyResultModal={setShowAlignKeyResultModal}
                setModalShow={setModalShow}
                details={details}
                setAlignment={setAlignment}
                alignment={alignment}
                setFormValues={setFormValues}
                formValues={formValues}
                cycleList={cycleList}
                updateKeyResults={updateKeyResults}
                selectedCycle={selectedCycle}
              />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default EditUserKeyResultModal;
