import React from "react";
import SideModal from "../../shared/sideModal/sideModal";
import "./editKeyResult.scss";
import EditKeyResultForm from "./editKeyResultForm/editKeyResultForm";

const EditKeyResultModal = ({ modalShow, setModalShow, setShowAlignKeyResultModal, setAlignment, alignment, setFormValues, formValues, dataToEdit, cycleList, updateKeyResults, selectedCycle }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} model={formValues} setModalShow={setModalShow} title="Edit Key Result" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <EditKeyResultForm
                setShowAlignKeyResultModal={setShowAlignKeyResultModal}
                setModalShow={setModalShow}
                dataToEdit={dataToEdit}
                setAlignment={setAlignment}
                alignment={alignment}
                setFormValues={setFormValues}
                formValues={formValues}
                cycleList={cycleList}
                selectedCycle={selectedCycle}
                updateKeyResults={updateKeyResults}
              />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default EditKeyResultModal;
