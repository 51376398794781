import { keyResultType } from "../../actions/keyResult/keyResultActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INTIAL_STATE = {
  authData: [],
  isLinkableRoute: false,
  isLoading: false,
};

export default (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(keyResultType.GET_CHECKIN_BY_ID):
      return { ...state, getCheckinByIdLoading: true };
    case SUCCESS(keyResultType.GET_CHECKIN_BY_ID):
      return {
        ...state,
        getCheckinByIdPayload: actions.payload,
        getCheckinByIdLoading: false,
      };
    case FAILURE(keyResultType.GET_CHECKIN_BY_ID):
      return {
        ...state,
        getCheckinByIdError: actions.payload,
        getCheckinByIdLoading: false,
      };

    case REQUEST(keyResultType.GET_USER_KEY_RESULT_BY_ID):
      return { ...state, getKeyResultByIdLoading: true };
    case SUCCESS(keyResultType.GET_USER_KEY_RESULT_BY_ID):
      return {
        ...state,
        getKeyResultByIdPayload: actions.payload,
        getKeyResultByIdLoading: false,
      };
    case FAILURE(keyResultType.GET_USER_KEY_RESULT_BY_ID):
      return {
        ...state,
        getKeyResultByIdError: actions.payload,
        getKeyResultByIdLoading: false,
      };

    case REQUEST(keyResultType.POST_TEAM_KEY_RESULTS):
      return { ...state, postKeyResultLoading: true };
    case SUCCESS(keyResultType.POST_TEAM_KEY_RESULTS):
      return {
        ...state,
        postKeyResultPayload: actions.payload,
        postKeyResultLoading: false,
      };
    case FAILURE(keyResultType.POST_TEAM_KEY_RESULTS):
      return {
        ...state,
        postKeyResultError: actions.payload,
        postKeyResultLoading: false,
      };

    case REQUEST(keyResultType.UPDATE_KEY_RESULTS):
      return { ...state, updateKeyResultLoading: true };
    case SUCCESS(keyResultType.UPDATE_KEY_RESULTS):
      return {
        ...state,
        updateKeyResultPayload: actions.payload,
        updateKeyResultLoading: false,
      };
    case FAILURE(keyResultType.UPDATE_KEY_RESULTS):
      return {
        ...state,
        updateKeyResultError: actions.payload,
        updateKeyResultLoading: false,
      };

    case REQUEST(keyResultType.GET_TEAM_KEY_RESULTS):
      return { ...state, keyResultLoading: true };
    case SUCCESS(keyResultType.GET_TEAM_KEY_RESULTS):
      return {
        ...state,
        keyResultPayload: actions.payload,
        keyResultLoading: false,
      };
    case FAILURE(keyResultType.GET_TEAM_KEY_RESULTS):
      return {
        ...state,
        keyResultError: actions.payload,
        keyResultLoading: false,
      };

    // Get key result contributions
    case REQUEST(keyResultType.GET_KEY_RESULTS_CONTRIBUTIONS):
      return {
        ...state,
        contributionsLoading: true,
      };
    case SUCCESS(keyResultType.GET_KEY_RESULTS_CONTRIBUTIONS):
      return {
        ...state,
        teamContributionsPayload: actions.payload,
        contributionsLoading: false,
      };
    case FAILURE(keyResultType.GET_KEY_RESULTS_CONTRIBUTIONS):
      return {
        ...state,
        contributionsError: actions.payload,
        contributionsLoading: false,
      };

    // User Key results Reducers

    case REQUEST(keyResultType.UPDATE_TEAM_KEY_RESULTS):
      return {
        ...state,
        updateTeamKeyResultLoading: true,
      };
    case SUCCESS(keyResultType.UPDATE_TEAM_KEY_RESULTS):
      return {
        ...state,
        updateTeamKeyResultPayload: actions.payload,
        updateTeamKeyResultLoading: false,
      };
    case FAILURE(keyResultType.UPDATE_TEAM_KEY_RESULTS):
      return {
        ...state,
        updateTeamKeyResultError: actions.payload,
        updateTeamKeyResultLoading: false,
      };

    case REQUEST(keyResultType.UPDATE_USER_KEY_RESULTS):
      return {
        ...state,
        updateUserKeyResultLoading: true,
      };
    case SUCCESS(keyResultType.UPDATE_USER_KEY_RESULTS):
      return {
        ...state,
        updateUserKeyResultPayload: actions.payload,
        updateUserKeyResultLoading: false,
      };
    case FAILURE(keyResultType.UPDATE_USER_KEY_RESULTS):
      return {
        ...state,
        updateUserKeyResultError: actions.payload,
        updateUserKeyResultLoading: false,
      };

    case REQUEST(keyResultType.DELETE_USER_KEY_RESULTS):
      return {
        ...state,
        deleteUserKeyResultLoading: true,
      };
    case SUCCESS(keyResultType.DELETE_USER_KEY_RESULTS):
      return {
        ...state,
        deleteUserKeyResultPayload: actions.payload,
        deleteUserKeyResultLoading: false,
      };
    case FAILURE(keyResultType.DELETE_USER_KEY_RESULTS):
      return {
        ...state,
        deleteUserKeyResultError: actions.payload,
        deleteUserKeyResultLoading: false,
      };

    case REQUEST(keyResultType.DELETE_TEAM_KEY_RESULTS):
      return {
        ...state,
        deleteTeamKeyResultLoading: true,
      };
    case SUCCESS(keyResultType.DELETE_TEAM_KEY_RESULTS):
      return {
        ...state,
        deleteTeamKeyResultPayload: actions.payload,
        deleteTeamKeyResultLoading: false,
      };
    case FAILURE(keyResultType.DELETE_TEAM_KEY_RESULTS):
      return {
        ...state,
        deleteTeamKeyResultError: actions.payload,
        deleteTeamKeyResultLoading: false,
      };

    // User Key Results by Id and Cycle
    case REQUEST(keyResultType.GET_USER_KEY_RESULTS):
      return { ...state, isLoading: true };
    case SUCCESS(keyResultType.GET_USER_KEY_RESULTS):
      return {
        ...state,
        userKeyResults: actions.payload,
        isLoading: false,
      };
    case FAILURE(keyResultType.GET_USER_KEY_RESULTS):
      return {
        ...state,
        userKeyResultError: actions.payload,
        isLoading: false,
      };

    case REQUEST(keyResultType.GET_USER_KEY_RESULT_LIST):
      return {
        ...state,
        userKeyResultListLoading: true,
      };
    case SUCCESS(keyResultType.GET_USER_KEY_RESULT_LIST):
      return {
        ...state,
        userKeyResultListPayload: actions.payload,
        userKeyResultListLoading: false,
      };
    case FAILURE(keyResultType.GET_USER_KEY_RESULT_LIST):
      return {
        ...state,
        userKeyResultListError: actions.payload,
        userKeyResultListLoading: false,
      };

    case REQUEST(keyResultType.POST_USER_KEY_RESULT):
      return { ...state, postUserKeyResultLoading: true };
    case SUCCESS(keyResultType.POST_USER_KEY_RESULT):
      return {
        ...state,
        postUserKeyResultPayload: actions.payload,
        postUserKeyResultLoading: false,
      };
    case FAILURE(keyResultType.POST_USER_KEY_RESULT):
      return {
        ...state,
        postUserKeyResultError: actions.payload,
        postUserKeyResultLoading: false,
      };

    case REQUEST(keyResultType.UPDATE_USER_KEY_RESULT):
      return { ...state, updateUserKeyResultLoading: true };
    case SUCCESS(keyResultType.UPDATE_USER_KEY_RESULT):
      return {
        ...state,
        updateUserKeyResultPayload: actions.payload,
        updateUserKeyResultLoading: false,
      };
    case FAILURE(keyResultType.UPDATE_USER_KEY_RESULT):
      return {
        ...state,
        updateUserKeyResultError: actions.payload,
        updateUserKeyResultLoading: false,
      };

    case REQUEST(keyResultType.SUBMIT_USER_REVIEW):
      return { ...state, submitUserReviewLoading: true };
    case SUCCESS(keyResultType.SUBMIT_USER_REVIEW):
      return {
        ...state,
        submitUserReviewPayload: actions.payload,
        submitUserReviewLoading: false,
      };
    case FAILURE(keyResultType.SUBMIT_USER_REVIEW):
      return {
        ...state,
        submitUserReviewError: actions.payload,
        submitUserReviewLoading: false,
      };

    case REQUEST(keyResultType.KEYRESULTS_BY_OBJECTIVE):
      return {
        ...state,
        keyResultByObjectiveLoading: true,
      };

    case SUCCESS(keyResultType.KEYRESULTS_BY_OBJECTIVE):
      return {
        ...state,
        keyResultByObjectivePayload: actions.payload,
        keyResultByObjectiveLoading: false,
      };
    case FAILURE(keyResultType.KEYRESULTS_BY_OBJECTIVE):
      return {
        ...state,
        keyResultByObjectiveError: actions.payload,
        keyResultByObjectiveLoading: false,
      };

    case REQUEST(keyResultType.ALIGN_KEY_RESULTS):
      return { ...state, alignKeyResultLoading: true };
    case SUCCESS(keyResultType.ALIGN_KEY_RESULTS):
      return {
        ...state,
        alignKeyResultPayload: actions.payload,
        alignKeyResultLoading: false,
      };
    case FAILURE(keyResultType.ALIGN_KEY_RESULTS):
      return {
        ...state,
        alignKeyResultError: actions.payload,
        alignKeyResultLoading: false,
      };
    case REQUEST(keyResultType.GET_REVIEW_CYCLE):
      return { ...state, reviewCycleLoading: true };
    case SUCCESS(keyResultType.GET_REVIEW_CYCLE):
      return {
        ...state,
        reviewCyclePayload: actions.payload,
        reviewCycleLoading: false,
      };
    case FAILURE(keyResultType.GET_REVIEW_CYCLE):
      return {
        ...state,
        reviewCycleError: actions.payload,
        reviewCycleLoading: false,
      };
    case REQUEST(keyResultType.GET_PARENT_KEYRESULT_TEAMID):
      return { ...state, getParentKeyResultByTeamLoading: true };
    case SUCCESS(keyResultType.GET_PARENT_KEYRESULT_TEAMID):
      return {
        ...state,
        getParentKeyResultByTeamPayload: actions.payload,
        getParentKeyResultByTeamLoading: false,
      };
    case FAILURE(keyResultType.GET_PARENT_KEYRESULT_TEAMID):
      return {
        ...state,
        getParentKeyResultByTeamError: actions.payload,
        getParentKeyResultByTeamLoading: false,
      };
    case REQUEST(keyResultType.GET_OBJECTIVE_TEAMID):
      return { ...state, getObjectiveTeamIdLoading: true };
    case SUCCESS(keyResultType.GET_OBJECTIVE_TEAMID):
      return {
        ...state,
        getObjectiveTeamIdPayload: actions.payload,
        getObjectiveTeamIdLoading: false,
      };
    case FAILURE(keyResultType.GET_OBJECTIVE_TEAMID):
      return {
        ...state,
        getObjectiveTeamIdError: actions.payload,
        getObjectiveTeamIdLoading: false,
      };

    case REQUEST(keyResultType.USER_DRAFT):
      return { ...state, getUserDraftLoading: true };
    case SUCCESS(keyResultType.USER_DRAFT):
      return {
        ...state,
        getUserDraftPayload: actions.payload,
        getUserDraftLoading: false,
      };
    case FAILURE(keyResultType.USER_DRAFT):
      return {
        ...state,
        getUserDraftError: actions.payload,
        getUserDraftLoading: false,
      };

    default:
      return { ...state };
  }
};
