import { userDetails } from "./user.service";

export const baseUrl = "https://claritapi.test.vggdev.com/api";

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return null;
};
