import React from "react";
import "./infoTip.scss";

const InfoTip = ({ itemName, infoText, itemStyle }) => {
  return (
    <>
      <span style={{ ...itemStyle }}>{itemName}</span>
      <span className="info-tip-container ml-1">
        <span data-text={infoText} className="info-icon info-tip__right">
          <i className="ri-information-line"></i>
        </span>{" "}
      </span>
    </>
  );
};

export default InfoTip;
