import { Get, Post, Put, Delete } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const keyResultType = {
  GET_KEY_RESULTS: "GET_KEY_RESULTS",
  GET_TEAM_KEY_RESULTS: "GET_TEAM_KEY_RESULTS",
  POST_TEAM_KEY_RESULTS: "POST_TEAM_KEY_RESULTS",
  DELETE_TEAM_KEY_RESULTS: "DELETE_TEAM_KEY_RESULTS",
  UPDATE_TEAM_KEY_RESULTS: "UPDATE_TEAM_KEY_RESULTS",
  KEYRESULTS_BY_OBJECTIVE: "KEYRESULTS_BY_OBJECTIVE",
  GET_KEY_RESULTS_CONTRIBUTIONS: "GET_KEY_RESULTS_CONTRIBUTIONS",

  GET_USER_KEY_RESULTS: "GET_USER_KEY_RESULTS",
  POST_USER_KEY_RESULTS: "POST_USER_KEY_RESULTS",
  UPDATE_USER_KEY_RESULTS: "UPDATE_USER_KEY_RESULTS",
  DELETE_USER_KEY_RESULTS: "DELETE_USER_KEY_RESULTS",
  POST_USER_KEY_RESULT: "POST_USER_KEY_RESULT",
  UPDATE_USER_KEY_RESULT: "UPDATE_USER_KEY_RESULT",
  SUBMIT_USER_REVIEW: "SUBMIT_USER_REVIEW",

  GET_USER_KEY_RESULT_LIST: "GET_USER_KEY_RESULT_LIST",
  GET_USER_KEY_RESULT_BY_ID: "GET_USER_KEY_RESULT_BY_ID",
  GET_CHECKIN_BY_ID: "GET_CHECKIN_BY_ID",
  GET_REVIEW_CYCLE: "GET_REVIEW_CYCLE",
  GET_PARENT_KEYRESULT_TEAMID: "GET_PARENT_KEYRESULT_TEAMID",
  GET_OBJECTIVE_TEAMID: "GET_OBJECTIVE_TEAMID",
  UPDATE_KEY_RESULTS: "UPDATE_KEY_RESULTS",
  USER_DRAFT: "USER_DRAFT",
};

// Team Key results
export const getTeamKeyResults = createAsyncAction(keyResultType.GET_TEAM_KEY_RESULTS, async (keyResultId) => {
  return await Get(`KeyResult​/team​​​​/contributingkeyresults/${keyResultId}`);
});

export const postTeamKeyResults = createAsyncAction(keyResultType.POST_TEAM_KEY_RESULTS, async () => {
  return await Post(`team/keyresult`);
});

export const deleteTeamKeyResults = createAsyncAction(keyResultType.DELETE_TEAM_KEY_RESULTS, async (keyresultId) => {
  return await Delete(`Team/keyresult/${keyresultId}`);
});

export const updateTeamKeyResults = createAsyncAction(keyResultType.UPDATE_TEAM_KEY_RESULTS, async (payload) => {
  return await Put(`KeyResult/team`, payload);
});

// User Key Results by Id and Cycle
export const getUserKeyResults = createAsyncAction(keyResultType.GET_USER_KEY_RESULTS, async (userId, reviewCycleId) => {
  let endpoint = "";
  if (reviewCycleId) {
    endpoint = await Get(`KeyResult/user/${userId}?reviewcycleId=${reviewCycleId}`);
  } else {
    endpoint = await Get(`KeyResult/user/${userId}`);
  }
  return endpoint;
});

export const updateUserKeyResults = createAsyncAction(keyResultType.UPDATE_USER_KEY_RESULTS, async () => {
  return await Put(`KeyResult/user`);
});

export const deleteUserKeyResults = createAsyncAction(keyResultType.DELETE_USER_KEY_RESULTS, async (userId) => {
  return await Delete(`KeyResult/user/${userId}`);
});

export const updateUserKeyResult = createAsyncAction(keyResultType.UPDATE_USER_KEY_RESULT, async (payload) => {
  return await Put(`KeyResult/user`, payload);
});

export const submitUserReview = createAsyncAction(keyResultType.SUBMIT_USER_REVIEW, async (payload) => {
  return await Post(`KeyResult/user/keyresult/submitreview`, payload);
});

export const postUserKeyResult = createAsyncAction(keyResultType.POST_USER_KEY_RESULT, async (payload) => {
  return await Post(`KeyResult/user`, payload);
});

// Key results by objectives
export const getKeyResultByObjective = createAsyncAction(keyResultType.KEYRESULTS_BY_OBJECTIVE, async (id) => {
  return await Get(`KeyResult/team/objectives/${id}`);
});

export const getKeyResultContributions = createAsyncAction(keyResultType.GET_KEY_RESULTS_CONTRIBUTIONS, async (keyResultId) => {
  return await Get(`KeyResult/team/contributingkeyresults/${keyResultId}`);
});

export const getKeyResultList = createAsyncAction(keyResultType.GET_KEY_RESULTS, async () => {
  return await Get(`team/keyresult`);
});

// get User Key result using id
export const getUsersKeyResultList = createAsyncAction(keyResultType.GET_USER_KEY_RESULT_LIST, async (reviewCycleID, pageNo = 1, limit = 10) => {
  return await Get(`KeyResult/user?pageNumber=${pageNo}&pageSize=${limit}${reviewCycleID !== "" ? "&reviewCycleID=" + reviewCycleID : ""}`);
});

export const alignKeyResults = createAsyncAction(keyResultType.ALIGN_KEY_RESULTS, async () => {
  return await Post(`team/keyresult`);
});

export const getKeyResults = createAsyncAction(keyResultType.GET_KEY_RESULT_LIST, async () => {
  return await Get(`team/keyresult`);
});
export const postKeyResults = createAsyncAction(keyResultType.POST_KEY_RESULTS, async () => {
  return await Post(`team/keyresult`);
});
export const updateKeyResults = createAsyncAction(keyResultType.UPDATE_KEY_RESULTS, async () => {
  return await Put(`team/keyresult`);
});

export const getUserKeyResultById = createAsyncAction(keyResultType.GET_USER_KEY_RESULT_BY_ID, async (id) => {
  return await Get(`user/keyresult/${id}`);
});

// user checkin data
export const getCheckinById = createAsyncAction(keyResultType.GET_USER_KEY_RESULT_BY_ID, async (id) => {
  return await Get(`user/keyresult/checkin/${id}`);
});

export const getParentKeyResultByTeamId = createAsyncAction(keyResultType.GET_PARENT_KEYRESULT_TEAMID, async (id) => {
  return await Get(`KeyResult/team/objectives/${id}?pageNumber=1&pageSize=10000000`);
});

export const getObectiveByTeamId = createAsyncAction(keyResultType.GET_OBJECTIVE_TEAMID, async (id, cycleId) => {
  return await Get(`Team/${id}/objectives?cycleId=${cycleId}&pageNumber=1&pageSize=10000000`);
});

export const getAllReviewCycle = createAsyncAction(keyResultType.GET_REVIEW_CYCLE, async () => {
  return await Get(`ReviewCycle/all`);
});

export const getUserDraft = createAsyncAction(keyResultType.USER_DRAFT, async () => {
  return await Get(`KeyResult/user/draft`);
});
