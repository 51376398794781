import { Get, Put, Post } from "../../../apiServices/apiHandler.service";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";

export const profileType = {
  CHANGE_OF_PASSWORD: "CHANGE_OF_PASSWORD",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  UPLOAD_PHOTO: "UPLOAD_PHOTO",
  UPDATE_PHOTO: "UPDATE_PHOTO",
  SHOW_STEP: "SHOW_STEP",
};

export const getUserProfile = createAsyncAction(profileType.GET_USER_PROFILE, async () => {
  return await Get(`user/updateinfo`);
});
export const updateUserProfile = createAsyncAction(profileType.UPDATE_USER_PROFILE, async (data) => {
  return await Put(`user/update`, data);
});
export const changeOfPassword = createAsyncAction(profileType.CHANGE_OF_PASSWORD, async (data, email) => {
  return await Post(`account/api/sso/changepassword?email=${email}`, data);
});
export const showOnboardingSteps = createAction(profileType.SHOW_STEP, (status) => status);
