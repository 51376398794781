import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

import "./accordion.scss";

const AccordionComponent = ({ accordionLabel, children, clickHeader }) => {
  return (
    <div className="row">
      <div className="col-12 accordion-container">
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={clickHeader ? "div" : Button} variant="link" eventKey="0">
                {accordionLabel}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body> {children}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionComponent;
