import { profileType } from "../../actions/profile/profileActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INTIAL_STATE = {
  isLinkableRoute: false,
};

export default (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(profileType.CHANGE_OF_PASSWORD):
      return { ...state, changePasswordLoading: true };
    case SUCCESS(profileType.CHANGE_OF_PASSWORD):
      return {
        ...state,
        changePasswordPayload: actions.payload,
        changePasswordLoading: false,
      };
    case FAILURE(profileType.CHANGE_OF_PASSWORD):
      return {
        ...state,
        changePasswordError: actions.payload,
        changePasswordLoading: false,
      };

    case REQUEST(profileType.GET_USER_PROFILE):
      return { ...state, getUserProfileLoading: true };
    case SUCCESS(profileType.GET_USER_PROFILE):
      return {
        ...state,
        getUserProfilePayload: actions.payload,
        getUserProfileLoading: false,
      };
    case FAILURE(profileType.GET_USER_PROFILE):
      return {
        ...state,
        getUserProfileError: actions.payload,
        getUserProfileLoading: false,
      };
    case REQUEST(profileType.UPDATE_USER_PROFILE):
      return { ...state, updateUserProfileLoading: true };
    case SUCCESS(profileType.UPDATE_USER_PROFILE):
      return {
        ...state,
        updateUserProfilePayload: actions.payload,
        updateUserProfileLoading: false,
      };
    case FAILURE(profileType.UPDATE_USER_PROFILE):
      return {
        ...state,
        updateUserProfileError: actions.payload,
        updateUserProfileLoading: false,
      };
    case REQUEST(profileType.UPLOAD_PHOTO):
      return { ...state, updatePhotoLoading: true };
    case SUCCESS(profileType.UPLOAD_PHOTO):
      return {
        ...state,
        updatePhotoPayload: actions.payload,
        updatePhotoLoading: false,
      };
    case FAILURE(profileType.UPLOAD_PHOTO):
      return {
        ...state,
        updatePhotoError: actions.payload,
        updatePhotoLoading: false,
      };
    case profileType.SHOW_STEP:
      return {
        ...state,
        stepStatus: actions.payload,
      };
    default:
      return { ...state };
  }
};
