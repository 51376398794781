import React from "react";
import "./pmsChip.scss";

const PMSChip = ({ children, onRemove }) => {
  return (
    <div className="custom-chip-container d-flex justify-content-center align-items-center my-1" data-testid={children}>
      <span className="value">{children} </span>
      <span className="d-flex justify-content-center align-items-center ml-2 remove" onClick={onRemove}>
        <i className="ri-close-line"></i>
      </span>
    </div>
  );
};

export default PMSChip;
