import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Button from "../../shared/button/Button";
import PMSSelect from "../../shared/selectInput/pmsSelect/pmsSelect";
import Lozenge from "../../shared/lozenges/lozenge";
import LinkIcon from "../../../assets/images/link.svg";
import CloseIcon from "../../../assets/images/close.svg";
import { getKeyResultByObjective } from "../../../redux/actions/keyResult/keyResultActions";
import { successMessage, errorMessage } from "../../../redux/actions/notification/notification";

const EditObjectiveForm = ({
  setShowAlignKeyResultModal,
  setModalShow,
  setAlignment,
  alignment,
  updateTeamObjective,
  details,
  selectedCycle,
  cycleList,
  addKeyResultValue,
  setAddKeyResultValue,
  setShowAddKeyResultModal,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [reviewCycle, setReviewCycle] = useState(selectedCycle);
  const [parentAlignment, setParentAlignment] = useState(details?.parentObjective?.description);
  const [keyResultsList, setKeyResultsList] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const select = useSelector(({ keyResults, teamObjectives }) => {
    return {
      keyResultsByObjectives: keyResults && keyResults.keyResultByObjectivePayload?.data?.data?.items,
      updatePayload: teamObjectives && teamObjectives.updateObjectivePayload,
      updateError: teamObjectives && teamObjectives.updateObjectiveError,
      isLoading: teamObjectives && teamObjectives.updateObjectiveLoading,
      errorResponse: teamObjectives && teamObjectives.updateObjectiveError,
    };
  });

  const { keyResultsByObjectives, updatePayload, updateError, isLoading } = select;

  useEffect(() => {
    dispatch(getKeyResultByObjective(details?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset form if edit is successful
  useEffect(() => {
    if (isSubmitted && updatePayload) {
      form.reset();
      setModalShow(false);
      setAlignment(null);
      setAddKeyResultValue(null);
      setKeyResultsList(null);
      setIsSubmitted(false);
      dispatch(successMessage(updatePayload?.data?.message));
    } else {
      isSubmitted && !isLoading && updateError && dispatch(errorMessage(updateError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    let arrayOfAddedKeyResults = [];
    if (addKeyResultValue) {
      arrayOfAddedKeyResults = keyResultsList.concat(addKeyResultValue);
      setKeyResultsList(arrayOfAddedKeyResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addKeyResultValue]);

  // filter Key results list
  const filterKeyResultList = (resultList) => {
    let finalList = [];
    resultList.map((list) => {
      return (finalList = finalList.concat({
        description: list.description,
        dueDate: list.dueDate,
        progress: parseInt(list.progress),
        startGoal: parseInt(list.startGoal),
        progressType: list.tracking.type,
        targetGoal: parseInt(list.targetGoal),
      }));
    });

    return finalList;
  };

  useEffect(() => {
    if (keyResultsByObjectives && keyResultsByObjectives.length > 0) {
      setKeyResultsList(filterKeyResultList(keyResultsByObjectives));
    } else {
      setKeyResultsList(keyResultsByObjectives);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyResultsByObjectives]);

  // handle submit, removing event below will erase value
  const submit = (event, value) => {
    let formValue = {
      teamObjectiveId: details.id,
      teamId: details.teamId,
      progress: details.progress,
      dueDate: value.dueDate,
      targetGoal: parseInt(value.targetGoal),
      startGoal: parseInt(value.startGoal),
      trackingType: value.trackingType,
      reviewCycleId: reviewCycle,
      description: value.objectives,
      alignedKeyResults: keyResultsList,
    };

    // add parent id
    if (alignment) {
      formValue = {
        ...formValue,
        parentOrganisationObjectiveId: alignment.id,
      };
    } else {
      formValue = {
        ...formValue,
        parentOrganisationObjectiveId: details.parentObjective.id,
      };
    }

    setIsSubmitted(true);
    dispatch(updateTeamObjective(formValue));
  };

  const handleAddKey = () => {
    setShowAddKeyResultModal(true);
  };

  // check form validity
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  return (
    <AvForm className="goal-form-content" data-testid="submit" model={details} onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="objectives">What is your objective?</label>
          <AvField
            autoComplete="off"
            type="text"
            name="objectives"
            className="form-field"
            // placeholder="Enter your objective here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter objectives",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="reviewCycle">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            id="reviewCycle"
            dataSource={cycleList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="description"
            valueExpr="cycleId"
            onChange={(e) => setReviewCycle(e)}
            controlHeight="48px"
            value={reviewCycle}
          />
        </div>
        <div className="col-6">
          <label htmlFor="dueDate">Due Date</label>
          <AvField
            autoComplete="off"
            type="date"
            name="dueDate"
            className="form-field"
            placeholder="Select date"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter due date",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="owner">Owner</label>
          <AvField
            autoComplete="off"
            type="text"
            className="form-field"
            name="owner"
            // value={details?.team?.teamOwner}
            value="TEAM OWNER"
            disabled={true}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter the owner",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="type">Type</label>
          <AvField
            autoComplete="off"
            type="text"
            name="objectiveType"
            className="form-field"
            placeholder="Enter the type"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter type",
              },
            }}
          />
        </div>
      </div>
      <div>
        <div className="alignment">Alignment</div>
        <div>
          {parentAlignment && !alignment && (
            <>
              <div className="alignment-title">
                {details?.parentObjective?.description}
                <img src={CloseIcon} alt="close icon" onClick={() => setParentAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> Company
              </div>
            </>
          )}
          {alignment && (
            <>
              <div className="alignment-title">
                {alignment.Keyresult}
                <img src={CloseIcon} alt="close icon" onClick={() => setAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> {alignment.Team.TeamName}
              </div>
            </>
          )}
        </div>
        {!alignment && (
          <div
            className="align-goal"
            onClick={() => {
              form.submit();
              setModalShow(false);
              setShowAlignKeyResultModal(true);
            }}
          >
            Update Alignment
          </div>
        )}
      </div>

      <div>
        <div className="alignment">Key Results</div>

        {keyResultsList?.length > 0 &&
          keyResultsList.map((value, index) => {
            return (
              <div className="key-results-content">
                <div>
                  <div>{value?.description}</div>
                  <div className="key-results-date">Due Date: {value?.dueDate}</div>
                </div>
                <div className="d-flex alignment-title">
                  <Lozenge className="sp-lozenge sp-lozenge--default" style={{ marginRight: "24px" }} content={value?.progressTrack === "KPI" ? "KPI" : "%"} />
                  {/* <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="ri-more-2-fill"></i>
                  </span> */}
                  {/* <img
                    src={CloseIcon}
                    alt="close icon"
                    onClick={() => {
                      keyResultsList.splice(index, 1);
                    }}
                  /> */}
                </div>
              </div>
            );
          })}
        <div className="add-key-result align-goal" data-testid="add-key-obj" onClick={handleAddKey}>
          {keyResultsList?.length > 0 ? " Add another Key Result" : " Add a Key Result"}
        </div>
      </div>

      <div>
        <div className="progress-track">Progress Tracking</div>
        <AvRadioGroup inline className="mb-0" data-testid="progressTrack" name="trackingType" label="">
          <AvRadio
            label="Percentage (%)"
            // value={details?.tracking?.type}
            value="PERCENTAGE"
            className="mb-0"
          />
          <AvRadio label="Key Performance Index (KPI)" value="KPI" className="mb-0" />
        </AvRadioGroup>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="startGoal">Start</label>
          <AvField
            autoComplete="off"
            type="number"
            name="startGoal"
            className="form-field"
            placeholder="Enter your start"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your start",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="targetGoal">Goal</label>
          <AvField
            autoComplete="off"
            type="number"
            className="form-field"
            name="targetGoal"
            placeholder="Enter your goal"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your goal",
              },
            }}
          />
        </div>
      </div>

      <hr className="border-btm" />
      <div className="row btn-goal-content">
        <div className="col-6">
          <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={() => setModalShow(false)}>
            Cancel
          </Button>
        </div>
        <div className="col-6">
          <Button size="sm" disabled={reviewCycle === "" || !validForm} color="blue" className="btn-goal" handleClick={() => form.submit()}>
            Save Changes
          </Button>
        </div>
      </div>
    </AvForm>
  );
};

export default EditObjectiveForm;
