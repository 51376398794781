import React from "react";
import "./overviewColors.scss";

const OverviewColors = ({ data }) => {
  return (
    <div className="overview-color-container">
      <div className="row mb">
        <div className="col-6 d-flex" aria-labelledby="at-risk-color-block" aria-describedby="at-risk">
          <div id="at-risk-block" className=" bg-atRisk color-block"></div>
          <div data-testid="at-risk" id="at-risk" className="text">
            {data && data.atRisk ? data.atRisk : "-"} At Risk
          </div>
        </div>
        <div className="col-6 d-flex" aria-labelledby="pending-color-block" aria-describedby="pending">
          <div id="pending-color-block" className="color-block bg-pending"></div>
          <div data-testid="pending" id="pending" className="text">
            {data && data.pending ? data.pending : "-"} Pending
          </div>
        </div>
      </div>
      <div className="row mb">
        <div className="col-6 d-flex" aria-labelledby="behind-color-block" aria-describedby="behind">
          <div id="behind-color-block" className="color-block bg-behind"></div>
          <div data-testid="behind" id="behind" className="text">
            {data && data.behind ? data.behind : "-"} Behind
          </div>
        </div>
        <div className="col-6 d-flex" aria-labelledby="not-started-color-block" aria-describedby="not-started">
          <div id="not-started-color-block" className="color-block bg-notStarted"></div>
          <div data-testid="not-started" id="not-started" className="text">
            {data && data.notStarted ? data.notStarted : "-"} Not Started
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 d-flex" aria-labelledby="on-track-color-block" aria-describedby="on-track">
          <div id="on-track-color-block" className="color-block bg-onTrack"></div>
          <div data-testid="on-track" id="on-track" className="text">
            {data && data.onTrack ? data.onTrack : "-"} On Track
          </div>
        </div>
        <div className="col-6 d-flex" aria-labelledby="completed-color-block" aria-describedby="completed">
          <div id="completed-color-block" className="color-block bg-completed"></div>
          <div data-testid="completed" id="completed" className="text">
            {data && data.completed ? data.completed : "-"} Completed
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewColors;
