import React from "react";
import { components } from "react-select";

const ValueContainer = ({ children, getValue, ...props }) => {
  var valueLength = getValue().length;
  // var optionsLength = props.selectProps.options.length;

  // Check if values are equal to options and display `All` instead of number.
  // var valueLength = optionsLength === valueLength ? "All" : valueLength;

  return (
    <components.ValueContainer {...props}>
      {valueLength && props.selectProps.useCount ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "4px 8px",
              width: "43px",
              height: "24px",
              background: "#3A434B",
              borderRadius: "12px",
              color: "#fff",
            }}
          >
            <span>{valueLength}</span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.selectProps.onChange([]);
                props.selectProps.onActionButtonClick([]);
                if (props.selectProps.onOptionSelected) {
                  props.selectProps.onOptionSelected({ target: { name: props.selectProps.name, value: null, label: null } });
                }
              }}
            >
              <i className="ri-close-line"></i>
            </span>
          </div>
          <div style={{ marginLeft: 8 }}>
            {React.Children.map(children, child => {
              // console.log(child);
              return child;
            })}
          </div>
        </>
      ) : (
        <div>
          {React.Children.map(children, child => {
            // console.log(child);
            return child;
          })}
        </div>
      )}
    </components.ValueContainer>
  );
};

export default ValueContainer;
