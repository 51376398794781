import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import EditTeamKeyResultForm from "./editTeamKeyResultForm";

const EditTeamKeyResultModal = ({ modalShow, setModalShow, setFormValues, formValues, details, cycleList, updateTeamKeyResults, selectedCycle }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Edit Key Result" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <EditTeamKeyResultForm
                setModalShow={setModalShow}
                details={details}
                setFormValues={setFormValues}
                formValues={formValues}
                cycleList={cycleList}
                updateTeamKeyResults={updateTeamKeyResults}
                selectedCycle={selectedCycle}
              />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default EditTeamKeyResultModal;
