import React, { useState } from "react";
import "./pmsTooltip.scss";

const PMSTooltip = ({ children, direction, content, delay, width }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div role="tooltip" aria-labelledby="tooltip-child" aria-describedby="tooltip-content" className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      <div id="tooltip-child">{children}</div>
      {active && (
        <div id="tooltip-content" className={`tip ${direction || "top"}`} style={{ minWidth: width ? width : 217 }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default PMSTooltip;
