import React from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import DocumentIcon from "../../../../assets/images/documents.svg";
import SVGS from "../../../../utils/svgs";
import { Button, LineLoader, LoadingTableContent, OverviewColors, PageTitle, PMSSelect, PMSTooltip, SearchBar } from "../../../shared";
import { getPercentColor } from "../../../../utils/helpers";

import "./allObjectives.scss";

const AllObjectives = props => {
  const {
    cycleOptions,
    onAddObjective,
    onShowDetails,
    publishedObjectives,
    isLoadingPublished,
    progressOverviewObjectives,
    isLoadingOverview,
    onCycleSelected,
    selectedCycle,
    isLoadingCycleOptions,
    onSearch,
  } = props;

  const getProgress = data => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  return (
    <div className="all-objectives-container">
      <div className="row pg-header">
        <div className="col-4">
          <PageTitle title={`${publishedObjectives && publishedObjectives.items && publishedObjectives.items.length ? publishedObjectives.items.length : "-"} Objectives`} />
        </div>
        <div className="col-8 d-flex flex-column align-items-end flex-md-row justify-content-end align-items-md-center">
          <div className="mb-2 m-md-0 header-select">
            <PMSSelect
              placeholder="Cycles"
              dataSource={cycleOptions}
              closeOnActionClick={true}
              displayExpr="description"
              valueExpr="cycleId"
              onChange={onCycleSelected}
              value={selectedCycle}
              isLoading={isLoadingCycleOptions}
              clearable
            />
          </div>
          <Button size="sm" handleClick={onAddObjective} className="ml-3">
            Add Objectives
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-auto">
          <PMSTooltip content={<OverviewColors data={progressOverviewObjectives?.overview} />} direction="bottom" width={247}>
            <div className="label">
              <span className="mr">Overview</span>
              {SVGS.info()}
            </div>
            <div className="label-text">
              <div className="progressBar">{isLoadingOverview ? <LineLoader lines={1} /> : <ProgressBar>{getProgress(progressOverviewObjectives?.overview)}</ProgressBar>}</div>
            </div>
          </PMSTooltip>
        </div>

        <div className="col-auto">
          <div className="label">{isLoadingOverview ? <LineLoader lines={1} /> : progressOverviewObjectives ? `${progressOverviewObjectives.avgProgress}%` : "-"}</div>
          <div className="label-text">Average Progress</div>
        </div>
      </div>
      <div className="row sub-items">
        <div className="col-auto">
          <div className="search-container">
            <SearchBar placeholder="Search" handleSearch={onSearch} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Table
            responsive
            style={{
              borderBottom: "1px solid #BAC2CA",
            }}
            borderless
          >
            <thead>
              <tr>
                <th width="60%">Title</th>
                {/* <th width="10%">Owner</th> */}
                <th width="10%">Last Update</th>
                <th width="20%">Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingPublished ? (
                <LoadingTableContent colSpan={3} />
              ) : (
                <>
                  {publishedObjectives &&
                    publishedObjectives.items &&
                    publishedObjectives.items.map(objective => (
                      <tr key={objective.id}>
                        <td className="td-title">
                          {objective.objectives} {SVGS.objective()}
                        </td>
                        {/* <td className="td-avatar">
                          <PMSTooltip content={getOrgDetails(objective.Organisation)} direction="left" width={171}>
                            <span>
                              <img className="table-avatar" src={objective.Organisation.image ? objective.Organisation.image : avatar} alt="owner avatar" />
                            </span>
                          </PMSTooltip>
                        </td> */}
                        <td className="td-date">{objective.lastUpdated}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-3">
                              <ProgressBar now={objective.progress} variant={getPercentColor(objective.progress)} />
                            </span>
                            <span className="mr-5">{objective.progress}%</span>
                            <span>
                              <Link to="#">
                                <span onClick={() => onShowDetails(objective)} title={`View details of ${objective.description}`}>
                                  {SVGS.caret({ direction: "right" })}
                                </span>
                              </Link>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </Table>
          {/* {showPaginator && <Paginator currentPage={currentPage} perPage={pageSize} pageCount={pageCount} totalItems={totalItems} handlePageClick={handlePageClick} />} */}
          {!isLoadingPublished && publishedObjectives.items && publishedObjectives.items.length === 0 && (
            <div className="empty-content">
              <img src={DocumentIcon} alt="empty objectives" />
              <div className="no-objectives">You have not added any Objective</div>
              <div className="add-objective">Click on the “Add Objective” button to get started</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllObjectives;
