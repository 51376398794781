import React from "react";
import { Route } from "react-router-dom";
import { userDetails } from "../apiServices/user.service";
import { Urls } from "../utils/urls";
import Login from "../components/auth/login/login";

/**
 * PrivateRoute locks a route unless the isAuthenticated prop is true
 *
 * @param {Bool} isAuthenticated - if true, renders `compenent` prop. If false, redirects to `redirect` location
 * @param {Component} component - Component to render when accessible
 * @param {String} redirect - path to redirect to when inaccessible
 */
const PrivateRoute = ({ isAuthenticated, component: Component, redirect, ...rest }) => {
  return <Route {...rest} render={(routeProps) => (userDetails() ? <Component {...routeProps} /> : <Component exact path={Urls.Login} component={Login} />)} />;
};

export default PrivateRoute;
