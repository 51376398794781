import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import MultipleTeams from "./multipleTeams/multipleTeams";
import SingleTeam from "./singleTeam/singleTeam";
import PMSModal from "../../shared/pmsModal/pmsModal";
import "./teamEditor.scss";

const TeamEditor = ({
  openModal,
  closeModal,
  teamsOptions,
  isLoadingTeamsOptions,
  savingTeam,
  uploadingBulkTeams,
  updatingTeam,

  data,
  onSubmit,
}) => {
  const [show, setShow] = useState(false);
  const [tabKey, setTabKey] = useState("single");
  const [isValid, setIsValid] = useState(false);
  const [upladedData, setUpladedData] = useState(undefined);
  const [formData, setFormData] = useState({
    teamName: "",
    teamOwnerEmail: "",
    teamManagerEmail: "",
    parentTeamId: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileUpload = evt => {
    setUpladedData(evt);
  };
  useEffect(() => {
    openModal ? handleShow() : handleClose();
    console.log(data ? data.parentTeamId && data.parentTeamId.toLowerCase() : "");

    console.log(teamsOptions);

    if (!openModal) {
      setFormData({
        teamName: "",
        teamOwnerEmail: "",
        teamOwnerManagerEmail: "",
        parentTeamId: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const switchTabs = key => {
    if (key === "single") {
    } else if (key === "multple") {
      setFormData({
        teamName: "",
        teamOwnerEmail: "",
        teamOwnerManagerEmail: "",
        parentTeamId: "",
      });
    }
    setTabKey(key);
  };
  useEffect(() => {
    setIsValid(formValid(formData));
  }, [formData]);

  useEffect(() => {
    setFormData({
      teamName: data ? data.teamName : "",
      parentTeamId: data ? data.parentTeamId && data.parentTeamId.toLowerCase() : "",
      teamOwnerEmail: data ? data.teamOwnerEmail : "",
      teamOwnerManagerEmail: data ? data.teamOwnerManagerEmail : "",
    });
  }, [data]);

  useEffect(() => {
    if (tabKey === "multiple") {
      setFormData({
        teamName: "",
        parentTeamId: "",
        teamOwnerEmail: "",
        teamOwnerManagerEmail: "",
      });
    } else if (tabKey === "single") {
      setUpladedData(undefined);
    }
  }, [tabKey]);

  const handleSubmit = () => {
    onSubmit(formData, tabKey);
  };

  const handleInputChange = e => {
    if (e.target) {
      const { value, name } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formValid = fd => {
    return !!fd.teamOwnerEmail && !!fd.teamName;
  };

  return (
    <PMSModal
      title={`${data ? "Edit" : "Add"} Team`}
      openModal={show}
      handlePrimaryButtonClick={handleSubmit}
      processing={savingTeam || uploadingBulkTeams || updatingTeam}
      primaryButtonText={data ? "Save Changes" : "Add Team"}
      containerId="team-editor"
      closeModal={closeModal}
      contentClassName="team-editor-content"
      showSecondaryButton={true}
      disablePrimaryButton={!isValid && !upladedData}
      closeOnOutsideClick={false}
      useCloseIcon
      useHeaderBorder={!!data}
      testId="team-editor-container"
    >
      <>
        {data ? (
          <SingleTeam formData={formData} handleChange={handleInputChange} teamsOptions={teamsOptions} inEditMode />
        ) : (
          <Tabs id="controlled-tab-team" activeKey={tabKey} onSelect={switchTabs}>
            <Tab eventKey="single" title="Single Team">
              {tabKey === "single" && <SingleTeam formData={formData} handleChange={handleInputChange} teamsOptions={teamsOptions} isLoadingTeamsOptions={isLoadingTeamsOptions} />}
            </Tab>
            <Tab eventKey="multiple" title="Multiple Teams">
              {tabKey === "multiple" && <MultipleTeams onFileUpload={handleFileUpload} />}
            </Tab>
          </Tabs>
        )}
      </>
    </PMSModal>
  );
};

export default TeamEditor;
