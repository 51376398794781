import React, { useState, useEffect } from "react";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

const AddKeyResult = ({ setAddKeyResultValue, submitForm, setSubmitForm, validForm }) => {
  const [form, setForm] = useState([]);

  useEffect(() => {
    if (submitForm) {
      form && form.submit && form.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  useEffect(() => typeof validForm === "function" && validForm(form && form.valid && form.valid()));

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    typeof validForm === "function" && validForm(emptyValidation);
  };

  const submit = (event, value) => {
    let newValue = {
      description: value.description,
      dueDate: value.dueDate,
      progress: parseInt(value.progress),
      startGoal: parseInt(value.progress),
      progressType: value.progressType,
      targetGoal: parseInt(value.targetGoal),
    };
    setAddKeyResultValue(newValue);
    setSubmitForm(false);
  };

  return (
    <div className="align-key-result-container">
      <AvForm className="goal-form-content" onValidSubmit={submit} ref={c => setForm(c)} onKeyUp={checkForm}>
        <div className="row layout-style">
          <div className="col-12">
            <label htmlFor="description">What is your key result?</label>
            <AvField
              autoComplete="off"
              type="text"
              name="description"
              className="form-field"
              placeholder="Enter here..."
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter key result",
                },
              }}
            />
          </div>
        </div>

        <div className="row layout-style">
          <div className="col-6">
            <label htmlFor="dueDate">Due Date</label>
            <AvField
              autoComplete="off"
              type="date"
              name="dueDate"
              className="form-field"
              placeholder="Select date"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter due date",
                },
              }}
            />
          </div>

          <div className="col-6">
            <label htmlFor="reviewCycle">Team</label>
            <AvField autoComplete="off" disabled={true} type="text" className="form-field" name="Team" value="Team" />
          </div>
        </div>

        <div className="row layout-style">
          <div className="col-12">
            <div className="progress-track">Progress Tracking</div>
            <AvRadioGroup inline className="mb-0" data-testid="progressTrack" name="progressType" label="" required>
              <AvRadio label="Percentage (%)" value="PERCENTAGE" className="mb-0" />
              <AvRadio label="Key Performance Index (KPI)" value="KPI" className="mb-0" />
            </AvRadioGroup>
          </div>
        </div>
        <div className="row layout-style">
          <div className="col-6">
            <label htmlFor="progress">Start</label>
            <AvField
              autoComplete="off"
              type="number"
              name="progress"
              className="form-field"
              placeholder="Enter your start"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please your start",
                },
              }}
            />
          </div>
          <div className="col-6">
            <label htmlFor="targetGoal">Goal</label>
            <AvField
              autoComplete="off"
              type="number"
              className="form-field"
              name="targetGoal"
              placeholder="Enter your goal"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter your goal",
                },
              }}
            />
          </div>
        </div>
      </AvForm>
    </div>
  );
};

export default AddKeyResult;
