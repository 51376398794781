import profile from "../../assets/images/profile.jpeg";

export default {
  publishedObjectives: {
    message: "organisation objectives",
    data: {
      items: [
        {
          id: 1,
          OrganisationId: 4,
          ReviewCycle: 7,
          objectives: "Generate more income",
          progress: 45,
          isComplete: false,
          lastUpdated: "28/4/2021",
          dueDate: "28/8/2021",
          ObjectiveType: "Organisation",
          Organisation: {
            orgName: "VGG",
            orgAddress: "ikeja",
            zipcode: 23401,
            state: "lagos",
          },
        },
        {
          id: 8,
          OrganisationId: 4,
          ReviewCycle: 7,
          objectives: "Generate more income",
          progress: 45,
          isComplete: false,
          lastUpdated: "28/4/2021",
          dueDate: "28/8/2021",
          ObjectiveType: "Organisation",
          Organisation: {
            orgName: "VGG",
            orgAddress: "ikeja",
            zipcode: 23401,
            state: "lagos",
          },
        },
        {
          id: 4,
          OrganisationId: 4,
          ReviewCycle: 7,
          objectives: "Generate more income",
          progress: 45,
          isComplete: false,
          lastUpdated: "28/4/2021",
          dueDate: "28/8/2021",
          ObjectiveType: "Organisation",
          Organisation: {
            orgName: "VGG",
            orgAddress: "ikeja",
            zipcode: 23401,
            state: "lagos",
          },
        },
      ],
    },
  },
  draftedObjectives: {
    message: "organisation objectives",
    data: [
      {
        id: 1,
        OrganisationId: 4,
        ReviewCycle: 7,
        description: "Generate more income",
        status: 45,
        isComplete: false,
        lastUpdated: "28/4/2021",
        dueDate: "28/8/2021",
        ObjectiveType: "Organisation",
        Organisation: {
          orgName: "VGG",
          orgAddress: "ikeja",
          zipcode: 23401,
          state: "lagos",
        },
      },
      {
        id: 8,
        OrganisationId: 4,
        ReviewCycle: 7,
        description: "Generate more income",
        status: 45,
        isComplete: false,
        lastUpdated: "28/4/2021",
        dueDate: "28/8/2021",
        ObjectiveType: "Organisation",
        Organisation: {
          orgName: "VGG",
          orgAddress: "ikeja",
          zipcode: 23401,
          state: "lagos",
        },
      },
      {
        id: 4,
        OrganisationId: 4,
        ReviewCycle: 7,
        description: "Generate more income",
        status: 45,
        isComplete: false,
        lastUpdated: "28/4/2021",
        dueDate: "28/8/2021",
        ObjectiveType: "Organisation",
        Organisation: {
          orgName: "VGG",
          orgAddress: "ikeja",
          zipcode: 23401,
          state: "lagos",
        },
      },
    ],
  },
  cycleData: [
    { id: 1, description: "2020 Cycle 1" },
    { id: 2, description: "2020 Cycle 2" },
    { id: 3, description: "2020 Cycle 3" },
    { id: 4, description: "2020 Cycle 4" },
    { id: 5, description: "2021 Cycle 1" },
    { id: 6, description: "2021 Cycle 2" },
    { id: 7, description: "2021 Cycle 3" },
    { id: 8, description: "2021 Cycle 4" },
  ],

  objectiveDrafts: [
    { id: 1, description: "Facilitate at least 2 user interview sessions" },
    { id: 2, description: "Redesign our new landing page" },
    { id: 3, description: "Support marketing and sales with design deliverables" },
  ],

  employees: [
    { name: "Nma Okafor", primary_team: "R & D", line_manager: "Israel Oyinlola", parent_team: "Engineering", key_results: "13", pending_krs: "40%(5/13)", status: "", last_submitted: "18/01/2021" },
    { name: "Jindu Opara", primary_team: "PowerTech", line_manager: "Rolak Osho", parent_team: "", key_results: "9", pending_krs: "80%(7/9)", status: "", last_submitted: "20/12/2020" },
    { name: "Abdulkadri Bello", primary_team: "Vigipay", line_manager: "John Ayoobola", parent_team: "", key_results: "21", pending_krs: "25%(5/21)", status: "", last_submitted: "28/01/2021" },
    {
      name: "Greg Collins",
      primary_team: "Quality Assurance",
      line_manager: "Sandra Asamade",
      parent_team: "Engineering",
      key_results: "10",
      pending_krs: "30%(3/10)",
      status: "",
      last_submitted: "16/02/2021",
    },
    { name: "Yahaya Kareem", primary_team: "GSV", line_manager: "Bimbo Bolu", parent_team: "", key_results: "15", pending_krs: "20%(3/15)", status: "", last_submitted: "08/03/2021" },
  ],

  objectives: [
    {
      id: 1,
      title: "Company Objective 1",
      owner: { full_name: "Israel Oyinlola", profile_img: null, title: "" },
      last_updated: "08/03/2021",
      status: { avg_progress: 75 },
      team_aligned: 28,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 2,
      title: "Company Objective 2",
      owner: { full_name: "Samuel Awoniyi", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 86 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 3,
      title: "Company Objective 3",
      owner: { full_name: "Wisdom Ossai", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 41 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 4,
      title: "Company Objective 4",
      owner: { full_name: "Chidinma Okafor", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 53 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 5,
      title: "Company Objective 5",
      owner: { full_name: "Gafar Popoola", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 69 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 6,
      title: "Company Objective 6",
      owner: { full_name: "James Bond", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 60 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 7,
      title: "Company Objective 7",
      owner: { full_name: "Van Damme", profile_img: null },
      last_updated: "08/03/2021",
      status: { avg_progress: 50 },
      team_aligned: 46,
      overview: {
        description: "This is an overview for company objective 1",
        progress: [
          { id: 1, percent: 10, description: "Complete large projects" },
          { id: 2, percent: 45, description: "Market share in recent applications" },
          { id: 3, percent: 45, description: "Employee management power" },
        ],
      },
    },
    {
      id: 8,
      title: "Company Objective 8",
      owner: { full_name: "Stephen Siggal", profile_img: null },
      last_updated: "08/03/2021",
      status: {
        avg_progress: 40,
        overview: {
          description: "This is an overview for company objective 1",
          progress: [
            { id: 1, percent: 10, description: "Complete large projects" },
            { id: 2, percent: 45, description: "Market share in recent applications" },
            { id: 3, percent: 45, description: "Employee management power" },
          ],
        },
      },
      team_aligned: 46,
    },
  ],

  companyProgress: [
    {
      id: 1,
      average: 71.7,
      overview: [
        { id: 1, percent: 10, description: "Complete large projects" },
        { id: 2, percent: 45, description: "Market share in recent applications" },
        { id: 3, percent: 45, description: "Employee management power" },
      ],
      description: "This is an overview of the company's progress so far",
    },
  ],

  alignedTeamsObjectives: {
    message: "successfully",
    data: {
      items: [
        {
          id: 1,
          orgObjId: 1,
          teamId: 3,
          objKeyresultType: "Team",
          teamName: "Advancly",
          completed: false,
          progress: 75,
          lastUpdatedDate: "15/03/2021",
          lastUpdated: "15/03/2021",
          objectives: "Generate more income",
          owner: {
            staffId: 3,
            image: profile,
            firstName: "Elvis ",
            lastName: " Jones",
            jobDescription: "Product Designer",
          },
        },
        {
          id: 2,
          orgObjId: 2,
          teamId: 4,
          objKeyresultType: "Team",
          teamName: "People Operations",
          completed: false,
          progress: 75,
          lastUpdatedDate: "15/03/2021",
          objectives: "Premium brand",
          owner: {
            staffId: 3,
            image: null,
            firstName: "Elvis ",
            lastName: " Jones",
            jobDescription: "Product Designer",
          },
        },
      ],
    },
  },

  alignedTeamsOptions: {
    message: "successfully",
    data: [
      {
        orgObjId: 1,
        teamId: 3,
        objKeyresultType: "Team",
        teamName: "Advancly",
        completed: false,
        progress: 75,
        lastUpdatedDate: "15/03/2021",
        objectives: "Generate more income",
        owner: {
          staffId: 3,
          image: null,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
      {
        orgObjId: 2,
        teamId: 4,
        objKeyresultType: "Team",
        teamName: "People Operations",
        completed: false,
        progress: 75,
        lastUpdatedDate: "15/03/2021",
        objectives: "Premium brand",
        owner: {
          staffId: 3,
          image: profile,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
    ],
  },

  objectiveKeyResults: {
    message: "success",
    data: [
      {
        id: 4,
        keyresult: "growww",
        lastUpdated: "Jan 2021",
        status: 4,
        progress: 54.5,
        managerComment: "alright",
        staffComment: "okay",
        contributionCount: 2,
        userId: 3,
        owner: {
          staffId: 3,
          image: null,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
      {
        id: 5,
        keyresult: "growww",
        lastUpdated: "Jan 2021",
        status: 4,
        progress: 54.5,
        managerComment: "alright",
        staffComment: "okay",
        contributionCount: 2,
        userId: 3,
        owner: {
          staffId: 3,
          image: profile,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
    ],
  },

  keyResultContributions: {
    message: "successfully",
    data: [
      {
        id: 6,
        keyresult: "growww",
        dateSubmitted: "Jan 2021",
        status: "approved",
        progress: 54.5,
        managerComment: "alright",
        contributors: "Quality people, Quality delivery",
        staffComment: "okay",
        userId: 8,
        owner: {
          staffId: 8,
          image: profile,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
      {
        id: 7,
        keyresult: "eat",
        dateSubmitted: "Jan 2021",
        status: "approved",
        progress: 54.5,
        managerComment: "alright",
        contributors: "Quality people, Quality delivery",
        staffComment: "okay",
        userId: 4,
        owner: {
          staffId: 4,
          image: null,
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
    ],
  },

  companyTeams: {
    pageNumber: 1,
    pageSize: 10,
    items: [
      {
        organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
        teamId: "F8EF143F-B97B-4541-BA90-13B9C295A993",
        id: "f8ef143f-b97b-4541-ba90-13b9c295a993",
        teamName: "Product Design",
        teamOwner: null,
        teamOwnerManager: null,
        teamOwnerEmail: null,
        teamOwnerId: "00000000-0000-0000-0000-000000000000",
        teamType: null,
        parentTeamId: "1112DB8F-F76A-492F-AC20-31DEFDFE69A4",
        parentTeam: "Default",
        teamOwnerJobTitle: null,
        teamOwnerManagerEmail: null,
        members: [
          {
            organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
            companyName: null,
            teamId: "f8ef143f-b97b-4541-ba90-13b9c295a993",
            firstName: "Sade",
            lastName: "Adun",
            email: "sade.adun@mailinator.com",
            primaryTeam: null,
            parentTeam: null,
            jobTitle: "Assistant EA",
            userId: "9B88CF5E-2033-464B-A6A1-7DB612D7ADAC",
            keyResultsCount: 0,
            manager: null,
            status: null,
            roles: null,
            image: null,
            userKeyResultStatus: null,
          },
        ],
        membersCount: 0,
      },
      {
        organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
        teamId: "5D584101-776D-4156-BCAB-51C575E14D74",
        id: "5d584101-776d-4156-bcab-51c575e14d74",
        teamName: "People Operations",
        teamOwner: "Nma Okafor",
        teamOwnerManager: "Chidinma okafor",
        teamOwnerEmail: "chidinma.okafor@venturegardengroup.com.ng",
        teamOwnerId: "4ca18f1d-dd48-4933-acc7-b300218ff9ef",
        teamType: null,
        parentTeamId: "1112DB8F-F76A-492F-AC20-31DEFDFE69A4",
        parentTeam: "Default",
        teamOwnerJobTitle: "EA",
        teamOwnerManagerEmail: "blueca992@yahoo.com",
        members: [
          {
            organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
            companyName: null,
            teamId: "5d584101-776d-4156-bcab-51c575e14d74",
            firstName: "Nma",
            lastName: "Okafor",
            email: "chidinma.okafor@venturegardengroup.com.ng",
            primaryTeam: null,
            parentTeam: null,
            jobTitle: "EA",
            userId: "4CA18F1D-DD48-4933-ACC7-B300218FF9EF",
            keyResultsCount: 0,
            manager: null,
            status: null,
            roles: null,
            image: null,
            userKeyResultStatus: null,
          },
          {
            organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
            companyName: null,
            teamId: "5d584101-776d-4156-bcab-51c575e14d74",
            firstName: "Chidinma",
            lastName: "Okafor",
            email: "chidinam@sharklasers.com",
            primaryTeam: null,
            parentTeam: null,
            jobTitle: "engineer",
            userId: "B13ABD17-035C-4E67-9918-CBBC31EEE92D",
            keyResultsCount: 0,
            manager: null,
            status: null,
            roles: null,
            image: null,
            userKeyResultStatus: null,
          },
        ],
        membersCount: 0,
      },
      {
        organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
        teamId: "8DFBC5CC-4635-4B45-9CDC-5FA17BE0269D",
        id: "8dfbc5cc-4635-4b45-9cdc-5fa17be0269d",
        teamName: "R&D",
        teamOwner: null,
        teamOwnerManager: null,
        teamOwnerEmail: null,
        teamOwnerId: "00000000-0000-0000-0000-000000000000",
        teamType: null,
        parentTeamId: "1112DB8F-F76A-492F-AC20-31DEFDFE69A4",
        parentTeam: "Default",
        teamOwnerJobTitle: null,
        teamOwnerManagerEmail: null,
        members: [],
        membersCount: 0,
      },
      {
        organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
        teamId: "B43FBA52-7FD8-4626-AC20-C396247F692C",
        id: "b43fba52-7fd8-4626-ac20-c396247f692c",
        teamName: "AviTech",
        teamOwner: null,
        teamOwnerManager: null,
        teamOwnerEmail: null,
        teamOwnerId: "00000000-0000-0000-0000-000000000000",
        teamType: null,
        parentTeamId: "F8EF143F-B97B-4541-BA90-13B9C295A993",
        parentTeam: "Product Design",
        teamOwnerJobTitle: null,
        teamOwnerManagerEmail: null,
        members: [],
        membersCount: 0,
      },
      {
        organisationId: "269233d2-9d8b-4bfc-bc43-54ac1d61212a",
        teamId: "FB83B8DE-BE4A-41EF-96CB-E990B2144A01",
        id: "fb83b8de-be4a-41ef-96cb-e990b2144a01",
        teamName: "Advancly",
        teamOwner: null,
        teamOwnerManager: null,
        teamOwnerEmail: null,
        teamOwnerId: "00000000-0000-0000-0000-000000000000",
        teamType: null,
        parentTeamId: "1112DB8F-F76A-492F-AC20-31DEFDFE69A4",
        parentTeam: "Default",
        teamOwnerJobTitle: null,
        teamOwnerManagerEmail: null,
        members: [],
        membersCount: 0,
      },
    ],
    totalCount: 5,
  },

  companyPeople: [
    { id: 1, name: "Agboola Taiwo", title: "Lead, Customer Success", primary_team: "Customer Success", profile_img: profile, last_seen: "08/03/2021" },
    { id: 2, name: "Belolisa Charles", title: "Product Design Intern", primary_team: "Product Design", profile_img: null, last_seen: "03/03/2021" },
    { id: 3, name: "Bosun Lawal", title: "Software Engineer", primary_team: "Advancly", profile_img: profile, last_seen: "26/02/2021" },
    { id: 4, name: "Cynthia Duru", title: "Senior Product Designer", primary_team: "Product Design", profile_img: null, last_seen: "07/03/2021" },
    { id: 5, name: "Caleb Ugbunam", title: "Enterprise Architect", primary_team: "Engineering", profile_img: null, last_seen: "07/01/2021" },
    { id: 6, name: "Daisy Nwoke", title: "Product Designer", primary_team: "Product Design", profile_img: null, last_seen: "16/03/2021" },
    { id: 7, name: "Dayo Affar", title: "Legal Intern", primary_team: "Legal", profile_img: null, last_seen: "30/01/2021" },
    { id: 8, name: "Ebenezar Obbey", title: "Manager, Financial Advisory", primary_team: "Finance", profile_img: null, last_seen: "11/04/2021" },
  ],

  peopleData: [
    {
      id: 0,
      name: "Nma Okafor",
      title: "Software Engineer",
      primary_team: "R & D",
      parent_team: "Engineering",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Logged",
      teamMember: { img: profile, name: "Israel" },
    },
    {
      id: 1,
      name: "Jindu Opara",
      title: "Product Designer Intern",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Logged",
      teamMember: { img: profile, name: "Bade" },
    },
    {
      id: 2,
      name: "Abdulkadri Bello",
      title: "Product Designer",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Logged",
      teamMember: { img: profile, name: "Kola" },
    },
    {
      id: 3,
      name: "Greg Collins",
      title: "Product Design Intern",
      primary_team: "Quality Assurance",
      parent_team: "Engineering",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Unlogged",
      teamMember: { img: null, name: "Diamond" },
    },
    {
      id: 4,
      name: "Yahaya Karim",
      title: "Product Designer",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Logged",
      teamMember: { img: null, name: "Israel" },
    },
  ],

  newPeopleData: [
    {
      userId: 2,
      firstName: "akin",
      lastName: "bayo",
      email: "akin@gmail.com",
      primaryTeam: "R&D",
      jobTitle: "Business analyst",
      role: "-",
      img: profile,
      lastSeen: "08/02/2020",
    },
    {
      userId: 5,
      firstName: "dada",
      lastName: "aku",
      email: "dadaku@gmail.com",
      primaryTeam: "R&D",
      jobTitle: "Software Eng",
      role: "-",
      img: profile,
      lastSeen: "08/02/2020",
    },
  ],

  overview: {
    message: "organisation objectives",
    data: {
      avgProgress: 65.3,
      overview: {
        pending: 6,
        atRisk: 9,
        completed: 15,
        behind: 3,
        onTrack: 32,
        notStarted: 7,
      },
    },
  },

  objectiveSummary: {
    message: "organisation objectives",
    data: {
      overview: {
        pending: 6,
        atRisk: 9,
        completed: 15,
        behind: 3,
        onTrack: 32,
        notStarted: 7,
      },
      avgProgress: 65.3,
      noOfObjAligned: 22,
    },
  },

  cycleOptions: {
    message: "cycle added successfully",
    data: [
      {
        id: 1,
        reviewCycle: "2021 cycle3",
        reviewCycleDueDate: "feb 2021",
        reviewCycleStartDate: "jan  2021",
      },
      {
        id: 2,
        reviewCycle: "2021 cycle2",
        reviewCycleDueDate: "january 2021",
        reviewCycleStartDate: "dec  2020",
      },
      {
        id: 3,
        reviewCycle: "2021 cycle1",
        reviewCycleDueDate: "august 2020",
        reviewCycleStartDate: "November  2020",
      },
    ],
  },

  apiPeople: {
    message: "sucess",
    data: {
      items: [
        {
          id: 5,
          firstName: "akin",
          lastName: "bayo",
          email: "akin@gmail.com",
          primaryTeam: "R&D",
          jobTitle: "Business analyst",
          role: "-",
          img: profile,
        },
        {
          id: 6,
          firstName: "dada",
          lastName: "aku",
          email: "dadaku@gmail.com",
          primaryTeam: "R&D",
          jobTitle: "Software Eng",
          role: "-",
          img: profile,
        },
        {
          id: 2,
          firstName: "wizzy",
          lastName: "wozzy",
          email: "wizzy@gmail.com",
          primaryTeam: "R&D",
          jobTitle: "Software Eng",
          role: "-",
          img: profile,
        },
      ],
    },
  },

  allTeams: {
    message: "successfully",
    data: [
      {
        orgObjId: 1,
        teamId: 3,
        reviewCycleId: 65,
        objKeyresultTypeId: "Team",
        completed: false,
        progress: 75,
        lastUpdatedDate: "15/03/2021",
        dueDate: "30/03/2021",
        objectives: "Generate more income",
        owner: {
          staffId: 3,
          image: "www.image.com",
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
      {
        orgObjId: 2,
        teamId: 4,
        reviewCycleId: 67,
        objKeyresultTypeId: "Team",
        completed: false,
        progress: 75,
        lastUpdatedDate: "15/03/2021",
        dueDate: "30/03/2021",
        objectives: "Premium brand",
        owner: {
          staffId: 3,
          image: "www.image.com",
          firstName: "Elvis ",
          lastName: " Jones",
          jobDescription: "Product Designer",
        },
      },
    ],
  },

  teamsDestribution: {
    message: "success",
    data: [
      { id: 1, department: "Administration", logged: 35, unlogged: 8 },
      { id: 2, department: "Advancly", logged: 18, unlogged: 10 },
      { id: 3, department: "AviTech", logged: 18, unlogged: 9 },
      { id: 4, department: "Coporate Communication", logged: 19, unlogged: 9 },
      { id: 5, department: "Customer Success", logged: 34, unlogged: 9 },
      { id: 6, department: "Engineering", logged: 38, unlogged: 8 },
      { id: 7, department: "Finance", logged: 43, unlogged: 10 },
      { id: 8, department: "Garden Data", logged: 52, unlogged: 10 },
      { id: 9, department: "GSV", logged: 55, unlogged: 8 },
      { id: 10, department: "HNI", logged: 43, unlogged: 9 },
      { id: 11, department: "Information Security", logged: 37, unlogged: 8 },
      { id: 12, department: "Legal", logged: 63, unlogged: 8 },
      { id: 13, department: "Operations", logged: 48, unlogged: 8 },
      { id: 14, department: "Product Design", logged: 55, unlogged: 10 },
      { id: 15, department: "Risk Management", logged: 22, unlogged: 9 },
      { id: 16, department: "R&D", logged: 23, unlogged: 9 },
      { id: 17, department: "Security", logged: 59, unlogged: 9 },
      { id: 18, department: "VGN", logged: 43, unlogged: 9 },
      { id: 19, department: "Vibranium Valley", logged: 37, unlogged: 10 },
      { id: 20, department: "VigiPay", logged: 47, unlogged: 10 },
    ],
  },

  teamDestributionByTimeLine: {
    message: "successful",
    data: {
      totalSessionsExpected: 360,
      totalLoggedSession: 215,
      lastPeriodRelation: -55,
      destribution: [
        {
          name: "Logged",
          data: [
            { x: "Administration", y: 35 },
            { x: "Advancely", y: 18 },
            { x: "Avitech", y: 18 },
            { x: "Coporate Communication", y: 19 },
            { x: "Customer Success", y: 34 },
            { x: "Engineering", y: 38 },
            { x: "Finance", y: 43 },
            { x: "Garden Data", y: 52 },
            { x: "GSV", y: 55 },
            { x: "HNI", y: 43 },
            { x: "Information Security", y: 37 },
            { x: "Operations", y: 48 },
            { x: "Product Design", y: 55 },
            { x: "Risk Management", y: 22 },
            { x: "R&D", y: 23 },
            { x: "Security", y: 59 },
            { x: "VGN", y: 43 },
            { x: "Vibranium Valley", y: 37 },
            { x: "VigiPay", y: 47 },
          ],
          color: "#6BE19A",
        },
        {
          name: "UnLogged",
          data: [
            { x: "Administration", y: 8 },
            { x: "Advancely", y: 10 },
            { x: "Avitech", y: 9 },
            { x: "Coporate Communication", y: 9 },
            { x: "Customer Success", y: 9 },
            { x: "Engineering", y: 8 },
            { x: "Finance", y: 10 },
            { x: "Garden Data", y: 10 },
            { x: "GSV", y: 8 },
            { x: "HNI", y: 9 },
            { x: "Information Security", y: 8 },
            { x: "Operations", y: 8 },
            { x: "Product Design", y: 8 },
            { x: "Risk Management", y: 10 },
            { x: "R&D", y: 8 },
            { x: "Security", y: 9 },
            { x: "VGN", y: 9 },
            { x: "Vibranium Valley", y: 10 },
            { x: "VigiPay", y: 9 },
          ],
          color: "#EA6262",
        },
      ],
    },
  },

  teamDestributionByCustomTimeLine: {
    message: "successful",
    data: {
      totalLogged: 10821,
      totalUnlogged: 358,
      noOfCycles: 4,
      distribution: [
        {
          name: "Administration",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 2 },
            { x: "18 Nov - 20 Nov 2020", y: 44 },
            { x: "05 Jan - 19 Jan 2021", y: 55 },
            { x: "04 Feb - 18 Feb 2021", y: 41 },
            { x: "25 Mar - 18 Apr 2021", y: 64 },
          ],
          color: "#8273D9",
        },
        {
          name: "AviTech",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 4 },
            { x: "18 Nov - 20 Nov 2020", y: 53 },
            { x: "05 Jan - 19 Jan 2021", y: 44 },
            { x: "04 Feb - 18 Feb 2021", y: 39 },
            { x: "25 Mar - 18 Apr 2021", y: 18 },
          ],
          color: "#5B8EF0",
        },
        {
          name: "Coporate Communication",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 6 },
            { x: "18 Nov - 20 Nov 2020", y: 53 },
            { x: "05 Jan - 19 Jan 2021", y: 32 },
            { x: "04 Feb - 18 Feb 2021", y: 33 },
            { x: "25 Mar - 18 Apr 2021", y: 52 },
          ],
          color: "#67DDE4",
        },
        {
          name: "Customer Success",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 25 },
            { x: "18 Nov - 20 Nov 2020", y: 22 },
            { x: "05 Jan - 19 Jan 2021", y: 43 },
            { x: "04 Feb - 18 Feb 2021", y: 21 },
            { x: "25 Mar - 18 Apr 2021", y: 55 },
          ],
          color: "#EA6262",
        },
        {
          name: "Engineering",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 49 },
            { x: "18 Nov - 20 Nov 2020", y: 32 },
            { x: "05 Jan - 19 Jan 2021", y: 52 },
            { x: "04 Feb - 18 Feb 2021", y: 13 },
            { x: "25 Mar - 18 Apr 2021", y: 39 },
          ],
          color: "#9D1515",
        },
        {
          name: "Finance",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 15 },
            { x: "18 Nov - 20 Nov 2020", y: 13 },
            { x: "05 Jan - 19 Jan 2021", y: 60 },
            { x: "04 Feb - 18 Feb 2021", y: 49 },
            { x: "25 Mar - 18 Apr 2021", y: 28 },
          ],
          color: "#6BE19A",
        },
        {
          name: "Garden Data",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 12 },
            { x: "18 Nov - 20 Nov 2020", y: 42 },
            { x: "05 Jan - 19 Jan 2021", y: 38 },
            { x: "04 Feb - 18 Feb 2021", y: 29 },
            { x: "25 Mar - 18 Apr 2021", y: 42 },
          ],
          color: "#0F42A4",
        },
        {
          name: "Legal",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 70 },
            { x: "18 Nov - 20 Nov 2020", y: 58 },
            { x: "05 Jan - 19 Jan 2021", y: 32 },
            { x: "04 Feb - 18 Feb 2021", y: 47 },
            { x: "25 Mar - 18 Apr 2021", y: 53 },
          ],
          color: "#FFD04D",
        },
        {
          name: "Product Design",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 25 },
            { x: "18 Nov - 20 Nov 2020", y: 23 },
            { x: "05 Jan - 19 Jan 2021", y: 33 },
            { x: "04 Feb - 18 Feb 2021", y: 23 },
            { x: "25 Mar - 18 Apr 2021", y: 13 },
          ],
          color: "#1B9098",
        },
        {
          name: "VGN",
          data: [
            { x: "03 Nov - 17 Nov 2020", y: 63 },
            { x: "18 Nov - 20 Nov 2020", y: 28 },
            { x: "05 Jan - 19 Jan 2021", y: 48 },
            { x: "04 Feb - 18 Feb 2021", y: 37 },
            { x: "25 Mar - 18 Apr 2021", y: 54 },
          ],
          color: "#F3A358",
        },
      ],
    },
  },

  oneOnOneStaff: {
    message: "success",
    data: [
      { id: 0, memberTitle: "Software Engineer", lineManager: "Israel Akins", status: "Logged", sessions: "3 of 4", teamMember: { img: profile, name: "Israel" } },
      { id: 1, memberTitle: "Product Designer Intern", lineManager: "Israel Akins", status: "Logged", sessions: "3 of 4", teamMember: { img: profile, name: "Bade" } },
      { id: 2, memberTitle: "Product Designer", lineManager: "Israel Akins", status: "Logged", sessions: "3 of 4", teamMember: { img: profile, name: "Kola" } },
      { id: 3, memberTitle: "Product Design Intern", lineManager: "Israel Akins", status: "Logged", sessions: "1 of 4", teamMember: { img: null, name: "Diamond" } },
      { id: 4, memberTitle: "Product Designer", lineManager: "Israel Akins", status: "Logged", sessions: "3 of 4", teamMember: { img: null, name: "Israel" } },
    ],
  },

  timelineOptions: {
    message: "Timeline Options",
    data: [
      { id: null, description: "Custom Timeline" },
      { id: 1, description: "Feb 2021" },
      { id: 2, description: "Mar 2021" },
      { id: 3, description: "Apr 2021" },
      { id: 4, description: "May 2021" },
    ],
  },

  okrCycleOptions: {
    message: "Timeline Options",
    data: [
      { id: 1, description: "2020 Cycle 1" },
      { id: 2, description: "2020 Cycle 2" },
      { id: 3, description: "2020 Cycle 3" },
      { id: 4, description: "2020 Cycle 4" },
    ],
  },

  okrPeople: {
    message: "success",
    data: [
      {
        id: 0,
        name: "Nma Okafor",
        title: "Software Engineer",
        primaryTeam: "R & D",
        parentTeam: "Engineering",
        keyResultsCount: 13,
        lastUpdated: "2021-03-03T14:07:41.150Z",
        lineManager: "Israel Oyinlola",
        status: "Logged",
        teamMember: { img: profile, name: "Israel" },
      },
      {
        id: 1,
        name: "Jindu Opara",
        title: "Product Designer Intern",
        primaryTeam: "PowerTech",
        parentTeam: "",
        keyResultsCount: 9,
        lastUpdated: "2021-02-12T14:07:41.150Z",
        lineManager: "Rolanke Osho",
        status: "Logged",
        teamMember: { img: profile, name: "Bade" },
      },
      {
        id: 2,
        name: "Abdulkadri Bello",
        title: "Product Designer",
        primaryTeam: "VigiPay",
        parentTeam: "",
        keyResultsCount: 21,
        lastUpdated: "2021-01-31T14:07:41.150Z",
        lineManager: "John Ayoobola",
        status: "Logged",
        teamMember: { img: profile, name: "Kola" },
      },
      {
        id: 3,
        name: "Greg Collins",
        title: "Product Design Intern",
        primaryTeam: "Quality Assurance",
        parentTeam: "Engineering",
        keyResultsCount: 10,
        lastUpdated: "2020-11-18T14:07:41.150Z",
        lineManager: "Sandra Isamade",
        status: "Unlogged",
        teamMember: { img: null, name: "Diamond" },
      },
      {
        id: 4,
        name: "Yahaya Karim",
        title: "Product Designer",
        primaryTeam: "GSV",
        parentTeam: "",
        keyResultsCount: 15,
        lastUpdated: "2020-08-24T14:07:41.150Z",
        lineManager: "Bimbo Dolu",
        status: "Logged",
        teamMember: { img: null, name: "Israel" },
      },
    ],
  },

  okrTeams: {
    message: "Success",
    data: [
      {
        id: 1,
        name: "Research and Development",
        type: "Department",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: "Engineering",
        teamLead: "Israel Oyinlola",
        contributingMembersKeyResults: "8 of 12",
        membersCount: 9,
        objectivesCount: 6,
      },
      {
        id: 2,
        name: "PowerTech",
        type: "SBU",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: "",
        teamLead: "Rolanke Osho",
        contributingMembersKeyResults: "13 of 32",
        membersCount: 23,
        objectivesCount: 17,
      },
      {
        id: 3,
        name: "VigiPay",
        type: "SBU",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: "",
        teamLead: "John Ayoobola",
        contributingMembersKeyResults: "26 of 28",
        membersCount: 34,
        objectivesCount: 4,
      },
      {
        id: 4,
        name: "Quality Assurance",
        type: "",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: "Engineering",
        teamLead: "Sandra Isamade",
        contributingMembersKeyResults: "2 of 15",
        membersCount: 12,
        objectivesCount: 10,
      },
      {
        id: 8,
        name: "GSV",
        type: "SBU",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: null,
        teamLead: "Bimbo Dolu",
        contributingMembersKeyResults: "11 of 22",
        membersCount: 29,
        objectivesCount: 13,
      },
      {
        id: 5,
        name: "Customer Success",
        type: "Department",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: null,
        lead: "Israel Oyinlola",
        contributingMembersKeyResults: "17 of 17",
        membersCount: 40,
        objectivesCount: 12,
      },
      {
        id: 6,
        name: "Engineering",
        type: "Division",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: null,
        lead: "Israel Oyinlola",
        contributingMembersKeyResults: "10 of 18",
        membersCount: 37,
        objectivesCount: 17,
      },
      {
        id: 7,
        name: "Financial Service",
        type: "Department",
        title: "Team Leader: Agboola Taiwo",
        parentTeam: null,
        lead: "Israel Oyinlola",
        contributingMembersKeyResults: "8 of 12",
        membersCount: 8,
        objectivesCount: 5,
      },
    ],
  },
};
