import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "../searchBar/searchBar";
import LinkIcon from "../../../assets/images/link.svg";
import RadioIcon from "../../../assets/images/radio.svg";
import RadioBtnIcon from "../../../assets/images/filled-radiobtn.svg";
import { getParentKeyResultByTeamId, getObectiveByTeamId } from "../../../redux/actions/keyResult/keyResultActions";
import LineAccordion from "./lineAccordion/lineAccordion";
import PMSSelect from "../../shared/selectInput/pmsSelect/pmsSelect";
import { getTeamsOptions } from "../../../redux/actions/settingsActions/teamSettingsActions";

import "./alignKeyResult.scss";
import { LoadingDetails } from "../loader/Loader";
import { userDetails } from "../../../apiServices/user.service";
import { errorMessage } from "../../../redux/actions/notification/notification";

const AlignKeyResult = ({ setAlignment, alignment, setDisabled }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(1);
  const [teamId, setTeamId] = useState(userDetails()?.teamId);
  const [reviewCycle, setReviewCycle] = useState("");

  const select = useSelector(({ keyResults, teamSettings }) => {
    return {
      loading: keyResults && keyResults.getParentKeyResultByTeamLoading,
      errorResponse: keyResults && keyResults.getParentKeyResultByTeamError,
      keyResultLoading: keyResults?.keyResultByObjectiveLoading,
      keyResultError: keyResults && keyResults?.keyResultByObjectiveError,

      getParentKeyResultByTeamPayload: keyResults?.getParentKeyResultByTeamPayload,
      getParentKeyResultByTeamLoading: keyResults?.getParentKeyResultByTeamLoading,
      getParentKeyResultByTeamError: keyResults?.getParentKeyResultByTeamError,
      getObjectiveTeamIdLoading: keyResults?.getObjectiveTeamIdLoading,
      getObjectiveTeamIdError: keyResults?.getObjectiveTeamIdError,
      getObjectiveTeamIdPayload: keyResults?.getObjectiveTeamIdPayload,

      reviewCyclePayload: keyResults?.reviewCyclePayload,
      reviewCycleError: keyResults?.reviewCycleError,
      reviewCycleLoading: keyResults?.reviewCycleLoading,

      teamsOptions: teamSettings?.teamsOptions,
      isLoadingTeamsOptions: teamSettings?.isLoadingTeamsOptions,
    };
  });

  const {
    getObjectiveTeamIdPayload,
    getObjectiveTeamIdLoading,
    getParentKeyResultByTeamPayload,
    getParentKeyResultByTeamLoading,
    reviewCycleLoading,
    reviewCycleError,
    reviewCyclePayload,
    teamsOptions,
  } = select;

  const onSelectKeyResult = (val) => {
    setAlignment({ ...alignment, ...val });
  };

  const dropdownList = reviewCyclePayload?.data?.data?.map((val) => {
    return {
      id: val.cycleId,
      name: val?.description,
      label: val?.description,
    };
  });

  console.log({ teamsOptions });
  const teamDropdownList = teamsOptions?.map((val) => {
    return {
      id: val.teamId,
      name: val?.teamName,
      label: val?.teamName,
    };
  });

  const SubItem = () => {
    return (
      <div className="sub-content">
        {getParentKeyResultByTeamLoading && <LoadingDetails />}

        {getParentKeyResultByTeamPayload?.data?.data?.items?.map((val, i) => {
          return (
            <div
              key={i}
              className={`d-flex justify-content-between ${i !== 0 && "sub-content-item"}`}
              onClick={() => {
                onSelectKeyResult(val);
                setDisabled(false);
              }}
            >
              <div>
                <div className="title">{val?.description}</div>
                <div className="sub-title">
                  <img src={LinkIcon} alt="link icon" /> {val?.teamName}
                </div>
              </div>
              <img src={val.id === (alignment && alignment.id) ? RadioBtnIcon : RadioIcon} alt="radio icon" className="radio-btn" />
            </div>
          );
        })}
      </div>
    );
  };

  const selectId = (id) => {
    id === selected ? setSelected("") : setSelected(id);
  };

  useEffect(() => {
    dispatch(getObectiveByTeamId(teamId, reviewCycle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCycle, teamId]);

  useEffect(() => {
    dispatch(getTeamsOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reviewCycleError && !reviewCycleLoading) {
      dispatch(errorMessage(reviewCycleError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewCycleLoading]);
  return (
    <div className="align-key-result-container">
      <div className="description">Align your new Key Result to Objectives and Key results from Teams in your organisation</div>
      <div className="textbox">
        <div className="search">
          <SearchBar placeholder="Search goals" handleSearch={() => {}} searchTerm="" />
        </div>
        <div className="pms-select">
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            dataSource={dropdownList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="name"
            valueExpr="id"
            onChange={(e) => {
              setReviewCycle(e.value);
            }}
            selectAllText="All Types"
            controlHeight="40px"
            value={[]}
          />
        </div>
        <div className="pms-select">
          <PMSSelect
            placeholder="Team"
            selectMultiple={false}
            dataSource={teamDropdownList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="name"
            valueExpr="id"
            onChange={(e) => {
              setTeamId(e.value);
            }}
            selectAllText="All Types"
            controlHeight="40px"
            value={[]}
          />
        </div>
        {/* <select className="form-control">
          <option>2020 Cycle 1</option>
        </select> */}
        {/* <select className="form-control">
          <option>All Teams</option>
        </select> */}
      </div>
      <div className="keyValues">
        {getObjectiveTeamIdLoading && <LoadingDetails />}
        {getObjectiveTeamIdPayload?.data?.data?.items?.map((value) => {
          return (
            <LineAccordion nested={1} objectiveId={value.id} key={value.id} selected={selected} subItems={value.id === selected && SubItem()}>
              <div
                className="d-flex justify-content-between"
                data-testid="lineAccordion"
                onClick={() => {
                  dispatch(getParentKeyResultByTeamId(value?.id));
                  selectId(value.id);
                }}
              >
                <div>
                  <div className="title">{value?.description}</div>
                  <div className="sub-title">
                    <img src={LinkIcon} alt="link icon" /> {value?.team?.teamName}
                  </div>
                </div>
                <img src={value.id === selected ? RadioBtnIcon : RadioIcon} alt="radio icon" className="radio-btn" />
              </div>
            </LineAccordion>
          );
        })}
      </div>
    </div>
  );
};

export default AlignKeyResult;
