import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FromMultipleTeams from "./fromMultipleTeams/fromMultipleTeams";
import FromSingleTeam from "./fromSingleTeam/fromSingleTeam";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import CustomTable from "../../../shared/table/table";
import "./inviteUserEditor.scss";

const InviteUserEditor = ({
  openModal,
  closeModal,
  isLoadingReview,
  isLoadingInviteSingle,
  isLoadingTeamsOptions,
  teamsOptions,
  uploadingUsersEmails,

  data,
  onSubmit,
}) => {
  const [show, setShow] = useState(false);
  const [tabKey, setTabKey] = useState("single");
  const [isValid, setIsValid] = useState(false);
  const [upladedData, setUpladedData] = useState(undefined);
  const [reviewMode, setReviewMode] = useState(false);
  const [reviewedData] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    teamId: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileUpload = evt => {
    setUpladedData(evt);
  };
  useEffect(() => {
    openModal ? handleShow() : handleClose();

    if (!openModal) {
      setFormData({
        email: "",
        teamId: "",
      });

      setReviewMode(false);
      setTabKey("single");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const switchTabs = key => {
    console.log(key);
    if (key === "single") {
    } else if (key === "multple") {
      setFormData({
        email: "",
        teamId: "",
      });
    }
    setTabKey(key);
  };
  useEffect(() => {
    setIsValid(formValid(formData));
  }, [formData]);

  useEffect(() => {
    setFormData({
      email: data ? data.email : "",
      teamId: data ? data.teamId : null,
    });
  }, [data]);

  useEffect(() => {
    if (tabKey === "multiple") {
      setFormData({
        email: "",
        teamId: null,
      });
    } else if (tabKey === "single") {
      setUpladedData(undefined);
    }
  }, [tabKey]);

  useEffect(() => {
    if (reviewedData) {
      setReviewMode(true);
    }
  }, [reviewedData]);

  // useEffect(() => {
  //   if (reviewEmailsPayload && reviewEmailsPayload.success && reviewEmailsPayload.data) {
  //     setReviewedData(reviewEmailsPayload.data);
  //   }
  // }, [reviewEmailsPayload]);

  // const handleSubmit = () => {
  //   if (tabKey === "single") {
  //     onSubmit(formData, tabKey, false);
  //     console.log(formData);
  //   } else if (tabKey === "multiple" && !reviewMode) {
  //     onSubmit(upladedData, tabKey, reviewMode);
  //   } else if (reviewMode) {
  //     console.log("submit excel!", upladedData);
  //     onSubmit(upladedData, tabKey, reviewMode);
  //   }
  // };

  const handleSubmit = () => {
    if (tabKey === "single") {
      onSubmit(formData, tabKey);
    } else {
      onSubmit(upladedData, tabKey);
    }
  };

  const handleReUpload = () => {
    setReviewMode(false);
  };

  const handleInputChange = e => {
    if (e.target) {
      const { value, name } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formValid = fd => {
    return !!fd.email && !!fd.teamId;
  };

  const tableHeads = [
    { id: 1, name: "User Email" },
    { id: 2, name: "Primary Team" },
    { id: 3, name: "Status" },
  ];

  const tableRows = tableData => {
    return tableData.map((value, index) => {
      return (
        <tr key={index + 1}>
          <td>{value.user_email}</td>
          <td>{value.primary_team}</td>
          <td>
            <span className={value.status.success ? "success" : "failure"}>{value.status.message}</span>
          </td>
        </tr>
      );
    });
  };

  return (
    <PMSModal
      title="Invite Users"
      openModal={show}
      handlePrimaryButtonClick={handleSubmit}
      processing={isLoadingInviteSingle || uploadingUsersEmails}
      primaryButtonText="Send Invite"
      // title={`${reviewMode ? "Review User Upload" : "Invite Users"}`}
      // processing={isLoadingInviteSingle || reviewingUsersEmails}
      // primaryButtonText={`${tabKey === "single" ? "Send Invite" : reviewMode ? "Send Invite" : "Review Upload"}`}
      // isPrimaryButtonOuline={!reviewMode}
      containerId="invite-user-editor"
      closeModal={closeModal}
      contentClassName="invite-user-editor-content"
      showSecondaryButton={false}
      disablePrimaryButton={!isValid && !upladedData}
      closeOnOutsideClick={false}
      showLeftButton={reviewMode}
      leftButtonText="Upload New File"
      leftButtonGhost
      onLeftButtonClick={handleReUpload}
      size={reviewMode ? "lg" : "md"}
      testId="inviteUserModal"
      useCloseIcon={!reviewMode}
      useHeaderBorder={false}
    >
      <div className="invite-user-editor-container" data-testid="invite-user-editor-container">
        {reviewMode && reviewedData ? (
          <CustomTable arrangedTableData={tableRows(reviewedData)} tableHeads={tableHeads} isLoading={isLoadingReview} tableData={reviewedData} searchTableData={reviewedData} showPaginator={false} />
        ) : (
          <Tabs id="controlled-tab-team" activeKey={tabKey} onSelect={switchTabs}>
            <Tab eventKey="single" title="from Single Team">
              {tabKey === "single" && <FromSingleTeam formData={formData} handleChange={handleInputChange} teamsOptions={teamsOptions} isLoadingTeamsOptions={isLoadingTeamsOptions} />}
            </Tab>
            <Tab eventKey="multiple" title="from Multiple Teams">
              {tabKey === "multiple" && <FromMultipleTeams onFileUpload={handleFileUpload} />}
            </Tab>
          </Tabs>
        )}
      </div>
    </PMSModal>
  );
};

export default InviteUserEditor;
