import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button } from "../../shared";
import "./pmsModal.scss";

const PMSModal = ({
  openModal,
  closeModal,

  children,
  title,
  processing,
  containerId,
  contentClassName,

  primaryButtonText,
  handlePrimaryButtonClick,
  disablePrimaryButton,
  primaryButtonColor = "blue",
  isPrimaryButtonGhost,
  isPrimaryButtonOuline,
  modalClassWidth,
  showSecondaryButton,
  secondaryButtonText = "Cancel",
  secondaryButtonColor = "normal",
  isScondaryButtonGhost = true,
  isScondaryButtonOuline,
  disableSecondaryButton,
  useHeaderBorder = true,
  closeOnOutsideClick = true,
  showPrimaryButton = true,
  showHeader = true,
  showFooter = true,
  showLeftButton,
  leftButtonText,
  leftButtonOutline,
  leftButtonGhost,
  disableLeftButton,
  onLeftButtonClick,
  leftButtonColor,
  size,
  scrollable,
  testId,
  useCloseIcon,
  headerBorderClass,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    openModal ? handleShow() : handleClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal
        show={show}
        size={size}
        dialogClassName={modalClassWidth === "730" && "modal-width"}
        onHide={closeModal}
        backdrop={closeOnOutsideClick || "static"}
        keyboard={false}
        id={containerId}
        className="pms-modal-container"
        contentClassName={contentClassName}
        data-testid="pms-modal"
        centered
      >
        {showHeader && (
          <Modal.Header closeButton>
            <span className="align-self-center modal-title">{title}</span>
          </Modal.Header>
        )}
        {useHeaderBorder && <hr className={`header-border ${headerBorderClass}`} />}
        <Modal.Body>{children}</Modal.Body>
        {showFooter && (
          <Modal.Footer>
            <div className="m-0 w-100 d-flex justify-content-between align-items-center">
              <div>
                {showLeftButton && leftButtonText && (
                  <a href="#/" className="pull-left" onClick={onLeftButtonClick}>
                    {leftButtonText}
                  </a>
                  // <Button
                  //   ghost={leftButtonGhost}
                  //   outline={leftButtonOutline && !leftButtonGhost}
                  //   color={leftButtonColor}
                  //   handleClick={onLeftButtonClick}
                  //   disabled={disableLeftButton}
                  //   className="mr-2 pull-left"
                  //   dataTestId="leftButton"
                  // >
                  //   {leftButtonText}
                  // </Button>
                )}
              </div>
              <div>
                {showPrimaryButton && primaryButtonText && (
                  <Button
                    color={primaryButtonColor}
                    handleClick={handlePrimaryButtonClick}
                    disabled={disablePrimaryButton}
                    ghost={isPrimaryButtonGhost}
                    outline={isPrimaryButtonOuline}
                    loading={processing}
                    className="pull-right"
                  >
                    {primaryButtonText}
                  </Button>
                )}
                {showSecondaryButton && (
                  <Button
                    ghost={isScondaryButtonGhost}
                    outline={isScondaryButtonOuline}
                    color={secondaryButtonColor}
                    handleClick={closeModal}
                    disabled={disableSecondaryButton}
                    className="mr-2 pull-right"
                    dataTestId="cancelButton"
                  >
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

PMSModal.propTypes = {
  openModal: PropTypes.bool,
  scrollable: PropTypes.bool,
  closeModal: PropTypes.func,

  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  processing: PropTypes.bool,
  containerId: PropTypes.string,
  contentClassName: PropTypes.string,

  primaryButtonText: PropTypes.string,
  handlePrimaryButtonClick: PropTypes.func,
  disablePrimaryButton: PropTypes.bool,
  primaryButtonColor: PropTypes.string,
  isPrimaryButtonGhost: PropTypes.bool,
  isPrimaryButtonOuline: PropTypes.bool,

  showSecondaryButton: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonColor: PropTypes.string,
  isScondaryButtonGhost: PropTypes.bool,
  isScondaryButtonOuline: PropTypes.bool,
  disableSecondaryButton: PropTypes.bool,

  useHeaderBorder: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  showLeftButton: PropTypes.bool,
  leftButtonText: PropTypes.string,
  leftButtonOutline: PropTypes.bool,
  leftButtonGhost: PropTypes.bool,
  disableLeftButton: PropTypes.bool,
  onLeftButtonClick: PropTypes.func,
  leftButtonColor: PropTypes.string,
  size: PropTypes.string,
  headerBorderClass: PropTypes.string,
};

PMSModal.defaultProps = {
  openModal: false,
  scrollable: false,
  closeModal: () => {},

  children: <div />,
  title: "",
  processing: false,
  containerId: "",
  contentClassName: "",

  handlePrimaryButtonClick: () => {},
  disablePrimaryButton: false,

  isPrimaryButtonGhost: false,
  isPrimaryButtonOuline: false,

  showSecondaryButton: false,
  secondaryButtonText: "Cancel",
  secondaryButtonColor: "normal",
  isScondaryButtonGhost: true,
  isScondaryButtonOuline: false,
  disableSecondaryButton: false,

  useHeaderBorder: true,
  closeOnOutsideClick: true,
  showLeftButton: false,
  leftButtonOutline: true,
  leftButtonGhost: true,
  disableLeftButton: false,
  onLeftButtonClick: () => {},
  size: "md",
  headerBorderClass: "",
};

export default PMSModal;
