import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { COMPANY_TEAMS_APIS } from "../../../apiServices/endPoints";

export const teamsActionTypes = {
  GET_ALL_TEAMS: "[COMPANY - TEAMS] GET_ALL_TEAMS",
  GET_TEAM_OBJECTIVES_BY_ID: "[COMPANY - TEAMS] GET_TEAM_OBJECTIVES_BY_ID",
  GET_TEAM_OBJECTIVE_KR: "[COMPANY - TEAMS] GET_TEAM_OBJECTIVE_KR",
  GET_TEAM_OVERVIEW: "[COMPANY - TEAMS] GET_TEAM_OVERVIEW",
  GET_REVIEW_CYCLE_OPTIONS: "[COMPANY - TEAMS] GET_REVIEW_CYCLE_OPTIONS",
};

export const LoadAllTeams = createAsyncAction(teamsActionTypes.GET_ALL_TEAMS, async ({ teamName, pageNumber, pageSize }) => {
  return await Get(`${COMPANY_TEAMS_APIS.getAll}${pageSize ? "?pageSize=" + pageSize : ""}${pageNumber ? "&pageNumber=" + pageNumber : ""}${teamName ? "&searchTerm=" + teamName : ""}`);
});

export const LoadTeamObjectivesById = createAsyncAction(teamsActionTypes.GET_TEAM_OBJECTIVES_BY_ID, async ({ teamId, cycleId, searchTerm }) => {
  return await Get(`${COMPANY_TEAMS_APIS.getTeamObjs}/${teamId}/objectives${cycleId ? "?cycleId=" + cycleId : ""}${searchTerm ? "&searchTerm=" + searchTerm : ""}`);
});

export const LoadTeamOverview = createAsyncAction(teamsActionTypes.GET_TEAM_OVERVIEW, async ({ teamId }) => {
  return await Get(`${COMPANY_TEAMS_APIS.getTeamOverview}/${teamId}`);
});

export const LoadTeamCycleOptions = createAsyncAction(teamsActionTypes.GET_REVIEW_CYCLE_OPTIONS, async () => {
  return await Get(COMPANY_TEAMS_APIS.getCycleOptions);
});
