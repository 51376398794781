import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AccordionComponent from "../../shared/accordion/accordion";
import ArrowDown from "../../../assets/images/arrow-down-tiny.svg";
import ArrowRight from "../../../assets/images/arrow-right-tiny.svg";
import { ProgressBar } from "react-bootstrap";
import { ContributionsIcon } from "../../shared/svgIcons/svgIcons";
import NotificationIcon from "../../../assets/icons/notification.svg";
import { getPercentColor } from "../../../utils/helpers";
import { getUserKeyResults } from "../../../redux/actions/keyResult/keyResultActions";
import { useDispatch, useSelector } from "react-redux";
import "./lineReport.scss";

const LineReportCard = ({ lineReportData, index, handleViewKeyresult, setDisapproveModal }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const select = useSelector(({ keyResults }) => {
    return {
      resultListLoading: keyResults && keyResults.isLoading,
      resultListPayload: keyResults && keyResults.userKeyResults?.data?.data,
    };
  });

  const overview = {
    pending: 6,
    atRisk: 9,
    completed: 15,
    behind: 3,
    onTrack: 32,
    notStarted: 7,
  };

  useEffect(() => {
    dispatch(getUserKeyResults(lineReportData?.userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgress = (data) => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  return (
    <div className="row d-flex">
      <div className="accordion-div container-fluid">
        <AccordionComponent
          accordionLabel={
            <div className="row m-0 accordionLabel" onClick={() => setSelected(index)}>
              <div style={{ height: "fit-content" }}>
                {selected === index ? (
                  <img src={ArrowDown} className="arrow-down mr-4" alt="arrow down icon" />
                ) : (
                  <img src={ArrowRight} className="arrow-up" style={{ marginRight: "26px" }} alt="arrow right icon" />
                )}
              </div>
              <div style={{ textAlign: "left" }}>
                <span className="mb-2">
                  {select.resultListPayload?.items?.length} Key Results
                  {/* {index + 1} */}
                  <span style={{ paddingLeft: "8px" }}>
                    <img src={NotificationIcon} alt="logged icon" />
                  </span>
                </span>
                <ProgressBar>{getProgress(overview)}</ProgressBar>
              </div>
            </div>
          }
          children={
            <div className="hover-effect">
              {select.resultListPayload &&
                select.resultListPayload?.items.map((val, index) => {
                  return (
                    <div key={index} className="children-container">
                      <div style={{ cursor: "pointer" }} onClick={() => handleViewKeyresult(val, lineReportData)}>
                        <div className="keyresultChildren">
                          {val?.keyresult}
                          <span style={{ paddingLeft: "11px" }}>
                            <ContributionsIcon />
                          </span>
                          <span style={{ paddingLeft: "8px" }}>
                            <img src={NotificationIcon} alt="logged icon" />
                          </span>
                        </div>
                        <div className="accordionLabelSubtext">
                          <span style={{ fontWeight: "bold" }}>{val?.parentObjective ? val.parentObjective?.team?.teamName : "undefined"} - </span>
                          <span>{val?.parentObjective?.description}</span>
                        </div>
                      </div>

                      {val?.status === "APPROVED" ? (
                        <div>
                          <span>
                            <Link to="#" className="link-text-blue">
                              Approve
                            </Link>
                            <Link to="#" className="link-text-red ml-3" onClick={() => setDisapproveModal(true)}>
                              Disapprove
                            </Link>
                          </span>
                        </div>
                      ) : (
                        <ProgressBar variant={getPercentColor(val?.progress)} now={val?.progress} className="mr-1 progress-mini" />
                      )}
                    </div>
                  );
                })}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default LineReportCard;
