import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDraft } from "../../../../redux/actions/keyResult/keyResultActions";
import { errorMessage } from "../../../../redux/actions/notification/notification";
import { LineLoader } from "../../../shared/loader/Loader";

import "./draft.scss";

const Draft = ({ setFormValues }) => {
  const dispatch = useDispatch();
  const { error, payload, loading } = useSelector(({ keyResults }) => {
    return {
      payload: keyResults?.getUserDraftPayload,
      loading: keyResults?.getUserDraftLoading,
      error: keyResults?.getUserDraftError,
    };
  });
  console.log({ draft: payload });
  useEffect(() => {
    if (error && !loading) {
      dispatch(errorMessage(error?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    dispatch(getUserDraft());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="draft">Drafts</div>
      {loading && <LineLoader lines={15} />}
      {!loading &&
        payload?.data?.data?.map((val) => {
          return (
            <div className="draft-content" key={val?.id}>
              <div className="desc">{val?.description}</div>
              <div
                className="resume"
                onClick={() => {
                  setFormValues({ description: val?.description });
                  console.log({ val: val?.description });
                }}
              >
                Resume
              </div>
            </div>
          );
        })}
    </>
  );
};
export default Draft;
