import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Button from "../../../../shared/button/Button";
import LinkIcon from "../../../../../assets/images/link.svg";
import CloseIcon from "../../../../../assets/images/close.svg";
import PMSSelect from "../../../../shared/selectInput/pmsSelect/pmsSelect";
import { postUserKeyResult, getAllReviewCycle, getObectiveByTeamId, updateUserKeyResult, getUsersKeyResultList, getUserDraft } from "../../../../../redux/actions/keyResult/keyResultActions";
import "./form.scss";
import { errorMessage, successMessage } from "../../../../../redux/actions/notification/notification";
import { userDetails } from "../../../../../apiServices/user.service";

const formData = ["reviewCycleId", "parentTeamKeyResultId", "description", "progressType", "targetGoal", "progress", "dueDate"];
const GoalForm = ({ setShowAlignKeyResultModal, setModalShow, modalShow, setAlignment, alignment, setFormValues, formValues }) => {
  console.log({ formValues });
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [reviewCycleId, setReviewCycle] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedDraft, setIsSubmittedDraft] = useState(false);
  const [isFormValid, setIsFormValid] = useState(null);

  // const [tracking, setTracking] = useState(null);
  const select = useSelector(({ keyResults }) => {
    return {
      reviewCyclePayload: keyResults && keyResults.reviewCyclePayload,
      reviewCycleError: keyResults && keyResults.reviewCycleError,
      reviewCycleLoading: keyResults && keyResults.reviewCycleLoading,
      payload: keyResults && keyResults.postUserKeyResultPayload,
      isLoading: keyResults && keyResults.postUserKeyResultLoading,
      errorResponse: keyResults && keyResults.postUserKeyResultError,

      payloadUpdate: keyResults?.updateUserKeyResultPayload,
      isLoadingUpdate: keyResults?.updateUserKeyResultLoading,
      errorResponseUpdate: keyResults?.updateUserKeyResultError,
    };
  });

  const { payload, isLoading, reviewCyclePayload, payloadUpdate, isLoadingUpdate, errorResponse, errorResponseUpdate } = select;

  const dropdownList = reviewCyclePayload?.data?.data?.map((val) => {
    return {
      id: val.cycleId,
      name: val?.description,
      label: val?.description,
    };
  });

  useEffect(() => {
    if ((isSubmitted || isSubmittedDraft) && payload && !isLoading) {
      form.reset();
      setModalShow(false);
      isSubmitted && dispatch(successMessage("Your Key Results have been submitted for approval. You will be notified once your Manager approves."));
      isSubmittedDraft && dispatch(successMessage("Your Key Results have been saved successfully"));
      isSubmittedDraft && dispatch(getUserDraft());
      setIsSubmitted(false);
      setIsSubmittedDraft(false);
      dispatch(getUsersKeyResultList(1, 10));
    } else if (errorResponse?.data?.message) {
      !isLoading && dispatch(errorMessage(errorResponse?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (payloadUpdate && form?.reset) {
      form.reset();
      setModalShow(false);
      setIsSubmitted(false);
      dispatch(successMessage("Your Key Results have been updated for approval."));
      dispatch(getUsersKeyResultList(1, 10));
    } else if (errorResponseUpdate?.data?.message) {
      !isLoading && dispatch(errorMessage(errorResponseUpdate?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdate]);

  useEffect(() => {
    const teamId = userDetails()?.teamId;
    dispatch(getObectiveByTeamId(teamId, ""));
    dispatch(getAllReviewCycle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (event, value) => {
    if (isFormValid && event !== "draft") {
      setIsSubmittedDraft(false);

      const updateDetails = {
        ...formValues,
        ...value,
        isPublished: true,
        parent: null,
        targetGoal: Number(value?.targetGoal),
        progress: Number(value?.progress),
        staffComment: "my comment",
        reviewCycleId: reviewCycleId || formValues?.reviewCycleId,
        parentTeamKeyResultId: alignment?.id || formValues?.parentTeamKeyResultId,
      };
      formValues?.edit
        ? dispatch(
            updateUserKeyResult({
              keyResultStatus: updateDetails?.keyResultStatus,
              reviewCycleId: updateDetails?.reviewCycleId,
              keyResultId: updateDetails?.keyResultId,
              parentTeamKeyResultId: updateDetails?.parentTeamKeyResultId,
              description: updateDetails?.description,
              progressType: "PERCENTAGE",
              staffComment: updateDetails?.staffComment || "",
              targetGoal: updateDetails?.targetGoal,
              isCompleted: true,
              progress: updateDetails?.progress,
              dueDate: updateDetails?.dueDate,
            })
          )
        : dispatch(
            postUserKeyResult({
              ...value,
              isPublished: true,
              targetGoal: Number(value?.targetGoal),
              progress: Number(value?.progress),
              staffComment: "my comment",
              reviewCycleId: reviewCycleId || formValues?.reviewCycleId,
              parentTeamKeyResultId: alignment?.id,
            })
          );
    }
  };

  const onSave = (event, errors, values) => {
    if (event === "draft") {
      setIsSubmittedDraft(true);
      setIsSubmitted(false);
      let payload = {};
      for (let val of formData) {
        if (!formValues?.[val] || formValues?.[val] === "") {
          if (val === "targetGoal" || val === "progress") {
            payload = { ...payload, [val]: 0 };
          } else if (val === "dueDate") {
            payload = { ...payload, [val]: new Date() };
          } else {
            payload = { ...payload, [val]: "" };
          }
        } else {
          payload = { ...payload, [val]: formValues?.[val] };
        }
      }
      dispatch(
        postUserKeyResult({
          ...payload,
          targetGoal: parseInt(payload?.targetGoal),
          progress: parseInt(payload?.progress),
        })
      );
    } else {
      setFormValues({ ...values, ...formValues, isPublished: false, reviewCycleId: reviewCycleId || formValues?.reviewCycleId });
    }
  };

  const onAddValue = (e) => {
    const { value, name } = e.target;
    const values = { ...formValues, [name]: value };
    setFormValues(values);
    let status = true;
    for (let val of formData) {
      if (!values?.[val] || values?.[val] === "") {
        status = false;
      }
    }
    setIsFormValid(status);
  };

  useEffect(() => {
    if (alignment?.id) {
      setFormValues({ ...formValues, parentTeamKeyResultId: alignment?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alignment?.id]);
  return (
    <AvForm className="goal-form-content" data-testid="submit" model={formValues} onValidSubmit={submit} onSubmit={onSave} ref={(c) => setForm(c)}>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="yourGoal">What is your goal?</label>
          <AvField autoComplete="off" type="text" name="description" value={formValues?.description} className="form-field" placeholder="Enter your goal here" onChange={onAddValue} />
          {formValues?.description === "" && isSubmitted && <div className="error-feedback-message-form">Please enter goal</div>}
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="reviewCycle">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            value={[]}
            dataSource={dropdownList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="name"
            valueExpr="id"
            onChange={(e) => {
              setReviewCycle(e.value);
              setFormValues({ ...formValues, reviewCycleId: e.value });
            }}
            selectAllText="All Types"
            controlHeight="48px"
          />
          {!formValues?.reviewCycleId && isSubmitted && <div className="error-feedback-message">Please select review cycle</div>}
        </div>
        <div className="col-6">
          <label htmlFor="dueDate">Due Date</label>
          <AvField autoComplete="off" type="date" name="dueDate" className="form-field" placeholder="Select date" onChange={onAddValue} />
          {!formValues?.dueDate && isSubmitted && <div className="error-feedback-message-form">Please choose due date</div>}
        </div>
      </div>
      <div>
        <div className="alignment">Alignment</div>
        <div>
          {(alignment || formValues?.parent?.keyresult) && (
            <>
              <div className="alignment-title">
                {alignment?.description || formValues?.parent?.keyresult}
                <img src={CloseIcon} alt="close icon" onClick={() => setAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> {alignment?.teamName}
              </div>
            </>
          )}
        </div>
        {!(alignment || formValues?.parent?.keyresult) && (
          <div
            className="align-goal"
            onClick={() => {
              form.submit();
              setModalShow(false);
              setShowAlignKeyResultModal(true);
            }}
          >
            Add an Alignment
            {!formValues?.parentTeamKeyResultId && isSubmitted && <div className="error-feedback-message">Please add alignment</div>}
          </div>
        )}
      </div>

      <div>
        <div className="progress-track">Progress Tracking</div>
        <AvRadioGroup inline className="mb-0" data-testid="progressTrack" name="progressType" label="" onChange={onAddValue} value={formValues?.progressType}>
          <AvRadio label="Percentage (%)" value="PERCENTAGE" className="mb-0" />
          <AvRadio label="Key Performance Index (KPI)" value="KPI" className="mb-0" />
        </AvRadioGroup>
        {!formValues?.progressType && isSubmitted && <div className="error-feedback-message-form">Please choose progress type</div>}
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="start">Progress</label>
          <AvField autoComplete="off" type="number" name="progress" className="form-field" placeholder="Enter your start" onChange={onAddValue} value={formValues?.progress} />
          {!formValues?.progress && isSubmitted && <div className="error-feedback-message-form">Please choose progress type</div>}
        </div>
        <div className="col-6">
          <label htmlFor="Goal">Target Goal</label>
          <AvField autoComplete="off" type="number" className="form-field" name="targetGoal" placeholder="Enter your goal" onChange={onAddValue} value={formValues?.targetGoal} />
          {!formValues?.targetGoal && isSubmitted && <div className="error-feedback-message-form">Please choose progress type</div>}
        </div>
      </div>
      <div className="row btn-goal-content">
        {formValues?.edit ? (
          <>
            <div className="col-6">
              <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={() => setModalShow(false)}>
                Close
              </Button>
            </div>
            <div className="col-6">
              <Button
                size="sm"
                loading={isLoadingUpdate}
                color="blue"
                className="btn-goal"
                handleClick={() => {
                  form.submit();
                  setIsSubmitted(true);
                }}
              >
                Save Change
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="col-6">
              <Button
                size="sm"
                className="btn-goal"
                outline={true}
                color="blue"
                disabled={!formValues?.description}
                loading={isSubmittedDraft && isLoading}
                handleClick={() => {
                  form.submit("draft");
                }}
              >
                Save as Draft
              </Button>
            </div>
            <div className="col-6">
              <Button
                size="sm"
                loading={isSubmitted && isLoading}
                color="blue"
                disabled={!isFormValid}
                className="btn-goal"
                handleClick={() => {
                  form.submit();
                  setIsSubmitted(true);
                }}
              >
                Publish
              </Button>
            </div>
          </>
        )}
      </div>
    </AvForm>
  );
};

export default GoalForm;
