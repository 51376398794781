import React from "react";
import Shimmer from "react-shimmer-effect";

import TableLoader from "./tableLoader";
import DashboardLoader from "./dashboardLoader/dashboardLoader";
import DetailLoader from "./detailLoader/detailLoader";
import { ShimmerTableRowLoader } from "../../shared";
import "./loader.scss";

export default function Loader() {
  return <div>Retriving Authentication State.....</div>;
}

export const LoadingTable = () => {
  return (
    <div className="table-loader-container">
      <TableLoader />
    </div>
  );
};

export const LoadingTableContent = ({ colSpan, rows, isChild, isGrandChild }) => {
  return (
    <ShimmerTableRowLoader
      rows={rows || 10}
      columnWidths={new Array(parseInt(colSpan)).fill(100 / colSpan)}
      isChild={isChild}
      isGrandChild={isGrandChild}
      // columnWidths={new Array(parseInt(colSpan)).fill(0).map(val => "100%")}
    />
  );
};

export const LoadingDashboard = ({ coLSpan }) => {
  return (
    <div className="table-loader-container">
      <DashboardLoader />
    </div>
  );
};

export const LoadingDetails = ({ coLSpan }) => {
  return (
    <div className="table-loader-container detail-loader">
      <DetailLoader />
    </div>
  );
};

export const LineReportLoadingDetails = ({ coLSpan }) => {
  return (
    <div className="table-loader-container">
      <DetailLoader height="inherit" width="100%" />
    </div>
  );
};

export const LoadingDropdownItems = ({ name }) => {
  return (
    <span className="loading-dropdown">
      Loading {name} <i className="fa fa-spin fa-spinner"></i>
    </span>
  );
};

export const LineLoader = ({ lines }) => {
  const lineNum = [];
  for (let i = 1; i <= (lines || 1); i++) {
    lineNum.push(i);
  }
  return (
    <>
      {lineNum.map((n) => (
        <Shimmer key={n}>
          <div className="shimmer-line" data-testid="line-loader" />
        </Shimmer>
      ))}
    </>
  );
};
