import React from "react";
import SideModal from "../sideModal/sideModal";
import KeyResultDetails from "./keyResultsData/keyResultDetails";
import "./viewKeyResults.scss";

const ViewKeyResultsModal = ({ modalShow, setModalShow, setModalCheckinShow, details, setModalEditShow, setModalDeleteShow, checkin, allowDelete, useJobTitle, setDataToEdit }) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="&nbsp;" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <KeyResultDetails
                allowDelete={allowDelete}
                details={details}
                setModalShow={setModalShow}
                setModalCheckinShow={setModalCheckinShow}
                setModalEditShow={setModalEditShow}
                setModalDeleteShow={setModalDeleteShow}
                checkin={checkin}
                useJobTitle={useJobTitle}
                setDataToEdit={setDataToEdit}
              />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default ViewKeyResultsModal;
