import React, { useEffect, useState } from "react";
import _debounce from "lodash.debounce";
import ObjectiveEditor from "./objectiveEditor/objectiveEditor";
import AlignedObjectives from "./alignedObjectives/alignedObjectives";
import AllObjectives from "./allObjectives/allObjectives";
import getPercentColor from "../../../utils/progress-color";
import { LoadCycleOptions, LoadDraftedObjectives, LoadOverviewObjectives, LoadPublishedObjectives, SaveObjective, LoadAlignedTeamsOptions } from "../../../redux/actions/company";

import "./objectives.scss";

import "./objectives.scss";
import { useDispatch, useSelector } from "react-redux";
import { LoadAlignedTeamsObjectives, LoadObjectiveSummary } from "../../../redux/actions/company/objectiveActions";
import { errorMessage, successMessage } from "../../../redux/actions/notification/notification";
import { userDetails } from "../../../apiServices/user.service";

const Objectives = () => {
  const dispatch = useDispatch();
  const {
    publishedObjectives,
    isLoadingPublished,
    progressOverviewObjectives,
    isLoadingOverview,
    isLoadingCycleOptions,
    cycleOptions,
    draftedObjectives,
    isLoadingDrafted,
    savingObjective,
    saveObjectivePayload,
    isLoadingAlignedTeamsObjectives,
    alignedTeamsObjectives,
    alignedTeamsOptions,
    isLoadingAlignedTeamsOptions,
    keyResultContributions,
    objectiveKeyResults,
    isLoadingObjectiveSummary,
    objectiveSummary,
    isLoadingKeyResultContributions,
    isLoadingObjectiveKeyResults,
  } = useSelector(({ companyObjectives }) => companyObjectives);
  const [companyName] = useState(userDetails()?.companyName);
  const [showModal, setShowModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [resumeDraft, setResumeDraft] = useState(null);
  const [objectivesSearchTerm, setObjectivesSearchTerm] = useState("");

  useEffect(() => {
    dispatch(LoadOverviewObjectives());
    dispatch(LoadCycleOptions());
    dispatch(LoadPublishedObjectives({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!savingObjective && saveObjectivePayload?.success) {
      setShowModal(false);
      dispatch(LoadPublishedObjectives({ cycleId: selectedCycle }));
      dispatch(successMessage(saveObjectivePayload?.message));
    } else {
      dispatch(errorMessage(saveObjectivePayload?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveObjectivePayload, savingObjective]);

  useEffect(() => {
    if (selectedCycle) {
      dispatch(LoadPublishedObjectives({ cycleId: selectedCycle }));
    } else {
      dispatch(LoadPublishedObjectives({}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCycle]);

  useEffect(() => {
    dispatch(LoadPublishedObjectives({ searchTerm: objectivesSearchTerm }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectivesSearchTerm]);

  useEffect(() => {
    if (!showModal) {
      setResumeDraft(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleResumeClick = evt => {
    setResumeDraft({
      description: evt.objectives,
      reviewCycleId: evt.reviewCycleId,
      dueDate: evt.dueDate,
      isPublished: evt.isPublished,
      owner: evt.organisation.organisationName,
    });
  };

  const handleAddObjective = () => {
    dispatch(LoadDraftedObjectives());
    if (!showModal) {
      setShowModal(true);
    }
  };

  const handleShowDetails = data => {
    if (!viewDetails) {
      setSelectedObjective(data);
      dispatch(LoadAlignedTeamsObjectives({ objId: data.id }));
      dispatch(LoadAlignedTeamsOptions(data.id));
      dispatch(LoadObjectiveSummary({ objId: data.id }));
      setViewDetails(true);
    }
  };

  const handleBackClick = () => {
    if (viewDetails) {
      setViewDetails(false);
      setSelectedObjective(null);
    }
  };

  const handleCycleSelect = evt => {
    setSelectedCycle(evt?.value);
  };

  const handleSave = data => {
    const { description, dueDate, isPublished, reviewCycleId } = data;

    dispatch(SaveObjective({ description, dueDate, isPublished, reviewCycleId }));
  };

  const handleSearchObjectives = _debounce((value, ctx, input, cb) => {
    setObjectivesSearchTerm(value?.target?.value);
  }, 1000);

  return (
    <div id="company-objectives-container">
      {viewDetails ? (
        <AlignedObjectives
          isLoadingAlignedTeamsObjectives={isLoadingAlignedTeamsObjectives}
          alignedTeamsObjectives={alignedTeamsObjectives}
          objective={selectedObjective}
          onBackClick={handleBackClick}
          alignedTeamsOptions={alignedTeamsOptions}
          isLoadingAlignedTeamsOptions={isLoadingAlignedTeamsOptions}
          getPercentColor={getPercentColor}
          keyResultContributions={keyResultContributions}
          objectiveKeyResults={objectiveKeyResults}
          isLoadingObjectiveSummary={isLoadingObjectiveSummary}
          objectiveSummary={objectiveSummary}
          isLoadingKeyResultContributions={isLoadingKeyResultContributions}
          isLoadingObjectiveKeyResults={isLoadingObjectiveKeyResults}
        />
      ) : (
        <AllObjectives
          onAddObjective={handleAddObjective}
          getPercentColor={getPercentColor}
          onShowDetails={handleShowDetails}
          publishedObjectives={publishedObjectives}
          isLoadingPublished={isLoadingPublished}
          progressOverviewObjectives={progressOverviewObjectives}
          isLoadingOverview={isLoadingOverview}
          onCycleSelected={handleCycleSelect}
          selectedCycle={selectedCycle}
          isLoadingCycleOptions={isLoadingCycleOptions}
          cycleOptions={cycleOptions}
          onSearch={handleSearchObjectives}
          searchTerm={objectivesSearchTerm}
        />
      )}

      <ObjectiveEditor
        cycleOptions={cycleOptions}
        isLoadingDraftedObjectives={isLoadingDrafted}
        objectiveDrafts={draftedObjectives.items}
        onResume={handleResumeClick}
        modalShow={showModal}
        setModalShow={setShowModal}
        onSave={handleSave}
        resumeDraft={resumeDraft}
        saving={savingObjective}
        organisation={companyName}
      />
    </div>
  );
};

export default Objectives;
