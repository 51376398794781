import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../shared/button/Button";
import DocumentIcon from "../../../assets/images/documents.svg";
import { LoadingTableContent } from "../../shared/loader/Loader";
import CycleModal from "./cycle/cycleModal";
import { errorMessage } from "../../../redux/actions/notification/notification";
import { getReview, deleteReview } from "../../../redux/actions/settingsActions/userSettingsActions";
import "./timePeriod.scss";
import TimePeriodList from "./listTimePeriod";
import DeleteModal from "../../shared/deleteModal/deleteModal";

const ReviewCycle = ({ setTimeType }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(null);
  const [seeAll, setSeeAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);

  const handleDelete = () => {
    dispatch(deleteReview(id?.orgId, id?.cycleId));
  };
  const addData = (val, type) => {
    setData({ val, type });
    setShowModal(true);
  };
  const { payloadReview, loadingReview, errorReview, payloadListReview, loadingListReview, errorListReview, payloadDelete, loadingDelete, errorDelete } = useSelector(({ userSettings }) => {
    return {
      payloadReview: userSettings?.postReviewPayload,
      loadingReview: userSettings?.postReviewLoading,
      errorReview: userSettings?.postReviewError,
      payloadListReview: userSettings?.getReviewPayload,
      loadingListReview: userSettings?.getReviewLoading,
      errorListReview: userSettings?.getReviewError,

      payloadDelete: userSettings?.deleteReviewPayload,
      loadingDelete: userSettings?.deleteReviewLoading,
      errorDelete: userSettings?.deleteReviewError,
    };
  });

  useEffect(() => {
    dispatch(getReview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingReview && payloadReview?.data) {
      setShowModal(false);
      dispatch(getReview());
    } else if (errorReview?.data?.message && !loadingReview) {
      dispatch(errorMessage(errorReview?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingReview]);

  useEffect(() => {
    if (errorListReview?.data?.message && !loadingListReview) {
      dispatch(errorMessage(errorListReview?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingListReview]);

  useEffect(() => {
    if (!loadingDelete && payloadDelete?.data) {
      setModalDeleteShow(false);
      dispatch(getReview());
    } else if (errorDelete?.data?.message && !loadingDelete) {
      dispatch(errorMessage(errorDelete?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDelete]);

  return (
    <div className="time-period-container">
      {!seeAll ? (
        <>
          <div className="row">
            <div className="col">
              <div className="review-cycle">Performance Review Cycle</div>
              <div className="review-desc">Set up cycles to map OKRs across the your organisation. Setting cycles in advance starts the next automatically once the current one elapses.</div>
              <Button size="sm" color="blue" outline={true} handleClick={() => addData(null, "review")}>
                Add Cycle
              </Button>
              <div className="row">
                <div className="col">
                  <div className="title-content">
                    <span className="subtitle">Upcoming Review Cycles</span>
                    <span
                      className="see-all"
                      onClick={() => {
                        setSeeAll(true);
                        setType("review");
                        setList(payloadListReview?.data?.data?.items);
                        setTimeType("review");
                      }}
                    >
                      See all upcoming cycles
                    </span>
                  </div>
                  <div className="table-container">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Cycle Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingListReview && <LoadingTableContent colSpan={4} />}
                        {payloadListReview?.data?.data?.items?.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{val?.description}</td>
                              <td>{new Date(val?.reviewCycleStartDate).toLocaleDateString("en-US")}</td>
                              <td>{new Date(val?.reviewCycleDueDate).toLocaleDateString("en-US")}</td>
                              <td>
                                <span className="edit" onClick={() => addData(val, "review")}>
                                  Edit
                                </span>
                                <span
                                  className="delete"
                                  onClick={() => {
                                    setId({ orgId: val?.organisationId, cycleId: val?.cycleId });
                                    setModalDeleteShow(true);
                                    setType("review");
                                  }}
                                >
                                  Delete
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {!loadingListReview && payloadListReview?.data?.data?.items?.length === 0 && (
                      <div className="empty-content">
                        <img src={DocumentIcon} alt="empty goals" />
                        <div className="no-goals">You have not added any Cycle</div>
                        <div className="add-goal">Click on the “Add Cycle button to get started</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <TimePeriodList
          loadingList={loadingListReview}
          list={list}
          setShowModal={setShowModal}
          setSeeAll={setSeeAll}
          type={type}
          addData={addData}
          setId={setId}
          setModalDeleteShow={setModalDeleteShow}
          setTimeType={setTimeType}
        />
      )}
      <CycleModal openModal={showModal} closeModal={() => setShowModal(false)} loading={loadingReview} data={data} />
      <DeleteModal modalShow={modalDeleteShow} setModalShow={setModalDeleteShow} handleDeleteObjectives={() => handleDelete()} idForAction="" title="Review" loading={loadingDelete} />
    </div>
  );
};

export default ReviewCycle;
