import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NavLinkWrapper from "./navLink/navLink";
import { Urls } from "../../../utils/urls";
import sidebarIcons from "./sidebarIcons";
import Steps from "../../auth/steps/steps";
import "./sideBar.scss";

const Sidebar = ({ collapse, setOnSideBarHover }) => {
  const history = useHistory();
  const [menu, setMenu] = useState(history.location.pathname.substr(1));

  const pathname = history.location.pathname.substr(1).split("/");

  const { stepStatus } = useSelector(({ profile }) => {
    return { stepStatus: profile?.stepStatus };
  });

  useEffect(() => {
    setMenu(pathname[0]);
  }, [pathname]);

  return (
    <nav className="sidebar-container">
      <div className="nav-bar">
        <ul>
          <NavLinkWrapper item="Home" to={Urls.Home} icon={sidebarIcons.dashboard(menu)} collapse={collapse} />
          <NavLinkWrapper item="Teams" to={Urls.Teams} icon={sidebarIcons.teams(menu)} collapse={collapse} />
          <NavLinkWrapper item="Company" icon={sidebarIcons.company(menu)} to={Urls.Company} collapse={collapse} />
          <hr className="separator" />
          <NavLinkWrapper item="Admin Settings" icon={sidebarIcons.settings(menu)} to={Urls.Settings} collapse={collapse} />
        </ul>
      </div>
      {stepStatus && <Steps />}
    </nav>
  );
};

export default Sidebar;
