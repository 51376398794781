import React from "react";
import ActivityDetails from "../../../shared/userActivityDetails/userActivityDetails";

// import "./details.scss";

const UpdatesDetails = ({ userId }) => {
  return (
    <div className="goal-view-content">
      <ActivityDetails id={userId} typeOfId="userId" />
    </div>
  );
};

export default UpdatesDetails;
