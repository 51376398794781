import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import ObjectivesDetails from "./form/objectivesDetails";
import ObjectivesDetailsTwo from "./form/objectivesDetailsTwo";
import KeyResultDetails from "../../../shared/viewKeyResultsSideModal/keyResultsData/keyResultDetails";

// import "./editGoal.scss";

const ViewObjectives = ({
  details,
  alignedKeyResultsData,
  levelTwoLoading,
  modalShow,
  setModalShow,
  getKeyresultData,
  getContributionsData,
  keyresultData,
  contributionsData,
  contributionsLoading,
  setIndividualKeyResultData,
  individualKeyResultData,
  modalLevel,
  setModalDeleteShow,
  setModalEditShow,
  setKeyResultModalEditShow,
  setDataToEdit,
  handleCheckinModal,
  handleSpecificLevelDelete,
}) => {
  const [modalViewOneShow, setModalViewOneShow] = React.useState(true);

  const navigateToViewOne = () => {
    setModalViewOneShow(true);
  };

  const navigateToViewTwo = () => {
    setModalViewOneShow(false);
  };

  return (
    <div>
      <SideModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        onClick={() => {
          setModalViewOneShow(true);
          setModalShow(false);
        }}
        title={
          modalViewOneShow ? (
            "View Objective"
          ) : (
            <span style={{ display: "flex", fontSize: "16px" }} data-testid="title-page-icon" onClick={() => navigateToViewOne(true)} className="mr-2 goback">
              <i className="ri-arrow-left-line pr-" style={{ paddingRight: "11px" }}></i> Back
            </span>
          )
        }
        formOnly={true}
      >
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              {modalViewOneShow && modalLevel === 1 && (
                <ObjectivesDetails
                  details={details}
                  setModalShow={setModalShow}
                  alignedKeyResultsData={alignedKeyResultsData}
                  levelTwoLoading={levelTwoLoading}
                  setIndividualKeyResultData={setIndividualKeyResultData}
                  navigateToViewTwo={navigateToViewTwo}
                  getContributionsData={getContributionsData}
                  getKeyresultData={getKeyresultData}
                  handleSpecificLevelDelete={handleSpecificLevelDelete}
                  setModalDeleteShow={setModalDeleteShow}
                  setModalEditShow={setModalEditShow}
                  setDataToEdit={setDataToEdit}
                />
              )}
              {!modalViewOneShow && modalLevel === 1 && (
                <ObjectivesDetailsTwo
                  setModalShow={setModalShow}
                  details={individualKeyResultData}
                  contributionsData={contributionsData}
                  contributionsLoading={contributionsLoading}
                  navigateToViewOne={navigateToViewOne}
                  handleSpecificLevelDelete={handleSpecificLevelDelete}
                  setModalDeleteShow={setModalDeleteShow}
                  setKeyResultModalEditShow={setKeyResultModalEditShow}
                  setDataToEdit={setDataToEdit}
                  handleCheckinModal={handleCheckinModal}
                />
              )}

              {modalLevel === 2 && (
                <ObjectivesDetailsTwo
                  details={details}
                  setModalShow={setModalShow}
                  contributionsData={contributionsData}
                  contributionsLoading={contributionsLoading}
                  keyresultData={keyresultData}
                  modalLevel={modalLevel}
                  handleSpecificLevelDelete={handleSpecificLevelDelete}
                  setModalDeleteShow={setModalDeleteShow}
                  setKeyResultModalEditShow={setKeyResultModalEditShow}
                  setDataToEdit={setDataToEdit}
                  handleCheckinModal={handleCheckinModal}
                />
              )}

              {modalLevel === 3 && (
                <KeyResultDetails
                  details={details}
                  setModalShow={setModalShow}
                  allowDelete
                  handleSpecificLevelDelete={handleSpecificLevelDelete}
                  setModalDeleteShow={setModalDeleteShow}
                  setKeyResultModalEditShow={setKeyResultModalEditShow}
                  setDataToEdit={setDataToEdit}
                />
              )}
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default ViewObjectives;
