import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Button from "../../shared/button/Button";
import ClaritLogo from "../../../assets/images/clarit-logo.svg";
import { resetPassword } from "../../../redux/actions/onboarding/onboarding";
import "../login/login.scss";
import { Urls } from "../../../utils/urls";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { payload, loading } = useSelector(({ onboarding }) => {
    return { error: onboarding?.resetPasswordError, payload: onboarding?.resetPasswordPayload, loading: onboarding?.resetPasswordLoading };
  });

  console.log({ payload, loading });

  const submit = (event, value) => {
    console.log({ value });
    dispatch(resetPassword(value));
    setShowSuccessMessage(true);
  };
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  return (
    <div className="login-form-container">
      <div className="logo">
        <img src={ClaritLogo} alt="logo" />
      </div>

      <div className="login-content">
        {showSuccessMessage ? (
          <div className="onsuccess-password-reset">
            <div className="square"></div>
            <div className="check-your-email">Check your mail</div>
            <div className="description">We have sent password-reset innstructions to your email.</div>
          </div>
        ) : (
          <>
            <div className="create-account">Reset Password</div>
            <div className="create-account-subtitle">Enter the email associated with your account and we’ll send you a password-reset email.</div>

            <AvForm data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
              <div className="row layout-style">
                <div className="col-12">
                  <label htmlFor="email">Email Address</label>
                  <AvField
                    autoComplete="off"
                    type="text"
                    name="email"
                    className="form-field"
                    placeholder="Enter your email address"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter your email address",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row layout-style">
                <div className="col-12">
                  <Button size="sm" className="btn-goal" color="blue" loading={loading} disabled={!validForm} handleClick={() => form.submit()}>
                    Reset Password
                  </Button>
                </div>
              </div>
              <div className="row layout-style">
                <div className="col go-back " onClick={() => history.push(Urls.Login)}>
                  Go back
                </div>
              </div>
            </AvForm>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
