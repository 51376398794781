import React from "react";
import emptyFolder from "../../../assets/images/folder.svg";
import "./emptyState.scss";

const EmptyState = props => {
  const { infoText, instruction } = props;
  return (
    <div className="empty-state-container d-flex flex-column align-items-center justify-content-center">
      <div className="icon">
        <img src={emptyFolder} alt="Empty Folder"></img>
      </div>
      <div className="info">
        {infoText && <p>{infoText}</p>}
        {instruction && <p>{instruction}</p>}
      </div>
    </div>
  );
};

export default EmptyState;
