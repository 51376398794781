import { teamsActionTypes } from "../../actions/company";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  isLoadingTeams: false,
  allTeams: [],
  allTeamsError: null,

  teamsObjectives: { items: [] },
  isLoadingTeamsObjectives: false,
  teamsObjectivesError: null,

  objectiveKeyResults: { items: [] },
  isLoadingObjectiveKeyResults: false,
  objectiveKeyResultsError: null,

  overview: null,
  isLoadingOverview: false,
  overviewError: null,

  isLoadingCycleOptions: false,
  cycleOptions: { items: [] },
  cycleOptionsError: null,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(teamsActionTypes.GET_ALL_TEAMS):
      return {
        ...state,
        isLoadingTeams: true,
      };
    case SUCCESS(teamsActionTypes.GET_ALL_TEAMS):
      return {
        ...state,
        isLoadingTeams: false,
        allTeams: actions.payload.data.data,
      };

    case FAILURE(teamsActionTypes.GET_ALL_TEAMS):
      return {
        ...state,
        allTeamsError: actions.payload,
        isLoadingTeams: false,
      };

    case REQUEST(teamsActionTypes.GET_TEAM_OBJECTIVES_BY_ID):
      return {
        ...state,
        isLoadingTeamsObjectives: true,
      };
    case SUCCESS(teamsActionTypes.GET_TEAM_OBJECTIVES_BY_ID):
      return {
        ...state,
        isLoadingTeamsObjectives: false,
        teamsObjectives: actions.payload.data.data,
      };
    case FAILURE(teamsActionTypes.GET_TEAM_OBJECTIVES_BY_ID):
      return {
        ...state,
        teamsObjectivesError: actions.payload,
        isLoadingTeamsObjectives: false,
      };

    case REQUEST(teamsActionTypes.GET_TEAM_OBJECTIVE_KR):
      return {
        ...state,
        isLoadingObjectiveKeyResults: true,
      };
    case SUCCESS(teamsActionTypes.GET_TEAM_OBJECTIVE_KR):
      return {
        ...state,
        isLoadingObjectiveKeyResults: false,
        objectiveKeyResults: actions.payload.data.data,
      };

    case FAILURE(teamsActionTypes.GET_TEAM_OBJECTIVE_KR):
      return {
        ...state,
        objectiveKeyResultsError: actions.payload,
        isLoadingObjectiveKeyResults: false,
      };

    case REQUEST(teamsActionTypes.GET_TEAM_OVERVIEW):
      return {
        ...state,
        isLoadingOverview: true,
      };
    case SUCCESS(teamsActionTypes.GET_TEAM_OVERVIEW):
      return {
        ...state,
        isLoadingOverview: false,
        overview: actions.payload.data.data,
      };

    case FAILURE(teamsActionTypes.GET_TEAM_OVERVIEW):
      return {
        ...state,
        overviewError: actions.payload,
        isLoadingOverview: false,
      };

    case REQUEST(teamsActionTypes.GET_REVIEW_CYCLE_OPTIONS):
      return {
        ...state,
        isLoadingCycleOptions: true,
      };
    case SUCCESS(teamsActionTypes.GET_REVIEW_CYCLE_OPTIONS):
      return {
        ...state,
        isLoadingCycleOptions: false,
        cycleOptions: actions.payload.data.data,
      };

    case FAILURE(teamsActionTypes.GET_REVIEW_CYCLE_OPTIONS):
      return {
        ...state,
        cycleOptionsError: actions.payload,
        isLoadingCycleOptions: false,
      };

    default:
      return { ...state };
  }
};
