import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import { addCommaToArrayOfStrings } from "../../../../utils/helpers";
import avatar from ".././../../../assets/images/avatar.svg";
import Button from "../../../shared/button/Button";
import SearchBar from "../../../shared/searchBar/searchBar";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import TeamEditor from "../../teamEditor/teamEditor";
import CustomTable from "../../../shared/table/table";
import AlertModal from "../../../shared/alertModal/alertModal";
import TableActionDropdown from "../../../shared/tableActionDropdown/tableActionDropdown";
import { deleteTeam, getTeams, getTeamsOptions, saveTeam, updateTeam } from "../../../../redux/actions/settingsActions/teamSettingsActions";

import "./viewTeams.scss";
import { errorMessage, successMessage } from "../../../../redux/actions/notification/notification";

const ViewTeams = props => {
  const dispatch = useDispatch();
  const { navigateToViewTeamMembers } = props;
  const [showEditorModal, setShowEditorModal] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [editorData, setEditorData] = useState(undefined);
  const [deleteTeamId, setDeleteTeamId] = useState(undefined);
  const [actionType, setActionType] = useState(undefined);
  const [tableData, setTableData] = useState([]);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [teamsFilterParams, setTeamsFilterParams] = useState(1);
  const select = useSelector(state => {
    return {
      allTeams: state.teamSettings && state.teamSettings?.allTeams?.data?.data,
      isLoading: state.teamSettings && state.teamSettings.isLoading,
      deletingTeam: state.teamSettings && state.teamSettings.deletingTeam,
      deleteTeamPayload: state.teamSettings && state.teamSettings.deleteTeamPayload,
      teamsOptions: state.teamSettings && state.teamSettings.teamsOptions,
      isLoadingTeamsOptions: state.teamSettings && state.teamSettings.isLoadingTeamsOptions,
      savingTeam: state.teamSettings && state.teamSettings.savingTeam,
      saveTeamPayload: state.teamSettings && state.teamSettings.saveTeamPayload,

      updatingTeam: state.teamSettings && state.teamSettings.updatingTeam,
      updateTeamPayload: state.teamSettings && state.teamSettings.updateTeamPayload,

      uploadingBulkTeams: state.teamSettings && state.teamSettings.uploadingBulkTeams,
      uploadBulkTeamsPayload: state.teamSettings && state.teamSettings.uploadBulkTeamsPayload,
    };
  });

  useEffect(() => {
    dispatch(getTeamsOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTeams(pageNo, pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize]);

  useEffect(() => {
    setTableData(select.allTeams?.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.allTeams && select.allTeams?.items.length]);

  useEffect(() => {
    dispatch(getTeams(pageNo, pageSize, "", teamsFilterParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsFilterParams]);

  useEffect(() => {
    if (select.deleteTeamPayload && !select.deleteTeam) {
      if (select.deleteTeamPayload.success) {
        setShowDeleteAlert(false);
        dispatch(successMessage(select.deleteTeamPayload.message && select.deleteTeamPayload.message.toLowerCase() !== "ok" ? select.deleteTeamPayload.message : "Team deleted successfully"));
        dispatch(getTeams(pageNo, pageSize));
      } else {
        dispatch(errorMessage(select.deleteTeamPayload.message));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.deleteTeamPayload, select.deleteTeam]);

  useEffect(() => {
    if (select.saveTeamPayload && !select.savingTeam) {
      if (select.saveTeamPayload.success) {
        setShowEditorModal(false);
        dispatch(successMessage(select.saveTeamPayload.message || "New team has been added successfully"));
        dispatch(getTeams(pageNo, pageSize));
      } else {
        dispatch(errorMessage(select.saveTeamPayload.message || "Somthing went wrong. Unable to add new team"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.savingTeam, select.saveTeamPayload]);

  useEffect(() => {
    if (select.updateTeamPayload && !select.updatingTeam) {
      if (select.updateTeamPayload.success) {
        setShowEditorModal(false);
        dispatch(successMessage(select.updateTeamPayload.message || "Team has been updated successfully"));
        dispatch(getTeams(pageNo, pageSize));
      } else {
        dispatch(errorMessage(select.updateTeamPayload.message || "Somthing went wrong. Unable to update team"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.updatingTeam, select.updateTeamPayload]);

  const tableHeads = [
    { id: 1, name: "Team Name" },
    { id: 2, name: "Parent Team" },
    { id: 3, name: "Team Lead" },
    { id: 4, name: "Members" },
    { id: 5, name: "Action" },
  ];

  const arrangeTableData = tableData => {
    if (tableData) {
      return (
        tableData &&
        tableData.map((value, index) => {
          return (
            <tr key={index + 1}>
              <td className="bordered">{value?.teamName}</td>
              <td className="bordered">{value?.parentTeam ? value?.parentTeam : "-"}</td>
              <td className="bordered">
                <span>
                  <img className="mr-3 table-avatar" src={value?.teamLead?.img ? value?.teamLead?.img : avatar} alt="team lead avatar" /> {value?.teamOwner}
                </span>
              </td>
              <td className="bordered">{value?.members?.length ? addCommaToArrayOfStrings(value.members) : "-"}</td>
              <td className="bordered">
                <div className="action">
                  <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} />
                </div>
              </td>
            </tr>
          );
        })
      );
    }
  };

  const handleEdit = (e, data) => {
    e.preventDefault();
    setShowEditorModal(true);
    setActionType("edit");
    setEditorData(data);
    console.log("Edit clicked", data);
    console.log(select.teamsOptions);
  };

  const handleView = (e, data) => {
    e.preventDefault();
    navigateToViewTeamMembers(data);
  };

  const handleDelete = (e, data) => {
    e.preventDefault();
    setShowDeleteAlert(true);
    setDeleteTeamId(data.teamId);
  };

  const handleSubmit = e => {
    if (editorData) {
      dispatch(updateTeam({ data: { ...e, parentTeamId: e.parentTeamId.toLowerCase() }, teamId: editorData.teamId.toLowerCase() }));
    } else {
      dispatch(saveTeam({ data: e }));
    }
  };

  // search table
  const handleSearch = _debounce((evt, ctx, input, cb) => {
    dispatch(getTeams(pageNo, pageSize, evt?.target?.value));
  }, 1000);

  const handleSelectRow = values => {
    console.log(values);
    if (values.length === 0) {
      setTeamsFilterParams("");
    }
  };

  const actions = [
    { id: 1, name: "Edit Team", method: handleEdit },
    { id: 2, name: "View Team Member", method: handleView },
    { id: 3, name: "Delete Team", method: handleDelete },
  ];

  useEffect(() => {
    if (!showEditorModal) {
      setEditorData(undefined);
      setActionType("create");
    } else if (showEditorModal && actionType === "edit") {
      setEditorData(editorData);
    }
    return () => {
      setEditorData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditorModal, actionType]);

  // const handleApplySelection = (items) => {
  //   console.log(items);
  // };

  // const onItemSelected = (item) => {
  //   console.log(item);
  // };

  const handleConfirmed = evt => {
    evt.preventDefault();
    dispatch(deleteTeam(deleteTeamId));
  };

  const handleTeamsFilter = evt => {
    let params = "";
    for (let i = 0; i < evt.length; i++) {
      params = params + `&teamId=${evt[i].value}`;
    }
    setTeamsFilterParams(params);
  };

  const handlePageClick = data => {
    console.log(data);
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  return (
    <div className="view-teams-container">
      <div className="row mb-3">
        <TeamEditor
          openModal={showEditorModal}
          closeModal={() => setShowEditorModal(false)}
          data={editorData}
          onSubmit={handleSubmit}
          teamsOptions={select.teamsOptions}
          isLoadingTeamsOptions={select.isLoadingTeamsOptions}
          savingTeam={select.savingTeam}
          updatingTeam={select.updatingTeam}
          uploadingBulkTeams={select.uploadingBulkTeams}
        />
        <AlertModal
          openModal={showDeleteAlert}
          closeModal={() => setShowDeleteAlert(false)}
          onConfirm={handleConfirmed}
          processing={select.deletingTeam}
          textContent="Please confirm that you want to delete this team from the Clarit platform."
          actionText="Delete"
          title="Delete Team"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="row mb-3">
                <div className="col-8" style={{ paddingRight: "0" }}>
                  <SearchBar placeholder="Search for a team" handleSearch={handleSearch} />
                </div>

                <div className="col-3" style={{ padding: "0" }}>
                  <PMSSelect
                    placeholder="Filter"
                    selectMultiple={true}
                    useSelectAll={true}
                    dataSource={select.teamsOptions}
                    processing={select.isLoading}
                    onActionButtonClick={handleTeamsFilter}
                    closeOnActionClick={true}
                    displayExpr="teamName"
                    valueExpr="teamId"
                    onChange={handleSelectRow}
                    menuWidth="220px"
                    selectAllText="All Teams"
                    useCount
                    value={[]}
                    clearable={false}
                  />
                </div>

                <div className="col-1" style={{ padding: "0" }}></div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 tableTopBtn-div mb-3">
              <Button size="sm" color="blue" handleClick={() => setShowEditorModal(true)} className="pull-right">
                Add Team
              </Button>
            </div>
          </div>

          <CustomTable
            arrangedTableData={arrangeTableData(select.allTeams?.items)}
            tableHeads={tableHeads}
            isLoading={select.isLoading}
            tableData={tableData}
            searchTableData={select.allTeams?.items}
            bordered
            currentPage={pageNo}
            perPage={pageSize}
            pageCount={Math.ceil(select.allTeams?.totalCount / pageSize)}
            totalItems={select.allTeams?.totalCount}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTeams;
