import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./lozenges.scss";

export const Lozenge = ({ className, content, variant, style, ...rest }) => {
  let baseClass = "sp-lozenge";

  let lozengeStyles = {
    ...style,
  };

  let classes = classNames(className, `${baseClass}`, variant && `${baseClass}--${variant}`);

  return (
    <span className={classes} {...rest} style={lozengeStyles}>
      {content}
    </span>
  );
};

Lozenge.defaultProps = {
  variant: "default",
};

Lozenge.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["default", "success", "failed", "pending"]),
  content: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
};

export default Lozenge;
