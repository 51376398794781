import React, { useState, useEffect } from "react";
import PMSSelect from "../../shared/selectInput/pmsSelect/pmsSelect";
import OneOnOnereport from "./oneOnOneReport/oneOnOneReport";
import CustomTimeLineModal from "./customTimeLineModal/customTimeLineModal";
import moment from "moment";
import OkrReport from "./okrReport/okrReport";
import { useDispatch, useSelector } from "react-redux";
import { getOneOnOneCycles, getOrganisationCycles } from "../../../redux/actions/cyclesActions/cyclesActions";
import { getOneOnOneInsights, getOneOnOneInsightsWithDate, getOkrInsights } from "../../../redux/actions/teamsActions/teamInsightsActions";

import "./insights.scss";

const Insights = ({ teamId }) => {
  const dispatch = useDispatch();
  const [selectedReport, setSelectedReport] = useState(1);
  const [showOneonOne, setShowOneonOne] = React.useState(true);
  const [customCycleList, setCustomCycleList] = useState([]);
  const [timeLineModal, setTimeLineModal] = useState(false);
  const [customTimeLineValue, setCustomTimeLineValue] = React.useState(false);
  const [duration, setDuration] = useState("");
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [selectedOkrCycle, setSelectedOkrCycle] = useState(null);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const select = useSelector((state) => {
    return {
      cycleList: state.organizationCycles && state.organizationCycles.oneOnOneCycleList?.data?.data?.items,
      okrCycleList: state.organizationCycles && state.organizationCycles.cycleList,
      oneOnOneCyclesLoading: state.organizationCycles && state.organizationCycles.oneOnOneCyclesLoading,
      oneOnOneLoading: state.teamInsights && state.teamInsights.oneOnOneLoading,
      okrLoading: state.teamInsights && state.teamInsights.okrLoading,
      oneOnOneInsights: state.teamInsights && state.teamInsights.teamOneOnOneInsights?.data?.data,
      okrInsights: state.teamInsights && state.teamInsights.teamOkrInsights?.data?.data,
    };
  });

  const { cycleList, okrCycleList, oneOnOneInsights, okrInsights, oneOnOneLoading, okrLoading } = select;

  useEffect(() => {
    dispatch(getOneOnOneCycles());
    dispatch(getOrganisationCycles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newCycleList = [{ id: "customDate", name: "Custom Timeline" }];
    newCycleList = newCycleList.concat(cycleList);
    setSelectedCycle(cycleList && cycleList[0].id);
    setCustomCycleList(newCycleList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleList]);

  useEffect(() => {
    if (okrCycleList?.length > 0) {
      setSelectedOkrCycle(okrCycleList && okrCycleList[0].cycleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okrCycleList]);

  useEffect(() => {
    if (showOneonOne && teamId && selectedCycle) {
      dispatch(getOneOnOneInsights(teamId, selectedCycle));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, selectedCycle, showOneonOne, pageNo, pageSize]);

  useEffect(() => {
    if (!showOneonOne && teamId && selectedOkrCycle) {
      dispatch(getOkrInsights(selectedOkrCycle, teamId, pageNo, pageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, selectedOkrCycle, showOneonOne, pageNo, pageSize]);

  const navigateToOneonOne = () => {
    setShowOneonOne(true);
  };

  const navigateToOkrReport = () => {
    setShowOneonOne(false);
  };

  const handlePageClick = (data) => {
    console.log(data);
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  const handleCustomSelect = (evt) => {
    if (evt.label === "Custom Timeline") {
      setTimeLineModal(true);
    } else {
      setCustomTimeLineValue(false);
      setSelectedCycle(evt.value);
    }
  };

  const handleSelectCycle = (evt) => {
    if (evt.label === "Custom Timeline") {
      setTimeLineModal(true);
    } else {
      setSelectedCycle(evt.value);
    }
  };

  const handleSelectOkrCycle = (evt) => {
    setSelectedOkrCycle(evt.value);
  };

  const handleSelectReport = (evt) => {
    console.log({ evt });
    if (evt.value === 1) {
      setShowOneonOne(true);
    } else {
      setShowOneonOne(false);
    }
    setSelectedReport(evt);
  };

  const handleFilter = (data, page) => {
    if (data[0].value === "logged" && page === "one_on_one_page") {
      dispatch(getOneOnOneInsights(teamId, selectedCycle, true));
      // dispatch(getOneOnOneInsights(teamId, selectedCycle, pageNo, pageSize, true));
    } else if (data[0].value === "unlogged" && page === "one_on_one_page") {
      // dispatch(getOneOnOneInsights(teamId, selectedCycle, pageNo, pageSize, false));
      dispatch(getOneOnOneInsights(teamId, selectedCycle, false));
    } else if (data[0].value === "logged" && page === "okr_page") {
      dispatch(getOkrInsights(selectedOkrCycle, teamId, pageNo, pageSize, true));
    } else if (data[0].value === "unlogged" && page === "okr_page") {
      dispatch(getOkrInsights(selectedOkrCycle, teamId, pageNo, pageSize, false));
    } else {
      return;
    }
  };

  const getAllInsights = () => {
    dispatch(getOneOnOneInsights(teamId, selectedCycle));
    // dispatch(getOneOnOneInsights(teamId, selectedCycle, pageNo, pageSize));
  };

  const setCustomTimeValue = (val) => {
    let startDate = moment(val.startDate, "YYYY-MM-DD").format("ll");
    let endDate = moment(val.endDate, "YYYY-MM-DD").format("ll");

    let duration = `${startDate} - ${endDate}`;
    console.log(duration);
    setDuration(duration);
    setCustomTimeLineValue(true);
    dispatch(getOneOnOneInsightsWithDate(teamId, val.startDate, val.endDate));
  };

  const reportList = [
    { id: 1, description: "1:1 Report" },
    { id: 2, description: "OKR Report" },
  ];

  return (
    <div>
      <CustomTimeLineModal openModal={timeLineModal} closeModal={() => setTimeLineModal(false)} setCustomTimeValue={setCustomTimeValue} submitValue={null} />
      <div className="row">
        <div className="col">
          <div className="row pb-3 sub-items">
            <div className="col-2">
              <div className="search-contain">
                <label for="react-select-2-input"></label>
                <PMSSelect
                  alt="report-dropdown"
                  dataSource={reportList}
                  closeOnActionClick={true}
                  displayExpr="description"
                  valueExpr="id"
                  onChange={handleSelectReport}
                  menuWidth="184px"
                  value={selectedReport}
                  // placeholder="sampleTest"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showOneonOne ? (
          <OneOnOnereport
            cycleList={cycleList}
            customCycleList={customCycleList}
            selectedCyle={selectedCycle}
            handleSelectCycle={handleSelectCycle}
            handleCustomSelect={handleCustomSelect}
            getAllInsights={getAllInsights}
            oneOnOneInsights={oneOnOneInsights}
            navigateToOkrReport={navigateToOkrReport}
            oneOnOneLoading={oneOnOneLoading}
            customTimeLineValue={customTimeLineValue}
            handleFilter={handleFilter}
            duration={duration}
          />
        ) : (
          <OkrReport
            cycleList={okrCycleList}
            handleSelectCycle={handleSelectOkrCycle}
            selectedCycle={selectedOkrCycle}
            getAllInsights={getAllInsights}
            okrInsights={okrInsights}
            navigateToOneonOne={navigateToOneonOne}
            okrLoading={okrLoading}
            handleFilter={handleFilter}
            pageNo={pageNo}
            pageSize={pageSize}
            handlePageClick={handlePageClick}
          />
        )}
      </div>
    </div>
  );
};

export default Insights;
