import { lineReportsType } from "../../actions/teamsActions/lineReportsActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  isLoading: true,
  lineReports: [],
  lineReportsMock: [
    {
      id: 7,
      firstName: "akin",
      lastName: "bayo",
      email: "akin@gmail.com",
      primaryTeam: "R&D",
      jobTitle: "Business analyst",
    },
    {
      id: 2,
      firstName: "john",
      lastName: "mbako",
      email: "akin@gmail.com",
      primaryTeam: "R&D",
      jobTitle: "Business analyst",
    },
    {
      id: 4,
      firstName: "cici",
      lastName: "winans",
      email: "akin@gmail.com",
      primaryTeam: "R&D",
      jobTitle: "Business analyst",
    },
  ],
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(lineReportsType.GET_LINE_REPORTS):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(lineReportsType.GET_LINE_REPORTS):
      return {
        ...state,
        isLoading: false,
        lineReports: actions.payload,
      };

    case FAILURE(lineReportsType.GET_LINE_REPORTS):
      return {
        ...state,
        lineReportsError: actions.payload,
        isLoading: true,
      };

    default:
      return { ...state };
  }
};
