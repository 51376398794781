import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../shared/button/Button";
import PMSSelect from "../../shared/selectInput/pmsSelect/pmsSelect";
import { postNotification, getNotification, putNotification } from "../../../redux/actions/settingsActions/userSettingsActions";
import { errorMessage, successMessage } from "../../../redux/actions/notification/notification";
import "./notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [frequency, setFrequency] = useState(null);

  const [disableNotification, setDisableNotification] = useState(true);

  const { list, listLoading, listError, payload, payloadError, payloadLoading } = useSelector(({ userSettings }) => {
    return {
      list: userSettings?.getNotificationPayload,
      listLoading: userSettings?.getNotificationLoading,
      listError: userSettings?.getNotificationError,
      payload: userSettings?.postNotificationPayload,
      payloadLoading: userSettings?.postNotificationLoading,
      payloadError: userSettings?.postNotificationError,
    };
  });

  const occurance = [
    { id: "Daily", name: "Daily", label: "Daily" },
    { id: "Weekly", name: "Weekly", label: "Weekly" },
    { id: "Every Two weeks", name: "Every Two weeks", label: "Every Two weeks" },
  ];

  const save = () => {
    if (list?.data?.data) {
      dispatch(putNotification({ startDate: new Date(date), frequency, id: list?.data?.data?.id }));
    } else {
      dispatch(postNotification({ startDate: new Date(date), frequency }));
    }
  };
  useEffect(() => {
    dispatch(getNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (list?.data?.data) {
      const dateValue = list?.data?.data?.startDate;
      setFrequency(list?.data?.data?.frequency);
      if (Object.prototype.toString.call(new Date(dateValue)) === "[object Date]") {
        setDate(
          `${new Date(dateValue).getFullYear()}-${(new Date(dateValue).getMonth() + 1).toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}-${new Date(dateValue)
            .getDate()
            .toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}`
        );
      }
    } else if (listError?.data?.message) {
      !listLoading && dispatch(errorMessage(listError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLoading]);

  useEffect(() => {
    if (payload?.data && !payloadLoading) {
      dispatch(successMessage("Notification setting saved successfully"));
      setDisableNotification(true);
    } else if (payloadError?.data?.message && !payloadLoading) {
      dispatch(errorMessage(payloadError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadLoading]);
  return (
    <div className="settings-notification-container">
      <div className="row">
        <div className="col">
          <div className="check-in">Check-in Reminders</div>
          <div className="review-desc">Set how frequently you want users in your organisation to be notified to review your OKRs.</div>
          <div className="row">
            <div className="col select-style">
              <label className="start-date">Start date</label>

              <input className="form-control" disabled={disableNotification} value={date} type="date" onChange={(e) => setDate(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="col-auto select-style">
              <div className="frequency">Every</div>
              <div className="from-select-items">
                <div className="from-select">
                  <PMSSelect
                    placeholder="Frequency"
                    selectMultiple={false}
                    value={
                      frequency
                        ? occurance?.find((option) => {
                            option["title"] = "edit";
                            return option?.id?.toLowerCase() === frequency?.toLowerCase();
                          })
                        : []
                    }
                    dataSource={occurance}
                    closeOnActionClick={true}
                    displayExpr="name"
                    valueExpr="id"
                    onChange={(e) => {
                      setFrequency(e.value);
                    }}
                    selectAllText="All Types"
                    controlHeight="48px"
                    disableControl={disableNotification}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="reminder">
            The next reminder would be on: <span className="next-reminder">Monday 8th Feb</span>
          </div>
          <div className="btns">
            {disableNotification ? (
              <Button size="sm" color="blue" outline={true} handleClick={() => setDisableNotification(!disableNotification)}>
                <span data-testid="edit-notification">Edit</span>
              </Button>
            ) : (
              <div className="edit-btn-content">
                <span className="cancel-btn" onClick={() => setDisableNotification(true)}>
                  Cancel
                </span>
                <Button size="sm" color="blue" loading={payloadLoading} disabled={payloadLoading} outline={true} handleClick={() => save()}>
                  Save Changes
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
