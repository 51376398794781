import { Get, Post, Put, Delete } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const UserSettingsActionTypes = {
  GET_USERS: "GET_USERS",
  USERS_DETAILS: "USERS_DETAILS",
  INVITE_SINGLE_TEAM_USERS: "INVITE_SINGLE_TEAM_USERS",
  GET_TEAMS_OPTIONS: "GET_TEAMS_OPTIONS",
  REVIEW_USERS_EMAILS: "REVIEW_USERS_EMAILS",
  UPLOAD_USERS_EMAILS: "UPLOAD_USERS_EMAILS",
  GET_USER_STATUS: "GET_USER_STATUS",
  GET_NOTIFICATION: "GET_NOTIFICATION",
  POST_NOTIFICATION: "POST_NOTIFICATION",
  GET_ONEONONE: "GET_ONEONONE",
  DELETE_ONEONONE: "DELETE_ONEONONE",
  POST_ONEONONE: "POST_ONEONONE",
  POST_REVIEW: "POST_REVIEW",
  GET_REVIEW: "GET_REVIEW",
  DELETE_REVIEW: "DELETE_REVIEW",
};

export const getUsers = createAsyncAction(UserSettingsActionTypes.GET_USERS, async (pageSize, pageNumber, searchTerm, statusParams) => {
  return await Get(
    `User/All${pageNumber ? "?pageNumber=" + pageNumber : ""}${pageSize ? "&pageSize=" + pageSize : ""}${searchTerm ? "&searchTearm=" + searchTerm : ""}${statusParams ? statusParams : ""}`
  );
});

export const getTeamsOptions = createAsyncAction(UserSettingsActionTypes.GET_TEAMS_OPTIONS, async () => {
  return await Get(`Team/GetAll`);
});

export const getUserStatus = createAsyncAction(UserSettingsActionTypes.GET_USER_STATUS, async () => {
  return await Get(`Util/dropdown/userStatus`);
});

export const inviteSingleTeamUsers = createAsyncAction(UserSettingsActionTypes.INVITE_SINGLE_TEAM_USERS, async (data) => {
  return await Post(`Account/onboard-single`, data);
});

export const reviewUsersEmails = createAsyncAction(UserSettingsActionTypes.REVIEW_USERS_EMAILS, async (data) => {
  return await Post(`Account/onboard-bulk/validate`, data);
});

export const uploadUsersEmails = createAsyncAction(UserSettingsActionTypes.UPLOAD_USERS_EMAILS, async (data) => {
  return await Post(`Account/onboard-bulk`, data);
});

export const getNotification = createAsyncAction(UserSettingsActionTypes.GET_NOTIFICATION, async () => {
  return await Get(`admin/notification/active`);
});

export const postNotification = createAsyncAction(UserSettingsActionTypes.POST_NOTIFICATION, async (data) => {
  return await Post(`admin/notification`, data);
});

export const putNotification = createAsyncAction(UserSettingsActionTypes.POST_NOTIFICATION, async (data) => {
  return await Put(`admin/notification`, data);
});

export const getOneOnOne = createAsyncAction(UserSettingsActionTypes.GET_ONEONONE, async (number = 1, size = 3) => {
  return await Get(`admin/oneononecycle?pageNumber=${number}&pageSize=${size}`);
});

export const postOnOnOne = createAsyncAction(UserSettingsActionTypes.POST_ONEONONE, async (data) => {
  return await Post(`admin/oneononecycle`, data);
});

export const putOnOnOne = createAsyncAction(UserSettingsActionTypes.POST_ONEONONE, async (data) => {
  return await Put(`admin/oneononecycle`, data);
});

export const deleteOneOnOne = createAsyncAction(UserSettingsActionTypes.DELETE_ONEONONE, async (id) => {
  return await Delete(`admin/oneononecycle/${id}`);
});

export const getReview = createAsyncAction(UserSettingsActionTypes.GET_REVIEW, async (number = 1, size = 10) => {
  return await Get(`reviewCycle/all-paged?pageNumber=${number}&pageSize=${size}`);
});

export const postReview = createAsyncAction(UserSettingsActionTypes.POST_REVIEW, async (data) => {
  return await Post(`reviewCycle`, data);
});

export const putReview = createAsyncAction(UserSettingsActionTypes.POST_REVIEW, async (data) => {
  return await Put(`reviewCycle`, data);
});

export const deleteReview = createAsyncAction(UserSettingsActionTypes.DELETE_REVIEW, async (orgId, cycleId) => {
  return await Delete(`ReviewCycle?cycleId=${cycleId}`);
});
