import React, { Fragment } from "react";
import { components } from "react-select";
import "./spMenu.scss";

const SpMenu = props => {
  const handleSelectAll = () => {
    if (props.selectProps.onSelectAll) {
      props.selectProps.onSelectAll();
      if (props.selectProps.allSelected) {
        props.selectProps.onChange([]);
      } else {
        props.selectProps.onChange(props.selectProps.options);
      }
    }
  };
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          {props.selectProps.isMulti && props.selectProps.useSelectAll && !props.selectProps.fetchingData && (
            <>
              <button className="sp-button sp-dropdown-list-item w-100 d-flex align-items-center" style={{ fontWeight: "normal", fontFamily: "Inter" }} onClick={handleSelectAll}>
                {props.selectProps.allSelected ? (
                  <i className="ri-checkbox-fill" style={{ fontSize: 18 }}></i>
                ) : props.selectProps.value && props.selectProps.value.length && props.selectProps.value < props.selectProps.options ? (
                  <i className="ri-checkbox-indeterminate-fill" style={{ fontSize: 18 }}></i>
                ) : (
                  <i className="ri-checkbox-blank-line" style={{ fontSize: 18 }}></i>
                )}
                &nbsp; {props.selectProps.selectAllText ? props.selectProps.selectAllText : `${props.selectProps.allSelected ? "Deselect" : "Select"} All`}
              </button>
              <hr className="m-0" />
            </>
          )}
          {props.selectProps.fetchingData ? <span className="fetching">Fetching data...</span> : <div>{props.children}</div>}
          {props.selectProps.isMulti && !props.selectProps.fetchingData && (
            <button
              className="sp-button sp-dropdown-action w-100 d-flex align-items-center justify-content-center"
              onClick={() => props.selectProps.onActionButtonClick(props.selectProps.value ? props.selectProps.value : [])}
            >
              {props.selectProps.actionButtonText}
            </button>
          )}
        </div>
      </components.Menu>
    </Fragment>
  );
};

export default SpMenu;
