import React from "react";
import PersonalIcon from "../../../../assets/images/personal-icon.svg";
import LocationIcon from "../../../../assets/images/location-white.svg";
import RoleIcon from "../../../../assets/images/role.svg";
import AvartaIcon from "../../../../assets/images/avatar.svg";
import Button from "../../button/Button";
import TableActionDropdown from "../../tableActionDropdown/tableActionDropdown";
import { ProgressBar } from "react-bootstrap";
import { getPercentColor } from "../../../../utils/helpers";

import "./details.scss";
import ActivityDetails from "../../userActivityDetails/userActivityDetails";
// setModalViewShow,  setShowCheckinModal
const KeyResultDetails = ({
  setModalShow,
  setKeyResultModalEditShow,
  setModalDeleteShow,
  setModalCheckinShow,
  checkin,
  details,
  useJobTitle,
  allowDelete,
  handleSpecificLevelDelete,
  setDataToEdit,
}) => {
  const { keyResultId, description, progress, team, parentObjective, owner, dueDate, dateSubmitted, firstName, lastName, lastUpdatedDate } = details || {};

  const handleEdit = () => {
    setModalShow(false);
    setKeyResultModalEditShow(true);
    setDataToEdit(details);
  };

  const handleDelete = () => {
    setModalShow(false);
    setModalDeleteShow(true);
    handleSpecificLevelDelete(keyResultId, 3);
  };

  const actions = [
    { id: 1, name: <span className="detail-action">Edit Key Result</span>, method: handleEdit },
    { id: 2, name: <span>Delete Key Result</span>, method: handleDelete },
  ];

  return (
    <div className="goal-view-content objectives-details">
      <div className="goal-title">{description}</div>
      <div className="personal">
        <img src={PersonalIcon} alt="personal icon" />
        Personal
      </div>
      <div className={!checkin ? "approved-full flex-and-space-between" : "approved"}>
        <ProgressBar now={progress} variant={getPercentColor(progress)} />

        <span className="d-flex" style={{ alignItems: "center" }}>
          {!checkin ? (
            <>
              <span className="percent-count">{progress}</span>
              {allowDelete && <TableActionDropdown data-testid="table-action-dropdown" actions={actions} />}
            </>
          ) : (
            <>
              <span className="percent-count">{progress}</span>
              <Button
                size="sm"
                className="btn-goal mr-2"
                color="blue"
                handleClick={() => {
                  setModalShow(false);
                  setModalCheckinShow(true);
                }}
              >
                <span className="checkin">
                  Check-in
                  <img src={LocationIcon} alt="location" />
                </span>
              </Button>
              <TableActionDropdown data-testid="table-action-dropdown" actions={actions} />
            </>
          )}
        </span>
      </div>
      <div className="alignment-content">
        <div className="alignment">Alignment</div>
        <div className="features">{parentObjective && parentObjective?.description}</div>
        <div className="role">
          <img src={RoleIcon} alt="role" /> {useJobTitle ? owner?.jobDescription : team?.parentTeam}
        </div>
      </div>
      <div className="owner-content">
        <div className="owner">Owner</div>
        <div className="name">
          <img src={AvartaIcon} alt="avatar" /> {(owner && owner.firstName) || (firstName && firstName)} {(owner && owner.lastName) || (lastName && lastName)}
        </div>
      </div>
      <div className="date-content">
        <div className="date-item">
          <div className="due-date">Due Date</div>
          <div className="date">{dueDate}</div>
        </div>
        <div className="date-item">
          <div className="due-date">Last Update</div>
          <div className="date">{lastUpdatedDate ? lastUpdatedDate : dateSubmitted}</div>
        </div>
      </div>

      <div className="activity-content">
        <div className="activity">Activity</div>
        <ActivityDetails id={keyResultId} typeOfId="keyresultId" />
      </div>
    </div>
  );
};

export default KeyResultDetails;
