import React, { useState } from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import ObjectivesDraft from "../objectivesDraft/objectivesDraft";
import NewObjectivesForm from "./form/newObjectivesForm";
// import "./newGoal.scss";

const NewObjective = ({ modalShow, setModalShow, setShowAddKeyResultModal, setShowAlignKeyResultModal, addKeyResultValue, teamId, setAlignment, alignment, setAddKeyResultValue, cycleList }) => {
  const [draftContent, setDraftContent] = useState([]);
  const resumeDraft = (data) => {
    setDraftContent(data);
  };

  return (
    <div className="side-modal-content">
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Create a new Objective">
        <div className="container sidebar-content">
          <div className="row">
            <div style={{ height: "100vh" }} className="col form-width">
              <NewObjectivesForm
                draftContent={draftContent}
                setShowAlignKeyResultModal={setShowAlignKeyResultModal}
                setShowAddKeyResultModal={setShowAddKeyResultModal}
                setModalShow={setModalShow}
                addKeyResultValue={addKeyResultValue}
                teamId={teamId}
                setAlignment={setAlignment}
                setAddKeyResultValue={setAddKeyResultValue}
                alignment={alignment}
                cycleList={cycleList}
              />
            </div>
            <div className="col modal-sub-nav">
              <ObjectivesDraft resumeDraft={resumeDraft} />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default NewObjective;
