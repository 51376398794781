import React from "react";
import ArrowDown from "../../../../assets/images/arrow-down-tiny.svg";
import ArrowRight from "../../../../assets/images/arrow-right-tiny.svg";

import "./lineAccordion.scss";

const LineAccordion = ({ objectiveId, selected, subItems, children }) => {
  return (
    <div className="content">
      <div className="content-item">
        {objectiveId === selected ? <img src={ArrowDown} className="arrow-down" alt="arrow down icon" /> : <img src={ArrowRight} className="arrow-up" alt="arrow right icon" />}
        <div className="items">
          {children}
          {subItems}
        </div>
      </div>
    </div>
  );
};

export default LineAccordion;
