import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import avatar from "../../../assets/images/avatar.svg";
// import profile from "../../../assets/images/profile.jpeg";
import { ViewTeamMembersProfile } from "../../../utils/urls";
import SearchBar from "../../shared/searchBar/searchBar";
import TableActionDropdown from "../../shared/tableActionDropdown/tableActionDropdown";
import CustomTable from "../../shared/table/table";
import { useDispatch, useSelector } from "react-redux";
import { assignTeamLead, getTeamMembers } from "../../../redux/actions/teamsActions/teamMembersActions";
import { successMessage, errorMessage } from "../../../redux/actions/notification/notification";

import "./teamMembers.scss";

const TeamMembers = ({ teamId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const select = useSelector(({ teamMembers }) => {
    return {
      isLoading: teamMembers && teamMembers?.isLoading,
      assignManagerPayload: teamMembers && teamMembers?.assignManagerPayload,
      allTeamMembers: teamMembers && teamMembers?.teamMembersPayload?.data?.data,
    };
  });

  const { isLoading, allTeamMembers, assignManagerPayload } = select;

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamMembers(teamId, pageNo, pageSize));
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, pageNo, pageSize]);

  useEffect(() => {
    if (assignManagerPayload?.data?.success && !isLoading) {
      dispatch(successMessage(assignManagerPayload?.data?.message));
    } else {
      !isLoading && dispatch(errorMessage(assignManagerPayload?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // search table
  const handleSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handlePageClick = (data) => {
    console.log(data);
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  const viewProfile = (e, details) => {
    e.preventDefault();
    history.push({
      pathname: ViewTeamMembersProfile(details.userId),
      state: {
        tab: "Members",
        memberProfile: details,
      },
    });
  };

  const makeLineManager = ({ teamId, userId }) => {
    dispatch(assignTeamLead({ teamId, userId }));
  };

  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
    { id: 3, name: "Role" },
    { id: 4, name: "Last Seen" },
    { id: 5, name: "Actions" },
  ];

  const actions = [
    { id: 1, name: "Go to profile", method: viewProfile },
    {
      id: 2,
      name: "Make Line Manager",
      method: (e, data) => {
        makeLineManager(data);
      },
    },
  ];

  const arrangeTableData = (tableData) => {
    if (tableData) {
      return tableData.map((value, index) => {
        return (
          <tr key={index + 1}>
            <td className="bordered">
              <span>
                <img className="mr-3 table-avatar" src={value.img ? value.img : avatar} alt="team member dp" />
                <span>
                  {value.firstName} {value.lastName}
                </span>
              </span>
            </td>

            <td className="bordered">{value.jobTitle ? value.jobTitle : "-"}</td>
            <td className="bordered">{value.role ? value.role : "-"}</td>
            <td className="bordered">{value.lastSeen ? value.lastSeen : "-"}</td>
            <td className="bordered">
              <div className="action">
                <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} />
              </div>
            </td>
          </tr>
        );
      });
    }
    return;
  };

  const search = (rows) => {
    // const columns = ["role", "jobTitle", "firstName", "lastName", "lastSeen"];
    const columns = ["jobTitle", "firstName", "lastName"];
    if (rows && rows !== []) {
      return rows.filter((row) =>
        columns.some((column) => {
          return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
        })
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <div className="row tabContent-title mr-0 ml-0" style={{ justifyContent: "space-between", alignItems: "center" }}>
          <div className="sp-section-title mb-3">{allTeamMembers?.items ? allTeamMembers?.items?.length : 0} Team Members</div>
          <div className="row mb-3">
            <SearchBar placeholder="Search for a team" handleSearch={handleSearch} searchTerm={searchTerm} />
          </div>
        </div>

        <CustomTable
          bordered
          arrangedTableData={arrangeTableData(search(allTeamMembers?.items))}
          tableHeads={tableHeads}
          isLoading={select.isLoading}
          tableData={allTeamMembers?.items}
          searchTableData={search(allTeamMembers?.items)}
          currentPage={pageNo}
          perPage={pageSize}
          // pageCount={allTeamMembers?.PageCount}
          totalItems={allTeamMembers?.totalCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export default TeamMembers;
