import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";

const DisapproveForm = ({ submitForm, validForm }) => {
  const [form, setForm] = useState([]);
  //   const [validForm, setValidForm] = useState(false);

  useEffect(() => typeof validForm === "function" && validForm(form && form.valid && form.valid()));

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    console.log({ emptyValidation });
    typeof validForm === "function" && validForm(emptyValidation);
  };

  const submit = (event, value) => {
    console.log({ value });
  };

  return (
    <AvForm className="checkin-form-content" data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="comment-box layout-style">Input comments to give your line report clarity on why this goal was disapproved</div>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="comment">Comments</label>
          <AvField
            autoComplete="off"
            type="textarea"
            name="comment"
            className="textarea-field"
            placeholder="Enter comments here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter goal",
              },
            }}
          />
        </div>
      </div>
      <div
        className="align-goal layout-style"
        // onClick={() => {
        //   setModalShow(false);
        //   setShowAlignKeyResultModal(true);
        // }}
      >
        Add an Attachment
      </div>
    </AvForm>
  );
};

export default DisapproveForm;
