import * as React from "react";
import "./radioInput.scss";

function RadioButton({ name, value, labelText, isChecked, onChange, className, ...props }) {
  return (
    <div className="wrapper">
      <input id={value} name={name} type="radio" value={value} checked={isChecked} onChange={onChange} {...props} />
      <label htmlFor={value}>{labelText}</label>
    </div>
  );
}

export default RadioButton;
