import React, { useState } from "react";
import ViewTeamMembers from "./ViewTeamMembers/viewTeamMembers";
import ViewTeams from "./ViewTeams/viewTeams";

const Teams = () => {
  const [viewAllTeamMembers, setViewAllTeamMembers] = React.useState(false);
  const [team, setTeam] = useState(null);

  // Viewing all Teams
  const navigateToViewTeam = () => {
    setViewAllTeamMembers(false);
  };

  const navigateToViewTeamMembers = details => {
    setTeam(details);
    setViewAllTeamMembers(true);
  };

  return <div>{!viewAllTeamMembers ? <ViewTeams navigateToViewTeamMembers={navigateToViewTeamMembers} /> : <ViewTeamMembers navigateToViewTeam={navigateToViewTeam} team={team} />}</div>;
};

export default Teams;
