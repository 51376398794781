import React, { useState, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import avatar from "../../../assets/images/avatar.svg";
import bell from "../../../assets/images/bell.svg";
import arrowDown from "../../../assets/images/arrow-down.svg";
import { Urls } from "../../../utils/urls";

import "./mainNavTop.scss";
import { signout, userDetails } from "../../../apiServices/user.service";

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  const [value] = useState("");

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled">{React.Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().startsWith(value))}</ul>
    </div>
  );
});

const MainNavTop = () => {
  const history = useHistory();
  const details = userDetails();
  return (
    <div className="main-nav-container">
      <img src={bell} alt="bell" data-testid="bell-icon" />
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <img src={avatar} alt="avatar" className="avatar" data-testid="avatar" />
          <img src={arrowDown} alt="arrowDown" className="arrow-down" data-testid="arrow-down" />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <div className="user-dropdown">
            <div className="name">
              {details?.firstName} {details?.lastName}
            </div>
            <div className="role">{details?.workTitle}</div>
            <div className="my-profile" onClick={() => history.push(Urls.Profile)}>
              My Profile
            </div>
            <div
              className="sign-out"
              onClick={() => {
                signout();
                history.push(Urls.Login);
              }}
            >
              Sign Out
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MainNavTop;
