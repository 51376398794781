import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./badges.scss";

export const Badges = ({ className, content, variant, customColors, style, enforceCharacterLimit, ...rest }) => {
  let baseClass = "sp-badge";

  let badgeStyles = {
    backgroundColor: customColors?.backgroundColor,
    color: customColors?.textColor,
    ...style,
  };

  let classes = classNames(className, `${baseClass}`, variant && `${baseClass}--${variant}`);

  let formattedContent = (variant, content) => {
    let newContent = content;
    let stringOfContent = content.toString();

    if (stringOfContent.length > 3 && enforceCharacterLimit) {
      newContent = stringOfContent.substr(0, 3);
    }

    switch (variant) {
      case "added":
        return `+${newContent}`;

      case "removal":
        return `-${newContent}`;

      default:
        return newContent;
    }
  };

  return (
    <span className={classes} {...rest} style={badgeStyles}>
      {formattedContent(variant, content)}
    </span>
  );
};

Badges.defaultProps = {
  variant: "primary",
  enforceCharacterLimit: true,
};

Badges.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  customColors: PropTypes.object,
  enforceCharacterLimit: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "important", "added", "removal"]),
  content: PropTypes.number.isRequired,
};
export default Badges;
