import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import SearchBar from "../../../shared/searchBar/searchBar";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import CustomTable from "../../../shared/table/table";
import { addCommaToArrayOfStrings } from "../../../../utils/helpers";
import TableActionDropdown from "../../../shared/tableActionDropdown/tableActionDropdown";
import Button from "../../../shared/button/Button";
import { getTeamsOptions, getUsers, getUserStatus, inviteSingleTeamUsers, uploadUsersEmails } from "../../../../redux/actions/settingsActions/userSettingsActions";
import UserEditor from "../userEditor/userEditor";
import AlertModal from "../../../shared/alertModal/alertModal";
import InviteUserEditor from "../inviteUserEditor/inviteUserEditor";
import { CSVLink } from "react-csv";
import "./viewUsers.scss";
import { errorMessage, successMessage } from "../../../../redux/actions/notification/notification";

const ViewUsers = ({ navigateToEditUserRoles }) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showUserInvite, setShowUserInvite] = useState(false);
  const [showUserEditorModal, setShowUserEditorModal] = useState(false);
  const [showUserDeactivateModal, setShowUserDeactivateModal] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [actionType, setActionType] = useState(undefined);
  const [tableData, setTableData] = useState([]);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [statusParams, setStatusParams] = useState("");

  const select = useSelector(state => {
    return {
      allUsers: state.userSettings && state.userSettings.allUsers?.data?.data,
      usersSuccess: state.userSettings && state.userSettings.allUsers?.data?.success,
      isLoading: state.userSettings && state.userSettings.isLoading,
      inviteSingleTeamUsersPayload: state.userSettings && state.userSettings.inviteSingleTeamUsersPayload,
      isLoadingInviteSingleTeamUsers: state.userSettings && state.userSettings.isLoadingInviteSingleTeamUsers,
      isLoadingTeamsOptions: state.userSettings && state.userSettings.isLoadingTeamsOptions,
      teamsOptions: state.userSettings && state.userSettings.teamsOptions,

      reviewingUsersEmails: state.userSettings && state.userSettings.reviewingUsersEmails,
      reviewEmailsPayload: state.userSettings && state.userSettings.reviewEmailsPayload,

      uploadingUsersEmails: state.userSettings && state.userSettings.uploadingUsersEmails,
      uploadEmailsPayload: state.userSettings && state.userSettings.uploadEmailsPayload,

      statusOptions: state.userSettings && state.userSettings.statusOptions?.data?.data,
      isLoadingStatusOptions: state.userSettings && state.userSettings.isLoadingStatusOptions,
    };
  });

  useEffect(() => {
    dispatch(getUsers(pageSize, pageNo));
    dispatch(getTeamsOptions());
    dispatch(getUserStatus());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    select.allUsers?.items && setTableData(select.allUsers?.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.allUsers && select.allUsers?.items.length]);

  useEffect(() => {
    if (select.inviteSingleTeamUsersPayload && select.inviteSingleTeamUsersPayload.success) {
      setShowUserInvite(false);
      dispatch(getUsers(pageSize, pageNo));
      dispatch(successMessage(select.inviteSingleTeamUsersPayload?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.inviteSingleTeamUsersPayload]);

  useEffect(() => {
    if (!select.uploadingUsersEmails && select.uploadEmailsPayload) {
      if (select.uploadEmailsPayload.success) {
        setShowUserInvite(false);
        dispatch(getUsers(pageSize, pageNo));
        dispatch(successMessage(select.uploadEmailsPayload?.message));
      } else {
        dispatch(errorMessage(select.uploadEmailsPayload?.message));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.uploadEmailsPayload, select.uploadingUsersEmails]);

  useEffect(() => {
    dispatch(getUsers(pageSize, pageNo, "", statusParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusParams]);

  const handleSumbitInvite = (data, tabKey, reviewMode) => {
    if (tabKey === "single") {
      const { teamId, email } = data;
      dispatch(inviteSingleTeamUsers({ teamId, email: email.split(",").map(v => v.trim()) }));
    } else if (tabKey === "multiple") {
      dispatch(uploadUsersEmails(data));
    }
  };

  const handleEditInfo = (e, data) => {
    e.preventDefault();
    setActionType("edit info");
    setUserData(data);
    setShowUserEditorModal(true);
  };

  const handleEditRole = (e, data) => {
    e.preventDefault();
    setActionType("edit role");
    setUserData(data);
    navigateToEditUserRoles(data);
  };

  const handleDeactivate = (e, data) => {
    e.preventDefault();
    setShowUserDeactivateModal(true);
  };

  const teamsList = [
    {
      value: 2,
      label: "R & D",
    },
    {
      value: 3,
      label: "UI/UX",
    },
    {
      value: 4,
      label: "Engineering",
    },
    {
      value: 5,
      label: "People Ops",
    },
  ];

  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Email Address" },
    { id: 3, name: "Manager" },
    { id: 4, name: "Primary Team" },
    { id: 5, name: "User Roles" },
    { id: 6, name: "Status" },
    { id: 7, name: "Action" },
  ];

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Email Address", key: "email" },
    { label: "Manager", key: "manager" },
    { label: "Primary Team", key: "primaryTeam" },
    { label: "User Roles", key: "roles" },
    { label: "Status", key: "status" },
  ];

  const rolesDropdownList = [
    { id: 1, name: "Line Report" },
    { id: 2, name: "Line Manager" },
    { id: 3, name: "Performance Audit" },
    { id: 4, name: "Admin" },
  ];

  const actions = [
    { id: 1, name: "Edit User Info", method: handleEditInfo },
    { id: 2, name: "Edit User Role", method: handleEditRole },
    { id: 3, name: "Deactivate", method: handleDeactivate },
  ];

  const arrangeTableData = tableData => {
    return (
      tableData &&
      tableData.map((value, index) => {
        return (
          <tr key={index}>
            <td className="bordered">{`${value && value.firstName ? value.firstName : "-"} ${value && value.lastName ? value.lastName : "-"}`}</td>
            <td className="bordered">{value && value.email ? value.email : "-"}</td>
            <td className="bordered">{value && value.manager ? value.manager : "-"}</td>
            <td className="bordered">{value && value.primaryTeam ? value.primaryTeam : "-"}</td>
            <td className="bordered">{addCommaToArrayOfStrings(value?.roles)}</td>
            <td className="bordered">{value && value.status ? value.status : "-"}</td>
            <td className="bordered">
              <div className="action">
                <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} />
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const handleSearch = _debounce((evt, ctx, input, cb) => {
    dispatch(getUsers(pageSize, pageNo, evt?.target?.value));
  }, 1000);

  const handleConfirmedDeactivate = () => {
    console.log("User Deactivated");
  };

  const handleSubmitEdit = data => {
    setSelectedRows("Submitting User Update", data);
  };

  // const handleSumbitInvite = (data, tabKey, reviewMode) => {
  //   if (tabKey === "single") {
  //     const { teamId, email } = data;
  //     dispatch(inviteSingleTeamUsers({ teamId, email: email.split(",").map(v => v.trim()) }));
  //   } else if (tabKey === "multiple" && !reviewMode) {
  //     dispatch(reviewUsersEmails(data));
  //   } else if (reviewMode) {
  //     dispatch(uploadUsersEmails(data));
  //   }
  // };

  const handleFilterByStatus = evt => {
    let params = "";
    for (let i = 0; i < evt.length; i++) {
      params = params + `&status=${evt[i].value}`;
    }
    setStatusParams(params);
  };

  const handleCreateNewTeamOption = data => {
    setSelectedRows("Create new Team User Update", data);
  };

  const filterTableData = rows => {
    const columns = ["status", "userRole"];
    let newData = [];

    if (selectedRows && selectedRows.length) {
      rows.filter(row => {
        selectedRows.some(selectedRow => {
          columns.some(column => {
            if (row[column] === selectedRow.label) {
              newData = newData.concat(row);
            }
            return true;
          });
          return true;
        });
        setTableData(newData);
        return true;
      });
    } else {
      setTableData(select.allUsers?.items);
    }
  };

  const handlePageClick = data => {
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  return (
    <div className="view-users-container">
      <InviteUserEditor
        openModal={showUserInvite}
        closeModal={() => setShowUserInvite(false)}
        data={userData}
        teamsOptions={select.teamsOptions}
        isLoadingTeamsOptions={select.isLoadingTeamsOptions}
        onSubmit={handleSumbitInvite}
        handleCreateNewTeamOption={handleCreateNewTeamOption}
        isLoadingInviteSingle={select.isLoadingInviteSingleTeamUsers}
        reviewingUsersEmails={select.reviewingUsersEmails}
        reviewEmailsPayload={select.reviewEmailsPayload}
        uploadingUsersEmails={select.uploadingUsersEmails}
        uploadEmailsPayload={select.uploadEmailsPayload}
      />
      <UserEditor
        openModal={showUserEditorModal}
        closeModal={() => setShowUserEditorModal(false)}
        data={userData}
        teamsList={teamsList}
        onSubmit={handleSubmitEdit}
        handleCreateNewTeamOption={handleCreateNewTeamOption}
        actionType={actionType}
      />
      <AlertModal
        openModal={showUserDeactivateModal}
        closeModal={() => setShowUserDeactivateModal(false)}
        onConfirm={handleConfirmedDeactivate}
        textContent="Please confirm that you want to deactivate this user from using the Clarit platform."
        actionText="Deactivate"
        title="Deactivate User"
      />
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="row mb-3">
                <div className="col-6" style={{ paddingRight: "0" }}>
                  <SearchBar placeholder="Search for a team" disabled={!select.usersSuccess} handleSearch={handleSearch} />
                </div>

                <div className="col-3" style={{ paddingLeft: "0" }}>
                  <PMSSelect
                    placeholder="Status"
                    selectMultiple={true}
                    useSelectAll={true}
                    dataSource={select.statusOptions?.map(v => ({ label: v, value: v }))}
                    onActionButtonClick={handleFilterByStatus}
                    closeOnActionClick={true}
                    menuWidth="220px"
                    selectAllText="All Users"
                    useCount
                    value={[]}
                    disableControl={!select.usersSuccess}
                    id="status-select"
                  />
                </div>
                <div className="col-3" style={{ padding: "0" }}>
                  <PMSSelect
                    placeholder="Role"
                    selectMultiple={true}
                    useSelectAll={true}
                    dataSource={rolesDropdownList}
                    onActionButtonClick={() => filterTableData(select.allUsers?.items)}
                    closeOnActionClick={true}
                    displayExpr="name"
                    valueExpr="id"
                    menuWidth="220px"
                    selectAllText="All Roles"
                    useCount
                    value={[]}
                    disableControl={!select.usersSuccess}
                    id="role-select"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 tableTopBtn-div mb-3">
              <Button size="sm" handleClick={() => setShowUserInvite(true)} className="pull-right sp-button-height ml-2">
                Invite Users
              </Button>
              <CSVLink data={tableData} headers={csvHeaders} filename="users.csv" type="button" className="sp-button sp-button--sm sp-button--outline-blue pull-right" disabled>
                Export
              </CSVLink>
              {/* <Button size="sm" outline handleClick={() => console.log("user export")} className="pull-right sp-button-height" disabled={!tableData.length}>
                Export
              </Button> */}
            </div>
          </div>

          <CustomTable
            arrangedTableData={arrangeTableData(tableData)}
            tableHeads={tableHeads}
            isLoading={select.isLoading}
            tableData={tableData}
            searchTableData={tableData}
            bordered
            currentPage={pageNo}
            perPage={pageSize}
            pageCount={Math.ceil(select.allUsers?.totalCount / pageSize)}
            totalItems={select.allUsers?.totalCount}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;
