import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.svg";
import Button from "../../shared/button/Button";
import Badges from "../../shared/badges/badges";
import SearchBar from "../../shared/searchBar/searchBar";
import OneOnOneModal from "./oneOnOneModal/oneOnOneModal";
import ViewUpdatesModal from "./viewUpdatesModal/viewUpdatesModal";
import DisapproveModal from "./disapproveModal/disapproveModal";
import ViewKeyResultsModal from "../../shared/viewKeyResultsSideModal/viewKeyResultsModal";
import { getLineReports } from "../../../redux/actions/teamsActions/lineReportsActions";
import { useDispatch, useSelector } from "react-redux";
import LineReportCard from "./lineReportCard";
import EditKeyResultModal from "../../shared/editKeyResult/editKeyResult";
import DeleteModal from "../../shared/deleteModal/deleteModal";
import DocumentIcon from "../../../assets/images/documents.svg";
import { LineReportLoadingDetails } from "../../shared/loader/Loader";

import "./lineReport.scss";

const LineReports = ({ teamDetails }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [modalViewShow, setModalViewShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [oneOnOneModal, setOneonOneModal] = useState(false);
  const [disapproveModal, setDisapproveModal] = useState(false);
  const [viewGoalModal, setViewGoalModal] = useState(false);
  const [keyResultData, setKeyResultData] = useState({});
  const [userId, setUserId] = useState(null);
  const [allLineReports, setAllLineReports] = useState(null);

  const select = useSelector((state) => {
    return {
      isLoading: state.lineReports && state.lineReports.isLoading,
      allLineReportsData: state.lineReports && state.lineReports.lineReports?.data?.data,
      activityLoading: state.userActivity && state.userActivity.activityLoading,
    };
  });

  const { isLoading, allLineReportsData, activityLoading } = select;

  useEffect(() => {
    setAllLineReports(allLineReportsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLineReportsData]);

  useEffect(() => {
    if (teamDetails) {
      dispatch(getLineReports(teamDetails?.teamOwnerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetails]);

  // search table
  const handleSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleViewKeyresult = (val, lineReportData) => {
    let newVal = {
      ...val,
      ...lineReportData,
    };
    setKeyResultData(newVal);
    // dispatch(getUserActivity(val.userId, "userId"));
    setViewGoalModal(true);
  };

  // const search = rows => {
  //   const columns = ["role", "memberTitle", "teamMember", "time"];
  //   if (rows !== []) {
  //     return rows.filter(row =>
  //       columns.some(column => {
  //         if (column === "teamMember") {
  //           let teamLeadData = { ...row[column] };
  //           return teamLeadData.name.toString().toLowerCase().indexOf(searchTerm) > -1;
  //         } else {
  //           return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
  //         }
  //       })
  //     );
  //   }
  // };

  return (
    <div className="row line-report-container">
      {isLoading ? (
        <div style={{ width: "100%" }}>
          <LineReportLoadingDetails />
        </div>
      ) : (
        <div className="col">
          <ViewKeyResultsModal
            details={keyResultData}
            modalShow={viewGoalModal}
            setModalShow={setViewGoalModal}
            setModalEditShow={setModalEditShow}
            setModalDeleteShow={setModalDeleteShow}
            activityLoading={activityLoading}
          />
          <EditKeyResultModal modalShow={modalEditShow} setModalShow={setModalEditShow} />
          <DeleteModal modalShow={modalDeleteShow} setModalShow={setModalDeleteShow} />
          <DisapproveModal modalShow={disapproveModal} setModalShow={() => setDisapproveModal(false)} />
          <ViewUpdatesModal modalShow={modalViewShow} setModalShow={setModalViewShow} userId={userId} />
          <OneOnOneModal openModal={oneOnOneModal} closeModal={() => setOneonOneModal(false)} />
          <div className="row tabContent-title mr-0 ml-0" style={{ justifyContent: "space-between", alignItems: "center" }}>
            <div className="sp-section-title mb-3">{allLineReports?.items?.length} Line Reports</div>
            <div className="row mb-3">
              <SearchBar placeholder="Search for a team" handleSearch={handleSearch} searchTerm={searchTerm} />
            </div>
          </div>

          <div>
            {allLineReports &&
              allLineReports?.items?.map((lineReport, index) => {
                return (
                  <div className="container-fluid main-container">
                    <div className="row title-div " style={{ justifyContent: "space-between" }}>
                      <div className="d-flex avatar-div">
                        <img className="mr-3 table-avatar" src={lineReport.image ? lineReport.image : avatar} alt="owner alt"></img>

                        <div>
                          <p className="mb-0">{`${lineReport.firstName} ${lineReport.lastName}`}</p>
                          <p className="mb-0">{lineReport.jobTitle}</p>
                        </div>
                      </div>
                      <div className="avatar-div">
                        <span data-testid="review-link">
                          <Link to="#" className="link-text" onClick={() => setOneonOneModal(true)}>
                            Review 1:1
                          </Link>
                        </span>
                        <div className="col pr-0">
                          <Button
                            size="sm"
                            outline={true}
                            color="blue"
                            handleClick={() => {
                              setModalViewShow(true);
                              setUserId(lineReport?.id);
                            }}
                            className="pull-right"
                          >
                            View Updates
                            {lineReport?.updates?.length > 1 && (
                              <Badges
                                variant="added"
                                className="ml-2"
                                content={`${lineReport.updates.length}`}
                                customColors={{
                                  textColor: "#2C6EEC",
                                  backgroundColor: "#E8EFFD",
                                }}
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <LineReportCard key={index} lineReportData={lineReport} handleViewKeyresult={handleViewKeyresult} setDisapproveModal={setDisapproveModal} index={index} />
                  </div>
                );
              })}
            {allLineReports?.items && allLineReports?.items?.length === 0 && (
              <div className="empty-content">
                <img src={DocumentIcon} alt="empty goals" />
                <div className="no-goals">You do not have any line reports in this team</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineReports;
