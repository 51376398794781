import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Button from "../../shared/button/Button";

import "./companyProfile.scss";

const CompanyProfile = () => {
  const [form, setForm] = useState([]);

  const submit = (event, value) => {
    console.log({ value });
  };
  return (
    <div className="company-profile-container">
      <div className="row">
        <div className="col">
          <div className="detail-update">
            <div className="title">Organisation Details</div>
            <div>
              <Button size="sm" outline className="update-btn" handleClick={() => form.submit()}>
                Update
              </Button>
            </div>
          </div>
          <AvForm onValidSubmit={submit} ref={(c) => setForm(c)}>
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="organisation" className="mt-0">
                      Organisation Name
                    </label>
                    <AvField
                      autoComplete="off"
                      type="text"
                      name="organisation"
                      id="organisation"
                      placeholder="Enter Organisation Name"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Organisation Name",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="address">Headquarters Address</label>
                    <AvField
                      autoComplete="off"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter Headquarters Address"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Headquarters Address",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="city">City/Town</label>
                    <AvField
                      autoComplete="off"
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Enter City/Town"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter City/Town",
                        },
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="zipCode">Zip Code (Optional)</label>
                    <AvField
                      autoComplete="off"
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      placeholder="Enter Zip Code (Optional)"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter Zip Code (Optional)",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
