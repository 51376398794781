import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Button from "../../shared/button/Button";
import ClaritLogo from "../../../assets/images/clarit-logo.svg";
import { forgotPassword } from "../../../redux/actions/onboarding/onboarding";

import "../login/login.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { payload, loading } = useSelector(({ onboarding }) => {
    return { error: onboarding?.forgotPasswordError, payload: onboarding?.forgotPasswordPayload, loading: onboarding?.forgotPasswordLoading };
  });

  console.log({ payload, loading });
  const submit = (event, value) => {
    console.log({ value });
    dispatch(forgotPassword(value));
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  return (
    <div className="login-form-container">
      <div className="logo">
        <img src={ClaritLogo} alt="logo" />
      </div>
      <div className="login-content">
        <div className="create-account">Reset Password</div>
        <div className="create-account-subtitle">Please enter your new password</div>

        <AvForm data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
          <div className="row layout-style">
            <div className="col-12">
              <label htmlFor="password">New Password</label>
              <span className="passwordToggle" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? "show" : "Hide"}
              </span>
              <AvField
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-field"
                placeholder="Enter your new password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter your new password",
                  },
                }}
              />
            </div>
          </div>
          <div className="row layout-style">
            <div className="col-12">
              <Button size="sm" className="btn-goal" loading={loading} color="blue" disabled={!validForm} handleClick={() => form.submit()}>
                Reset Password
              </Button>
            </div>
          </div>
        </AvForm>
      </div>
    </div>
  );
};

export default ForgotPassword;
