import React from "react";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { AvField } from "availity-reactstrap-validation";
import "./userRoleEditor.scss";
import RadioInput from "../../../shared/radioInput/radioInput";

const UserRoleEditor = ({ formData, selectedRole, handleInputChange }) => {
  return (
    <div className="user-role-editor-container">
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-7">
              <AvForm className="user-role-form">
                <div className="row mb-4">
                  <div className="col-10">
                    <label htmlFor="fullName" className="form-label">
                      User
                    </label>
                    <AvField
                      autoComplete="off"
                      data-testid="user-fullname"
                      type="text"
                      name="fullName"
                      value={formData?.fullName}
                      placeholder="Enter Full Name"
                      onChange={handleInputChange}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter fullname",
                        },
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="sp-section-title">Roles</div>
                    <p className="">Give users varying access to the Clarit platform by assigning them roles.</p>
                    <RadioInput name="userRole" labelText="Admin" value="admin" isChecked={selectedRole === "admin"} onChange={handleInputChange} />
                    <p className="info">
                      Has full access to Insights (Company-wide), Platform settings, Billings, and 1:1 Compliance data (Company wide). Can Invite & Deactivate Users, assign User roles and Add & Delete
                      Teams.
                    </p>
                    <RadioInput name="userRole" labelText="Performance Auditor" isChecked={selectedRole === "performanceAuditor"} value="performanceAuditor" onChange={handleInputChange} />
                    <p className="info">Has access to Insights (Company-wide), and 1:1 Compliance data (Company wide).</p>
                    <RadioInput name="userRole" labelText="Line Manager" isChecked={selectedRole === "lineManager"} value="lineManager" onChange={handleInputChange} />
                    <p className="info">Has access to Insights and 1:1 Compliance data for Teams, Subteams & Line reports.</p>
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRoleEditor;
