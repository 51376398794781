export const COMPANY_OBJECTIVES_APIS = {
  getObjectives: "Objective/organisation/isPublished",
  overview: "organisation/objectives/overview",
  orgObjective: "Objective/organisation",
  alignedTeamsObj: "Objective/organisation/alignment",
  cycleOptions: "ReviewCycle/all",
  alignedTeamsOptions: "Team/aligned/organisationobjective",
  objKeyresults: "Team/keyresult",
  alignedKeyResults: "Objective/alignedkeyresults",
};

export const COMPANY_PEOPLE_APIS = {
  getAll: "Team/membersorg",
};

export const COMPANY_TEAMS_APIS = {
  getAll: "Team/teams/paged",
  getSearched: "Team/searchteam",
  getTeamObjs: "Team",
  getTeamOverview: "Team/overview",
  getCycleOptions: "ReviewCycle/all",
};

export const COMPANY_INSIGHTS_APIS = {
  getTeamsDestribution: "organisation/insight/teamsdestribution",
  getOneOnOneStaff: "organisation/employees/oneonone",
  getTeamsOptions: "Teams",
  getOkr: "organisation/okr",
};
