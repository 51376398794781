import { peopleActionTypes } from "../../actions/company";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  isLoadingPeople: false,
  people: {},
  peopleError: null,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(peopleActionTypes.GET_PEOPLE):
      return {
        ...state,
        isLoadingPeople: true,
      };
    case SUCCESS(peopleActionTypes.GET_PEOPLE):
      return {
        ...state,
        isLoadingPeople: false,
        people: actions.payload.data.data,
      };

    case FAILURE(peopleActionTypes.GET_PEOPLE):
      return {
        ...state,
        peopleError: actions.payload,
        isLoadingPeople: false,
      };

    default:
      return { ...state };
  }
};
