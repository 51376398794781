import React from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import { LoadingTableContent } from "../../shared/loader/Loader";
import EmptyState from "../emptyState/emptyState";
import Paginator from "../paginator/paginator";

const CustomTable = props => {
  const {
    arrangedTableData,
    tableHeads,
    isLoading,
    searchTableData,
    tableData,
    pageCount,
    pageSize,
    currentPage,
    handlePageClick,
    totalItems,
    emptyStateInfoText,
    emptyStateInstructionText,
    showPaginator,
    bordered,
    topHeaderBorder,
    borderBottom,
  } = props;
  return (
    <div className="row table-container">
      <div className="col-12">
        {/* Shimmer Loader on Loader */}
        {isLoading ? (
          <Table responsive style={borderBottom ? { borderBottom: "1px solid #BAC2CA" } : {}}>
            <thead>
              <tr>
                {tableHeads.map(tableHead => {
                  return <th key={tableHead.id}>{tableHead.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <LoadingTableContent colSpan={tableHeads.length} />
            </tbody>
          </Table>
        ) : (
          //  Table Data after Loader
          <>
            {tableData.length ? (
              <>
                <Table responsive style={borderBottom ? { borderBottom: "1px solid #BAC2CA" } : {}} borderless>
                  <thead>
                    <tr>
                      {tableHeads.map(tableHead => {
                        return (
                          <th className={`${bordered ? "bordered" : ""} ${topHeaderBorder ? "bordered-top" : ""}`} key={tableHead.id}>
                            {tableHead.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      (searchTableData.length ? (
                        // data rows
                        arrangedTableData
                      ) : (
                        // No search data found
                        <tr>
                          <td colSpan="7" className="font-weight-bold text-center pt-4" style={{ display: "table-cell" }}>
                            No data available for search criteria
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <EmptyState infoText={emptyStateInfoText || "You haven’t added anything to the platform"} instruction={emptyStateInstructionText || "Click on button to get started"} />
              </>
            )}
          </>
        )}
        {showPaginator && <Paginator currentPage={currentPage} perPage={pageSize} pageCount={pageCount} totalItems={totalItems} handlePageClick={handlePageClick} />}
      </div>
    </div>
  );
};

CustomTable.propTypes = {
  arrangedTableData: PropTypes.arrayOf(Object),
  tableHeads: PropTypes.arrayOf(Object).isRequired,
  isLoading: PropTypes.bool,
  showPaginator: PropTypes.bool,
  searchTableData: PropTypes.arrayOf(Object),
  tableData: PropTypes.arrayOf(Object),
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func,
  totalItems: PropTypes.number,
  emptyStateInfoText: PropTypes.string,
  emptyStateInstructionText: PropTypes.string,
};

CustomTable.defaultProps = {
  arrangedTableData: [],
  tableHeads: [],
  isLoading: false,
  showPaginator: true,
  searchTableData: [],
  tableData: [],
  pageCount: 1,
  pageSize: 1,
  currentPage: 1,
  handlePageClick: () => {},
  totalItems: 1,
  emptyStateInfoText: "No Data Available",
  emptyStateInstructionText: "",
  topHeaderBorder: false,
};

export default CustomTable;
