import React, { useState } from "react";
import CheckinForm from "./form/form";
import "./checkinModal.scss";
import PMSModal from "../pmsModal/pmsModal";

const CheckinModal = ({ show, setShow, closeModal, formTitle, titleIcon, onSubmit, checkinError }) => {
  const [allowSubmit, setAllowSubmit] = useState(null);
  const [form, setForm] = useState([]);
  const validForm = (event) => {
    if (event !== undefined) {
      setAllowSubmit(event);
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onValidSubmit = (evt, value) => {
    onSubmit(value);
  };
  return (
    <div className="checkin-modal-content">
      <PMSModal
        title="Check-In"
        openModal={show}
        handlePrimaryButtonClick={handleSubmit}
        processing={false}
        primaryButtonText="Check-In"
        containerId="check-in-editor"
        closeModal={closeModal ? closeModal : () => setShow(false)}
        contentClassName=""
        showSecondaryButton={true}
        useHeaderBorder
        disablePrimaryButton={!allowSubmit}
        closeOnOutsideClick={false}
        useCloseIcon
      >
        <CheckinForm validForm={validForm} formTitle={formTitle} titleIcon={titleIcon} onValidSubmit={onValidSubmit} form={form} setForm={setForm} checkinError={checkinError} />
      </PMSModal>
    </div>
  );
};
export default CheckinModal;
