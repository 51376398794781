import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CloseIcon from "../../../assets/images/close-icon.svg";
import DoneIcon from "../../../assets/images/done.svg";
import ArrowRightIcon from "../../../assets/images/arrow-right.svg";
import WelcomeIcon from "../../../assets/images/welcomeIcon.svg";
import ConfirmIcon from "../../../assets/images/confirm.svg";
import { showOnboardingSteps } from "../../../redux/actions/profile/profileActions";

import "./steps.scss";

const Steps = () => {
  const dispatch = useDispatch();
  const [step] = useState(1);

  return (
    <div className="step-container">
      <div className="step-content">
        <div className="step-head">
          <div>
            <div className="title">
              Welcome to Clarit <img src={WelcomeIcon} alt="welcome icon" />
            </div>
            <div className="subtitle">Follow these steps to get you up and running.</div>
            <div className="progress-count">
              <span className="count">17%</span> <ProgressBar variant="success" now="20" />
            </div>
          </div>
          <img src={CloseIcon} className="close-step" alt="close-icon" onClick={() => dispatch(showOnboardingSteps(false))} />
        </div>
        <div className="instruction-content">
          <div className="instruction">
            <span className="step-text line-through">
              <img src={DoneIcon} alt="done icon" />
              Create an account
            </span>
          </div>
          <div className="instruction">
            <span className="step-text">
              {step > 1 ? (
                <img src={DoneIcon} alt="done icon" className="step-icon" />
              ) : (
                <span className="instruction-count" data-testid="count">
                  2
                </span>
              )}
              Complete company profile
            </span>
            {step === 1 && <img src={ArrowRightIcon} alt="arrow-right-icon" />}
          </div>
          <div className="instruction">
            <span className="step-text">
              {step > 2 ? (
                <img src={DoneIcon} alt="done icon" className="step-icon" />
              ) : (
                <span className="instruction-count" data-testid="count">
                  3
                </span>
              )}
              Set OKR timelines
            </span>
            {step === 2 && <img src={ArrowRightIcon} alt="arrow-right-icon" />}
          </div>
          <div className="instruction">
            <span className="step-text">
              {step > 3 ? (
                <img src={DoneIcon} alt="done icon" className="step-icon" />
              ) : (
                <span className="instruction-count" data-testid="count">
                  4
                </span>
              )}
              Create a company objective
            </span>
            {step === 3 && <img src={ArrowRightIcon} alt="arrow-right-icon" />}
          </div>
          <div className="instruction">
            <span className="step-text">
              {step > 4 ? (
                <img src={DoneIcon} alt="done icon" className="step-icon" />
              ) : (
                <span className="instruction-count" data-testid="count">
                  5
                </span>
              )}
              Add Teams
            </span>
            {step === 4 && <img src={ArrowRightIcon} alt="arrow-right-icon" />}
          </div>
          <div className="instruction">
            <span className="step-text">
              {step > 5 ? (
                <img src={DoneIcon} alt="done icon" className="step-icon" />
              ) : (
                <span className="instruction-count" data-testid="count">
                  6
                </span>
              )}
              Add Users
            </span>
            {step === 5 && <img src={ArrowRightIcon} alt="arrow-right-icon" />}
          </div>
        </div>
      </div>
      <span className="get-started">
        Get Started <img src={ConfirmIcon} className="close-step" alt="confirm checklist icon" />
      </span>
    </div>
  );
};

export default Steps;
