import React, { useState } from "react";
import "./timePeriod.scss";
import ReviewCycle from "./reviewCycle";
import OneOnOneCycle from "./oneOnOneCycle";

const TimePeriod = () => {
  const [typeTime, setTimeType] = useState(null);
  return (
    <div className="time-period-container">
      {(typeTime === null || typeTime === "review") && <ReviewCycle setTimeType={setTimeType} />}
      {typeTime === null && (
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
      )}
      {(typeTime === null || typeTime === "one") && <OneOnOneCycle setTimeType={setTimeType} />}
    </div>
  );
};

export default TimePeriod;
