import React, { useState } from "react";
import avatar from "../../../assets/images/avatar.svg";
import loggedIcon from "../../../assets/icons/logged.svg";
import unloggedIcon from "../../../assets/icons/unlogged.svg";
import { Link } from "react-router-dom";

import "./oneOnOneReportTable.scss";
import ReportModal from "./reportModal/reportModal";
import { Button, CustomTable, PMSSelect, SearchBar } from "../../shared";

const OneOnOneReportTable = ({ customTimelineValue, staff, loading, onTeamFilterSelect, selectedTeams, teamsFilterOptions, loadingTeamsOptions, onStatusFilterSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dropdownList = [
    { id: "logged", name: "Logged" },
    { id: "unlogged", name: "Not Logged" },
  ];
  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
    { id: 3, name: "Line Manager" },
    { id: 4, name: customTimelineValue ? "Sessions Logged" : "Status" },
    { id: 5, name: "Action" },
  ];

  const handleModal = val => {
    setShowModal(true);
  };

  const arrangeTableData = (d = []) => {
    return d.map((value, index) => {
      return (
        <tr key={index + 1} className="reports-table">
          <td className="bordered">
            <span>
              <img className="mr-3 table-avatar" src={value.teamMember.img ? value.teamMember.img : avatar} alt="team member avatar" /> {value.teamMember.name}
            </span>
          </td>

          <td className="bordered">{value.memberTitle}</td>
          <td className="bordered">{value.lineManager}</td>
          <td className="bordered">
            {!customTimelineValue ? (
              <span className="d-flex">
                <img className="mr-2" src={value.status === "Logged" ? loggedIcon : unloggedIcon} alt="user d[p" />
                {value.status}
              </span>
            ) : (
              value.sessions
            )}
          </td>
          <td className="bordered">
            <Link to="#" onClick={() => handleModal(value)} className="resume">
              View 1:1 history
            </Link>
          </td>
        </tr>
      );
    });
  };

  // search table
  const handleSearch = e => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  const search = rows => {
    const columns = ["memberTitle", "lineManager", "status", "sessions", "teamMember"];
    if (rows && rows.length) {
      return rows.filter(row => {
        return columns.some(column => {
          let searched;
          if (typeof row[column] === "string" || typeof row[column] === "number") {
            searched = row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
          } else {
            if (row.teamMember && row.teamMember.name) {
              searched = row.teamMember.name.toString().toLowerCase().indexOf(searchTerm) > -1;
            }
          }
          return searched;
        });
      });
    }
    return rows;
  };

  return (
    <div className="one-on-one-container">
      <div className="row mb-3">
        <ReportModal openModal={showModal} closeModal={() => setShowModal(false)} useCloseIcon useButtomBorder />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="row mb-3">
                <div className="col-auto pr-0">
                  <div className="search-container">
                    <SearchBar placeholder="Search" handleSearch={handleSearch} searchTerm={searchTerm} />
                  </div>
                </div>

                {!customTimelineValue && (
                  <div className="col-auto pl-0">
                    <div className="select-container">
                      <PMSSelect
                        placeholder="Status"
                        selectMultiple
                        useSelectAll
                        dataSource={dropdownList}
                        onActionButtonClick={onStatusFilterSelect}
                        closeOnActionClick
                        displayExpr="name"
                        valueExpr="id"
                        menuWidth="220px"
                        selectAllText="All Users"
                        useCount
                        value={[]}
                      />
                    </div>
                  </div>
                )}
                <div className="col-auto pl-0">
                  <div className="select-container">
                    <PMSSelect
                      placeholder="All Teams"
                      selectMultiple={true}
                      useSelectAll={true}
                      dataSource={teamsFilterOptions}
                      isLoading={loadingTeamsOptions}
                      onActionButtonClick={onTeamFilterSelect}
                      closeOnActionClick
                      displayExpr="name"
                      valueExpr="id"
                      menuWidth="220px"
                      selectAllText="All Teams"
                      useCount
                      value={[]}
                      hidePlaceholder={selectedTeams}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 tableTopBtn-div mb-3">
              <Button size="sm" color="blue" handleClick={() => {}} className="pull-right">
                Export
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <CustomTable arrangedTableData={arrangeTableData(search(staff))} tableHeads={tableHeads} isLoading={loading} tableData={staff} searchTableData={search(staff)} bordered />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOneReportTable;
