import React, { useState } from "react";
import profile from "../../../assets/images/profile.jpeg";
import { Button, PMSSelect, SearchBar, CustomTable } from "../../shared";

import "./okrReportTable.scss";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";

const OKRReportTable = ({ teamsFilterOptions, showTeamFilter, teams, staff, reportType, loading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedRows, setSelectedRows] = useState([]);
  const [filterOption, setFilterOption] = useState(1);

  const [tableData, setTableData] = useState([]);

  const mockTableData = [
    {
      id: 0,
      name: "Nma Okafor",
      title: "Software Engineer",
      primary_team: "R & D",
      parent_team: "Engineering",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Unlogged",
      teamMember: { img: profile, name: "Israel" },
    },
    {
      id: 1,
      name: "Jindu Opara",
      title: "Product Designer Intern",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "logged",
      teamMember: { img: profile, name: "Bade" },
    },
    {
      id: 2,
      name: "Abdulkadri Bello",
      title: "Product Designer",
      primary_team: "Quality Assurance",
      parent_team: "Engineering",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "logged",
      teamMember: { img: profile, name: "Kola" },
    },
    {
      id: 3,
      name: "Greg Collins",
      title: "Product Design Intern",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "Unlogged",
      teamMember: { img: null, name: "Diamond" },
    },
    {
      id: 4,
      name: "Yahaya Karim",
      title: "Product Designer",
      primary_team: "",
      parent_team: "",
      key_results: "",
      last_updated: "",
      line_manager: "Israel Akins",
      status: "logged",
      teamMember: { img: null, name: "Israel" },
    },
  ];

  const teamsList = [
    { id: 1, name: "Adminstration" },
    { id: 2, name: "Advancly" },
    { id: 3, name: "Avitech" },
    { id: 4, name: "Coporate Communication" },
    { id: 5, name: "Customer Success" },
    { id: 6, name: "Engineering" },
    { id: 7, name: "Financial Service" },
    { id: 8, name: "GSV" },
  ];

  const peopleColumnTitles = [
    { id: 1, name: "Name" },
    { id: 2, name: "Line Manager" },
    { id: 3, name: "Parent Team" },
    { id: 4, name: "Primary Team" },
    { id: 5, name: "Key Results" },
    { id: 6, name: "Last Updated" },
    { id: 7, name: "Status" },
  ];

  const teamsColumnTitles = [
    { id: 1, name: "Name" },
    { id: 2, name: "Line Manager" },
    { id: 3, name: "Parent Team" },
    { id: 4, name: "Objectives" },
    { id: 5, name: "Members with contributing KRs" },
    { id: 7, name: "Status" },
  ];

  // const handleModal = val => {
  //   setShowModal(true);
  //   setModalValues(val);
  // };

  const peopleRows = tableData => {
    return tableData.map((value, index) => {
      return (
        <tr key={index + 1} className="reports-table">
          <td className="bordered">{value.name ? value.name : "--"}</td>

          <td className="bordered">{value.primaryTeam ? value.primaryTeam : "--"}</td>
          <td className="bordered">{value.lineManager ? value.lineManager : "--"}</td>
          <td className="bordered">{value.parentTeam ? value.parentTeam : "--"}</td>
          <td className="bordered">{typeof value.keyResultsCount === "number" ? value.keyResultsCount : "--"}</td>
          <td className="bordered">{value.lastUpdated ? moment(value.lastUpdated).format("DD/MM/YYYY") : "--"}</td>
          <td className="bordered">
            <ProgressBar>
              <ProgressBar variant="pending" now="16" />
              <ProgressBar variant="notStarted" now="16" />
              <ProgressBar variant="atRisk" now="16" />
              <ProgressBar variant="behind" now="16" />
              <ProgressBar variant="onTrack" now="20" />
              <ProgressBar variant="completed" now="16" />
            </ProgressBar>
          </td>
        </tr>
      );
    });
  };

  const teamsRows = tableData => {
    return tableData.map((value, index) => {
      return (
        <tr key={index + 1} className="reports-table">
          <td className="bordered">{value.name ? value.name : "--"}</td>
          <td className="bordered">{value.teamLead ? value.teamLead : "--"}</td>
          <td className="bordered">{value.parentTeam ? value.parentTeam : "--"}</td>
          <td className="bordered">{typeof value.objectivesCount === "number" ? value.objectivesCount : "--"}</td>
          <td className="bordered">{value.contributingMembersKeyResults ? value.contributingMembersKeyResults : "--"}</td>
          <td className="bordered">
            <ProgressBar>
              <ProgressBar variant="pending" now="16" />
              <ProgressBar variant="notStarted" now="16" />
              <ProgressBar variant="atRisk" now="16" />
              <ProgressBar variant="behind" now="16" />
              <ProgressBar variant="onTrack" now="20" />
              <ProgressBar variant="completed" now="16" />
            </ProgressBar>
          </td>
        </tr>
      );
    });
  };

  // search table
  const handleSearch = e => {
    e.preventDefault();
    const { value } = e.target;
    setSearchTerm(value);
  };

  // const search = rows => {
  //   const columns = ["name", "primary_team", "line_manager", "parent_team", "key_results", "last_updated"];
  //   if (rows.length) {
  //     return rows.filter(row =>
  //       columns.some(column => {
  //         if (column === "name") {
  //           let newRows = row[column];
  //           return newRows.toString().toLowerCase().indexOf(searchTerm) > -1;
  //         } else {
  //           return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
  //         }
  //       })
  //     );
  //   }
  // };

  const handleSelectRow = values => {
    // setSelectedRows(values);
  };

  const handleSelectFilter = values => {
    setFilterOption(values);
  };

  const filterTableData = rows => {
    const column = "primary_team";
    let newData = [];

    if (tableData && tableData.length) {
      rows.filter(row => {
        tableData.some(selectedRow => {
          if (row[column] === selectedRow.label) {
            newData = newData.concat(row);
          }
          return newData;
        });
        setTableData(newData);
        return newData;
      });
    } else {
      setTableData(mockTableData);
    }
  };

  return (
    <div className="okr-rpt-container">
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12">
              <div className="row mb-3">
                <div className="col-auto pr-0">
                  <div className="search-container">
                    <SearchBar placeholder="Search for a user" handleSearch={handleSearch} searchTerm={searchTerm} />
                  </div>
                </div>

                <div className="col-auto pl-0">
                  <div className="select-container">
                    <PMSSelect
                      placeholder="Filter By Team"
                      selectMultiple={true}
                      useSelectAll={true}
                      dataSource={teamsList}
                      onActionButtonClick={() => filterTableData(mockTableData)}
                      closeOnActionClick={true}
                      displayExpr="name"
                      valueExpr="id"
                      onChange={handleSelectRow}
                      menuWidth="220px"
                      selectAllText="All Teams"
                      useCount
                      value={[]}
                    />
                  </div>
                </div>

                {showTeamFilter && teamsFilterOptions && (
                  <div className="col-auto pl-0">
                    <div className="select-container">
                      <PMSSelect
                        placeholder="Filter"
                        dataSource={teamsFilterOptions}
                        closeOnActionClick={true}
                        displayExpr="description"
                        valueExpr="id"
                        onChange={handleSelectFilter}
                        menuWidth="220px"
                        value={filterOption}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 tableTopBtn-div mb-3">
              <Button size="sm" color="blue" handleClick={() => {}} className="pull-right">
                Export
              </Button>
            </div>
          </div>

          <CustomTable
            arrangedTableData={reportType === "people" ? peopleRows(staff) : teamsRows(teams)}
            tableHeads={reportType === "people" ? peopleColumnTitles : teamsColumnTitles}
            isLoading={loading}
            tableData={reportType === "people" ? staff : teams}
            searchTableData={reportType === "people" ? staff : teams}
            bordered
          />
        </div>
      </div>
    </div>
  );
};

export default OKRReportTable;
