import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { CustomTable, PMSSelect, SearchBar, Button } from "../../../../shared";
import SVGS from "../../../../../utils/svgs";
import DATA from "../../../data";
import { Urls } from "../../../../../utils/urls";
import "./pendingKeyResults.scss";

const PendingKeyResults = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(true);
  const [activeRoutState, setRouteState] = useState(null);

  useEffect(() => {
    setRouteState(history?.location?.state);
    setTimeout(() => {
      setIsLoading(false);
      setEmployeeData(DATA.employees);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const employeeData = [
  //   { name: "Nma Okafor", primary_team: "R & D", line_manager: "Israel Oyinlola", parent_team: "Engineering", key_results: "13", pending_krs: "40%(5/13)", last_submitted: "18/01/2021" },
  //   { name: "Jindu Opara", primary_team: "PowerTech", line_manager: "Rolak Osho", parent_team: "", key_results: "9", pending_krs: "80%(7/9)", last_submitted: "20/12/2020" },
  //   { name: "Abdulkadri Bello", primary_team: "Vigipay", line_manager: "John Ayoobola", parent_team: "", key_results: "21", pending_krs: "25%(5/21)", last_submitted: "28/01/2021" },
  //   { name: "Greg Collins", primary_team: "Quality Assurance", line_manager: "Sandra Asamade", parent_team: "Engineering", key_results: "10", pending_krs: "30%(3/10)", last_submitted: "16/02/2021" },
  //   { name: "Yahaya Kareem", primary_team: "GSV", line_manager: "Bimbo Bolu", parent_team: "", key_results: "15", pending_krs: "20%(3/15)", last_submitted: "08/03/2021" },
  // ];

  const teamsList = [
    { id: 1, name: "Adminstration" },
    { id: 2, name: "Advancly" },
    { id: 3, name: "Avitech" },
    { id: 4, name: "Coporate Communication" },
    { id: 5, name: "Customer Success" },
    { id: 6, name: "Engineering" },
    { id: 7, name: "Financial Service" },
    { id: 8, name: "GSV" },
  ];

  const columnHeaders = [
    { id: 1, name: "Name" },
    { id: 2, name: "Primary Team" },
    { id: 3, name: "Line Manager" },
    { id: 4, name: "Parent Team" },
    { id: 5, name: "Key Results" },
    { id: 6, name: "Pending Key Results" },
    { id: 7, name: "Last Submit Date" },
  ];

  const handleActionClick = e => {
    console.log("action click event", e);
  };

  const handleGoBack = () => {
    history.push({
      pathname: Urls.Company,
      state: activeRoutState,
    });
  };

  const handleSelectRow = e => {
    console.log("selected row", e);
  };

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const search = rows => {
    const columns = ["Name", "primary_team", "line_manager", "parent_team", "key_results", "pending_krs", "last_submitted"];
    if (rows && rows.length) {
      return rows.filter(row =>
        columns.some(column => {
          if (column === "Name") {
            let newRows = { ...row[column] };
            return newRows.toString().toLowerCase().indexOf(searchTerm) > -1;
          } else {
            return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
          }
        })
      );
    } else {
      return [];
    }
  };

  const bodyRows = data => {
    return data.map((value, index) => {
      return (
        <tr key={index + 1} className="reports-table">
          <td className="bordered">{value.name ? value.name : "--"}</td>
          <td className="bordered">{value.primary_team ? value.primary_team : "--"}</td>
          <td className="bordered">{value.line_manager ? value.line_manager : "--"}</td>
          <td className="bordered">{value.parent_team ? value.parent_team : "--"}</td>
          <td className="bordered">{value.key_results ? value.key_results : "--"}</td>
          <td className="bordered">{value.pending_krs ? value.pending_krs : "--"}</td>
          <td className="bordered">{value.last_submitted ? value.last_submitted : "--"}</td>
        </tr>
      );
    });
  };
  return (
    <div className="pending-kr-container">
      <div className="row">
        <div className="col-auto">
          <span data-testid="back-icon-container" onClick={handleGoBack} className="back-icon-container">
            {SVGS.backArrow()}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <h1 className="pg-title">Employees with Pending Key Results</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <div className="d-flex insight-info">
            <span>90%</span>&nbsp;
            <span>(324/360)</span>
          </div>
        </div>
      </div>

      <div className="row justify-content-between">
        <div className="col-auto">
          <div className="row">
            <div className="col-auto">
              <div className="search-container">
                <SearchBar placeholder="Search" handleSearch={handleSearch} searchTerm={searchTerm} />
              </div>
            </div>
            <div className="col-auto pl-0">
              <div className="select-container">
                <PMSSelect
                  placeholder="Filter By Team"
                  selectMultiple={true}
                  useSelectAll={true}
                  dataSource={teamsList}
                  onActionButtonClick={handleActionClick}
                  closeOnActionClick
                  displayExpr="name"
                  valueExpr="id"
                  onChange={handleSelectRow}
                  menuWidth="220px"
                  selectAllText="All Teams"
                  useCount
                  value={[]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <Button size="sm" color="blue" handleClick={() => {}} className="pull-right">
            Export
          </Button>
        </div>
      </div>

      <CustomTable arrangedTableData={bodyRows(search(employeeData))} tableHeads={columnHeaders} isLoading={isLoading} tableData={employeeData} searchTableData={search(employeeData)} bordered />
    </div>
  );
};

export default PendingKeyResults;
