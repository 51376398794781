import { UserSettingsActionTypes } from "../../actions/settingsActions/userSettingsActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  isLoading: false,
  allUsers: [],
  allUsersError: null,

  inviteSingleTeamUsersPayload: null,
  isLoadingInviteSingleTeamUsers: false,

  teamsOptions: [],
  isLoadingTeamsOptions: false,
  teamsOptionsError: null,

  statusOptions: [],
  isLoadingStatusOptions: false,
  statusOptionsError: null,

  reviewingUsersEmails: false,
  reviewEmailsPayload: null,

  uploadingUsersEmails: false,
  uploadEmailsPayload: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REQUEST(UserSettingsActionTypes.GET_USERS):
      return {
        ...state,
        isLoading: true,
      };

    case SUCCESS(UserSettingsActionTypes.GET_USERS):
      return {
        ...state,
        isLoading: false,
        allUsers: payload,
      };

    case FAILURE(UserSettingsActionTypes.GET_USERS):
      return {
        ...state,
        isLoading: false,
        allUsersError: payload,
      };

    case REQUEST(UserSettingsActionTypes.GET_USER_STATUS):
      return {
        ...state,
        isLoadingStatusOptions: true,
      };

    case SUCCESS(UserSettingsActionTypes.GET_USER_STATUS):
      return {
        ...state,
        isLoadingStatusOptions: false,
        statusOptions: payload,
      };

    case FAILURE(UserSettingsActionTypes.GET_USER_STATUS):
      return {
        ...state,
        isLoadingStatusOptions: false,
        statusOptionsError: payload,
      };

    case REQUEST(UserSettingsActionTypes.INVITE_SINGLE_TEAM_USERS):
      return {
        ...state,
        isLoadingInviteSingleTeamUsers: true,
      };

    case SUCCESS(UserSettingsActionTypes.INVITE_SINGLE_TEAM_USERS):
      return {
        ...state,
        isLoadingInviteSingleTeamUsers: false,
        inviteSingleTeamUsersPayload: payload?.data,
      };

    case FAILURE(UserSettingsActionTypes.INVITE_SINGLE_TEAM_USERS):
      return {
        ...state,
        isLoadingInviteSingleTeamUsers: false,
        inviteSingleTeamUsersPayload: payload?.data,
      };

    case REQUEST(UserSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: true,
      };

    case SUCCESS(UserSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: false,
        teamsOptions: payload?.data?.data,
      };

    case FAILURE(UserSettingsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: false,
        teamsOptionsError: payload?.data,
      };

    case REQUEST(UserSettingsActionTypes.REVIEW_USERS_EMAILS):
      return {
        ...state,
        reviewingUsersEmails: true,
      };

    case SUCCESS(UserSettingsActionTypes.REVIEW_USERS_EMAILS):
      return {
        ...state,
        reviewingUsersEmails: false,
        reviewEmailsPayload: payload?.data?.data,
      };

    case FAILURE(UserSettingsActionTypes.REVIEW_USERS_EMAILS):
      return {
        ...state,
        reviewingUsersEmails: false,
        reviewEmailsPayload: payload?.data,
      };

    case REQUEST(UserSettingsActionTypes.UPLOAD_USERS_EMAILS):
      return {
        ...state,
        uploadingUsersEmails: true,
      };

    case SUCCESS(UserSettingsActionTypes.UPLOAD_USERS_EMAILS):
      return {
        ...state,
        uploadingUsersEmails: false,
        uploadEmailsPayload: payload?.data || payload,
      };

    case FAILURE(UserSettingsActionTypes.UPLOAD_USERS_EMAILS):
      return {
        ...state,
        uploadingUsersEmails: false,
        uploadEmailsPayload: payload?.data || payload,
      };
    case REQUEST(UserSettingsActionTypes.GET_ONEONONE):
      return { ...state, getOneOnOneLoading: true };
    case SUCCESS(UserSettingsActionTypes.GET_ONEONONE):
      return {
        ...state,
        getOneOnOnePayload: payload,
        getOneOnOneLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.GET_ONEONONE):
      return {
        ...state,
        getOneOnOneError: payload,
        getOneOnOneLoading: false,
      };

    case REQUEST(UserSettingsActionTypes.POST_ONEONONE):
      return { ...state, postOneOnOneLoading: true };
    case SUCCESS(UserSettingsActionTypes.POST_ONEONONE):
      return {
        ...state,
        postOneOnOnePayload: payload,
        postOneOnOneLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.POST_ONEONONE):
      return {
        ...state,
        postOneOnOneError: payload,
        postOneOnOneLoading: false,
      };

    case REQUEST(UserSettingsActionTypes.GET_NOTIFICATION):
      return { ...state, getNotificationLoading: true };
    case SUCCESS(UserSettingsActionTypes.GET_NOTIFICATION):
      return {
        ...state,
        getNotificationPayload: payload,
        getNotificationLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.GET_NOTIFICATION):
      return {
        ...state,
        getNotificationError: payload,
        getNotificationLoading: false,
      };

    case REQUEST(UserSettingsActionTypes.POST_NOTIFICATION):
      return { ...state, postNotificationLoading: true };
    case SUCCESS(UserSettingsActionTypes.POST_NOTIFICATION):
      return {
        ...state,
        postNotificationPayload: payload,
        postNotificationLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.POST_NOTIFICATION):
      return {
        ...state,
        postNotificationError: payload,
        postNotificationLoading: false,
      };
    case REQUEST(UserSettingsActionTypes.POST_REVIEW):
      return { ...state, postReviewLoading: true };
    case SUCCESS(UserSettingsActionTypes.POST_REVIEW):
      return {
        ...state,
        postReviewPayload: payload,
        postReviewLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.POST_REVIEW):
      return {
        ...state,
        postReviewError: payload,
        postReviewLoading: false,
      };
    case REQUEST(UserSettingsActionTypes.GET_REVIEW):
      return { ...state, getReviewLoading: true };
    case SUCCESS(UserSettingsActionTypes.GET_REVIEW):
      return {
        ...state,
        getReviewPayload: payload,
        getReviewLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.GET_REVIEW):
      return {
        ...state,
        getReviewError: payload,
        getReviewLoading: false,
      };

    case REQUEST(UserSettingsActionTypes.DELETE_REVIEW):
      return { ...state, deleteReviewLoading: true };
    case SUCCESS(UserSettingsActionTypes.DELETE_REVIEW):
      return {
        ...state,
        deleteReviewPayload: payload,
        deleteReviewLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.DELETE_REVIEW):
      return {
        ...state,
        deleteReviewError: payload,
        deleteReviewLoading: false,
      };

    case REQUEST(UserSettingsActionTypes.DELETE_ONEONONE):
      return { ...state, deleteOneOnOneLoading: true };
    case SUCCESS(UserSettingsActionTypes.DELETE_ONEONONE):
      return {
        ...state,
        deleteOneOnOnePayload: payload,
        deleteOneOnOneLoading: false,
      };
    case FAILURE(UserSettingsActionTypes.DELETE_ONEONONE):
      return {
        ...state,
        deleteOneOnOneError: payload,
        deleteOneOnOneLoading: false,
      };
    default:
      return { ...state };
  }
};
