import React from "react";
import { PMSModal } from "../../../shared";
import ReportInfo from "./reportInfo";

const ReportModal = ({ openModal, closeModal }) => {
  return (
    <div className="report-modal-container">
      <PMSModal
        title="One-on One: Line Report’s Name"
        openModal={openModal}
        processing={false}
        modalClassWidth="730"
        containerId="user-editor"
        closeModal={closeModal}
        contentClassName=""
        showPrimaryButton={false}
        useHeaderBorder={true}
        closeOnOutsideClick={false}
        useCloseIcon
        primaryButtonText=""
      >
        <ReportInfo />
      </PMSModal>
    </div>
  );
};

export default ReportModal;
