import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Button from "../../shared/button/Button";
import ClaritLogo from "../../../assets/images/clarit-logo.svg";
import { login } from "../../../redux/actions/onboarding/onboarding";
import { Urls } from "../../../utils/urls";

import { setUserDetails, userDetails } from "../../../apiServices/user.service";
import { errorMessage } from "../../../redux/actions/notification/notification";
import "./login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const submit = (event, value) => {
    setSubmitStatus(true);
    dispatch(login(value));
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  const { payload, loading, error } = useSelector(({ onboarding }) => {
    return { error: onboarding?.loginError, payload: onboarding?.loginPayload, loading: onboarding?.loginLoading };
  });

  useEffect(() => {
    if (submitStatus) {
      if (payload?.data?.success && !loading) {
        setUserDetails(payload?.data?.data);
        setSubmitStatus(false);
        history.push(Urls.Home);
      } else if (error?.data?.message && !loading) {
        setSubmitStatus(false);
        dispatch(errorMessage(error?.data?.message));
      }
    }
    if (userDetails() && !submitStatus) {
      history.push(Urls.Home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className="login-form-container">
      <div className="logo">
        <img src={ClaritLogo} alt="logo" />
      </div>
      <div className="login-content">
        <div className="create-account">Login</div>
        <div className="create-account-subtitle">Enter details to proceed</div>

        <AvForm data-testid="submit" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
          <div className="row layout-style">
            <div className="col-12">
              <label htmlFor="email">Email Address</label>
              <AvField
                autoComplete="off"
                type="text"
                name="email"
                className="form-field"
                placeholder="Enter your email address"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter your email address",
                  },
                }}
              />
            </div>
          </div>
          <div className="row layout-style">
            <div className="col-12">
              <label htmlFor="password">Password</label>
              <span className="passwordToggle" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? "show" : "Hide"}
              </span>
              <AvField
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                name="password"
                className="form-field"
                placeholder="Enter your password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter your password",
                  },
                }}
              />
            </div>
          </div>
          <div className="row layout-style">
            <div className="col forgot-password" onClick={() => history.push(Urls.ResetPassword)}>
              Forgot Password?
            </div>
          </div>
          <div className="row layout-style">
            <div className="col-12">
              <Button size="sm" className="btn-goal" loading={loading} color="blue" disabled={!validForm} handleClick={() => form.submit()}>
                Log in
              </Button>
            </div>
          </div>
          <div className="row layout-style">
            <div className="col already ">
              Don’t have an account?{" "}
              <span className="link-text" onClick={() => history.push(Urls.Registration)}>
                Create Account
              </span>
            </div>
          </div>
        </AvForm>
      </div>
    </div>
  );
};

export default Login;
