import React from "react";
import SideModal from "../../../shared/sideModal/sideModal";
import Draft from "../draft/draft";
import GoalForm from "./form/form";

import "./newGoal.scss";

const NewGoal = ({ modalShow, setModalShow, setShowAlignKeyResultModal, setAlignment, alignment, setFormValues, formValues }) => {
  return (
    <div className={formValues?.edit ? "side-modal-edit-content" : "side-modal-edit-content"}>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} formOnly={formValues?.edit} title={`${formValues?.edit ? "Edit Key Result" : "Create a new Key Result"}`}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <GoalForm
                setShowAlignKeyResultModal={setShowAlignKeyResultModal}
                setModalShow={setModalShow}
                setAlignment={setAlignment}
                alignment={alignment}
                setFormValues={setFormValues}
                formValues={formValues}
                modalShow={modalShow}
              />
            </div>
            {!formValues?.edit && (
              <div className="col modal-sub-nav">
                <Draft setFormValues={setFormValues} />
              </div>
            )}
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default NewGoal;
