import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation/lib";
import "./singleTeam.scss";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";

const SingleTeam = ({ handleChange, formData, teamsOptions, loadingTeamsOptions, inEditMode }) => (
  <div className="single-team-container">
    <AvForm className="comment-form">
      <div className={`${inEditMode ? "mt-24" : ""} row mb-10`}>
        <div className="col-12">
          <label htmlFor="teamName" className="form-label">
            Team Name
          </label>
          <AvField
            autoComplete="off"
            type="text"
            name="teamName"
            id="teamName"
            value={formData.teamName}
            placeholder="e.g Engineering"
            onChange={handleChange}
            validate={{
              required: {
                value: true,
                errorMessage: "Please a name for team",
              },
            }}
          />
        </div>
      </div>
      <div className="row mb-24">
        <div className="col-12">
          <label htmlFor="teamOwnerEmail" className="form-label">
            Team Owner
          </label>
          <AvField
            autoComplete="off"
            type="email"
            name="teamOwnerEmail"
            id="teamOwnerEmail"
            value={formData.teamOwnerEmail}
            placeholder="e.g johndoe@xyz.com"
            onChange={handleChange}
            validate={{
              required: {
                value: true,
                errorMessage: "Please a name for team",
              },
              email: {
                value: true,
                errorMessage: "Email not valid",
              },
            }}
          />
        </div>
      </div>
      <div className="row mb-24">
        <div className="col-6" style={{ paddingRight: 7 }}>
          <label htmlFor="teamOwnerManagerEmail" className="form-label">
            Owner Manager (Optional)
          </label>
          <AvField
            autoComplete="off"
            type="email"
            name="teamOwnerManagerEmail"
            id="teamOwnerManagerEmail"
            value={formData.teamOwnerManagerEmail}
            placeholder="e.g johndoe@xyz.com"
            onChange={handleChange}
            validate={{
              email: {
                value: true,
                errorMessage: "Email not valid",
              },
            }}
          />
        </div>
        <div className="col-6" style={{ paddingLeft: 7 }}>
          <label htmlFor="parentTeamId" className="form-label">
            Parent Team (Optional)
          </label>
          <PMSSelect
            placeholder="Select from List"
            dataSource={teamsOptions}
            onOptionSelected={handleChange}
            displayExpr="teamName"
            valueExpr="teamId"
            controlHeight="48px"
            name="parentTeamId"
            id="parentTeamId"
            value={formData.parentTeamId}
            isLoading={loadingTeamsOptions}
          />
        </div>
      </div>
    </AvForm>
  </div>
);

export default SingleTeam;
