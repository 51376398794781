import React, { useEffect, useState } from "react";

import Button from "../../../shared/button/Button";
import UserRoleEditor from "../userRoleEditor/userRoleEditor";
import { PageTitle } from "../../../shared/titlePage/titlePage";

const UserRoleEditorParent = ({ navigateToViewUsers, selectedUser }) => {
  const [selectedRole, setSelectedRole] = useState("lineManager");
  const [formData, setFormData] = useState({});
  const handleBackClick = () => {
    navigateToViewUsers();
  };

  const handleSaveClick = () => {
    console.log(formData);
  };

  const handleInputChange = (evt) => {
    const { value, name } = evt.target;
    setSelectedRole(value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    selectedUser && setFormData({ fullName: selectedUser.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="view-teams-container">
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="row mb-3">
                <div className="col-12" style={{ paddingRight: "0" }}>
                  <PageTitle title="Edit User Role" onClick={handleBackClick} showArrow={true} />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 tableTopBtn-div mb-3">
              <Button size="sm" className="pull-right ml-2" outline handleClick={handleSaveClick}>
                Save Changes
              </Button>
              <Button size="sm" color="normal" className="pull-right" ghost handleClick={handleBackClick}>
                Cancel
              </Button>
            </div>
          </div>

          <UserRoleEditor data-testid="user-role-editor" handleInputChange={handleInputChange} formData={formData} selectedRole={selectedRole} />
        </div>
      </div>
    </div>
  );
};

export default UserRoleEditorParent;
