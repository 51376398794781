import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const lineReportsType = {
  GET_LINE_REPORTS: "GET_LINE_REPORTS",
};

export const getLineReports = createAsyncAction(lineReportsType.GET_LINE_REPORTS, async (managerId) => {
  return await Get(`Team/manager/${managerId}/linereport`);
  // return await Get(`Team/manager/${teamId}/${managerId}/linereport`);
});
