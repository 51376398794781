import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { ProgressBar } from "react-bootstrap";
import _debounce from "lodash.debounce";

import SVGS from "../../../../utils/svgs";

import { PageTitle, PMSSelect, SearchBar, CheckinModal, PMSTooltip, OverviewColors, LoadingTableContent, LineLoader } from "../../../shared";

import "./alignedObjectives.scss";
import TableRow from "./tableRow";
import { LoadKeyResultContributions, LoadObjectiveKeyResults } from "../../../../redux/actions/company";
import { LoadAlignedTeamsObjectives } from "../../../../redux/actions/company/objectiveActions";
import { useDispatch } from "react-redux";
import ObjectiveViewer from "./objectiveViewer/objectiveViewer";

const AlignedObjectives = props => {
  const dispatch = useDispatch();
  const {
    onBackClick,
    objective,
    isLoadingAlignedTeamsObjectives,
    alignedTeamsObjectives,
    alignedTeamsOptions,
    isLoadingAlignedTeamsOptions,
    keyResultContributions,
    isLoadingKeyResultContributions,
    isLoadingObjectiveKeyResults,
    objectiveKeyResults,
    isLoadingObjectiveSummary,
    objectiveSummary,
  } = props;

  const [selectedLevel1, setSelectedLevel1] = useState("");
  const [selectedLevel2, setSelectedLevel2] = useState("");
  const [selectedLevel3, setSelectedLevel3] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [viewDetails, setViewDetails] = useState(false);
  const [viewKeyResult, setViewKeyResult] = useState(false);
  const [showCheckinModal, setShowCheckinModal] = useState(false);
  const [objectiveData, setObjectiveData] = useState(null);
  const [keyResultData, setKeyResultData] = useState(null);

  const handleSelect = evt => {
    setSelectedTeam(evt?.value);
  };

  useEffect(() => {
    dispatch(LoadAlignedTeamsObjectives({ objId: objective?.id, teamId: selectedTeam }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(LoadAlignedTeamsObjectives({ objId: objective?.id, teamId: selectedTeam }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    dispatch(LoadAlignedTeamsObjectives({ objId: objective?.id, teamId: selectedTeam, searchTerm }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearch = _debounce(evt => {
    setSearchTerm(evt.target.value);
  }, 1000);

  const handleCheckinSubmit = formValue => {
    // console.log("form value", formValue);
  };

  const handleView = (evt, data, level) => {
    if (level === 2) {
      setViewKeyResult(true);
      setKeyResultData(data);
      setObjectiveData(null);
      setViewDetails(true);
      dispatch(LoadKeyResultContributions({ krId: data.id }));
    } else if (level === 1) {
      setViewKeyResult(false);
      setKeyResultData(null);
      setObjectiveData(data);
      dispatch(LoadObjectiveKeyResults({ objId: data.id }));
      setViewDetails(true);
    }
  };
  const getProgress = data => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  const actions = [{ id: 1, name: <span className="td-action">View Details</span>, method: handleView }];

  const handleRowClick = (level, levelClass, id, isClosed) => {
    if (!isClosed && level === 1) {
      dispatch(LoadObjectiveKeyResults({ objId: id }));
    } else if (!isClosed && level === 2) {
      dispatch(LoadKeyResultContributions({ krId: id }));
    }

    if (levelClass === "level1") {
      if (id === selectedLevel1) {
        setSelectedLevel1("");
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel1(id);
        setSelectedLevel2("");
        setSelectedLevel3("");
      }
    }
    if (levelClass === "level2") {
      if (id === selectedLevel2) {
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel2(id);
        setSelectedLevel3("");
      }
    }
    if (levelClass === "level3") {
      id === selectedLevel3 ? setSelectedLevel3("") : setSelectedLevel3(id);
    }
  };

  const handleCheckin = () => {
    setShowCheckinModal(true);
  };
  const getTableRow = (value, index, level = 1) => {
    let selectedLevel = "";
    if (level === 1) {
      selectedLevel = selectedLevel1;
    } else if (level === 2) {
      selectedLevel = selectedLevel2;
    } else if (level === 3) {
      selectedLevel = selectedLevel3;
    }
    return (
      <TableRow
        key={index}
        value={value}
        level={level}
        selectedLevel={selectedLevel}
        onClick={handleRowClick}
        getTableRow={getTableRow}
        showActionIcon
        alignedTeamsObjectives={alignedTeamsObjectives?.items}
        keyResultContributions={keyResultContributions?.items}
        objectiveKeyResults={objectiveKeyResults?.items}
        actions={actions}
        handleCheckin={handleCheckin}
        loading={isLoadingKeyResultContributions || isLoadingObjectiveKeyResults}
        isLoadingKeyResultContributions={isLoadingKeyResultContributions}
        isLoadingObjectiveKeyResults={isLoadingObjectiveKeyResults}
      />
    );
  };

  return (
    <div className="row objective-details-container">
      <div className="col">
        <div className="row pg-header">
          <div className="col-4 d-flex flex-md-row align-items-center">
            <PageTitle title={objective && objective.objectives ? objective.objectives : "-"} showArrow onClick={onBackClick} backArrowTitle="Back to Company Objectives" />
          </div>
          <div className="col-8 d-flex flex-column flex-md-row justify-content-end align-items-center">
            <div className="pb-2 p-md-0 header-select">
              <PMSSelect
                placeholder="Teams"
                dataSource={alignedTeamsOptions}
                closeOnActionClick={true}
                displayExpr="teamName"
                valueExpr="teamId"
                onChange={handleSelect}
                isLoading={isLoadingAlignedTeamsOptions}
                // menuWidth="220px"
                value={selectedTeam}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between"></div>
        <div className="row">
          <div className="col-auto">
            <PMSTooltip content={<OverviewColors data={objectiveSummary?.overview} />} direction="bottom" width={247}>
              <div className="label">
                <span className="mr">Overview</span>
                {SVGS.info()}
              </div>
              <div className="label-text">
                <div className="progressBar">{isLoadingObjectiveSummary ? <LineLoader lines={1} /> : <ProgressBar>{getProgress(objectiveSummary?.overview)}</ProgressBar>}</div>
              </div>
            </PMSTooltip>
          </div>

          <div className="col-auto">
            <div className="label">{isLoadingObjectiveSummary ? <LineLoader lines={1} /> : objectiveSummary && objectiveSummary.avgProgress ? `${objectiveSummary.avgProgress}%` : "-"}</div>
            <div className="label-text">Average Progress</div>
          </div>

          <div className="col-auto">
            <div className="label">{isLoadingObjectiveSummary ? <LineLoader lines={1} /> : objectiveSummary && objectiveSummary.noOfObjAligned ? objectiveSummary.noOfObjAligned : "-"} </div>
            <div className="label-text">Team Objectives Aligned</div>
          </div>
        </div>
        <div className="row sub-items">
          <div className="col-auto">
            <div className="search-container">
              <SearchBar placeholder="Search" handleSearch={handleSearch} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Table
              responsive
              style={{
                borderBottom: "1px solid #BAC2CA",
              }}
              borderless
            >
              <thead>
                <tr>
                  <th width="60%">Title</th>
                  <th width="10%">Owner</th>
                  <th width="10%">Last Update</th>
                  <th width="15%">Status</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody className="panel">
                {isLoadingAlignedTeamsObjectives ? (
                  <LoadingTableContent colSpan={5} />
                ) : (
                  <>{alignedTeamsObjectives && alignedTeamsObjectives.items && alignedTeamsObjectives.items.map((team, idx) => getTableRow(team, idx))}</>
                )}
              </tbody>
              {/* <LoadingTableContent colSpan={tableHeads.length} /> */}
            </Table>
            {!isLoadingAlignedTeamsObjectives && alignedTeamsObjectives.items && alignedTeamsObjectives.items.length === 0 && (
              <div className="empty-content">
                {SVGS.documentIcon()}
                <div className="no-goals">You have not added any Team for this Objective</div>
                <div className="add-goal">Go back and Click on the “Add Team” button to get started</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ObjectiveViewer
        showModal={viewDetails}
        setShowModal={setViewDetails}
        viewKeyResult={viewKeyResult}
        objectiveData={objectiveData}
        keyResultData={keyResultData}
        loading={isLoadingKeyResultContributions || isLoadingObjectiveKeyResults}
        keyResultContributions={keyResultContributions?.items}
        objectiveKeyResults={objectiveKeyResults?.items}
      />
      <CheckinModal show={showCheckinModal} setShow={setShowCheckinModal} formTitle="Key Result title" titleIcon={SVGS.teamsIcon()} onSubmit={handleCheckinSubmit} />
    </div>
  );
};

export default AlignedObjectives;
