import { Get, Post } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const activityType = {
  GET_USER_ACTIVITY: "GET_USER_ACTIVITY",
  POST_USER_ACTIVITY: "POST_USER_ACTIVITY",
  UPDATE_USER_ACTIVITY: "UPDATE_USER_ACTIVITY",
  DELETE_USER_ACTIVITY: "DELETE_USER_ACTIVITY",
};

// User Key Results
export const getUserActivity = createAsyncAction(activityType.GET_USER_ACTIVITY, async (id, typeOfId, pageNo, pageSize) => {
  return await Get(`CheckIn/keyresult?${typeOfId}=${id}`);
});

export const postUserCheckin = createAsyncAction(activityType.POST_USER_ACTIVITY, async (keyresultId, payload) => {
  return await Post(`CheckIn/${keyresultId}`, payload);
});
