const CHART_OPTIONS = {
  get: ({ id, xaxisTitle, yaxisTitle, columnWidth, graphTitle }) => ({
    chart: {
      id,
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: "#efefef",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    noData: {
      text: "No Data Available",
    },
    xaxis: {
      title: {
        text: xaxisTitle,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#3A434B",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-title",
        },
      },
      labels: {
        trim: true,
        hideOverlappingLabels: false,
        rotate: 0,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: yaxisTitle || "Number of Session",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#3A434B",
          fontSize: "12px",
          fontFamily: "Inter",
          fontWeight: 500,
          cssClass: "apexcharts-yaxis-title",
        },
      },
    },
    title: {
      text: graphTitle || "Destribution by Team",
      style: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Inter",
        color: "#24292E",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: "Inter",
        zIndex: 100001,
      },
      followCursor: true,
    },
    plotOptions: {
      bar: {
        columnWidth: columnWidth || 16,
        barHeight: "100%",
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
  }),
};

export default CHART_OPTIONS;
