import React, { useState } from "react";
import PMSModal from "../pmsModal/pmsModal";
import AlignKeyResult from "./alignKeyResult";
import "./alignKeyResult.scss";

const AlignKeyResultModal = ({ openModal, closeModal, setAlignment, alignment }) => {
  const [disabled, setDisabled] = useState(true);
  return (
    <div className="align-key-result-container">
      <PMSModal
        title="Align Key Results"
        openModal={openModal}
        handlePrimaryButtonClick={closeModal}
        processing={false}
        modalClassWidth="730"
        primaryButtonText="Save Alignment"
        containerId="user-editor"
        closeModal={closeModal}
        contentClassName=""
        showSecondaryButton={true}
        useHeaderBorder={false}
        disablePrimaryButton={disabled}
        closeOnOutsideClick={false}
      >
        <AlignKeyResult setDisabled={setDisabled} setAlignment={setAlignment} alignment={alignment} />
      </PMSModal>
    </div>
  );
};

export default AlignKeyResultModal;
