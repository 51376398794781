import { teamInsightsType } from "../../actions/teamsActions/teamInsightsActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  oneOnOneLoading: false,
  okrLoading: false,
  teamOneOnOneInsights: [],
  teamOneOnOneInsightsMock: {
    message: "success",
    loggedSessionCount: 7,
    unloggedSessionCount: 17,
    data: [
      {
        firstName: "dada",
        lastName: "aku",
        email: "dadaku@gmail.com",
        jobTitle: "Software Eng",
        status: "logged",
        lineManager: "Josh",
        parentTeam: "Rnd",
      },
      {
        firstName: "henry",
        lastName: "nwosu",
        email: "henry@gmail.com",
        jobTitle: "Designer",
        status: "unlogged",
        lineManager: "kemi",
        parentTeam: "Rnd",
      },
    ],
  },
  teamOkrInsights: [],
  teamOkrInsightsMock: [
    {
      id: 3,
      firstName: "dada",
      lastName: "aku",
      email: "dadaku@gmail.com",
      jobTitle: "Software Eng",
      status: "logged",
      lineManager: "Josh",
      lastUpdated: "15/03/2021",
      contributing_krs: "(1/2)",
      not_started_krs: "(1/1)",
      keyresults: [
        {
          id: 1,
          teamObjId: 32,
          keyresult: "Sign up two new business in Q1",
          completed: false,
          progress: 35,
          lastUpdatedDate: "15/03/2021",
          dueDate: "18/03/2021",
          reviewCycleId: 65,
          objKeyresultType: "Team",
        },
        {
          id: 1,
          teamObjId: 32,
          keyresult: "Sign up two new business in Q1",
          completed: false,
          progress: 35,
          lastUpdatedDate: "15/03/2021",
          dueDate: "18/03/2021",
          reviewCycleId: 65,
          objKeyresultType: "Team",
        },
      ],
    },
    {
      id: 8,
      firstName: "henry",
      lastName: "nwosu",
      email: "henry@gmail.com",
      jobTitle: "Designer",
      status: "unlogged",
      lineManager: "kemi",
      lastUpdated: "15/03/2021",
      contributing_krs: "(1/2)",
      not_started_krs: "(1/1)",
      keyresults: [[], []],
    },
  ],
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(teamInsightsType.GET_ONE_ON_ONE_INSIGHTS):
      return {
        ...state,
        oneOnOneLoading: true,
      };

    case SUCCESS(teamInsightsType.GET_ONE_ON_ONE_INSIGHTS):
      return {
        ...state,
        oneOnOneLoading: false,
        teamOneOnOneInsights: actions.payload,
      };

    case FAILURE(teamInsightsType.GET_ONE_ON_ONE_INSIGHTS):
      return {
        ...state,
        teamOneOnOneError: actions.payload,
        oneOnOneLoading: false,
      };

    case REQUEST(teamInsightsType.GET_OKR_INSIGHTS):
      return {
        ...state,
        okrLoading: true,
      };

    case SUCCESS(teamInsightsType.GET_OKR_INSIGHTS):
      return {
        ...state,
        okrLoading: false,
        teamOkrInsights: actions.payload,
      };

    case FAILURE(teamInsightsType.GET_OKR_INSIGHTS):
      return {
        ...state,
        teamOkrError: actions.payload,
        okrLoading: false,
      };

    default:
      return { ...state };
  }
};
