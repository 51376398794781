import React, { useEffect } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Datepicker } from "../../../../shared";

import "./customTimelineForm.scss";

const CustomTimelineForm = ({ validForm, formData, handleChange, resetFormData }) => {
  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      validForm(true);
    } else {
      validForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, validForm]);

  useEffect(() => {
    return () => {
      resetFormData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom-timeline-form-container">
      <AvForm className="goal-form-content mt-24">
        <div className="row layout-style">
          <div className="col-12 mb-24">
            <label htmlFor="startDate">Start Date</label>
            <Datepicker id="startDate" name="startDate" mode="single" dateFormat="Y-m-d" value={formData.startDate} onChange={handleChange} />
          </div>
        </div>

        <div className="row layout-style">
          <div className="col-12 mb-32">
            <label htmlFor="endDate">End Date</label>
            <Datepicker id="endDate" name="endDate" mode="single" dateFormat="Y-m-d" value={formData.endDate} onChange={handleChange} />
          </div>
        </div>
      </AvForm>
    </div>
  );
};

export default CustomTimelineForm;
