import React from "react";
import { useSelector } from "react-redux";
import _uniqueId from "lodash/uniqueId";
import SVGS from "../../../utils/svgs";
import ArrowDown from "../../../assets/images/arrow-down-tiny.svg";
import ArrowRight from "../../../assets/images/arrow-right-tiny.svg";
import LocationIcon from "../../../assets/images/location.svg";
import { TableActionDropdown, PMSTooltip, UserTooltipDetail } from "../../shared";
import { ProgressBar } from "react-bootstrap";
import { getPercentColor } from "../../../utils/helpers";
import { ContributionsIcon, TeamsIcon } from "../../shared/svgIcons/svgIcons";

const TeamObjectiveRows = (props) => {
  const userId = JSON.parse(localStorage.getItem("user_details"))?.userId;
  const { actions, allTeamObjectives, value, level, selectObj, selectedLevel, tableRow, getContributionsData, getKeyResultsData } = props;
  const select = useSelector((state) => {
    return {
      // level 1
      levelTwoLoading: state.keyResults && state.keyResults.keyResultByObjectiveLoading,
      keyResultsByObjectives: state.keyResults && state.keyResults.keyResultByObjectivePayload?.data?.data?.items,
      keyResultsContributions: state.keyResults && state.keyResults.teamContributionsPayload?.data?.data?.items,
      contributionsLoading: state.keyResults && state.keyResults.contributionsLoading,
    };
  });

  const { keyResultsByObjectives, keyResultsContributions } = select;

  return (
    <>
      <tr key={_uniqueId(`teamObjRow-`)}>
        <td
          className={`level${level}`}
          onClick={() => {
            if (level === 1 && !(selectedLevel === value.id)) {
              getKeyResultsData(value?.id);
            } else if (level === 2 && !(selectedLevel === value.id)) {
              getContributionsData(value.id);
            }
            selectObj(`level${level}`, value?.id);
          }}
          style={{ cursor: "pointer" }}
        >
          {level !== 3 && (selectedLevel === value?.id ? <img src={ArrowDown} className="arrow-up" alt="arrow right icon" /> : <img src={ArrowRight} className="arrow-down" alt="arrow down icon" />)}

          <span className="accordionLabel">
            {(value?.objectives && value.objectives) || (value?.description && value.description)}
            <span className="team-icon">{level !== 3 ? <TeamsIcon /> : <ContributionsIcon />}</span>
          </span>
          {level !== 3 && (
            <div className="accordionLabelSubtext">
              {level === 1 && (
                <>
                  <span style={{ fontWeight: "bold" }}>Company / </span>
                  {/* <span style={{ fontWeight: "bold" }}>{value?.parentTeam} / </span> */}
                  <span>{value?.parentObjective?.description}</span>
                </>
              )}
              {level === 2 && (
                <>
                  <span>This key result has {value?.numberOfContributions} contributions</span>
                </>
              )}
            </div>
          )}
        </td>
        <td className="td-avatar">
          {level === 1 ? (
            <PMSTooltip content={<UserTooltipDetail name={`${value?.team?.teamOwner}`} title={value?.team?.jobTitle} />} direction="left" width={171}>
              <span>{value?.team?.image ? <img className="mr-3 table-avatar" src={value?.team?.image} alt="owner name" /> : SVGS.avatar()}</span>
            </PMSTooltip>
          ) : (
            <PMSTooltip content={<UserTooltipDetail name={`${value?.owner?.firstName} ${value?.owner?.lastName}`} title={value?.owner?.jobDescription} />} direction="left" width={171}>
              <span>{value?.owner?.image ? <img className="mr-3 table-avatar" src={value?.owner?.image} alt="owner name" /> : SVGS.avatar()}</span>
            </PMSTooltip>
          )}
        </td>
        <td>{value?.lastUpdatedDate}</td>
        <td>
          <div className="approved">
            <ProgressBar data-testid="progress-bar" now={value?.progress} variant={getPercentColor(value?.progress)} />
            <span className="percent-count">{value?.progress}%</span>
            {value?.completed && <img src={LocationIcon} alt="location" />}
          </div>
        </td>
        <td>
          {level === 1 && value?.team.teamOwnerId === userId ? (
            actions?.length && <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} level={level} />
          ) : level !== 1 && value?.owner.staffId === userId ? (
            actions?.length && <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} level={level} />
          ) : (
            <TableActionDropdown data-testid="table-action-dropdown" actions={[actions[0]]} data={value} level={level} />
          )}
        </td>
      </tr>
      {/* select level 1 to reveal level 2 */}
      {selectedLevel === value?.id &&
        allTeamObjectives?.items?.length &&
        level === 1 &&
        keyResultsByObjectives &&
        keyResultsByObjectives?.map((level1, index) => {
          const newLevel = level + 1;
          return tableRow(level1, index, newLevel);
        })}

      {/* select level 2 to reveal contributions level */}
      {/* different endpoint for contributions level */}
      {selectedLevel === value?.id &&
        allTeamObjectives?.items?.length &&
        level === 2 &&
        keyResultsContributions &&
        keyResultsContributions?.map((level1, index) => {
          const newLevel = level + 1;
          return tableRow(level1, index, newLevel);
        })}
    </>
  );
};

export default TeamObjectiveRows;
