import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation/lib";
import "./memberEditor.scss";
import PMSModal from "../../../../shared/pmsModal/pmsModal";
import PMSCheckbox from "../../../../shared/pmsCheckbox/pmsCheckbox";

const MemberEditor = ({
  openModal,
  closeModal,
  processing,

  data,
  onSubmit,
  onInputChange,
}) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    console.log(emptyValidation);
    setValidForm(emptyValidation);
  };

  const submitForm = () => {
    console.log("submitting...");
  };

  useEffect(() => {
    openModal ? handleShow() : handleClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <PMSModal
      title={`${data ? "Edit" : "Add"} Team Member to Administration`}
      openModal={show}
      handlePrimaryButtonClick={onSubmit}
      processing={processing}
      primaryButtonText={data ? "Save Changes" : "Add Members"}
      disablePrimaryButton={!validForm}
      showSecondaryButton
      containerId="member-editor"
      closeModal={closeModal}
      contentClassName="member-editor-content"
      useCloseIcon
    >
      <AvForm className="mt-24" onValidSubmit={submitForm} ref={c => setForm(c)} onKeyUp={checkForm}>
        <div className="row mb-11">
          <div className="col-12">
            <label htmlFor="emails" className="form-label">
              Email Address
            </label>
            <AvField
              type="textarea"
              rows={4}
              name="emails"
              placeholder="Enter emails seperated by space or commas"
              onChange={onInputChange}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter at least one email address",
                },
              }}
            />
          </div>
        </div>
        <div className="row mb-30">
          <div className="col-auto">
            <PMSCheckbox label="Invite users not already on PMS" labelClassName="checkbox-label" onChange={onInputChange} name="sendInvite" />
          </div>
        </div>
      </AvForm>
    </PMSModal>
  );
};

export default MemberEditor;
