import { Post, Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";

export const onboardingType = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  STEP_ACTIVITY: "STEP_ACTIVITY",
  RESET_PASSWORD: "RESET_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  NEW_ACCOUNT: "NEW_ACCOUNT",
  GET_INVITE_USER: "GET_INVITE_USER",
};

export const signup = createAsyncAction(onboardingType.SIGNUP, async (data) => {
  return await Post(`account/register`, data);
});

export const activateInviteUser = createAsyncAction(onboardingType.SIGNUP, async (data) => {
  return await Post(`account/invite/activate`, data);
});

export const getInviteUser = createAsyncAction(onboardingType.GET_INVITE_USER, async (id) => {
  return await Get(`account/invite?inviteId=${id}`);
});
export const login = createAsyncAction(onboardingType.LOGIN, async (data) => {
  return await Post(`account/authenticate`, data);
});

export const resetPassword = createAsyncAction(onboardingType.RESET_PASSWORD, async (data) => {
  return await Post(`account/sso/resetPassword`, data);
});

export const forgotPassword = createAsyncAction(onboardingType.FORGOT_PASSWORD, async (data) => {
  return await Post(`account/forgotPassword`, data);
});
export const newAccount = createAction(onboardingType.NEW_ACCOUNT, (data) => {
  return data;
});
