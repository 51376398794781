import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Button from "../../../shared/button/Button";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import LinkIcon from "../../../../assets/images/link.svg";
import CloseIcon from "../../../../assets/images/close.svg";

const EditUserKeyResultForm = ({ setShowAlignKeyResultModal, setModalShow, setAlignment, alignment, updateTeamKeyResults, details, cycleList, selectedCycle }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [reviewCycle, setReviewCycle] = useState(selectedCycle);
  const [validForm, setValidForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const select = useSelector(({ keyResults }) => {
    return {
      payload: keyResults && keyResults.updateKeyResultPayload,
      isLoading: keyResults && keyResults.updateKeyResultLoading,
      errorResponse: keyResults && keyResults.updateKeyResultError,
    };
  });

  const { payload, isLoading } = select;

  // reset form if edit is successful
  useEffect(() => {
    if (isSubmitted && payload) {
      form.reset();
      setModalShow(false);
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // handle submit, removing event below will erase value
  const submit = (event, value) => {
    let formValue = {
      keyResultId: details.keyResultId,
      dueDate: value.dueDate,
      targetGoal: value.targetGoal,
      startGoal: value.startGoal,
      progressType: value.tracking,
      reviewCycleId: reviewCycle,
      isCompleted: false,
      progress: details.progress,
      description: value.keyresult,
    };

    if (alignment) {
      formValue = {
        ...formValue,
        parentTeamKeyResultId: alignment.id,
      };
    }

    setIsSubmitted(true);
    dispatch(updateTeamKeyResults(formValue));
  };

  // check form validity
  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  return (
    <AvForm className="goal-form-content" data-testid="submit" model={details} onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="description">What is your keyresult?</label>
          <AvField
            autoComplete="off"
            type="text"
            name="description"
            className="form-field"
            // placeholder="Enter your objective here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter keyresults",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="reviewCycle">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            id="reviewCycle"
            dataSource={cycleList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="description"
            valueExpr="cycleId"
            onChange={(e) => setReviewCycle(e)}
            controlHeight="48px"
            value={reviewCycle}
          />
        </div>
        <div className="col-6">
          <label htmlFor="dueDate">Due Date</label>
          <AvField
            autoComplete="off"
            type="date"
            name="dueDate"
            className="form-field"
            placeholder="Select date"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter due date",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="owner">Owner</label>
          <AvField
            autoComplete="off"
            type="text"
            className="form-field"
            name="owner"
            value={`${details?.owner?.firstName} ${details?.owner?.lastName}`}
            disabled={true}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter the owner",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="type">Type</label>
          <AvField
            autoComplete="off"
            type="text"
            name="objectiveKeyresultType"
            className="form-field"
            placeholder="Enter the type"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter type",
              },
            }}
          />
        </div>
      </div>
      <div>
        <div className="alignment">Alignment</div>
        <div>
          {alignment && (
            <>
              <div className="alignment-title">
                {alignment.Keyresult}
                <img src={CloseIcon} alt="close icon" onClick={() => setAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> {alignment.Team.TeamName}
              </div>
            </>
          )}
        </div>
        {!alignment && (
          <div
            className="align-goal"
            onClick={() => {
              form.submit();
              setModalShow(false);
              setShowAlignKeyResultModal(true);
            }}
          >
            Add an Alignment
          </div>
        )}
      </div>

      <div>
        <div className="progress-track">Progress Tracking</div>
        <AvRadioGroup inline className="mb-0" data-testid="progressTrack" name="tracking" label="" value={details?.tracking?.type}>
          <AvRadio label="Percentage (%)" value="PERCENTAGE" className="mb-0" />
          <AvRadio label="Key Performance Index (KPI)" value="KPI" className="mb-0" />
        </AvRadioGroup>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="startGoal">Start</label>
          <AvField
            autoComplete="off"
            type="number"
            name="startGoal"
            className="form-field"
            placeholder="Enter your start"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your start",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="targetGoal">Goal</label>
          <AvField
            autoComplete="off"
            type="number"
            className="form-field"
            name="targetGoal"
            placeholder="Enter your goal"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your goal",
              },
            }}
          />
        </div>
      </div>

      <hr className="border-btm" />
      <div className="row btn-goal-content">
        <div className="col-6">
          <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={() => setModalShow(false)}>
            Cancel
          </Button>
        </div>
        <div className="col-6">
          <Button size="sm" disabled={reviewCycle === "" || !validForm} color="blue" className="btn-goal" handleClick={() => form.submit()}>
            Save Changes
          </Button>
        </div>
      </div>
    </AvForm>
  );
};

export default EditUserKeyResultForm;
