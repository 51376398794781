import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const orgCyclesType = {
  GET_ORG_CYCLES: "GET_ORG_CYCLES",
  GET_ONE_ON_ONE_CYCLES: "GET_ONE_ON_ONE_CYCLES",
};

export const getOrganisationCycles = createAsyncAction(orgCyclesType.GET_ORG_CYCLES, async () => {
  return await Get(`ReviewCycle/all`);
});

export const getOneOnOneCycles = createAsyncAction(orgCyclesType.GET_ONE_ON_ONE_CYCLES, async () => {
  return await Get(`Admin/oneononecycle`);
});
