import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const teamInsightsType = {
  GET_ONE_ON_ONE_INSIGHTS: "GET_ONE_ON_ONE_INSIGHTS",
  GET_LOGGED_ONE_ON_ONE_INSIGHTS: "GET_LOGGED_ONE_ON_ONE_INSIGHTS",
  GET_OKR_INSIGHTS: "GET_OKR_INSIGHTS",
};

// Team Insights
export const getOneOnOneInsights = createAsyncAction(teamInsightsType.GET_ONE_ON_ONE_INSIGHTS, async (teamId, cycleId, pageNo, pageSize, status) => {
  let endpoint = "";
  if (status !== undefined) {
    endpoint = await Get(`OneOnOne/report?onOnOnecycleId=${cycleId}&isLogged=${status}&teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  } else {
    endpoint = await Get(`OneOnOne/report?onOnOnecycleId=${cycleId}&teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  }
  return endpoint;
});

export const getOneOnOneInsightsWithDate = createAsyncAction(teamInsightsType.GET_ONE_ON_ONE_INSIGHTS, async (teamId, startDate, endDate, pageNo, pageSize, searchTerm) => {
  let endpoint = "";
  if (searchTerm === undefined) {
    endpoint = await Get(`OneOnOne/report?teamId=${teamId}&from=${startDate}&to=${endDate}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  } else {
    endpoint = await Get(`OneOnOne/report?teamId=${teamId}&from=${startDate}&to=${endDate}&searchTerm=${searchTerm}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  }
  return endpoint;
});

export const getOkrInsights = createAsyncAction(teamInsightsType.GET_OKR_INSIGHTS, async (cycleId, teamId, pageNo, pageSize, status) => {
  let endpoint = "";
  if (status !== undefined) {
    endpoint = await Get(`Admin/insights/okrreport/people?isLogged=${status}&cycleId=${cycleId}&teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  } else {
    endpoint = await Get(`Admin/insights/okrreport/people?cycleId=${cycleId}&teamId=${teamId}&pageNumber=${pageNo}&pageSize=${pageSize}`);
  }
  return endpoint;
});
