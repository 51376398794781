import React, { useState } from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import AddKeyResult from "./addKeyResult";
// import "./alignKeyResult.scss";

const AddKeyResultModal = ({ addKeyResultValue, setAddKeyResultValue, openModal, closeModal }) => {
  const [submitForm, setSubmitForm] = useState(null);
  const [form, setForm] = useState(null);
  const validForm = (event) => {
    if (event !== undefined) {
      setForm(event);
    }
  };

  const handleClick = () => {
    setSubmitForm(true);
    closeModal();
  };

  return (
    <div className="align-key-result-container">
      <PMSModal
        title="Add Key Results"
        openModal={openModal}
        handlePrimaryButtonClick={handleClick}
        processing={false}
        modalClassWidth="480"
        primaryButtonText="Add Key Result"
        containerId="user-editor"
        closeModal={closeModal}
        contentClassName=""
        showSecondaryButton={true}
        useHeaderBorder={false}
        disablePrimaryButton={!form}
        closeOnOutsideClick={false}
      >
        <AddKeyResult addKeyResultValue={addKeyResultValue} setAddKeyResultValue={setAddKeyResultValue} submitForm={submitForm} setSubmitForm={setSubmitForm} validForm={(e) => validForm(e)} />
      </PMSModal>
    </div>
  );
};

export default AddKeyResultModal;
