// helper functions
export const addCommaToArrayOfStrings = values => {
  let arrayOfNames = [];
  if (values) {
    values.some(val => {
      let fullName = `${val.firstName} ${val.lastName}`;
      arrayOfNames.push(fullName);
      return fullName;
    });
  }

  let convertToString = arrayOfNames.join();
  let seperatedValues = convertToString.replace(",", ", ");
  return seperatedValues || "-";
};

export const getPercentColor = percent => {
  let color = "";
  if (percent >= 70) {
    return "success";
  } else if (percent < 70 && percent > 50) {
    return "info";
  } else if (percent <= 50 && percent >= 40) {
    return "warning";
  } else if (percent < 40) {
    return "danger";
  }
  return color;
};

export const createDropdownList = (dataset, id, identifier) => {
  let newList = [];
  dataset &&
    dataset.forEach(data => {
      let filteredData = {
        [id]: data[id],
        [identifier]: data[identifier],
      };
      newList.push(filteredData);
    });
  return newList;
};
