import { insightsActionTypes } from "../../actions/company";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";
import DATA from "../../../components/company/data";

const INITIAL_STATE = {
  isLoadingTeamsSessions: false,
  teamsSessions: DATA.teamDestributionByTimeLine.data,
  teamsSessionsError: null,

  isLoadingTeamsSessionsCustomTimeline: false,
  teamsSessionsCustomTimeline: DATA.teamDestributionByCustomTimeLine.data,
  teamsSessionsCustomTimelineError: null,

  isLoadingOneOnOneStaff: false,
  oneOnOneStaff: DATA.oneOnOneStaff.data,
  oneOnOneStaffError: null,

  teamsOptions: DATA.companyTeams,
  isLoadingTeamsOptions: false,
  teamsOptionsError: null,

  timelineOptions: DATA.timelineOptions.data,
  isLoadingTimelineOptions: false,
  timelineOptionsError: null,

  okrTeams: DATA.okrTeams.data,
  isLoadingOkrTeams: false,
  okrTeamsError: null,

  okrPeople: DATA.okrPeople.data,
  isLoadingOkrPeople: false,
  okrPeopleError: null,

  okrCycleOptions: DATA.okrCycleOptions.data,
  isLoadingOkrCycleOptions: false,
  okrCycleOptionsError: null,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(insightsActionTypes.GET_TEAMS_SESSIONS):
      return {
        ...state,
        isLoadingTeamsSessions: true,
      };
    case SUCCESS(insightsActionTypes.GET_TEAMS_SESSIONS):
      return {
        ...state,
        isLoadingTeamsSessions: false,
        teamsSessions: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_TEAMS_SESSIONS):
      return {
        ...state,
        teamsSessionsError: actions.payload,
        isLoadingTeamsSessions: false,
      };

    case REQUEST(insightsActionTypes.GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE):
      return {
        ...state,
        isLoadingTeamsSessionsCustomTimeline: true,
      };
    case SUCCESS(insightsActionTypes.GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE):
      return {
        ...state,
        isLoadingTeamsSessionsCustomTimeline: false,
        teamsSessionsCustomTimeline: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_TEAMS_SESSIONS_BY_CUSTOM_TIMELINE):
      return {
        ...state,
        teamsSessionsCustomTimelineError: actions.payload,
        isLoadingTeamsSessionsCustomTimeline: false,
      };

    case REQUEST(insightsActionTypes.GET_ONE_ON_ONE_STAFF):
      return {
        ...state,
        isLoadingOneOnOneStaff: true,
      };
    case SUCCESS(insightsActionTypes.GET_ONE_ON_ONE_STAFF):
      return {
        ...state,
        isLoadingOneOnOneStaff: false,
        oneOnOneStaff: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_ONE_ON_ONE_STAFF):
      return {
        ...state,
        oneOnOneStaffError: actions.payload,
        isLoadingOneOnOneStaff: false,
      };

    case REQUEST(insightsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: true,
      };
    case SUCCESS(insightsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingTeamsOptions: false,
        teamsOptions: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_TEAMS_OPTIONS):
      return {
        ...state,
        teamsOptionsError: actions.payload,
        isLoadingTeamsOptions: false,
      };

    case REQUEST(insightsActionTypes.GET_OKR_TEAMS):
      return {
        ...state,
        isLoadingOkrTeams: true,
      };
    case SUCCESS(insightsActionTypes.GET_OKR_TEAMS):
      return {
        ...state,
        isLoadingOkrTeams: false,
        okrTeams: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_OKR_TEAMS):
      return {
        ...state,
        okrTeamsError: actions.payload,
        isLoadingOkrTeams: false,
      };

    case REQUEST(insightsActionTypes.GET_OKR_PEOPLE):
      return {
        ...state,
        isLoadingOkrPeople: true,
      };
    case SUCCESS(insightsActionTypes.GET_OKR_PEOPLE):
      return {
        ...state,
        isLoadingOkrPeople: false,
        okrPeople: actions.payload.data,
      };

    case FAILURE(insightsActionTypes.GET_OKR_PEOPLE):
      return {
        ...state,
        okrPeopleError: actions.payload,
        isLoadingOkrPeople: false,
      };

    default:
      return { ...state };
  }
};
