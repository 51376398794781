import React from "react";
// import XLSX from "xlsx";

import ImportExcel from "../../../shared/importExcel/importExcel";
import "./multipleTeams.scss";

const MultipleTeams = props => {
  const { onFileUpload } = props;
  const excelTemplate = [{ "Team Name": "", "Parent Team": "", "Team Owner": "", "Team Owner Manager": "", "Members": "" }];

  const handleFileUpload = data => {
    if (onFileUpload) {
      onFileUpload(data);
    }
  };

  // const handleDownload = () => {};

  // const handleInputChange = () => {
  //   console.log("input change");
  // };

  return (
    <div className="multiple-teams-container" data-testid="multiple-teams">
      <ImportExcel onFileUploadChanged={handleFileUpload} excelTemplate={excelTemplate} templateName="clarit-teams-template" />
    </div>
  );
};

export default MultipleTeams;
