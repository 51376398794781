import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import { resetPassword } from "../../../redux/actions/onboarding/onboarding";

import { changeOfPassword } from "../../../redux/actions/profile/profileActions";
import "./changePassword.scss";

const ChangePassword = ({ submitForm, validForm }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const select = useSelector(({ profile }) => {
    return {
      changePasswordPayload: profile && profile.changePasswordPayload,
      changePasswordError: profile && profile.changePasswordError,
      changePasswordLoading: profile && profile.changePasswordLoading,
    };
  });
  console.log({ select });
  // const { changePasswordError, changePasswordLoading, changePasswordPayload } = select;
  useEffect(() => {
    if (submitForm) {
      form && form.submit && form.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  useEffect(() => typeof validForm === "function" && validForm(form && form.valid && form.valid()));

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    // console.log({ emptyValidation });
    typeof validForm === "function" && validForm(emptyValidation);
  };
  const submit = (event, value) => {
    console.log({ value });

    dispatch(changeOfPassword(value));
  };
  return (
    <AvForm className="change-password-container" onKeyUp={checkForm} data-testid="submit" onInv onValidSubmit={submit} ref={(c) => setForm(c)}>
      <div className="row" onClick={checkForm}>
        <div className="col-12">
          <label htmlFor="fullName">Current Password</label>
          <span className="show-password" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? "Hide" : "Show"}
          </span>

          <AvField
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            name="currentPassword"
            placeholder="Enter Current Password"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter Current Password",
              },
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <label htmlFor="email">New Password</label>
          <AvField
            autoComplete="off"
            type="password"
            name="newPassword"
            placeholder="Enter New Password"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter New Password",
              },
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 confirm-password">
          <label htmlFor="email">Confirm New Password</label>
          <AvField
            autoComplete="off"
            type="password"
            name="confirmPassword"
            placeholder="Enter Confirm New Password"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter Confirm New Password",
              },
              match: { value: "newPassword", errorMessage: "The new password and its confirmation do not match" },
            }}
          />
        </div>
      </div>
    </AvForm>
  );
};

export default ChangePassword;
