import React, { useState } from "react";
import { useSelector } from "react-redux";

import ViewContent from "../shared/viewContent/viewContent";
import Goal from "./goals/goals";
import OneOnOne from "./oneOnOne/oneOnOne";
import WelcomeMessage from "../auth/welcomeMessage/welcomeMessage";
import { userDetails } from "../../apiServices/user.service";

import "./home.scss";

const Home = () => {
  const { newAccount } = useSelector(({ onboarding }) => {
    return { newAccount: onboarding?.newAccount };
  });
  const tabDetails = [
    { id: 1, key: "Goals", title: "Key Results", content: <Goal /> },
    { id: 2, key: "One-on-One", title: "One-on-One", content: <OneOnOne /> },
  ];
  const [key, setKey] = useState("Goals");
  console.log({ userDetails: userDetails() });
  return (
    <section className="row home-container">
      <div className="col">
        <div className="name">Hi {userDetails()?.firstName}</div>
        <div className="sub-title">
          {userDetails()?.teamName || "default"}
          <span className="divider">|</span>Line Manager: {userDetails()?.manager}
        </div>
        <div className="card-body px-0">
          <ViewContent activeKey={key} tabDetails={tabDetails} handleSelect={(k) => setKey(k)} />
        </div>
      </div>
      {newAccount && <WelcomeMessage />}
    </section>
  );
};

export default Home;
