import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { convertFileSize } from "../../../utils/convertFileSize";
import XLSX from "xlsx";
import ExportExcel from "../exportExcel/exportExcel";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 4,
  borderColor: "#d7dce0",
  borderStyle: "solid",
  backgroundColor: "#f4f5f6",
  boxSizing: "border-box",
  height: 124,
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#1354D3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ImportExcel = ({ className, disabled, excelTemplate, onFileUploadChanged, templateName, readFileBeforeUpload }) => {
  const [fileInfo, setFileInfo] = useState(undefined);
  const [transformedData, setTransformedData] = useState(undefined);
  let dropzoneRef;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length) {
      return;
    }
    if (!readFileBeforeUpload) {
      const fd = new FormData();
      fd.append("sheet", acceptedFiles[0], acceptedFiles[0].name);

      setTransformedData(fd);
      setFileInfo({ name: acceptedFiles[0].name, size: acceptedFiles[0].size });
      return;
    }

    acceptedFiles.forEach(file => {
      setFileInfo({ name: file.name, size: file.size });
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        /* Update state */
        console.log(data);
        setTransformedData(data);
      };
      rABS ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = () => {
    setFileInfo(undefined);
    setTransformedData(undefined);
  };

  const handleOpen = e => {
    e.preventDefault();
    dropzoneRef.open();
  };

  useEffect(() => {
    if (onFileUploadChanged) {
      onFileUploadChanged(transformedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedData]);

  useEffect(() => {
    return () => {
      setFileInfo(undefined);
      setTransformedData(undefined);
    };
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <Dropzone
            data-testid="drop-input"
            multiple={false}
            className={`dropzone ${className}`}
            activeClassName="dropzone-active"
            onDrop={onDrop}
            ref={node => {
              dropzoneRef = node;
            }}
            noClick
            disabled={disabled}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
              <div {...getRootProps({ style: { ...baseStyle, ...(isDragActive ? activeStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {}) } })}>
                <div className="row w-100 align-items-center">
                  <div className="col-1 pl-0 pr-1">
                    <i className="ri-folder-fill"></i>
                  </div>
                  <div className="col-11 pl-2">
                    {fileInfo ? (
                      <>
                        <div className="d-flex mb-0 align-items-center justify-content-between font-weight-bold">
                          <p style={{ color: "#261B64" }} className="mb-0">
                            {fileInfo.name}
                          </p>
                          <span>
                            <i className="ri-checkbox-circle-fill"></i>
                          </span>
                        </div>
                        <div className="d-flex mb-0 align-items-center justify-content-between">
                          <p className="mb-0" style={{ color: "#3A434B" }}>
                            {convertFileSize(fileInfo.size)}
                          </p>
                          <span>
                            <a href="#/" onClick={handleRemove}>
                              Remove
                            </a>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="d-flex mb-0 align-items-center font-weight-bold" style={{ color: "#261B64" }}>
                          Upload Excel File
                        </p>
                        <p className="d-flex mb-0 align-items-center">
                          <span style={{ color: "#3A434B" }}>Drop your file here or</span>
                          &nbsp;
                          <a href="#/" onClick={handleOpen}>
                            browse
                          </a>
                          <input {...getInputProps()} />
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <p className="d-flex mb-0 align-items-center">
            <span style={{ color: "#3A434B" }}>Don’t have the recommended template?</span>
            &nbsp;
            <ExportExcel labelText="Download" excelData={excelTemplate} fileName={templateName} />
          </p>
        </div>
      </div>
    </>
  );
};

ImportExcel.propTypes = {
  /**
   * Passed into the component to create custom style to the dropzone
   */
  className: PropTypes.string,
  /**
   * A json/object of the template the use will be downloading
   */
  excelTemplate: PropTypes.arrayOf(PropTypes.object),
  /**
   * Used to enable/disable dropzone
   */
  disabled: PropTypes.bool,
  /**
   * Function that is called when a file is uploaded or removed
   */
  onFileUploadChanged: PropTypes.func.isRequired,
  /**
   * The name you wish the user to see when the template is downloaded
   */
  templateName: PropTypes.string,
};

ImportExcel.defaultProps = {
  disabled: false,
  templateName: "pms-template",
  className: "",
};

export default ImportExcel;
