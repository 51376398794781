import { activityType } from "../../actions/userActions/userActivityActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  activityLoading: false,
  userActivity: [],
  checkinError: false,
  checkinSuccess: false,
  userActivityMock: [
    {
      reviewCycleId: 3,
      keyresultId: 2,
      initialGoal: 0,
      currentProgress: 4,
      targetGoal: 10,
      comment: "I took 2 courses",
      action: "Daisy Nwoke made a check-in to",
      destination: "Design Advancly Operational Dashboard",
      timeline: "2 days",
      attachmentId: 8,
    },
    {
      reviewCycleId: 3,
      keyresultId: 2,
      initialGoal: 0,
      currentProgress: 4,
      targetGoal: 10,
      comment: "I took 5 courses",
      action: "Daisy Nwoke made a check-in to",
      destination: "Design Advancly Operational Dashboard",
      timeline: "7 days",
      attachmentId: 8,
    },
    {
      reviewCycleId: 3,
      keyresultId: 2,
      initialGoal: 0,
      currentProgress: 4,
      targetGoal: 10,
      comment: "I completed the migration",
      action: "Daisy Nwoke made a check-in to",
      destination: "Design Advancly Operational Dashboard 3",
      timeline: "7 days",
      attachmentId: 8,
    },
  ],
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(activityType.GET_USER_ACTIVITY):
      return {
        ...state,
        activityLoading: true,
      };

    case SUCCESS(activityType.GET_USER_ACTIVITY):
      return {
        ...state,
        activityLoading: false,
        userActivity: actions.payload,
      };

    case FAILURE(activityType.GET_USER_ACTIVITY):
      return {
        ...state,
        userActivityError: actions.payload,
        activityLoading: false,
      };

    case REQUEST(activityType.POST_USER_ACTIVITY):
      return {
        ...state,
        activityLoading: true,
      };

    case SUCCESS(activityType.POST_USER_ACTIVITY):
      return {
        ...state,
        activityLoading: false,
        checkinSuccess: true,
      };

    case FAILURE(activityType.POST_USER_ACTIVITY):
      return {
        ...state,
        activityLoading: false,
        checkinError: actions.payload,
      };

    default:
      return { ...state };
  }
};
