import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

import "./datepicker.scss";

const Datepicker = props => {
  const { className, duration, id, mode, dateFormat, maxDate, name, onChange, minDate, defaultDate, height, value, disabled } = props;
  const handleChange = (_, e) => {
    onChange({ target: { name, value: e } });
  };

  return (
    <span className={className}>
      <span className="duration">{duration}</span>
      <Flatpickr
        className={`flatpicker-wrapper h-${height || 48}`}
        id={id}
        name={name}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        options={{
          mode: mode,
          dateFormat: dateFormat,
          maxDate: maxDate,
          minDate: minDate,

          defaultDate: defaultDate,
          monthSelectorType: "static",
        }}
      />
      <span style={{ marginLeft: "-29px", cursor: "pointer" }} className="input-button" title="toggle" data-toggle>
        <i className="icon-calendar">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2ZM18 8V4H15V6H13V4H7V6H5V4H2V8H18ZM18 10H2V18H18V10ZM4 12H9V16H4V12Z"
              fill="#3A434B"
            />
          </svg>
        </i>
      </span>
    </span>
  );
};

export default Datepicker;
