import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";

import "./form.scss";

const CheckinForm = ({ validForm, formTitle, titleIcon, onValidSubmit, checkinError }) => {
  const [form, setForm] = useState([]);
  const submit = (event, value) => {
    console.log({ value });
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    validForm(emptyValidation);
  };

  return (
    <AvForm data-testid="checkin-" className="checkin-form" onValidSubmit={submit} ref={(c) => setForm(c)} onChange={checkForm}>
      <div className="checkin-title layout-style">
        <span className="icon-container">{typeof titleIcon === "string" ? <img src={titleIcon} alt="personal icon" /> : titleIcon}</span>
        {formTitle}
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="currentProgress">Progress</label>
          <AvField
            autoComplete="off"
            type="number"
            name="currentProgress"
            className="form-field"
            placeholder="Enter your progress"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your progress",
              },
            }}
          />
        </div>
        <div className="col-6">
          <label htmlFor="targetGoal">Goal</label>
          <AvField
            autoComplete="off"
            type="number"
            name="targetGoal"
            className="form-field"
            placeholder="Enter your goal here"
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter goal",
              },
            }}
          />
        </div>
      </div>
      <div className="additional-info layout-style">Additional information</div>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="comment">Comments (Optional)</label>
          <AvField
            autoComplete="off"
            type="textarea"
            name="comment"
            className="textarea-field"
            placeholder="Enter your comment"
            validate={{
              required: {
                value: false,
                errorMessage: "Please enter your comment",
              },
            }}
          />
        </div>
      </div>
      <div className="align-goal layout-style">Add an Attachment</div>
    </AvForm>
  );
};

export default CheckinForm;
