import React from "react";
import Shimmer from "react-shimmer-effect";
import "./shimmerTableRowLoader.scss";

/**
 *
 * @param {rows, columnWidths} props
 * @rows: Specify number of rows
 * @columnWidths: Specify the number of columns for the shimmer to use by providing a list of widths in percentage
 */
const ShimmerTableRowLoader = props => {
  const { rows, columnWidths, isChild, isGrandChild } = props;
  const rowList = [];
  for (let i = 1; i <= (rows || 5); i++) {
    rowList.push(i);
  }

  return (
    <>
      {rowList.map(row => (
        <tr key={row} className={`cl-margin-padding ${isChild ? "child-row" : ""} ${isGrandChild ? "grand-child-row" : ""}`}>
          {columnWidths.map((columnWidth, idx) => (
            <td width={`${columnWidth}%`} key={`${columnWidth}${idx}`} style={{ display: "table-cell" }}>
              <Shimmer>
                <div className="shimmer-line" data-testid="td-line-loader" />
              </Shimmer>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default ShimmerTableRowLoader;
