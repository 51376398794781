import React from "react";

const SearchBar = props => {
  const { placeholder, searchTerm, handleSearch, disabled, ...rest } = props;

  return (
    <div className="sp-pt--32 search-bar">
      <div className="input">
        <label htmlFor="searchBar" className="sr-only">
          Search Bar
        </label>
        <input data-testid="searchBar" aria-label="Search Bar" className="input__text" type="text" placeholder={placeholder} value={searchTerm} onChange={handleSearch} disabled={disabled} {...rest} />
        <button className="input__btn" style={{ lineHeight: "1" }}>
          <i className="ri-search-line"></i>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
