import { Delete, Get, Post } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const teamObjectivesType = {
  GET_TEAM_OBJECTIVES: "GET_TEAM_OBJECTIVES",
  GET_TEAM_OBJECTIVES_OVERVIEW: "GET_TEAM_OBJECTIVES_OVERVIEW",
  SAVE_OBJECTIVES_DRAFTS: "SAVE_OBJECTIVES_DRAFTS",
  GET_OBJECTIVES_DRAFTS: "GET_OBJECTIVES_DRAFTS",
  GET_KEY_RESULT_BY_ALIGNMENT: "GET_KEY_RESULT_BY_ALIGNMENT",
  DELETE_OBJECTIVE: "DELETE_OBJECTIVE",
  POST_TEAM_OBJECTIVE: "POST_TEAM_OBJECTIVE",
  UPDATE_TEAM_OBJECTIVE: "UPDATE_TEAM_OBJECTIVE",
};

export const getTeamObjectivesByTeamId = createAsyncAction(teamObjectivesType.GET_TEAM_OBJECTIVES, async (teamId, cycleId) => {
  return await Get(`Team/${teamId}/objectives?cycleId=${cycleId}`);
});

export const getTeamProgress = createAsyncAction(teamObjectivesType.GET_TEAM_OBJECTIVES_OVERVIEW, async (teamId, cycleId) => {
  return await Get(`team/${teamId}/${cycleId}/objectives_overview`);
});

export const getAlignedKeyResults = createAsyncAction(teamObjectivesType.GET_KEY_RESULT_BY_ALIGNMENT, async (id) => {
  return await Get(`team/keyresult/${id}/alignment`);
});

export const saveObjectivesDrafts = (payload) => {
  return { type: teamObjectivesType.SAVE_OBJECTIVES_DRAFTS, payload: payload };
};

export const deleteObjective = createAsyncAction(teamObjectivesType.DELETE_OBJECTIVE, async (teamId) => {
  return await Delete(`Team/objective/${teamId}`);
});

export const postTeamObjective = createAsyncAction(teamObjectivesType.POST_TEAM_OBJECTIVE, async (payload) => {
  return await Post(`Team/objective`, payload);
});

export const updateTeamObjective = createAsyncAction(teamObjectivesType.UPDATE_TEAM_OBJECTIVE, async (payload) => {
  return await Post(`Objective/team`, payload);
});
