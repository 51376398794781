import React from "react";
import CheckedBox from "../../../assets/images/checked-box.svg";
import ClaritLogo from "../../../assets/images/clarit-logo.svg";
import Form from "./form/form";
import "./registration.scss";

const Registration = () => {
  return (
    <div className="container-fluid registration-container">
      <div className="row">
        <div className="col-auto details-content">
          <div className="logo">
            <img src={ClaritLogo} alt="logo" />
          </div>
          <div className="title">Champion growth across your organisation.</div>
          <div className="sub-title">Create an account to start your 14-day free trial. No strings attached.</div>
          <div className="checked-item">
            <img src={CheckedBox} alt="checked" />
            Align Objectives across the entire company
          </div>
          <div className="checked-item">
            <img src={CheckedBox} alt="checked" />
            Keep track off your one-on-one meetings{" "}
          </div>
          <div className="checked-item">
            <img src={CheckedBox} alt="checked" />
            Monitor and measure performance company-wide And so much more...
          </div>
        </div>
        <div className="col">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Registration;
