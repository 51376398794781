import React from "react";
import SideModal from "../sideModal/sideModal";
import EditObjectiveForm from "./editObjectiveForm";

const EditObjectiveModal = ({
  modalShow,
  setModalShow,
  setShowAlignKeyResultModal,
  setAlignment,
  alignment,
  setFormValues,
  formValues,
  details,
  selectedCycle,
  cycleList,
  updateTeamObjective,
  modalLevel,
  addKeyResultValue,
  setAddKeyResultValue,
  setShowAddKeyResultModal,
}) => {
  return (
    <div>
      <SideModal modalShow={modalShow} setModalShow={setModalShow} title="Edit Objective" formOnly={true}>
        <div className="container sidebar-content">
          <div className="row">
            <div className="col form-width">
              <EditObjectiveForm
                setShowAlignKeyResultModal={setShowAlignKeyResultModal}
                setModalShow={setModalShow}
                details={details}
                setAlignment={setAlignment}
                alignment={alignment}
                setFormValues={setFormValues}
                formValues={formValues}
                selectedCycle={selectedCycle}
                cycleList={cycleList}
                updateTeamObjective={updateTeamObjective}
                modalLevel={modalLevel}
                addKeyResultValue={addKeyResultValue}
                setAddKeyResultValue={setAddKeyResultValue}
                setShowAddKeyResultModal={setShowAddKeyResultModal}
              />
            </div>
          </div>
        </div>
      </SideModal>
    </div>
  );
};
export default EditObjectiveModal;
