import React, { Fragment } from "react";
import { components } from "react-select";
import "./spOption.scss";

const SpOption = props => (
  <Fragment>
    <components.Option {...props}>
      <div className="w-100 d-flex align-items-center" style={{ height: 40 }}>
        {props.selectProps.isMulti && (
          <span>{props.isSelected ? <i className="ri-checkbox-fill" style={{ fontSize: 18 }}></i> : <i className="ri-checkbox-blank-line" style={{ fontSize: 18 }}></i>}</span>
        )}{" "}
        <span style={{ margin: "0 10px 3px 10px" }}>{props.children}</span>
      </div>
    </components.Option>
  </Fragment>
);

export default SpOption;
