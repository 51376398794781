import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./viewContent.scss";

/**
 *
 * @param {handleSelect, key, tabDetails} props
 * @handleSelect returns tab event
 * @key:
 * @tabDetails:
 */

const ViewContent = (props) => {
  const { tabDetails, activeKey, handleSelect } = props;

  return (
    <Tabs id="controlled-tab-settings" activeKey={activeKey} onSelect={handleSelect} className="px-0">
      {tabDetails.map((tabDetail) => {
        return (
          <Tab key={tabDetail.id} eventKey={tabDetail.key} title={tabDetail.title}>
            {tabDetail.content}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default ViewContent;
