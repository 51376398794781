import { teamsType } from "../../actions/teamsActions/teamActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  teamsLoading: false,
  allTeams: [],
  allTeamsMock: {
    success: true,
    message: "string",
    errors: ["string"],
    data: [
      {
        organisationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamId: "1",
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamName: "R&D",
        teamOwner: "isreal oyinlola",
        teamOwnerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamType: "SBU",
        parentTeamId: "string",
        parentTeam: "string",
        membersCount: 19,
      },
      {
        organisationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamId: "2",
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamName: "Devops",
        teamOwner: "isreal oyinlola",
        teamOwnerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamType: "SBU",
        parentTeam: "string",
        parentTeamId: "string",
        membersCount: 19,
      },
      {
        organisationId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamId: "3",
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamName: "HNI",
        teamOwner: "isreal oyinlola",
        teamOwnerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        teamType: "SBU",
        parentTeam: "string",
        parentTeamId: "string",
        membersCount: 7,
      },
    ],
  },
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(teamsType.GET_ORG_TEAMS):
      return {
        ...state,
        teamsLoading: true,
      };

    case SUCCESS(teamsType.GET_ORG_TEAMS):
      return {
        ...state,
        teamsLoading: false,
        allTeams: actions.payload,
      };

    case FAILURE(teamsType.GET_ORG_TEAMS):
      return {
        ...state,
        allTeamsError: actions.payload,
        teamsLoading: false,
      };

    default:
      return { ...state };
  }
};
