import React from "react";

export default {
  settings: (menu) => {
    return (
      <span className="icon">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.55595 2.03C3.24044 1.41685 4.04441 0.951993 4.91728 0.664665C5.16687 0.977348 5.48373 1.22975 5.8443 1.40311C6.20487 1.57647 6.59987 1.66633 6.99995 1.666C7.40003 1.66633 7.79504 1.57647 8.15561 1.40311C8.51618 1.22975 8.83303 0.977348 9.08262 0.664665C9.95549 0.951993 10.7595 1.41685 11.444 2.03C11.2983 2.4023 11.2384 2.80262 11.2686 3.20126C11.2988 3.59989 11.4185 3.9866 11.6186 4.33267C11.8183 4.67939 12.0937 4.97663 12.4241 5.20228C12.7546 5.42793 13.1316 5.57617 13.5273 5.636C13.7143 6.53527 13.7143 7.4634 13.5273 8.36267C12.754 8.48133 12.0406 8.936 11.6186 9.666C11.4184 10.0122 11.2987 10.399 11.2684 10.7977C11.2382 11.1965 11.2982 11.5969 11.444 11.9693C10.7594 12.5822 9.95544 13.0469 9.08262 13.334C8.83297 13.0214 8.51608 12.7692 8.15552 12.5959C7.79495 12.4227 7.39998 12.3329 6.99995 12.3333C6.59987 12.333 6.20487 12.4229 5.8443 12.5962C5.48373 12.7696 5.16687 13.022 4.91728 13.3347C4.04446 13.0475 3.2405 12.5829 2.55595 11.97C2.70172 11.5976 2.76171 11.1972 2.73147 10.7984C2.70123 10.3996 2.58154 10.0128 2.38128 9.66667C2.18148 9.32006 1.90612 9.02295 1.57567 8.79742C1.24523 8.5719 0.868201 8.42375 0.472618 8.364C0.285494 7.46452 0.285494 6.53615 0.472618 5.63667C0.868262 5.57684 1.24533 5.42859 1.57578 5.20295C1.90623 4.9773 2.18155 4.68006 2.38128 4.33333C2.58144 3.98726 2.70107 3.60056 2.73131 3.20193C2.76155 2.80329 2.70161 2.40296 2.55595 2.03067V2.03ZM7.99995 8.73133C8.22924 8.60097 8.43048 8.42654 8.59209 8.21808C8.75369 8.00962 8.87246 7.77126 8.94157 7.51672C9.01068 7.26217 9.02876 6.99647 8.99476 6.73491C8.96076 6.47335 8.87536 6.2211 8.74348 5.99268C8.6116 5.76426 8.43583 5.56418 8.22631 5.40396C8.01679 5.24374 7.77764 5.12655 7.52264 5.05913C7.26765 4.99172 7.00183 4.9754 6.7405 5.01114C6.47917 5.04687 6.22749 5.13394 5.99995 5.26733C5.54434 5.53443 5.21287 5.9709 5.07788 6.4815C4.94288 6.99209 5.01533 7.53535 5.2794 7.99272C5.54348 8.4501 5.97774 8.78445 6.48742 8.92283C6.99711 9.06121 7.54084 8.99237 7.99995 8.73133Z"
            fill={menu === "settings" ? "#1354D3" : "#0E347E"}
          />
        </svg>
      </span>
    );
  },
  dashboard: (menu) => {
    return (
      <span className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 13.3334C13.3333 13.5102 13.2631 13.6797 13.1381 13.8048C13.013 13.9298 12.8435 14 12.6667 14H3.33333C3.15652 14 2.98695 13.9298 2.86193 13.8048C2.7369 13.6797 2.66666 13.5102 2.66666 13.3334V7.33336H0.666664L7.55133 1.07469C7.67407 0.963013 7.83405 0.901123 8 0.901123C8.16594 0.901123 8.32592 0.963013 8.44866 1.07469L15.3333 7.33336H13.3333V13.3334ZM7.33333 8.66669V12.6667H8.66666V8.66669H7.33333Z"
            fill={menu === "" ? "#0E347E" : "#677684"}
          />
        </svg>
      </span>
    );
  },
  company: (menu) => (
    <span className="icon">
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.0013 12.3333H15.3346V13.6667H0.667969V12.3333H2.0013V0.999999C2.0013 0.823188 2.07154 0.653618 2.19656 0.528594C2.32159 0.40357 2.49116 0.333332 2.66797 0.333332H13.3346C13.5114 0.333332 13.681 0.40357 13.806 0.528594C13.9311 0.653618 14.0013 0.823188 14.0013 0.999999V12.3333ZM5.33464 6.33333V7.66667H7.33463V6.33333H5.33464ZM5.33464 3.66667V5H7.33463V3.66667H5.33464ZM5.33464 9V10.3333H7.33463V9H5.33464ZM8.66797 9V10.3333H10.668V9H8.66797ZM8.66797 6.33333V7.66667H10.668V6.33333H8.66797ZM8.66797 3.66667V5H10.668V3.66667H8.66797Z"
          fill={menu === "company" ? "#1354D3" : "#677684"}
        />
      </svg>
    </span>
  ),
  teams: (menu) => (
    <span className="icon">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.00065 6.33334C7.88471 6.33334 8.73255 6.68453 9.35767 7.30965C9.98279 7.93477 10.334 8.78261 10.334 9.66667V13.6667H3.66732V9.66667C3.66732 8.78261 4.01851 7.93477 4.64363 7.30965C5.26875 6.68453 6.1166 6.33334 7.00065 6.33334ZM2.52598 8.33734C2.41989 8.69519 2.35722 9.06451 2.33932 9.43734L2.33398 9.66667V13.6667H0.333984V10.6667C0.333853 10.0917 0.546003 9.53696 0.929741 9.10881C1.31348 8.68066 1.84178 8.40926 2.41332 8.34667L2.52665 8.33734H2.52598ZM11.4753 8.33734C12.0686 8.3735 12.6257 8.63464 13.0331 9.06749C13.4404 9.50035 13.6672 10.0723 13.6673 10.6667V13.6667H11.6673V9.66667C11.6673 9.20467 11.6007 8.75867 11.4753 8.33734ZM2.66732 4.33334C3.10935 4.33334 3.53327 4.50893 3.84583 4.82149C4.15839 5.13405 4.33398 5.55798 4.33398 6C4.33398 6.44203 4.15839 6.86595 3.84583 7.17851C3.53327 7.49107 3.10935 7.66667 2.66732 7.66667C2.22529 7.66667 1.80137 7.49107 1.48881 7.17851C1.17625 6.86595 1.00065 6.44203 1.00065 6C1.00065 5.55798 1.17625 5.13405 1.48881 4.82149C1.80137 4.50893 2.22529 4.33334 2.66732 4.33334ZM11.334 4.33334C11.776 4.33334 12.1999 4.50893 12.5125 4.82149C12.8251 5.13405 13.0007 5.55798 13.0007 6C13.0007 6.44203 12.8251 6.86595 12.5125 7.17851C12.1999 7.49107 11.776 7.66667 11.334 7.66667C10.892 7.66667 10.468 7.49107 10.1555 7.17851C9.84291 6.86595 9.66732 6.44203 9.66732 6C9.66732 5.55798 9.84291 5.13405 10.1555 4.82149C10.468 4.50893 10.892 4.33334 11.334 4.33334ZM7.00065 0.333336C7.7079 0.333336 8.38617 0.614287 8.88627 1.11438C9.38637 1.61448 9.66732 2.29276 9.66732 3C9.66732 3.70725 9.38637 4.38552 8.88627 4.88562C8.38617 5.38572 7.7079 5.66667 7.00065 5.66667C6.29341 5.66667 5.61513 5.38572 5.11503 4.88562C4.61494 4.38552 4.33398 3.70725 4.33398 3C4.33398 2.29276 4.61494 1.61448 5.11503 1.11438C5.61513 0.614287 6.29341 0.333336 7.00065 0.333336Z"
          fill={menu === "teams" ? "#1354D3" : "#677684"}
        />
      </svg>
    </span>
  ),
};
