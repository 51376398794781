import React from "react";
import { ProgressBar } from "react-bootstrap";

import SVGS from "../../../../utils/svgs";

import { PMSTooltip, UserTooltipDetail, LoadingTableContent } from "../../../shared";

import { getPercentColor } from "../../../../utils/helpers";
import "./alignedObjectives.scss";
import { uniqueId } from "lodash";
import { Link } from "react-router-dom";

const TableRow = ({
  value,
  level = 1,
  selectedLevel,
  onClick,
  getTableRow,
  alignedTeamsObjectives,
  keyResultContributions,
  objectiveKeyResults,
  actions,
  handleCheckin,
  isLoadingKeyResultContributions,
  isLoadingObjectiveKeyResults,
  showActionIcon,
}) => {
  return (
    <>
      <tr key={uniqueId(`level${level}_row_`)}>
        <td onClick={() => onClick(level, `level${level}`, value.id, selectedLevel === value.id)} className={`level${level} d-flex`}>
          <span className="arrow-contain">{level !== 3 && (selectedLevel === value.id ? SVGS.arrowDown({ small: true }) : SVGS.arrowRight())}</span>
          <div>
            <span className="td-title">
              {value.hasOwnProperty("keyresult") ? value.keyresult || value.description : value.objectives}
              {}
              <span className="team-icon">{SVGS.teamsIcon()}</span>
            </span>
            <div className="td-subtitle">
              {value.hasOwnProperty("keyresult") ? (value.hasOwnProperty("contributionCount") ? `This Key result has ${value.contributionCount} contributions` : value.contributors) : value.teamName}
            </div>
          </div>
        </td>
        <td className="td-avatar">
          <PMSTooltip
            content={<UserTooltipDetail name={value.owner && `${value.owner.firstName} ${value.owner.lastName}`} title={value.owner && value.owner.jobDescription} />}
            direction="left"
            width={171}
          >
            <span>{value.owner?.image ? <img className="mr-3 table-avatar" src={value.owner?.image} alt={value.owner?.ownerName || "owner"} /> : SVGS.avatar()}</span>
          </PMSTooltip>
        </td>
        <td>{level === 1 ? (value.lastUpdated ? value.lastUpdated : "-") : value.lastUpdatedDate ? value.lastUpdatedDate : "-"}</td>
        <td>
          <div className="d-flex align-items-center">
            <span className="mr-3">
              <ProgressBar now={value.progress} variant={getPercentColor(value.progress)} />
            </span>
            <span className="mr-3">{value.progress}%</span>
            {value.completed && (
              <span className="mr-3 check-in" onClick={handleCheckin} title="Check-in">
                <i className="ri-map-pin-line"></i>
              </span>
            )}
          </div>
        </td>
        {showActionIcon && (
          <td>
            <div className="dropdown d-flex justify-content-center">
              <span id="dropdownMenuButton" data-toggle="dropdown" className="dd-icon" aria-haspopup="true" aria-expanded="false" title="Actions">
                <i className="ri-more-2-fill"></i>
              </span>
              <div className="dropdown-menu action-dropdown py-0" aria-labelledby="dropdownMenuButton">
                {actions.map(action => (
                  <Link className="dropdown-item d-flex align-items-center pl-3" key={action.id} to="#" onClick={e => action.method(e, value, level)}>
                    {action.name}
                  </Link>
                ))}
              </div>
            </div>
          </td>
        )}
      </tr>
      {selectedLevel === value.id && alignedTeamsObjectives.length && level === 1 ? (
        isLoadingObjectiveKeyResults ? (
          <LoadingTableContent colSpan={5} rows={3} isChild />
        ) : objectiveKeyResults && objectiveKeyResults.length ? (
          objectiveKeyResults.map((level1, index) => {
            const newLevel = level + 1;
            return getTableRow(level1, index, newLevel);
          })
        ) : (
          <tr>
            <td colSpan={4} className="no-data">
              No Key Result for this objective
            </td>
          </tr>
        )
      ) : null}
      {selectedLevel === value.id && alignedTeamsObjectives.length && level === 2 ? (
        isLoadingKeyResultContributions ? (
          <LoadingTableContent colSpan={5} rows={3} isGrandChild />
        ) : keyResultContributions && keyResultContributions.length ? (
          keyResultContributions.map((level1, index) => {
            const newLevel = level + 1;
            return getTableRow(level1, index, newLevel);
          })
        ) : (
          <tr>
            <td colSpan={5} className="no-data">
              No Contribution for this Key Result
            </td>
          </tr>
        )
      ) : null}
    </>
  );
};

export default TableRow;
