export const ObjectivesActionTypes = {
  FETCHING_OBJECTIVES: "FETCHING_OBJECTIVES",
  FETCHING_OBJECTIVES_SUCCESS: "FETCHING_OBJECTIVES_SUCCESS",
  FETCHING_OBJECTIVES_ERROR: "FETCHING_OBJECTIVES_ERROR",
  SAVE_OBJECTIVES_DRAFTS: "SAVE_OBJECTIVES_DRAFTS",
  FETCH_OBJECTIVES_DRAFTS: "FETCH_OBJECTIVES_DRAFTS",
};

export const fetchObjectives = (payload) => ({
  type: ObjectivesActionTypes.FETCHING_OBJECTIVES,
  payload: payload,
});

export const fetchObjectivesSuccess = (payload) => ({
  type: ObjectivesActionTypes.FETCHING_OBJECTIVES_SUCCESS,
  payload: payload,
});

export const fetchObjectivesError = (payload) => ({
  type: ObjectivesActionTypes.FETCHING_OBJECTIVES_ERROR,
  payload: payload,
});

export const fetchObjectivesDrafts = (payload) => ({
  type: ObjectivesActionTypes.FETCH_OBJECTIVES_DRAFTS,
  payload: payload,
});

export const saveObjectivesDrafts = (payload) => ({
  type: ObjectivesActionTypes.SAVE_OBJECTIVES_DRAFTS,
  payload: payload,
});
