import React from "react";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import PersonalIcon from "../../../../../../assets/images/personal-icon.svg";
import CompanyIcon from "../../../../../../assets/images/company.svg";
import AvartaIcon from "../../../../../../assets/images/avatar.svg";
import ArrowRight from "../../../../../../assets/images/arrow-greater-than-sign.svg";
import getPercentColor from "../../../../../../utils/progress-color";
import Lozenge from "../../../../../shared/lozenges/lozenge";
import { LineLoader } from "../../../../../shared";

import "./details.scss";

const ObjectivesDetails = ({ onViewKeyResult, objectiveKeyResults, loading, objectiveData }) => {
  return (
    <div className="goal-view-content view-details-container">
      <div className="goal-title">{objectiveData && objectiveData.objectives ? objectiveData.objectives : "-"}</div>
      <div className="personal">
        <img src={PersonalIcon} alt="personal icon" />
        {objectiveData && objectiveData.organisation ? objectiveData.organisation.organisationName : "-"}
      </div>
      <div className="approved-full flex-and-space-between">
        <ProgressBar now={objectiveData?.progress} variant={getPercentColor(objectiveData?.progress)} data-testid="progress-bar" />

        <span className="d-flex align-items-center">
          <span className="percent-count">{objectiveData && typeof objectiveData.progress === "number" ? objectiveData.progress : "-"}%</span>
          {/* <TableActionDropdown data-testid="table-action-dropdown" actions={actions} /> */}
        </span>
      </div>
      <div className="alignment-content">
        <div className="alignment">Alignment</div>
        <div className="features">Quality People, Quality Delivery</div>
        <div className="role">
          <img src={CompanyIcon} alt="role" /> Company
        </div>
      </div>

      <div className="keyresults-content">
        <div className="kr-section-title">Key Results</div>

        {loading ? (
          <>
            <div className="loader-content w-100 mb-3">
              <div className="w-75">
                <LineLoader />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
              </div>
            </div>
            <div className="loader-content w-100">
              <div className="w-75">
                <LineLoader />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
                <div className="w-25">
                  <LineLoader className="w-25" />
                </div>
              </div>
            </div>
          </>
        ) : objectiveKeyResults && objectiveKeyResults.length ? (
          objectiveKeyResults.map(kr => (
            <div className="key-result-content d-flex justify-content-between align-items-center">
              <div>
                <div className="key-result-title">{kr.description ? kr.description : "-"}</div>
                <div className="key-result-date">Due Date: {kr.dueDate ? moment(new Date(kr?.dueDate), "DD/MM/YYYY").format("L") : "-"}</div>
              </div>
              <div>
                <Lozenge variant={getPercentColor(kr?.progress)} style={{ marginRight: "24px" }} content={`${kr?.progress}`} />
                <span onClick={() => onViewKeyResult(kr)} className="navigate-icon">
                  <img src={ArrowRight} className="arrow-down" alt="arrow right icon" />
                </span>
              </div>
            </div>
          ))
        ) : (
          <span className="no-data">No Key Result for this objective</span>
        )}
      </div>

      <div className="owner-content">
        <div className="owner">Owner</div>
        <div className="name">
          {objectiveData && objectiveData.owner ? (
            <>
              <img src={objectiveData.owner.image ? objectiveData.owner.image : AvartaIcon} alt="Profile" /> {`${objectiveData?.owner?.firstName} ${objectiveData?.owner?.lastName}`}
            </>
          ) : (
            <>
              <img src={AvartaIcon} alt="avatar" /> "- -"
            </>
          )}
        </div>
      </div>
      <div className="date-content">
        <div className="date-item">
          <div className="due-date">Due Date</div>
          <div className="date">{moment(new Date(objectiveData?.dueDate), "DD/MM/YYYY").format("L")}</div>
        </div>
        <div className="date-item">
          <div className="due-date">Last Update</div>
          <div className="date">{moment(new Date(objectiveData?.lastUpdated), "DD/MM/YYYY").format("L")}</div>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesDetails;
