import React from "react";
import Chart from "react-apexcharts";
import { MoonLoader } from "react-spinners";
import CHART_OPTIONS from "../../../../utils/chartOptions";
import { PMSSelect } from "../../../shared";

const ChartSection = ({ selectedReport, customTimelineValue, filterChartOptions, handleFilterChart, filterChartValue, teamsSessionsCustomTimeline, isLoadingTeamsSessions, teamsSessions }) => {
  return (
    selectedReport !== "okr" && (
      <div className="row">
        <div className="col-12">
          {isLoadingTeamsSessions ? (
            <div className="loader-contain  mb-2">
              <MoonLoader size={60} />
            </div>
          ) : (
            <div className="chart-container">
              {customTimelineValue && selectedReport === "oneonone" ? (
                <>
                  <div className="chart-filter">
                    <PMSSelect dataSource={filterChartOptions} closeOnActionClick={true} displayExpr="description" valueExpr="id" onChange={handleFilterChart} value={filterChartValue} />
                  </div>
                  <Chart
                    options={CHART_OPTIONS.get({ id: "multi-line-chart", xaxisTitle: "Time Periods", columnWidth: 8 })}
                    series={teamsSessionsCustomTimeline.distribution}
                    type="line"
                    width="100%"
                    height="300"
                  />
                </>
              ) : (
                <>{teamsSessions && <Chart options={CHART_OPTIONS.get({ id: "bar-chart", xaxisTitle: "Teams" })} series={teamsSessions.destribution} type="bar" width="100%" height="300" />}</>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ChartSection;
