import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import CheckinForm from "./form/form";
import "./checkinModal.scss";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import { postUserCheckin } from "../../../../redux/actions/userActions/userActivityActions";

const CheckinModal = ({ modalShow, setModalShow, setShowAlignKeyResultModal, onSubmit, details }) => {
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [checkInData, setCheckInData] = useState(null);
  const [form, setForm] = useState(null);
  const validForm = (event) => {
    if (event !== undefined) {
      setForm(event);
    }
  };

  useEffect(() => {
    if (checkInData) {
      dispatch(postUserCheckin(details?.id, checkInData));
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData]);

  // useEffect(() => {
  //   if (isSubmitted && success) {
  //     form.reset();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading]);

  return (
    <div className="checkin-modal-content">
      <PMSModal
        title="Check-In"
        openModal={modalShow}
        handlePrimaryButtonClick={() => {
          setSubmitForm(new Date());
          setIsSubmitted(true);
        }}
        processing={false}
        primaryButtonText="Check-In"
        containerId="user-editor"
        closeModal={() => setModalShow(false)}
        contentClassName=""
        showSecondaryButton={true}
        useHeaderBorder={false}
        disablePrimaryButton={!form}
        closeOnOutsideClick={false}
      >
        <CheckinForm
          setShowAlignKeyResultModal={setShowAlignKeyResultModal}
          setModalShow={setModalShow}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
          validForm={(e) => validForm(e)}
          details={details}
          setCheckInData={setCheckInData}
          isSubmitted={isSubmitted}
        />
      </PMSModal>
    </div>
  );
};
export default CheckinModal;
