import { orgCyclesType } from "../../actions/cyclesActions/cyclesActions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  orgCyclesLoading: false,
  teamMembers: [],
  cycleList: [],
  oneOnOneCycleList: [],
  oneOnOneCyclesLoading: false,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(orgCyclesType.GET_ORG_CYCLES):
      return {
        ...state,
        orgCyclesLoading: true,
      };

    case SUCCESS(orgCyclesType.GET_ORG_CYCLES):
      return {
        ...state,
        orgCyclesLoading: false,
        cycleList: actions.payload.data.data,
      };

    case FAILURE(orgCyclesType.GET_ORG_CYCLES):
      return {
        ...state,
        cycleListError: actions.payload,
        orgCyclesLoading: true,
      };
    case REQUEST(orgCyclesType.GET_ONE_ON_ONE_CYCLES):
      return {
        ...state,
        oneOnOneCyclesLoading: true,
      };

    case SUCCESS(orgCyclesType.GET_ONE_ON_ONE_CYCLES):
      return {
        ...state,
        oneOnOneCyclesLoading: false,
        oneOnOneCycleList: actions.payload,
      };

    case FAILURE(orgCyclesType.GET_ONE_ON_ONE_CYCLES):
      return {
        ...state,
        oneOnOneCycleListError: actions.payload,
        oneOnOneCyclesLoading: false,
      };

    default:
      return { ...state };
  }
};
