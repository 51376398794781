import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import SVGS from "../../../utils/svgs";
import { ViewTeamMembersProfile } from "../../../utils/urls";
import avatar from "../../../assets/images/avatar.svg";
import { CustomTable, PageTitle, SearchBar } from "../../shared";
import "./people.scss";
import { LoadPeople } from "../../../redux/actions/company";

const People = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { people, isLoadingPeople } = useSelector(({ companyPeople }) => companyPeople);
  const [showEditorModal] = useState(false);
  const [editorData, setEditorData] = useState(undefined);
  const [actionType, setActionType] = useState(undefined);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });

  useEffect(() => {
    dispatch(LoadPeople({ pageNo: paging.pageNumber, pageSize: paging.pageSize }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const onPageClick = evt => {
    console.log(evt);
    const { selected } = evt;
    setPaging(prev => ({ ...prev, pageNumber: selected + 1 }));
  };

  const tableHeads = [
    { id: 1, name: "Name" },
    { id: 2, name: "Title" },
    { id: 3, name: "Primary Team" },
    // { id: 4, name: "Last Seen" },
    { id: 5, name: "" },
  ];
  const handleDetailClick = data => {
    history.push({ pathname: ViewTeamMembersProfile(data.id), state: { tab: "people", memberProfile: data } });
  };

  const tableRows = data => {
    return (
      data &&
      data.map((person, idx) => {
        return (
          <tr key={person.userId}>
            <td className="bordered">
              <span className="img-contain">
                {person.img ? (
                  <img className="table-avatar" src={person.img} alt={`${person.firstName} ${person.lastName}`} />
                ) : (
                  <img className="table-avatar" src={avatar} alt={`${person.firstName} ${person.lastName}`} />
                )}
              </span>
              &nbsp; {`${person.firstName} ${person.lastName}`}
            </td>
            <td className="bordered">{person.jobTitle ? person.jobTitle : "--"}</td>
            <td className="bordered">{person.primaryTeam ? person.primaryTeam : "--"}</td>
            {/* <td className="bordered">{person.lastSeen}</td> */}
            <td className="bordered">
              <div className="action">
                <span onClick={() => handleDetailClick(person)} title={`View details of ${person.firstName} ${person.lastName}`}>
                  {SVGS.caret({ direction: "right" })}
                </span>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const handleSearch = _debounce(event => {
    dispatch(LoadPeople({ name: event.target.value, pageNo: paging.pageNumber, pageSize: paging.pageSize }));
    // cb(true);
  }, 1000);

  // const search = rows => {
  //   const columns = ["jobTitle", "firstName", "lastName", "primaryTeam"];
  //   if (rows && rows.length) {
  //     return rows.filter(row =>
  //       columns.some(column => {
  //         return row[column].toString().toLowerCase().indexOf(searchTerm) > -1;
  //       })
  //     );
  //   }

  //   return rows;
  // };

  useEffect(() => {
    if (!showEditorModal) {
      setEditorData(undefined);
      setActionType("create");
    } else if (showEditorModal && actionType === "edit") {
      setEditorData(editorData);
    }
    return () => {
      setEditorData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditorModal, actionType]);

  return (
    <div className="company-people-container">
      <div className="row mb-3">
        <div className="container-fluid">
          <div className="row pg-header">
            <div className="col">
              <div className="d-flex justify-content-between align-items-center">
                <PageTitle title={`${people && people.items ? people.items.length : "-"} Employees`} />
                <div className="search-contain">
                  <SearchBar placeholder="Search for an employee" handleSearch={handleSearch} />
                </div>
              </div>
            </div>
          </div>

          <CustomTable
            arrangedTableData={tableRows(people?.items)}
            tableHeads={tableHeads}
            isLoading={isLoadingPeople}
            tableData={people.items}
            searchTableData={people.items}
            showPaginator
            currentPage={paging.pageNumber}
            pageSize={paging.pageSize}
            pageCount={people?.totalCount}
            handlePageClick={onPageClick}
            bordered
          />
        </div>
      </div>
    </div>
  );
};

export default People;
