import React from "react";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import Cycle from "./cycle";
import "./cycle.scss";

const CycleModal = ({ openModal, closeModal, loading, data }) => {
  return (
    <PMSModal
      title="Add Cycle"
      openModal={openModal}
      processing={loading}
      primaryButtonText=""
      containerId="user-editor"
      closeModal={closeModal}
      contentClassName="user-editor-content"
      showSecondaryButton={false}
      useHeaderBorder={true}
      closeOnOutsideClick={false}
    >
      <Cycle closeModal={closeModal} data={data} />
    </PMSModal>
  );
};

export default CycleModal;
