import { combineReducers } from "redux";
import keyResultReducers from "./keyResult/keyResultReducers";
import teamObjectivesReducers from "../reducers/teamReducers/teamObjectivesReducers";
import teamMembersReducers from "../reducers/teamReducers/teamMembersReducer";
import profileReducers from "./profile/profileReducers";
import userActivityReducers from "./userReducers/userActivityReducer";
import teamsReducer from "./teamReducers/teamReducers";
import cyclesReducer from "./cyclesReducer/cyclesReducer";
import teamSettingsReducer from "./settingsReducer/teamSettingsReducer";
import userSettingsReducer from "./settingsReducer/userSettingsReducer";
import { objectiveReducers, teamsReducers, peopleReducers, insightsReducers } from "./company";
import lineReportsReducer from "./teamReducers/lineReportsReducer";
import teamInsightsReducer from "./teamReducers/teamInsightsReducer";
import onBoardingReducer from "./onboarding/onboarding";
import notificationReducer from "./notification/notification";

export default combineReducers({
  keyResults: keyResultReducers,
  teamObjectives: teamObjectivesReducers,
  teamMembers: teamMembersReducers,
  lineReports: lineReportsReducer,
  teamInsights: teamInsightsReducer,
  profile: profileReducers,
  userActivity: userActivityReducers,
  organizationTeams: teamsReducer,
  organizationCycles: cyclesReducer,
  teamSettings: teamSettingsReducer,
  userSettings: userSettingsReducer,

  companyObjectives: objectiveReducers,
  companyPeople: peopleReducers,
  companyTeams: teamsReducers,
  companyInsights: insightsReducers,
  onBoardingReducer: onBoardingReducer,
  onboarding: onBoardingReducer,
  notification: notificationReducer,
});
