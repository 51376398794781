import React, { useState } from "react";
import DocumentIcon from "../../../../assets/images/documents.svg";
import SearchBar from "../../../shared/searchBar/searchBar";
import Button from "../../../shared/button/Button";

// import "./oneOnOne.scss";

const ModalInfo = ({ closeModal }) => {
  const [type, setType] = useState("present");
  const [month, setMonth] = useState("");
  const [viewComment, setViewComment] = useState(false);
  const viewContent = (mytype, mymonth) => {
    setType(mytype);
    setMonth(mymonth);
  };
  return (
    <div className="container-fluid p-4">
      <div className="row report-info one-on-one-content">
        <div className="col-auto">
          <div className="search-content">
            <div className="search">
              <SearchBar placeholder="Search" handleSearch={() => {}} searchTerm="" />
            </div>
            <div className="present-content">
              <div className="name">Present</div>
              <div className="monthly" onClick={() => viewContent("present", "February 2021")}>
                February 2021 <span className="dot">&nbsp;</span>
              </div>
            </div>
            <div className="past-content">
              <div className="name">Past</div>
              <div className="monthly" onClick={() => viewContent("past", "January 2021")}>
                January 2021
              </div>
              <div className="monthly" onClick={() => viewContent("past", "December 2020")}>
                December 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "November 2020")}>
                November 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "October 2020")}>
                October 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "September 2020")}>
                September 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "August 2020")}>
                August 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "July 2020")}>
                July 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "June 2020")}>
                June 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "May 2020")}>
                May 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "April 2020")}>
                April 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "March 2020")}>
                March 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "February 2020")}>
                February 2020
              </div>
              <div className="monthly" onClick={() => viewContent("past", "January 2020")}>
                January 2020
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          {type === "present" && (
            <div className="empty-content">
              <img src={DocumentIcon} alt="empty goals" />
              <div className="no-goals">No Time period selected</div>
              <div className="add-goal">Select a time period to view/add summary</div>
            </div>
          )}
          {type !== "present" && !viewComment && (
            <div className="item-content">
              <div className="summary">{month} Summary</div>
              <div className="desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
              <div>
                <label htmlFor="summary">Add Comment</label>
                <textarea className="form-control" placeholder="Add a summary of this month’s 1:1"></textarea>
              </div>
              <div className="pull-right d-flex">
                <div className="pr-3">
                  <Button size="sm" color="default" handleClick={closeModal} className="cancel-key">
                    Cancel
                  </Button>
                </div>

                <div>
                  <Button size="sm" color="blue" handleClick={() => setViewComment(true)} className="add-key">
                    Add Comment
                  </Button>
                </div>
              </div>
            </div>
          )}

          {viewComment && (
            <div className="item-content">
              <div className="month-summary">February 2021 Summary</div>
              <div className="narration">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
              <div className="manager-comment">Manager’s Comment</div>
              <div className="narration">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ModalInfo;
