import React, { useState } from "react";
import ViewContent from "../shared/viewContent/viewContent";
import Teams from "./teamSettings/teamSettings";
import TimePeriod from "./timePeriod/timePeriod";
import Users from "./Users/users";
import Notifications from "./notifications/notifications";
import CompanyProfile from "./companyProfile/companyProfile";

const Settings = () => {
  const tabDetails = [
    { id: 1, key: "Profile", title: "Company Profile", content: <CompanyProfile /> },
    { id: 2, key: "Teams", title: "Teams", content: <Teams /> },
    { id: 3, key: "Users", title: "Users", content: <Users /> },
    { id: 4, key: "Time", title: "Time Periods", content: <TimePeriod /> },
    { id: 5, key: "Notifications", title: "Notifications", content: <Notifications /> },
  ];
  const [key, setKey] = useState("Teams");

  return (
    <section className="row">
      <div className="col">
        <div className="content-header d-flex justify-content-between">
          <span>Settings</span>
        </div>
        <div className="card-body px-0">
          <ViewContent activeKey={key} tabDetails={tabDetails} handleSelect={(k) => setKey(k)} />
        </div>
      </div>
    </section>
  );
};

export default Settings;
