import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../shared/button/Button";
import DocumentIcon from "../../../assets/images/documents.svg";
import ArrowLeft from "../../../assets/images/arrow-left2.svg";
import Search from "../../../assets/images/search.svg";
import { LoadingTableContent } from "../../shared/loader/Loader";
import { getReview, getOneOnOne } from "../../../redux/actions/settingsActions/userSettingsActions";
import "./timePeriod.scss";
import { Paginator } from "../../shared";

const TimePeriodList = ({ type, setSeeAll, setTimeType, addData, setId, setModalDeleteShow }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const deleteItem = (val) => {
    type === "one" ? setId(val?.id) : setId({ cycleId: val?.cycleId, orgId: val?.organisationId });
    setModalDeleteShow(true);
  };

  const { payloadListReview, loadingListReview, payloadList, loadingList } = useSelector(({ userSettings }) => {
    return {
      payloadListReview: userSettings?.getReviewPayload,
      loadingListReview: userSettings?.getReviewLoading,
      payloadList: userSettings?.getOneOnOnePayload,
      loadingList: userSettings?.getOneOnOneLoading,
    };
  });

  const handlePageClick = (val) => {
    if (type === "review") {
      dispatch(getReview(val?.selected + 1));
    } else if (type === "one") {
      dispatch(getOneOnOne(val?.selected + 1));
    }
  };
  useEffect(() => {
    if (type === "review") {
      dispatch(getReview());
    } else if (type === "one") {
      dispatch(getOneOnOne());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (type === "review" && payloadListReview) {
      const { pageNumber, pageSize, totalCount } = payloadListReview?.data?.data;
      setCurrentPage(pageNumber);
      setPageSize(pageSize);
      setTotalItems(totalCount);
      setList(payloadListReview?.data?.data?.items);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingListReview]);

  useEffect(() => {
    if (type === "one" && payloadList) {
      console.log("testing");
      const { pageNumber, pageSize, totalCount } = payloadList?.data?.data || {};
      setCurrentPage(pageNumber);
      setPageSize(pageSize);
      setTotalItems(totalCount);
      setList(payloadList?.data?.data?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingList]);

  return (
    <div className="time-period-container">
      <div className="row">
        <div className="col">
          <div className="search-title">
            <span
              className="upcoming"
              onClick={() => {
                setSeeAll(false);
                setTimeType(null);
              }}
            >
              <img src={ArrowLeft} alt="arrow left" /> {type === "one" ? "Upcoming 1:1 Cycles" : "Upcoming Review Cycles"}
            </span>
            <div className="search-input">
              <span className="search">
                <img src={Search} alt="search input" className="search-icon" />
                <input placeholder="Search cycle name" className="form-control" />
              </span>
              <Button size="sm" color="blue" handleClick={() => addData(null, type)}>
                Add Cycle
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="table-container">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Cycle Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(loadingListReview || loadingList) && <LoadingTableContent colSpan={4} />}
                    {list?.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>{val?.name || val?.description}</td>
                          <td>{new Date(val?.startDate || val?.reviewCycleStartDate).toLocaleDateString("en-US")}</td>
                          <td>{new Date(val?.endDate || val?.reviewCycleDueDate).toLocaleDateString("en-US")}</td>
                          <td>
                            <span className="edit" onClick={() => addData(val, type)}>
                              Edit
                            </span>
                            <span className="delete" onClick={() => deleteItem(val)}>
                              Delete
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {list?.length > 0 && <Paginator currentPage={currentPage} perPage={pageSize} pageCount={Math.ceil(totalItems / pageSize)} totalItems={totalItems} handlePageClick={handlePageClick} />}
                {!(loadingListReview || loadingList) && list?.length === 0 && (
                  <div className="empty-content">
                    <img src={DocumentIcon} alt="empty goals" />
                    <div className="no-goals">You have not added any Cycle</div>
                    <div className="add-goal">Click on the “Add Cycle button to get started</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePeriodList;
