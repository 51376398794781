import React from "react";
import { LineLoader } from "..";

import "./draft.scss";

const Draft = ({ title, data, onAction, isLoading }) => {
  const onClick = (e, item) => {
    e.preventDefault();
    onAction(item);
  };

  const lineNum = [];
  for (let i = 1; i <= 5; i++) {
    lineNum.push(i);
  }

  return (
    <>
      <div className="draft">{title || "Drafts"}</div>
      {isLoading ? (
        <>
          {lineNum.map(i => (
            <div className="draft-content" key={i}>
              <div className="desc">
                <LineLoader />
              </div>
              <div className="result-key" style={{ width: "65%" }}>
                <LineLoader />
              </div>
              <div className="resume" style={{ width: "40%" }}>
                <span>
                  <LineLoader />
                </span>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {data && data.length ? (
            data.map((item, idx) => (
              <div className="draft-content" key={item.id}>
                <div className="desc">{item.objectives}</div>
                {item.resultKey && <div className="result-key">{item.resultKey}</div>}
                <div className="resume">
                  <span>
                    <a href="#/" onClick={e => onClick(e, item)}>
                      Resume
                    </a>
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div>No Data Available</div>
          )}
        </>
      )}
    </>
  );
};
export default Draft;
