import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DocumentIcon from "../../../../assets/images/documents.svg";
import LocationIcon from "../../../../assets/images/location.svg";
import ProfileIcon from "../../../../assets/images/profile.svg";
import { LoadingTableContent } from "../../../shared/loader/Loader";
import TableActionDropdown from "../../../shared/tableActionDropdown/tableActionDropdown";
import { getPercentColor } from "../../../../utils/helpers";
import { submitUserReview, getUsersKeyResultList } from "../../../../redux/actions/keyResult/keyResultActions";
import { errorMessage, successMessage } from "../../../../redux/actions/notification/notification";
import { Paginator } from "../../../shared";

const GoalTableContent = ({ data, selectAll, loadingContent, setModalDeleteShow, selectAllStatus, actions, selectGoal, setShowCommentModal, setModalCheckinShow }) => {
  const dispatch = useDispatch();
  const [keyResultId, setKeyResultId] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const { payload, isLoading, error, deleteUserKeyResultPayload, deleteUserKeyResultLoading, deleteUserKeyResultError } = useSelector(({ keyResults }) => {
    return {
      payload: keyResults?.submitUserReviewPayload,
      isLoading: keyResults?.submitUserReviewLoading,
      error: keyResults?.submitUserReviewError,
      deleteUserKeyResultError: keyResults?.deleteUserKeyResultError,
      deleteUserKeyResultPayload: keyResults?.deleteUserKeyResultPayload,
      deleteUserKeyResultLoading: keyResults?.deleteUserKeyResultLoading,
    };
  });

  const submitReview = (id) => {
    setKeyResultId(id);
    dispatch(submitUserReview({ keyResultIds: [id] }));
  };
  const handlePageClick = (val) => {
    setPage(val?.selected + 1);
    dispatch(getUsersKeyResultList("", val?.selected + 1));
  };

  useEffect(() => {
    const { pageNumber, pageSize, totalCount } = data || {};
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
    setTotalItems(totalCount);
  }, [data]);

  useEffect(() => {
    if (payload) {
      setKeyResultId(null);
    } else if (error?.data?.message && !isLoading) {
      dispatch(errorMessage(error?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (deleteUserKeyResultPayload && !deleteUserKeyResultLoading) {
      dispatch(getUsersKeyResultList("", page));
      setModalDeleteShow(false);
      dispatch(successMessage("User key result deleted successfully"));
    } else if (deleteUserKeyResultError && !deleteUserKeyResultLoading) {
      dispatch(errorMessage(deleteUserKeyResultError?.data?.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserKeyResultLoading]);
  return (
    <>
      <Table
        responsive
        style={{
          borderBottom: "1px solid #BAC2CA",
        }}
      >
        <thead>
          <tr>
            <td>
              <input type="checkbox" onChange={selectAll} alt="checkbox" />
            </td>
            <td className="title-header">Title</td>
            <td>Last Update</td>
            <td>Status</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {!loadingContent &&
            data?.items?.map((value) => {
              return (
                <tr>
                  <td>
                    <input type="checkbox" checked={selectAllStatus?.indexOf(value?.keyResultId) > -1} onChange={() => selectGoal(value?.keyResultId)} alt="checkbox" />
                  </td>
                  <td>
                    <div className="td-title">
                      {value?.description} <img src={ProfileIcon} alt="profile" />
                    </div>
                    <div className="td-subtitle">{value?.parent?.keyresult}</div>
                  </td>
                  <td className="td-date">{value?.dateSubmitted}</td>
                  <td>
                    {/* {!submittedReview && (
                      <>
                        {!submitApproval ? (
                          <span className="td-status" onClick={() => setSubmittedReview(true)}>
                            {value.status}
                          </span>
                        ) : (
                          <span className="pending">Pending Approval</span>
                        )}
                      </>
                    )} */}
                    {value?.status === "NOT_STARTED" && (
                      <span className="submit-review" onClick={() => submitReview(value?.keyResultId)}>
                        {isLoading && keyResultId === value?.keyResultId ? <i className="fa fa-spin fa-spinner"></i> : "Submit for Review"}
                      </span>
                    )}
                    {value.status === "approved" && (
                      <div className="approved">
                        <ProgressBar now={value?.progress} variant={getPercentColor(value?.progress)} />

                        <span className="percent-count">{value?.progress}%</span>
                        <img src={LocationIcon} alt="location" onClick={() => setModalCheckinShow(true)} />
                      </div>
                    )}
                    {value.status === "disapproved" && (
                      <div className="approved">
                        <span className="disapprove-status">Disapproved</span>
                        <span className="td-status" onClick={() => setShowCommentModal(true)}>
                          View Details
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <TableActionDropdown data-testid="table-action-dropdown" actions={actions} data={value} />
                  </td>
                </tr>
              );
            })}
        </tbody>

        {loadingContent && <LoadingTableContent colSpan={5} />}
      </Table>

      {data?.items?.length > 0 && <Paginator currentPage={currentPage} perPage={pageSize} pageCount={Math.ceil(totalItems / pageSize)} totalItems={totalItems} handlePageClick={handlePageClick} />}

      {!loadingContent && (!data || (data && data?.items?.length === 0)) && (
        <div className="empty-content">
          <img src={DocumentIcon} alt="empty goals" />
          <div className="no-goals">You have not added any Goals</div>
          <div className="add-goal">Click on the “Add Goal” button to get started</div>
        </div>
      )}
    </>
  );
};
export default GoalTableContent;
