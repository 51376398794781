import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Button from "../../../../shared/button/Button";
import PMSSelect from "../../../../shared/selectInput/pmsSelect/pmsSelect";
import Lozenge from "../../../../shared/lozenges/lozenge";
import LinkIcon from "../../../../../assets/images/link.svg";
import CloseIcon from "../../../../../assets/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { saveObjectivesDrafts, postTeamObjective } from "../../../../../redux/actions/teamsActions/teamObjectivesActions";
// import "./form.scss";

const NewObjectivesForm = ({ setShowAlignKeyResultModal, setShowAddKeyResultModal, draftContent, addKeyResultValue, teamId, setAlignment, alignment, setAddKeyResultValue, cycleList }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState([]);
  const [reviewCycle, setReviewCycle] = useState(cycleList && cycleList[0]?.id);
  const [validForm, setValidForm] = useState(false);
  const [keyResultsList, setKeyResultsList] = useState([]);
  const [btn, setBtn] = useState([""]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const select = useSelector(({ teamObjectives }) => {
    return {
      objectivesDrafts: teamObjectives && teamObjectives.objectivesDraft,
      postObjectivesLoading: teamObjectives && teamObjectives.postObjectivesLoading,
      postObjectivesPayload: teamObjectives && teamObjectives.postObjectivesPayload,
    };
  });

  const { postObjectivesLoading, postObjectivesPayload } = select;

  useEffect(() => {
    let arrayOfAddedKeyResults = [];
    if (addKeyResultValue) {
      arrayOfAddedKeyResults = keyResultsList.concat(addKeyResultValue);
      setKeyResultsList(arrayOfAddedKeyResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addKeyResultValue]);

  // reset form if post is successful
  useEffect(() => {
    if (isSubmitted && postObjectivesPayload) {
      form.reset();
      setAlignment(null);
      setAddKeyResultValue(null);
      setKeyResultsList(null);
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postObjectivesLoading]);

  const submit = (event, value) => {
    let formValue = {
      reviewCycleId: reviewCycle,
      alignedKeyResults: keyResultsList,
      isCompleted: false,
      isPublished: false,
      progress: 0,
      description: value.description,
      parentOrganisationObjectiveId: alignment?.objectiveId,
      teamId: teamId,
      dueDate: value.dueDate,
    };
    let draftData = "";

    if (btn === "draft") {
      draftData = select.objectivesDrafts.concat(formValue);
      dispatch(saveObjectivesDrafts(draftData));
    } else {
      if (!alignment) {
        alert("Please select an alignment");
      } else {
        setIsSubmitted(true);
        setAlignment(null);
        dispatch(postTeamObjective(formValue));
      }
    }
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    console.log({ emptyValidation });
    setValidForm(emptyValidation);
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    setBtn("draft");
    form.submit();
  };

  const handleAddKey = () => {
    setShowAddKeyResultModal(true);
  };

  return (
    <AvForm className="goal-form-content" data-testid="submit" model="" onValidSubmit={submit} ref={(c) => setForm(c)} onKeyUp={checkForm}>
      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="description">Objective Title</label>
          <AvField
            autoComplete="off"
            type="text"
            name="description"
            className="form-field"
            value={draftContent?.length ? draftContent?.objectiveTitle : ""}
            placeholder="Enter your objective here..."
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter your objective",
              },
            }}
          />
        </div>
      </div>
      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="reviewCycle">Review Cycle</label>
          <PMSSelect
            placeholder="Review Cycle"
            selectMultiple={false}
            id="reviewCycle"
            dataSource={cycleList}
            onActionButtonClick={(e) => console.log({ e })}
            closeOnActionClick={true}
            displayExpr="description"
            valueExpr="cycleId"
            onChange={(e) => setReviewCycle(e.label)}
            controlHeight="48px"
            value={reviewCycle}
            // value={draftContent ? draftContent.reviewCycle : reviewCycle}
          />
          {/* <AvField
            autoComplete="off"
            type="text"
            className="form-field"
            name="reviewCycle"
            placeholder="Enter your review cycle"
            validate={{
              required: {
                value: true,
                errorMessage: "Please your review cycle",
              },
            }}
          /> */}
        </div>
        <div className="col-6">
          <label htmlFor="dueDate">Due Date</label>
          <AvField
            autoComplete="off"
            type="date"
            name="dueDate"
            className="form-field"
            placeholder="Select date"
            value={draftContent?.length ? draftContent?.dueDate : ""}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter due date",
              },
            }}
          />
        </div>
      </div>

      <div className="row layout-style">
        <div className="col-12">
          <label htmlFor="team">Team</label>
          <AvField
            autoComplete="off"
            disabled={true}
            type="text"
            name="team"
            className="form-field"
            placeholder="Product Design"
            value={draftContent?.length ? draftContent?.team : "Product Design"}
          />
        </div>
      </div>

      <div className="row layout-style">
        <div className="col-6">
          <label htmlFor="owner">Owner</label>
          <AvField autoComplete="off" disabled={true} type="text" className="form-field" name="owner" placeholder={"Team Leader"} value={draftContent?.length ? draftContent?.owner : "Team Leader"} />
        </div>
        <div className="col-6">
          <label htmlFor="type">Type</label>
          <AvField autoComplete="off" disabled={true} type="text" name="type" className="form-field" placeholder="Team" value={draftContent?.length ? draftContent.type : "Team"} />
        </div>
      </div>

      <div>
        <div className="alignment">Alignment</div>
        <div>
          {alignment && (
            <>
              <div className="alignment-title">
                {alignment?.Keyresult}
                <img src={CloseIcon} alt="close icon" onClick={() => setAlignment(null)} />
              </div>
              <div className="alignment-sub-title">
                <img src={LinkIcon} alt="link icon" /> {alignment?.Team?.TeamName}
              </div>
            </>
          )}
        </div>
        {!alignment && (
          <div
            className="align-goal"
            onClick={() => {
              setShowAlignKeyResultModal(true);
            }}
          >
            Add an Alignment
          </div>
        )}
      </div>
      <div>
        <div className="alignment">Key Results</div>

        {keyResultsList?.length > 0 &&
          keyResultsList.map((value, index) => {
            return (
              <div className="key-results-content">
                <div>
                  <div>{value?.description}</div>
                  <div className="key-results-date">Due Date: {value?.dueDate}</div>
                </div>
                <div className="d-flex">
                  <Lozenge className="sp-lozenge sp-lozenge--default" style={{ marginRight: "24px" }} content={value?.progressTrack === "kpi" ? "KPI" : "%"} />
                  {/* <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="ri-more-2-fill"></i>
                  </span> */}
                </div>
              </div>
            );
          })}
        <div className="add-key-result align-goal" data-testid="add-key-obj" onClick={handleAddKey}>
          {keyResultsList?.length > 0 ? " Add another Key Result" : " Add a Key Result"}
        </div>
      </div>

      <div className="row btn-goal-content">
        <div className="col-6">
          <Button size="sm" className="btn-goal" outline={true} color="blue" handleClick={handleSaveDraft}>
            Save as Draft
          </Button>
        </div>
        <div className="col-6">
          <Button size="sm" disabled={reviewCycle === "" || !validForm} color="blue" className="btn-goal" handleClick={() => form.submit()}>
            Publish
          </Button>
        </div>
      </div>
    </AvForm>
  );
};

export default NewObjectivesForm;
