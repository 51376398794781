import { objectivesActionTypes } from "../../actions/company";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";
import DATA from "../../../components/company/data";

const INITIAL_STATE = {
  isLoadingPublished: false,
  publishedObjectives: { items: [] },
  publishedObjectivesError: null,

  isLoadingDrafted: false,
  draftedObjectives: { items: [] },
  draftedObjectivesError: null,

  isLoadingOverview: false,
  progressOverviewObjectives: DATA.overview.data,
  progressOverviewObjectivesError: null,

  isLoadingCycleOptions: false,
  cycleOptions: [],
  cycleOptionsError: null,

  isLoadingAlignedTeamsObjectives: false,
  alignedTeamsObjectives: { items: [] },
  alignedTeamsObjectivesError: null,

  isLoadingAlignedTeamsOptions: false,
  alignedTeamsOptions: [],
  alignedTeamsOptionsError: null,

  isLoadingObjectiveKeyResults: false,
  objectiveKeyResults: { items: [] },
  objectiveKeyResultsError: null,

  isLoadingKeyResultContributions: false,
  keyResultContributions: { items: [] },
  keyResultContributionsError: null,

  isLoadingObjectiveSummary: false,
  objectiveSummary: DATA.objectiveSummary.data,
  objectiveSummaryError: null,

  savingObjective: false,
  saveObjectivePayload: null,
  saveObjectiveError: null,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(objectivesActionTypes.GET_PUBLISHED_OBJECTIVES):
      return {
        ...state,
        isLoadingPublished: true,
      };
    case SUCCESS(objectivesActionTypes.GET_PUBLISHED_OBJECTIVES):
      return {
        ...state,
        isLoadingPublished: false,
        publishedObjectives: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_PUBLISHED_OBJECTIVES):
      return {
        ...state,
        publishedObjectivesError: actions.payload,
        isLoadingPublished: false,
      };

    case REQUEST(objectivesActionTypes.GET_DRAFTED_OBJECTIVES):
      return {
        ...state,
        isLoadingDrafted: true,
      };
    case SUCCESS(objectivesActionTypes.GET_DRAFTED_OBJECTIVES):
      return {
        ...state,
        isLoadingDrafted: false,
        draftedObjectives: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_DRAFTED_OBJECTIVES):
      return {
        ...state,
        draftedObjectivesError: actions.payload,
        isLoadingDrafted: false,
      };

    case REQUEST(objectivesActionTypes.GET_OVERVIEW_OBJECTIVES):
      return {
        ...state,
        isLoadingOverview: true,
      };
    case SUCCESS(objectivesActionTypes.GET_OVERVIEW_OBJECTIVES):
      return {
        ...state,
        isLoadingOverview: false,
        progressOverviewObjectives: actions.payload.data,
      };

    case FAILURE(objectivesActionTypes.GET_OVERVIEW_OBJECTIVES):
      return {
        ...state,
        progressOverviewObjectivesError: actions.payload,
        isLoadingOverview: false,
      };

    case REQUEST(objectivesActionTypes.GET_CYCLE_OPTIONS):
      return {
        ...state,
        isLoadingCycleOptions: true,
      };
    case SUCCESS(objectivesActionTypes.GET_CYCLE_OPTIONS):
      return {
        ...state,
        isLoadingCycleOptions: false,
        cycleOptions: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_CYCLE_OPTIONS):
      return {
        ...state,
        cycleOptionsError: actions.payload,
        isLoadingCycleOptions: false,
      };

    case REQUEST(objectivesActionTypes.GET_ALIGNED_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingAlignedTeamsOptions: true,
      };
    case SUCCESS(objectivesActionTypes.GET_ALIGNED_TEAMS_OPTIONS):
      return {
        ...state,
        isLoadingAlignedTeamsOptions: false,
        alignedTeamsOptions: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_ALIGNED_TEAMS_OPTIONS):
      return {
        ...state,
        alignedTeamsOptionsError: actions.payload,
        isLoadingAlignedTeamsOptions: false,
      };

    case REQUEST(objectivesActionTypes.GET_ALIGNED_TEAMS_OBJECTIVES):
      return {
        ...state,
        isLoadingAlignedTeamsObjectives: true,
      };
    case SUCCESS(objectivesActionTypes.GET_ALIGNED_TEAMS_OBJECTIVES):
      return {
        ...state,
        isLoadingAlignedTeamsObjectives: false,
        alignedTeamsObjectives: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_ALIGNED_TEAMS_OBJECTIVES):
      return {
        ...state,
        alignedTeamsObjectivesError: actions.payload,
        isLoadingAlignedTeamsObjectives: false,
      };

    case REQUEST(objectivesActionTypes.GET_OBJECTIVE_KEY_RESULTS):
      return {
        ...state,
        isLoadingObjectiveKeyResults: true,
      };
    case SUCCESS(objectivesActionTypes.GET_OBJECTIVE_KEY_RESULTS):
      return {
        ...state,
        isLoadingObjectiveKeyResults: false,
        objectiveKeyResults: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_OBJECTIVE_KEY_RESULTS):
      return {
        ...state,
        objectiveKeyResultsError: actions.payload,
        isLoadingObjectiveKeyResults: false,
      };

    case REQUEST(objectivesActionTypes.GET_KEY_RESULT_CONTRIBUTIONS):
      return {
        ...state,
        isLoadingKeyResultContributions: true,
      };
    case SUCCESS(objectivesActionTypes.GET_KEY_RESULT_CONTRIBUTIONS):
      return {
        ...state,
        isLoadingKeyResultContributions: false,
        keyResultContributions: actions.payload.data.data,
      };

    case FAILURE(objectivesActionTypes.GET_KEY_RESULT_CONTRIBUTIONS):
      return {
        ...state,
        keyResultContributionsError: actions.payload,
        isLoadingKeyResultContributions: false,
      };

    case REQUEST(objectivesActionTypes.SAVE_OBJECTIVE):
      return { ...state, savingObjective: true, saveObjectivePayload: null };
    case SUCCESS(objectivesActionTypes.SAVE_OBJECTIVE):
      return {
        ...state,
        saveObjectivePayload: actions.payload.data,
        savingObjective: false,
      };
    case FAILURE(objectivesActionTypes.SAVE_OBJECTIVE):
      return {
        ...state,
        saveObjectiveError: actions.payload,
        savingObjective: false,
      };

    case REQUEST(objectivesActionTypes.GET_OBJECTIVE_SUMMARY):
      return { ...state, isLoadingObjectiveSummary: true };
    case SUCCESS(objectivesActionTypes.GET_OBJECTIVE_SUMMARY):
      return {
        ...state,
        objectiveSummary: actions.payload,
        isLoadingObjectiveSummary: false,
      };
    case FAILURE(objectivesActionTypes.GET_OBJECTIVE_SUMMARY):
      return {
        ...state,
        objectiveSummaryError: actions.payload,
        isLoadingObjectiveSummary: false,
      };

    default:
      return { ...state };
  }
};
