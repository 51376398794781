import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation/lib";
import Chips from "react-chips";
import InfoTip from "../../../shared/infoTip/infoTip";
import PMSChip from "../../../shared/pmsChip/pmsChip";
import PMSModal from "../../../shared/pmsModal/pmsModal";
import PMSSelect from "../../../shared/selectInput/pmsSelect/pmsSelect";
import "./userEditor.scss";

const UserEditor = ({ openModal, closeModal, processing, managersList, teamsList, data, onSubmit }) => {
  const [form, setForm] = useState([]);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [chipSuggestions, setChipSuggestions] = useState([]);
  const [chips, setChips] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    parentTeam: "",
    primaryTeam: "",
    manager: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!openModal) {
      handleClose();
      setFormData({
        teamName: "",
        email: "",
        parentTeam: "",
        primaryTeam: "",
        manager: "",
      });
      setChipSuggestions([]);
    } else {
      handleShow();
      setChipSuggestions(["DevOps", "VigiPay", "R & D", "Advancely", "PowerTech"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    setIsValid(formValid(formData));
  }, [formData]);

  useEffect(() => {
    setChips(data && data.teams);
    setIsValid(!!data);
    setValidForm(!!data);
    setFormData({
      fullName: data ? data.name : "",
      email: data ? data.emailAddress : "",
      primaryTeam: data ? 2 : "",
      manager: data ? 1 : "",
      otherTeams: data ? data.teams : [],
    });
  }, [data]);

  const handleSubmit = () => {
    form.submit();
  };

  const handleInputChange = (e, meta) => {
    if (e && e.value) {
      const { value } = e;
      const { name } = meta;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const formValid = fd => {
    return !!fd.primaryTeam && !!fd.manager;
  };

  const handleEmailsChange = chips => {
    setChips(chips);
  };

  const removeChip = index => {
    console.log(index);
  };

  const submit = (evt, value) => {
    console.log({ ...formData, otherTeams: chips, ...value });
  };

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation = obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  return (
    <PMSModal
      title={`${data ? "Edit" : "Add"} User`}
      openModal={show}
      handlePrimaryButtonClick={handleSubmit}
      processing={processing}
      primaryButtonText={data ? "Save Changes" : "Add User"}
      containerId="user-editor"
      closeModal={closeModal}
      contentClassName="user-editor-content"
      showSecondaryButton={true}
      useHeaderBorder={false}
      disablePrimaryButton={!(isValid && validForm)}
      closeOnOutsideClick={false}
      useCloseIcon
    >
      <div className="user-editor-container" data-testid="user-editor-container">
        <AvForm className="comment-form" onKeyUp={checkForm} data-testid="userEditorForm" onValidSubmit={submit} ref={c => setForm(c)} model={formData}>
          <div className="row mb-10">
            <div className="col-12">
              <label htmlFor="fullName" className="form-label">
                Name
              </label>
              <AvField
                autoComplete="off"
                data-testid="user-fullname"
                type="text"
                name="fullName"
                placeholder="Enter Full Name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter Full Name",
                  },
                }}
              />
            </div>
          </div>
          <div className="row mb-10">
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <AvField
                autoComplete="off"
                data-testid="user-email"
                type="email"
                name="email"
                placeholder="Enter Email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please email address",
                  },
                }}
              />
            </div>
          </div>
          <div className="row mb-24">
            <div className="col-12">
              <label htmlFor="primaryTeam" className="form-label w-50 d-flex">
                <InfoTip
                  itemName="Primary Team"
                  infoText="This is the primary team that
the user reports to."
                />
              </label>
              <PMSSelect
                arialLabel="primary-team"
                testId="primary-team"
                name="primaryTeam"
                id="primaryTeam"
                placeholder="Select or Search in the List"
                dataSource={teamsList}
                onChange={handleInputChange}
                controlHeight="48px"
                value={formData.primaryTeam}
              />
            </div>
          </div>
          <div className="row mb-24">
            <div className="col-12">
              <label htmlFor="manager" className="form-label">
                Manager
              </label>
              <PMSSelect
                arialLabel="primary-team"
                testId="manager"
                name="manager"
                placeholder="Select or Search in the List"
                dataSource={managersList}
                onChange={handleInputChange}
                controlHeight="48px"
                value={formData.manager}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 chips-control">
              <label htmlFor="otherTeams" className="form-label">
                Other Teams
              </label>
              <Chips
                value={chips}
                placeholder={chips && chips.length ? "" : "Enter team name and select to add"}
                onChange={handleEmailsChange}
                suggestions={chipSuggestions}
                onRemove={removeChip}
                renderChip={(value, selected, onRemove) => <PMSChip handleRemove={onRemove}>{value}</PMSChip>}
                createChipKeys={[13, 188, 32]}
              />
            </div>
          </div>
        </AvForm>
      </div>
    </PMSModal>
  );
};

export default UserEditor;
