import { Get } from "../../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const teamsType = {
  GET_ORG_TEAMS: "GET_ORG_TEAMS",
};

export const getOrgTeams = createAsyncAction(teamsType.GET_ORG_TEAMS, async () => {
  return await Get(`Team/GetAll`);
});
