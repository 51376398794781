import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import MemberEditor from "./membersEditor/memberEditor";
import { SearchBar, AlertModal, CustomTable, Button } from "../../../shared";
import { PageTitle } from "../../../shared/titlePage/titlePage";
import { getTeamMembers, saveTeamMembers, performActionOnMember } from "../../../../redux/actions/settingsActions/teamSettingsActions";

import "./viewTeamMembers.scss";
import { errorMessage, successMessage } from "../../../../redux/actions/notification/notification";

const ViewTeamMembers = ({ navigateToViewTeam, team }) => {
  const dispatch = useDispatch();
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [action, setAction] = useState(null);
  const select = useSelector(state => {
    return {
      allTeamMembers: state.teamSettings && state.teamSettings.allTeamMembers?.data?.data,
      isLoading: state.teamSettings && state.teamSettings.isLoading,
      savingTeamMembers: state.teamSettings && state.teamSettings.savingMembers,
      saveTeamMembersPayload: state.teamSettings && state.teamSettings.saveTeamMembersPayload,
      performingAction: state.teamSettings && state.teamSettings.performingAction,
      performActionPayload: state.teamSettings && state.teamSettings.performActionPayload,
    };
  });
  const [formData, setFormData] = useState({
    emails: "",
    sendInvite: false,
  });

  useEffect(() => {
    dispatch(getTeamMembers(team?.teamId, pageNo, pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize]);

  useEffect(() => {
    console.log(action);
    // dispatch(getTeamMembers(team?.teamId, pageNo, pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    setTableData(select.allTeamMembers?.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.allTeamMembers && select.allTeamMembers?.items.length]);

  useEffect(() => {
    if (!select.savingTeamMembers && select.saveTeamMembersPayload) {
      if (select.saveTeamMembersPayload.success) {
        setShowEditorModal(false);
        dispatch(successMessage(select.saveTeamMembersPayload.message || "New members has been added successfully"));
        dispatch(getTeamMembers(team?.teamId, pageNo, pageSize));
      } else {
        dispatch(errorMessage(select.saveTeamMembersPayload.message || "Something went wrong. Unable to add new members"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.saveTeamMembersPayload, select.savingTeamMembers]);

  useEffect(() => {
    if (!select.performingAction && select.performActionPayload) {
      if (select.performActionPayload.success) {
        dispatch(
          successMessage(select.performActionPayload?.message || action.type === "make" ? "Member has been successfully made the team leader" : "Member has been successfully removed from this team")
        );
        setAction(null);
        dispatch(getTeamMembers(team?.teamId, pageNo, pageSize));
      } else {
        dispatch(errorMessage(select.performActionPayload?.message || "Something went wrong. Unable to perform action on member"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select.performingAction, select.performActionPayload]);

  const handleAction = (event, value) => {
    setAction({
      type: event,
      title: `${event[0].toUpperCase() + event.slice(1).toLowerCase()} Member`,
      description: `${event} ${value.firstName} ${value.lastName}  ${event === "make" ? "a member of" : "from"}`,
      buttonText: `${event[0].toUpperCase() + event.slice(1).toLowerCase()}`,
      member: value,
    });
  };

  const tableHeads = [
    { id: 1, name: "Team Members" },
    { id: 2, name: "Role" },
    { id: 3, name: "Status" },
    { id: 5, name: "Action" },
  ];

  const arrangeTableData = tableData => {
    if (tableData) {
      return tableData.map((value, index) => {
        return (
          <tr key={index}>
            <td className="team-member bordered">{`${value?.firstName} ${value?.lastName}`}</td>
            <td className="bordered">{value.jobTitle ? value.jobTitle : "-"}</td>
            <td className="bordered">{value.status ? value.status : "-"}</td>
            <td className="bordered">
              <span className="blue-color cursor mr-4" onClick={() => handleAction("make", value)}>
                Make Leader
              </span>
              <span className="red-color cursor mr-2" onClick={() => handleAction("remove", value)}>
                Remove Member
              </span>
            </td>
          </tr>
        );
      });
    }
    return;
  };

  const handleSearch = _debounce(evt => {
    console.log(typeof evt.target.value);
    dispatch(getTeamMembers(team?.teamId, pageNo, pageSize, evt.target.value));
  }, 1000);

  const handleBackClicked = e => {
    e.preventDefault();
    navigateToViewTeam();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { emails, sendInvite } = formData;
    saveTeamMembers({ data: { emails: emails.split(",").map(v => v.trim()), sendInvite }, teamId: team.teamId });
  };

  const handlePageClick = data => {
    console.log(data);
    let selected = data.selected;
    setPageNo(selected + 1);
  };

  const handleInputChange = e => {
    if (e.target) {
      const { value, name } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleConfirmed = evt => {
    evt.preventDefault();
    dispatch(performActionOnMember({ teamId: team.teamId, memberId: action.member.id, action: action.type }));
  };

  return (
    <div className="view-team-member-container">
      <div className="row mb-3">
        <AlertModal
          openModal={action}
          closeModal={() => setAction(null)}
          onConfirm={handleConfirmed}
          processing={select.performingAction}
          textContent={`Please confirm that you want to ${action?.description} ${team?.teamName}.`}
          actionText={action?.buttonText}
          title={action?.title}
          actionColor={action?.type === "make" && "blue"}
        />
        <MemberEditor
          openModal={showEditorModal}
          closeModal={() => setShowEditorModal(false)}
          onSubmit={handleSubmit}
          processing={select.savingTeamMembers}
          onInputChange={handleInputChange}
          formData={formData}
        />
        <div className="container-fluid">
          <div className="row header-container">
            <div className="col-lg-7 col-md-12 col-sm-12 d-flex align-items-center">
              <div className="row">
                <div className="col-auto">
                  <PageTitle title={team?.teamName} onClick={handleBackClicked} showArrow={true} />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
              <div className="search-contain">
                <SearchBar placeholder="Search" handleSearch={handleSearch} />
              </div>
              <Button size="sm" color="blue" className="pull-right" handleClick={() => setShowEditorModal(true)}>
                Add Member
              </Button>
            </div>
          </div>
          <CustomTable
            arrangedTableData={arrangeTableData(tableData)}
            tableHeads={tableHeads}
            isLoading={select.isLoading}
            tableData={tableData}
            searchTableData={tableData}
            bordered
            currentPage={pageNo}
            perPage={pageSize}
            pageCount={Math.ceil(select.allTeamMembers?.totalCount / pageSize)}
            totalItems={select.allTeamMembers?.totalCount}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTeamMembers;
