import React, { useEffect, useState } from "react";
import { ProgressBar, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import _debounce from "lodash.debounce";
import { LoadingTableContent, OverviewColors, PageTitle, PMSSelect, PMSTooltip, SearchBar, LineLoader } from "../../../shared";
import SVGS from "../../../../utils/svgs";
import { Urls } from "../../../../utils/urls";
import { useDispatch, useSelector } from "react-redux";
import { LoadTeamObjectivesById, LoadTeamOverview, LoadTeamCycleOptions } from "../../../../redux/actions/company";
import { LoadKeyResultContributions, LoadObjectiveKeyResults } from "../../../../redux/actions/company";
import TableRow from "../../objectives/alignedObjectives/tableRow";

import "./companyTeamObjectives.scss";

const CompanyTeamObjectives = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const team = history?.location?.state?.data;
  const { teamsObjectives, isLoadingTeamsObjectives, overview, isLoadingOverview, isLoadingCycleOptions, cycleOptions } = useSelector(({ companyTeams }) => companyTeams);
  const { keyResultContributions, objectiveKeyResults, isLoadingKeyResultContributions, isLoadingObjectiveKeyResults } = useSelector(({ companyObjectives }) => companyObjectives);

  const [selectedCycle, setSelectedCycle] = useState(null);
  const [selectedLevel1, setSelectedLevel1] = useState("");
  const [selectedLevel2, setSelectedLevel2] = useState("");
  const [selectedLevel3, setSelectedLevel3] = useState("");

  useEffect(() => {
    console.log(params);
    dispatch(LoadTeamCycleOptions());
    dispatch(LoadTeamOverview({ teamId: params.id }));
    dispatch(LoadTeamObjectivesById({ teamId: params.id, cycleId: selectedCycle }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(LoadTeamObjectivesById({ teamId: params.id, cycleId: selectedCycle }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCycle]);

  const handleRowClick = (level, levelClass, id, isClosed) => {
    if (!isClosed && level === 1) {
      dispatch(LoadObjectiveKeyResults({ objId: id }));
    } else if (!isClosed && level === 2) {
      dispatch(LoadKeyResultContributions({ krId: id }));
    }

    if (levelClass === "level1") {
      if (id === selectedLevel1) {
        setSelectedLevel1("");
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel1(id);
        setSelectedLevel2("");
        setSelectedLevel3("");
      }
    }
    if (levelClass === "level2") {
      if (id === selectedLevel2) {
        setSelectedLevel2("");
        setSelectedLevel3("");
      } else {
        setSelectedLevel2(id);
        setSelectedLevel3("");
      }
    }
    if (levelClass === "level3") {
      id === selectedLevel3 ? setSelectedLevel3("") : setSelectedLevel3(id);
    }
  };

  const getTableRow = (value, index, level = 1) => {
    let selectedLevel = "";
    if (level === 1) {
      selectedLevel = selectedLevel1;
    } else if (level === 2) {
      selectedLevel = selectedLevel2;
    } else if (level === 3) {
      selectedLevel = selectedLevel3;
    }
    return (
      <TableRow
        key={index}
        value={value}
        level={level}
        selectedLevel={selectedLevel}
        onClick={handleRowClick}
        getTableRow={getTableRow}
        alignedTeamsObjectives={teamsObjectives?.items}
        keyResultContributions={keyResultContributions?.items}
        objectiveKeyResults={objectiveKeyResults?.items}
        loading={isLoadingKeyResultContributions || isLoadingObjectiveKeyResults}
        isLoadingKeyResultContributions={isLoadingKeyResultContributions}
        isLoadingObjectiveKeyResults={isLoadingObjectiveKeyResults}
      />
    );
  };

  const handleSelectCycle = e => {
    setSelectedCycle(e?.value);
  };

  const handleGoBack = () => {
    history.push({ pathname: Urls.Company, state: { tab: "teams" } });
  };

  const handleSearch = _debounce(evt => {
    dispatch(LoadTeamObjectivesById({ teamId: params.id, searchTerm: evt.target.value }));
  }, 1000);

  const getProgress = data => (data ? Object.entries(data).map(([key, value]) => <ProgressBar key={value} variant={key} now={value} />) : "-");

  return (
    <div className="company-team-details-container">
      <div className="row">
        <div className="col-auto">
          <span data-testid="back-icon-container" onClick={handleGoBack} className="back-icon-container" title="Back to Company Teams">
            {SVGS.backArrow()}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <h1 className="team-name">{team && team.teamName ? team.teamName : "-"}</h1>
          <h6 className="team-leader">Team Leader: {team && team.teamOwner ? team.teamOwner : "-"}</h6>
        </div>
      </div>

      <div className="row pg-header">
        <div className="col-4 d-flex flex-md-row align-items-center">
          <PageTitle title={`${teamsObjectives.items && typeof teamsObjectives.items.length === "number" ? teamsObjectives.items.length : "-"} Objectives`} />
        </div>
        <div className="col-8">
          <div className="pb-2 p-md-0 header-select">
            <PMSSelect
              placeholder="Cycles"
              dataSource={cycleOptions}
              closeOnActionClick={true}
              displayExpr="description"
              valueExpr="cycleId"
              onChange={handleSelectCycle}
              menuWidth="220px"
              value={selectedCycle}
              isLoading={isLoadingCycleOptions}
              clearable
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <PMSTooltip content={<OverviewColors />} direction="bottom" width={247}>
            <div className="label">
              <span className="mr">Overview</span>
              {SVGS.info()}
            </div>
            <div className="label-text">
              <div className="progressBar">{isLoadingOverview ? <LineLoader lines={1} /> : <ProgressBar>{getProgress(overview?.overview)}</ProgressBar>}</div>
            </div>
          </PMSTooltip>
        </div>

        <div className="col-auto">
          {/* <div className="label">{companyProgress ? `${companyProgress.average}%` : "-"}</div> */}
          <div className="label">67.6%</div>
          <div className="label-text">Average Progress</div>
        </div>
      </div>
      <div className="row search-row">
        <div className="col-auto">
          <div className="search-container">
            <SearchBar placeholder="Search" handleSearch={handleSearch} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Table
            responsive
            style={{
              borderBottom: "1px solid #BAC2CA",
            }}
            borderless
          >
            <thead>
              <tr>
                <th width="65%">Title</th>
                <th width="10%">Owner</th>
                <th width="10%">Last Update</th>
                <th width="15%">Status</th>
              </tr>
            </thead>
            <tbody className="panel">
              {isLoadingTeamsObjectives ? <LoadingTableContent colSpan={4} /> : <>{teamsObjectives && teamsObjectives.items && teamsObjectives.items.map((team, idx) => getTableRow(team, idx))}</>}
            </tbody>
            {/* <LoadingTableContent colSpan={tableHeads.length} /> */}
          </Table>
          {!isLoadingTeamsObjectives && teamsObjectives.items && teamsObjectives.items.length === 0 && (
            <div className="empty-content">
              {SVGS.documentIcon()}
              <div className="no-goals">You have not added any Team for this Objective</div>
              <div className="add-goal">Go back and Click on the “Add Team” button to get started</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyTeamObjectives;
