import React from "react";
import { useHistory } from "react-router-dom";
import ArrowRightIcon from "../../../assets/images/title-page-arrow.svg";
import "./titlePage.scss";

export const PageTitle = ({ url, title, showArrow, onClick, backArrowTitle }) => {
  const history = useHistory();
  return (
    <span className="title-page-container" data-testid="title-page-container" title={backArrowTitle || "Go Back"}>
      {showArrow && (
        <span data-testid="title-page-icon" onClick={onClick ? onClick : () => history.push(url)} className="go-back-nav">
          <img src={ArrowRightIcon} alt="arrow-right" />
        </span>
      )}
      <span className="sp-section-title" data-testid="section-title">
        {title}
      </span>
    </span>
  );
};

export default PageTitle;
