import React, { useState } from "react";
import PMSModal from "../../shared/pmsModal/pmsModal";
import Message from "./messages";

const WelcomeMessage = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <PMSModal
      title=""
      openModal={showModal}
      handlePrimaryButtonClick={() => {}}
      processing={false}
      primaryButtonText=""
      containerId="user-editor"
      closeModal={setShowModal}
      contentClassName="welcome-message-editor-content"
      showSecondaryButton={false}
      useHeaderBorder={false}
      disablePrimaryButton={false}
      closeOnOutsideClick={false}
      size="lg"
      showHeader={false}
      showFooter={false}
    >
      <Message setShowModal={setShowModal} />
    </PMSModal>
  );
};

export default WelcomeMessage;
