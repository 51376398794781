import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileIcon from "../../../assets/images/file.svg";
import DocumentIcon from "../../../assets/images/documents.svg";
import DownloadIcon from "../../../assets/images/download.svg";
import { getUserActivity } from "../../../redux/actions/userActions/userActivityActions";
import { LineLoader } from "../../shared";

import "./userActivityDetails.scss";

const ActivityDetails = ({ id, typeOfId }) => {
  const dispatch = useDispatch();
  const [activityData, setActivityData] = useState(null);

  const select = useSelector((state) => {
    return {
      userActivity: state.userActivity && state.userActivity.userActivity?.data?.data?.items,
      activityLoading: state.userActivity && state.userActivity.activityLoading,
    };
  });

  const { userActivity, activityLoading } = select;

  useEffect(() => {
    dispatch(getUserActivity(id, typeOfId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setActivityData(userActivity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivity]);

  const subtractDates = (date) => {
    let duration = "";
    let dateTwo = new Date();

    let diff = (dateTwo - Date.parse(date)) / 1000;
    diff = Math.abs(Math.floor(diff));
    let days = Math.floor(diff / (24 * 60 * 60));

    if (days && days === 0) {
      duration = "today";
    } else {
      days && days === 1 ? (duration = "1 day ago") : (duration = `${days} days ago`);
    }

    return duration;
  };

  return (
    <div className="activity-content-details">
      {activityLoading && <LineLoader lines={10} />}

      {activityData &&
        activityData.length > 0 &&
        activityData.map((activity, index) => {
          return (
            <div key={index} className="activity-items">
              <div className="desc">
                <span className="desc-text">
                  {activity.userFullName} made a check-in to <span className="activity-title">{activity.keyResult}</span>
                </span>
                <span className="desc-date">{subtractDates(activity.updatedAt)}</span>
              </div>
              <div className="percent-items">
                <span className="percent">Start: {activity?.initialGoal}%</span>
                <span className="percent">Goal: {activity.targetGoal}%</span>
                <span className="percent">
                  Update: <span className="update">{activity.goalProgress}%</span>
                </span>
              </div>
              {activity.comment && (
                <div className="comment-content">
                  <span className="comment">Comment:</span> {activity.comment}
                  <div className="file-content">
                    <div className="file-details">
                      <img src={FileIcon} alt="file icon" />
                      <div>
                        <div className="file-name">User Stories.pdf</div>
                        <div className="file-size">2.4mb</div>
                      </div>
                    </div>
                    <img src={DownloadIcon} alt="download icon" />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {!activityLoading && activityData?.length <= 0 && (
        <div className="empty-content">
          <img src={DocumentIcon} alt="empty goals" />
          <div className="no-goals">No activity for this key result</div>
        </div>
      )}
    </div>
  );
};

export default ActivityDetails;
