import React, { useState } from "react";
import ViewUsers from "./viewUsers/viewUsers";
import UserRoleEditorParent from "./userRoleEditorParent/userRoleEditorParent";

const Users = () => {
  const [editUserRole, setEditUserRole] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigateToViewUsers = () => {
    setEditUserRole(false);
  };

  const navigateToEditUserRoles = data => {
    setSelectedUser(data);
    setEditUserRole(true);
  };

  const onInviteuser = (formData, tabType) => {
    if (tabType === "single") {
      console.log(formData);
    }
  };

  return (
    <div className="users-container">
      {!editUserRole ? (
        <ViewUsers navigateToEditUserRoles={navigateToEditUserRoles} handleSumbitInvite={onInviteuser} />
      ) : (
        <UserRoleEditorParent navigateToViewUsers={navigateToViewUsers} selectedUser={selectedUser} />
      )}
    </div>
  );
};
export default Users;
